import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {Autocomplete, MenuItem, TextField,} from "@mui/material";
import {GridColDef} from "@mui/x-data-grid";
import {AxiosError} from "axios";
import React, {useEffect, useState} from "react";
import ExcelIcon from "../../../assets/Plataforma/excelIcon.svg";
import PourposeIcon from "../../../assets/Plataforma/pourposeIcon.svg";
import Datatable from "../../../components/Datatable";
import StatusCard from "../../../components/StatusCard";
import {EnumStatusLead, EnumStatusLeadColorMap, EnumStatusLeadNumericMap,} from "../../../enums/EnumStatusLead.enum";
import {useAuth} from "../../../hooks/useAuth";
import {ICaptadorListItem} from "../../../models/Captador";
import {IBackofficeListItem} from "../../../models/Colaborador/Colaborador";
import {ILead, ILeadsRelatoriosFilters,} from "../../../models/Lead";
import {ILeadsStats} from "../../../models/Stats";
import * as captadorServices from "../../../services/api/CaptadorService";
import * as colaboradorServices from "../../../services/api/ColaboradorService";
import * as services from "../../../services/api/LeadService";
import * as D from "../../../styles/appComponents";
import {moneyFormatter} from "../../../utils/moneyFormatter";
import * as C from "./style";
import {useNavigate} from "react-router-dom";
import SkeletonDefaultPage from "../../../components/SkeletonLoads/DefaultPage";
import NewLeadModal from "./Modais/NewLead";
import LeadModal from "./Modais/LeadModal";
import {toastMessage} from "../../../utils/toastMessage";
import {CELULAR} from "../../../utils/masInputs";
import {jwtDecode} from "jwt-decode";

enum leadsReportEnum {
  STATUS = "status",
  CAPTADORID = "captadorId",
  BACKOFFICEID = "backofficeId",
  DATAINICIO = "dataInicio",
  DATAFIM = "dataFim",
}

const Leads: React.FC = () => {
  //region Variaveis
  const {user} = useAuth();
  const [loading, setLoading] = useState<boolean>(true);
  const [opeLead, setOpeLead] = useState<boolean>(false);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [open, setOpen] = useState(false);
  const [openNewLead, setOpenNewLead] = useState(false);
  const [statusOpen, setStatusOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1024);
  const [leadsRows, setLeadsRows] = useState<ILead[]>([]);
  const [currentId, setCurrentId] = useState<string | undefined>(undefined);
  const [backoffices, setBackoffices] = useState<IBackofficeListItem[]>([]);
  const [captadores, setCaptadores] = useState<ICaptadorListItem[]>([]);
  const navigate = useNavigate();
  const [stats, setStats] = useState<ILeadsStats>({
    leads: 0,
    aguardandoEnvioConta: 0,
    contaAnexada: 0,
    propostaGerada: 0,
    cancelado: 0,
  });
  const [leadsReport, setLeadsReport] = useState<ILeadsRelatoriosFilters>({
    status: "",
    captadorId: "",
    backofficeId: "",
    dataInicio: "",
    dataFim: "",
  });
  const columns: GridColDef[] = [
    {
      field: "idReferencial",
      headerName: "Id",
      renderCell: (params) => <D.GridField>{params.value}</D.GridField>,
    },
    {
      field: "nome",
      headerName: "Nome",
      flex: 1,
      renderCell: (params) => <D.GridField>{params.value}</D.GridField>,
    },
    {
      field: "celular",
      headerName: "Celular",
      flex: 1,
      renderCell: (params) => <D.GridField>{new CELULAR().execute(params.value)}</D.GridField>,
    },
    {
      field: "nomeCaptador",
      headerName: "Parceiro",
      flex: 1,
      renderCell: (params) => <D.GridField>{params.value}</D.GridField>,
    },
    {
      field: "valorMedioConta",
      headerName: "Valor",
      flex: 1,
      renderCell: (params) => (
        <D.GridField>{moneyFormatter.format(Number(params.value))}</D.GridField>
      ),
    },
    {
      field: "createdAt",
      headerName: "Data",
      flex: 1,
      renderCell: (params) => (
        <D.GridField>
          {new Date(String(params.value)).toLocaleDateString()}
        </D.GridField>
      ),
    },
    {
      field: "status",
      headerName: "Status",
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <D.GridField $alignCenter>
          <D.Circle color={EnumStatusLeadColorMap[Number(params.value)]}/>
        </D.GridField>
      ),
    },
    {
      field: "proposta",
      headerName: "Gerar Proposta",
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        const jwt = jwtDecode(localStorage.getItem("token")!) as { captadorId?: string };
        return (
          <D.GridField
            $alignCenter
            onClick={() => jwt.captadorId ? navigate(`/Parceiro/Novo/Proposta/${params.id}`) : navigate(`/Colaborador/Novo/Proposta/${params.id}`)}
          >
            <D.GridImage src={PourposeIcon} alt="pourposeIcon"/>
          </D.GridField>
        );
      },
    },
    {
      field: "outras",
      headerName: "Outras",
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <D.GridField $alignCenter>
          <D.DefaultGridButton onClick={() => handleOpen(params.row.id)}>
            Ver mais
          </D.DefaultGridButton>
        </D.GridField>
      ),
    },
  ];  //endregion

  //region Services
  const getBackoffices: () => void = () => {
    colaboradorServices
      .GetBackoffices()
      .then((response) => {
        setBackoffices(response.data);
      })
      .catch((e: AxiosError) => {
        toastMessage("error", e.response
          ? String(e.response?.data)
          : "Houve um erro ao pegar os backoffices.");
      });
  };

  const getCaptadores: () => void = () => {
    captadorServices
      .GetCaptadores()
      .then((response) => {
        setCaptadores(response.data);
      })
      .catch((e: AxiosError) => {
        toastMessage("error", e.response
          ? String(e.response?.data)
          : "Houve um erro ao pegar os captadores.");
      });
  };

  const geraRelatorio: () => void = () => {
    services
      .GeraRelatorio(leadsReport)
      .then(async (response) => {
        const blob = response.data;

        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = `leads_${new Date()
          .toISOString()
          .slice(0, 19)
          .replace(/[-:T]/g, "")}.xlsx`;
        link.click();

        URL.revokeObjectURL(link.href);
      })
      .catch((e: AxiosError) => {
        toastMessage("error", e.response
          ? String(e.response?.data)
          : "Houve um erro ao gerar o relatório.");
      });
  };

  const getLeadsStats: () => Promise<void> = async () => {
    await services
      .GetLeadsStats()
      .then((response: { data: React.SetStateAction<ILeadsStats> }) => {
        setStats(response.data);
      })
      .catch((e: AxiosError) => {
        toastMessage("error", e.response
          ? String(e.response?.data)
          : "Houve um erro ao pegar os dados.");
      })
  };

  const getLeads: () => Promise<void> = async () => {
    await getLeadsStats();

    await services
      .GetLeads(page, pageSize, search)
      .then((response) => {
        setLeadsRows(response.data.data);
        setTotalPages(response.data.totalPages);
        setLoading(false);
      })
      .catch((e: AxiosError) => {
        toastMessage("error", e.response
          ? String(e.response?.data)
          : "Houve um erro ao pegar os dados.");
      });
  };
  //endregion

  //region UI
  const handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const name: string = event.target.name;
    const value: string = event.target.value;

    setLeadsReport((state: ILeadsRelatoriosFilters) => {
      const updatedState = {
        ...state,
        [name]: value,
      };
      return updatedState;
    });
  }

  const handleStatusOpen: () => void = () => {
    setStatusOpen(!statusOpen);
  };

  const handleChangePage: (value: number) => void = (value: number) => {
    setPage(value);
  };

  const handleChangePageSize: (value: number) => void = (value: number) => {
    setPageSize(value);
  };

  const handleOpen: (id: string) => Promise<void> = async (id: string) => {
    setCurrentId(id);
    setOpeLead(true);
  };

  const handleSearch: (value: string) => Promise<void> = async (value: string) => {
    setPage(1);
    setSearch(value);
  };

  const handleOpenNewLead: () => Promise<void> = async () => {
    setOpenNewLead(true);
  };

  const callbackNewLeadModal: () => void = () => {
    setOpenNewLead(false);
    getLeads();
  }

  const callbackLeadModal: () => void = () => {
    setOpeLead(false);
    setCurrentId(undefined);
    getLeads();
  }

  useEffect(() => {
    getLeads();
  }, [page, pageSize, search, open]);

  useEffect(() => {
    getBackoffices();
    getCaptadores();
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1024);
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);
  //endregion

  return (
    <>
      {loading ? (
        <SkeletonDefaultPage/>
      ) : (
        <C.Container>
          <C.Title onClick={() => handleStatusOpen()}>
            Status
            {isMobile && (
              <>
                {!statusOpen ? (
                  <KeyboardArrowDownRoundedIcon/>
                ) : (
                  <KeyboardArrowUpIcon/>
                )}
              </>
            )}
          </C.Title>

          {(!isMobile || (statusOpen && isMobile)) && (
            <C.StatusArea>
              <C.StatusWrapper>
                <StatusCard color="blue" text="Leads" number={stats.leads}/>
              </C.StatusWrapper>
              <C.StatusWrapper>
                <StatusCard
                  color="yellow"
                  text="Aguardando envio de conta"
                  number={stats.aguardandoEnvioConta}
                />
              </C.StatusWrapper>
              <C.StatusWrapper>
                <StatusCard
                  color="green"
                  text="Conta Anexada"
                  number={stats.contaAnexada}
                />
              </C.StatusWrapper>
              <C.StatusWrapper>
                <StatusCard
                  color="purple"
                  text="Proposta Gerada"
                  number={stats.propostaGerada}
                />
              </C.StatusWrapper>
              <C.StatusWrapper>
                <StatusCard
                  color="red"
                  text="Cancelado"
                  number={stats.cancelado}
                />
              </C.StatusWrapper>
            </C.StatusArea>
          )}
          <D.DataArea $align="right">
            <D.ContainedButton
              startIcon={<AddCircleOutlineOutlinedIcon/>}
              onClick={handleOpenNewLead}
            >
              Nova lead
            </D.ContainedButton>
            <Datatable
              columns={columns}
              rows={leadsRows}
              pageNumber={page}
              pageSize={pageSize}
              onChangePage={(e) => handleChangePage(e)}
              onChangePageSize={(e) => handleChangePageSize(e)}
              totalPages={totalPages}
              onSearch={handleSearch}
            />
            <C.LineBreak/>
            <C.ReportsArea>
              <C.SelectArea>
                <C.ReportsLabel>Status de leads</C.ReportsLabel>
                <C.ReportsSelect
                  displayEmpty
                  inputProps={{"aria-label": "Without label"}}
                  defaultValue={""}
                  onChange={(e) => handleInputChange({
                    target: {
                      name: leadsReportEnum.STATUS,
                      value: e.target.value as string
                    }
                  } as unknown as unknown as React.ChangeEvent<HTMLInputElement>)}
                >
                  <MenuItem value="">
                    <C.DefaultColorText>Todos os status</C.DefaultColorText>
                  </MenuItem>
                  {Object.keys(EnumStatusLead).map((key, index) => (
                    <MenuItem
                      key={key}
                      value={
                        EnumStatusLeadNumericMap[
                          EnumStatusLead[key as keyof typeof EnumStatusLead]
                          ]
                      }
                    >
                      {EnumStatusLead[key as keyof typeof EnumStatusLead]}
                    </MenuItem>
                  ))}
                </C.ReportsSelect>
              </C.SelectArea>
              <C.SelectArea>
                <C.ReportsLabel>Selecione um captador</C.ReportsLabel>
                <Autocomplete
                  options={captadores}
                  fullWidth
                  noOptionsText="Nenhum captador encontrado"
                  getOptionLabel={(option) => option.nome}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Todos os captadores"
                      inputProps={{
                        ...params.inputProps,
                        "aria-label": "Without label",
                      }}
                    />
                  )}
                  defaultValue={null}
                  onChange={(event, newValue) => {
                    handleInputChange({
                      target: {
                        name: leadsReportEnum.CAPTADORID,
                        value: newValue?.id as string
                      }
                    } as unknown as unknown as React.ChangeEvent<HTMLInputElement>)
                  }}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                />
              </C.SelectArea>
              <C.SelectArea>
                <C.ReportsLabel>Selecione o backoffice</C.ReportsLabel>
                <Autocomplete
                  options={backoffices}
                  fullWidth
                  noOptionsText="Nenhum backoffice encontrado"
                  getOptionLabel={(option) => option.nome}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Todos os backoffices"
                      inputProps={{
                        ...params.inputProps,
                        "aria-label": "Without label",
                      }}
                    />
                  )}
                  defaultValue={null}
                  onChange={(event, newValue) => {
                    handleInputChange({
                      target: {
                        name: leadsReportEnum.BACKOFFICEID,
                        value: newValue?.id as string
                      }
                    } as unknown as unknown as React.ChangeEvent<HTMLInputElement>)
                  }}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                />
              </C.SelectArea>
              <C.SelectArea>
                <C.ReportsLabel>Data da ultima movimentação</C.ReportsLabel>
                <D.DateTextfield
                  type="date"
                  label="Data início"
                  fullWidth
                  value={leadsReport.dataInicio}
                  onChange={(e) => handleInputChange({
                    target: {
                      name: leadsReportEnum.DATAINICIO,
                      value: e.target.value as string
                    }
                  } as unknown as unknown as React.ChangeEvent<HTMLInputElement>)}
                />
              </C.SelectArea>
              <C.SelectArea>
                <C.ReportsLabel>Data da ultima movimentação</C.ReportsLabel>
                <D.DateTextfield
                  type="date"
                  label="Data fim"
                  fullWidth
                  value={leadsReport.dataFim}
                  onChange={(e) => handleInputChange({
                    target: {
                      name: leadsReportEnum.DATAFIM,
                      value: e.target.value as string
                    }
                  } as unknown as unknown as React.ChangeEvent<HTMLInputElement>)}
                />
              </C.SelectArea>
            </C.ReportsArea>
            <C.ExportButton
              onClick={geraRelatorio}
              variant="contained"
              startIcon={
                <img
                  style={{width: "20px", color: "white", fill: "white"}}
                  src={ExcelIcon}
                  alt="excelIcon"
                />
              }
            >
              Gerar relatório
            </C.ExportButton>
          </D.DataArea>
        </C.Container>
      )}
      <NewLeadModal
        openNewLead={openNewLead}
        callback={callbackNewLeadModal}
      />
      <LeadModal
        openNewLead={opeLead}
        callback={callbackLeadModal}
        id={currentId}
      />
    </>
  );
};

export default Leads;
