import { useMemo, useState } from "react";
import ReadonlyArray from "../types/ReadonlyArray";

interface IUseArrayMethods<T, K extends keyof T> {
  setValores: React.Dispatch<React.SetStateAction<ReadonlyArray<T>>>;
  updateValor: (index: number, novoValor: T) => void;
  updateParcialValor: (index: number, valor: Partial<T>) => void;
  updateChaveValor: (index: number, chave: K, valor: T[K]) => void;
  push: (elemento: T) => void;
  removeIndex: (index: number) => void;
}

const useArray = <T, K extends keyof T = keyof T>(
  vetorInicial: ReadonlyArray<T> = []
): [ReadonlyArray<T>, IUseArrayMethods<T, K>] => {
  const [valores, setValores] = useState<ReadonlyArray<T>>(vetorInicial);

  const metodos = useMemo(() => {
    const updateValor = (index: number, novoValor: T): void => {
      setValores((v) => {
        const novosValores = [...v];
        novosValores[index] = novoValor;
        return novosValores;
      });
    };

    const updateParcialValor = (index: number, valor: Partial<T>): void => {
      setValores((v) => {
        const novosValores = [...v];
        novosValores[index] = { ...novosValores[index], ...valor };
        return novosValores;
      });
    };

    const updateChaveValor = (
      index: number,
      chave: keyof T,
      valor: T[keyof T]
    ): void => {
      setValores((v) => {
        const novosValores = [...v];
        novosValores[index] = { ...novosValores[index], [chave]: valor };
        return novosValores;
      });
    };

    const push = (elemento: T): void => {
      setValores((v) => [...v, elemento]);
    };

    const removeIndex = (index: number): void => {
      setValores((v) => v.filter((_, i) => i !== index));
    };

    return {
      setValores,
      updateValor,
      updateParcialValor,
      updateChaveValor,
      push,
      removeIndex,
    };
  }, []);

  return [valores, metodos];
};

export default useArray;
