export class HandleObjects{
    static Copy(dataUpdated:any, datToUpdated:any){

        Object.entries(dataUpdated).forEach(([key, value]) => {
            if (key in datToUpdated && value !== undefined && value !== null) {
                    (datToUpdated  as Record<string, any>)[key] = value;
            }
        });

    }

    static VerifyIfExistPropertities<T>(data:T, keys:(keyof T)[]){

        return !keys.some((key) => data?.[key] === null)

    }
}
