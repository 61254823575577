export enum EnumContribuicaoSindical {
    pagou = 0,
    naoPagou = 1,
    isento = 2,
}

export const enumContribuicaoSindicalMap = [
    {
        name: "Pagou",
        value: EnumContribuicaoSindical.pagou
    },
    {
        name: "Não pagou",
        value: EnumContribuicaoSindical.naoPagou
    },
    {
        name: "Isento",
        value: EnumContribuicaoSindical.isento
    }
];
  