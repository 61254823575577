import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import SearchIcon from "@mui/icons-material/Search";
import {Box, InputAdornment, Stack} from "@mui/material";
import {GridColDef} from "@mui/x-data-grid";
import {ptBR} from "@mui/x-data-grid/locales";
import React, {Dispatch, SetStateAction, useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import * as D from "../../../../styles/appComponents";
import * as C from "../../Colaborador/Gerador/style";
import TotalCard from "../../../../components/TotalCard";
import {AxiosResponse} from "axios";
import {
  GetContas,
  GetStatusConta,
  SetConta,
  UpdateConta,
} from "../../../../services/api/ContaService";
import {toastMessage} from "../../../../utils/toastMessage";
import InserirNovoPagamento from "../Components/InserirNovoPagamento";
import {moneyFormatter} from "../../../../utils/moneyFormatter";
import {EnumMeses} from "../../../../enums/EnumMeses";
import comprovante from "../../../../assets/Icones/comprovante.svg";
import {GetUrl} from "../../../../services/api/AnexoService";
import {
  INovoPagamento,
  IStatusOrdemPagamento,
} from "../../../../models/OrdemPagamento";
import {IContaOrdemPagamento} from "../../../../models/Conta";

const Account: React.FC = () => {
  //region Variáveis
  const {contaId} = useParams();
  const [page]: (number | Dispatch<SetStateAction<number>>)[] = useState(1);
  const [pageNumber] = useState(1);
  const [statusOpen, setStatusOpen] = useState(false);
  const [currentConta, setCurrentConta] = useState<string | undefined>(undefined);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1024);
  const [isVisibleList, setVisibleList] = useState<boolean>(true);
  const [statusOrdemPagamento, setStatusOrdemPagamento] =
    useState<IStatusOrdemPagamento>({
      valorTotal: 0,
      valorTotalKwh: 0,
      valorTotalQuitado: 0,
      valorTotalKwhQuitado: 0,
      valorTotalAPagar: 0,
      valorTotalKwhAPagar: 0,
    });
  const [contaOrdemPagamento, setContaOrdemPagamento] =
    useState<IContaOrdemPagamento>({
      id: null,
      idReferencial: null,
      createdAt: null,
      updatedAt: null,
      dataReferencia: null,
      instalacao: null,
      sigla: null,
      kwhInjetado: null,
      descontoTarifa: null,
      valorBase: null,
      valorCredito: null,
      saldo: null,
      energiaDistribuidora: null,
      energiaGerador: null,
      diferenca: null,
      tarifaBase: null,
      tarifaDistribuidora: null,
      tipoConexao: null,
      dataLimiteQuitacao: null,
      status: null,
      visualizacao: true,
      active: true,
      contas: [],
      usina: {
        id: null,
        idReferencial: null,
        createdAt: null,
        updatedAt: null,
        nomeUsina: null,
        cpf: null,
        instalacao: null,
        sigla: null,
      },
    });
  const [novoPagamento, setNovoPagamento] = useState<INovoPagamento>({
    data: null,
    tipoPagamento: null,
    valor: null,
    energiaTotalGd: null,
    assunto: null,
    descricao: null,
    comprovante: null,
    ordemPagamentoId: null,
    comprovanteNome: null,
    cliente: null,
    comprovanteId: null
  });
  const columns: GridColDef[] = [
    {
      field: "idReferencial",
      headerName: "ID",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "data",
      headerName: "Data",
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params: any) => {
        return (
          <D.GridField $alignCenter>
            {new Date(params.value.slice(0, 10)).toLocaleDateString()}
          </D.GridField>
        )
      },
    },
    {
      field: "descricao",
      headerName: "Descrição",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "valor",
      headerName: "Valor",
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params: any) => (
        <D.GridField $alignCenter>
          {moneyFormatter.format(params.value)}
        </D.GridField>
      ),
    },
    {
      field: "comprovanteId",
      headerName: "Comprovante",
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params: any) => (
        <D.GridField $alignCenter>
          <D.GridImage
            onClick={() => {
              if (![undefined, null].includes(params.value)) {
                findImageByComprovanteId(params.value);
              }
            }}
            $disable={[undefined, null].includes(params.value)}
            src={comprovante}
            alt="comprovante"
          />
        </D.GridField>
      ),
    },
    {
      field: "outras",
      headerName: "Outras",
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params: any) => (
        <D.DefaultGridButton onClick={() => handleEditButton(params.row.id)}>
          Editar
        </D.DefaultGridButton>
      ),
    },
  ];
  //endregion

  //region Services
  const getStatus: (id: string) => Promise<void> = async (id: string) => {
    try {
      const response: AxiosResponse<IStatusOrdemPagamento, any> =
        await GetStatusConta(id);

      setStatusOrdemPagamento({...response.data});
    } catch (e: unknown) {
      toastMessage("error", "Erro ao tentarmos pegar os status");
    }
  };

  const getContas: (id: string) => Promise<void> = async (id: string) => {
    try {
      const response: AxiosResponse<IContaOrdemPagamento, any> =
        await GetContas(id, 10, pageNumber);

      setContaOrdemPagamento(response.data);
    } catch (e: unknown) {
      toastMessage("error", "Erro ao tentarmos pegar as contas");
    }
  };

  const onSaveNovoPagamento: () => Promise<void> = async () => {
    try {
      const response:
        | AxiosResponse<INovoPagamento, any>
        | Promise<AxiosResponse<INovoPagamento, any>> = !currentConta
        ? await SetConta(novoPagamento)
        : UpdateConta(novoPagamento);

      if (response) {
        toastMessage(
          "success",
          !currentConta
            ? "Conta inserida com sucesso"
            : "Conta alterada com sucesso"
        );

        if(!currentConta) {
          toggleTableVisibility()
        }else
          setCurrentConta(currentConta);

        if (typeof contaId === "string") {
          setTimeout(() => {
            Promise.all([getStatus(contaId), getContas(contaId)]);
          }, 500);
        }
      }
    } catch (e: unknown) {
      toastMessage(
        "error",
        !currentConta
          ? "Erro após inserir a conta"
          : "Erro após atualizar a conta"
      );
    }
  };

  const findImageByComprovanteId: (id: string) => Promise<void> = async (
    id: string
  ) => {
    try {
      const response: AxiosResponse<string, any> = await GetUrl(id);

      window.open(response.data);
    } catch (e: unknown) {
      toastMessage("error", "Erro ao tentarmos pegar o comprovante");
    }
  };
  //endregion

  //region UI
  const onChangeNovoPagamento: (novoPagamento: INovoPagamento) => void = (
    novoPagamento: INovoPagamento
  ) => {
    setNovoPagamento({...novoPagamento});
  };

  const handleStatusOpen: () => void = () => {
    setStatusOpen(!statusOpen);
  };

  const toggleTableVisibility: () => void = () => {
    setVisibleList(!isVisibleList);

    if (!isVisibleList) {
      setCurrentConta(undefined);
    }
  };

  const handleEditButton: (id: string) => void = (id: string) => {
    setCurrentConta(id);
    toggleTableVisibility();
  };

  useEffect(() => {
    const handleResize: () => void = () => {
      setIsMobile(window.innerWidth < 1024);
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (typeof contaId === "string") {
      Promise.all([getStatus(contaId), getContas(contaId)]);
    }
  }, [contaId]);
  //endregion

  return (
    <>
      <C.Container>
        <C.Title onClick={() => handleStatusOpen()}>
          Status
          {isMobile && (
            <>
              {!statusOpen ? (
                <KeyboardArrowDownRoundedIcon/>
              ) : (
                <KeyboardArrowUpIcon/>
              )}
            </>
          )}
        </C.Title>

        {(!isMobile || (statusOpen && isMobile)) && (
          <C.StatusArea>
            <C.StatusWrapper>
              <TotalCard
                color="blue"
                title="Valor total"
                text={moneyFormatter.format(statusOrdemPagamento.valorTotal)}
                evolutionNumber="-8%"
                evolution={false}
              />
              <TotalCard
                color="blue"
                title="Valor total de kwh"
                text={moneyFormatter.format(statusOrdemPagamento.valorTotalKwh)}
                evolutionNumber="-8%"
                evolution={false}
              />
            </C.StatusWrapper>
            <C.StatusWrapper>
              <TotalCard
                color="green"
                title="Valor quitado"
                text={moneyFormatter.format(
                  statusOrdemPagamento.valorTotalQuitado
                )}
                evolutionNumber="-8%"
                evolution={false}
              />
              <TotalCard
                color="green"
                title="Energia quitada"
                text={moneyFormatter.format(
                  statusOrdemPagamento.valorTotalKwhQuitado
                )}
                evolutionNumber="-8%"
                evolution={false}
              />
            </C.StatusWrapper>
            <C.StatusWrapper>
              <TotalCard
                color="yellow"
                title="A pagar"
                text={moneyFormatter.format(
                  statusOrdemPagamento.valorTotalAPagar
                )}
                evolutionNumber="-8%"
                evolution={false}
              />
              <TotalCard
                color="yellow"
                title="Energia a pagar"
                text={moneyFormatter.format(
                  statusOrdemPagamento.valorTotalKwhAPagar
                )}
                evolutionNumber="-8%"
                evolution={false}
              />
            </C.StatusWrapper>
          </C.StatusArea>
        )}
        {isVisibleList ? (
          <>
            <D.DataArea $align="right">
              <Box
                sx={{
                  width: "100%",
                  flexDirection: "row",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Stack
                  sx={{
                    width: "50%",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                  }}
                >
                  <Stack sx={{width: "100%"}}>
                    <Stack sx={{fontWeight: "bold"}}>ID</Stack>
                    <Stack>{contaOrdemPagamento.idReferencial}</Stack>
                  </Stack>
                  <Stack sx={{width: "100%"}}>
                    <Stack sx={{fontWeight: "bold"}}>Referente</Stack>
                    <Stack>
                      {" "}
                      {`${
                        EnumMeses[
                          contaOrdemPagamento.dataReferencia
                            ? new Date(
                              contaOrdemPagamento.dataReferencia
                            ).getMonth()
                            : 0
                          ] ?? "Mês"
                      }/${
                        contaOrdemPagamento.dataReferencia
                          ? new Date(
                            contaOrdemPagamento.dataReferencia
                          ).getFullYear()
                          : "Ano"
                      }`}
                    </Stack>
                  </Stack>
                  <Stack sx={{width: "100%"}}>
                    <Stack sx={{fontWeight: "bold"}}>Número</Stack>
                    <Stack>{contaOrdemPagamento.instalacao}</Stack>
                  </Stack>
                  <Stack sx={{width: "100%"}}>
                    <Stack sx={{fontWeight: "bold"}}>Usina</Stack>
                    <Stack sx={{whiteSpace: "nowrap"}}>
                      {contaOrdemPagamento.usina.nomeUsina}
                    </Stack>
                  </Stack>
                </Stack>
                <Stack
                  sx={{
                    width: "50%",
                    flexDirection: "row",
                    justifyContent: "flex-end",
                  }}
                >
                  <D.ContainedButton
                    startIcon={<AddCircleOutlineIcon/>}
                    onClick={toggleTableVisibility}
                  >
                    Novo Pagamento
                  </D.ContainedButton>
                  <D.DefaultSearchInput
                    size="small"
                    variant="outlined"
                    onChange={() => null}
                    placeholder="Pesquisar"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon/>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Stack>
              </Box>
              <D.DefaultTable
                disableColumnResize
                disableRowSelectionOnClick
                localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
                rows={contaOrdemPagamento.contas}
                columns={columns}
                hideFooter
                disableColumnFilter
                disableColumnSelector
                disableColumnMenu
              />
              <D.DefaultPagination
                page={page}
                count={pageNumber}
                size={"medium"}
                siblingCount={1}
                showFirstButton
                showLastButton
                color="primary"
              />
              <C.LineBreak/>
            </D.DataArea>
          </>
        ) : (
          <>
            <InserirNovoPagamento
              callbackExit={toggleTableVisibility}
              onSave={onSaveNovoPagamento}
              onChange={onChangeNovoPagamento}
              fieldErros={null}
              id={currentConta}
              ordemPagamento={contaOrdemPagamento}
            />
          </>
        )}
      </C.Container>
    </>
  );
};

export default Account;
