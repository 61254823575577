export const CPFTransform = (value: string) => {
    if(value){
        return value
        ?.replace(/\D/g, "")
        ?.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4")
        ?.slice(0, 14);
    }else return "";

}

export const  TelefoneFormatter = (telefone: string): string => {
    telefone = telefone?.replace(/\D/g, "");
    
    if (telefone?.startsWith("55")) {
      telefone = telefone?.slice(2);
    } else if (telefone?.length > 11) {
      telefone = telefone?.slice(2);
    }

    if (telefone?.length < 10 || telefone?.length > 11) {
      throw new Error("Número de telefone inválido.");
    }

    return telefone?.length === 11
      ? telefone?.replace(/(\d{2})(\d{5})(\d{4})/, "($1) $2-$3")
      : telefone?.replace(/(\d{2})(\d{4})(\d{4})/, "($1) $2-$3");
  }