export const formDataTransform = (model: any, formData:FormData): void => {

    Object.entries(model).forEach(([key, value]) => {
        if (Array.isArray(value)) {
            (value || []).forEach((item: any, index: number) => {
                formData.append(`${key}[${index}]`, JSON.stringify(item));
            });

        } else if(value instanceof Date) {
            formData.append(key, value?.toISOString() || "");

        } else if(value instanceof File) {
            formData.append(key, value || "");
            
        } else {
            formData.append(key, value?.toString() || "");
        }
    });

};
