import React, {Dispatch, SetStateAction, useEffect, useState} from 'react';
import SkeletonDefaultPage from "../../../../components/SkeletonLoads/DefaultPage";
import * as C from "../Gerador/style";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import StatusCard from "../../../../components/StatusCard";
import * as D from "../../../../styles/appComponents";
import Datatable from "../../../../components/Datatable";
import ExcelIcon from "../../../../assets/Plataforma/excelIcon.svg";
import {GridColDef, GridRenderCellParams} from "@mui/x-data-grid";
import {AxiosResponse} from "axios";
import {IPaginatedList} from "../../../../models/PaginatedList";
import {toastMessage} from "../../../../utils/toastMessage";
import {
  GetDashboardOperadora,
  GetOperadoraReport,
  GetPaginatedOperadora
} from "../../../../services/api/OperadoraService";
import ModalDistribuidora from "./ Modals";
import {ColorType} from "../../../../types/ColorType";
import {IOperadora, IOperadoraDashboard} from "../../../../models/Operadora";

function Operadora() {
  //region Variáveis
  const [loading, setLoading]: (boolean | Dispatch<SetStateAction<boolean>>)[] = useState<boolean>(true);
  const [id, setId]: [string | undefined, (value: (((prevState: (string | undefined)) => (string | undefined)) | string | undefined)) => void] = useState<string | undefined>(undefined);
  const [modalOpen, setModalOpen]: (boolean | Dispatch<SetStateAction<boolean>>)[] = useState<boolean>(false);
  const [operadora, setOperadora] = useState<IOperadora[]>([]);
  const [isMobile, setIsMobile]: (boolean | Dispatch<SetStateAction<boolean>>)[] = useState(window.innerWidth < 1024);
  const [page, setPage]: (number | Dispatch<SetStateAction<number>>)[] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [statusOpen] = useState(false);
  const [dashboardOperadora, setdashboardOperadora] = useState<IOperadoraDashboard>({
    ativos: 0,
    inativos: 0,
  });
  const columns: GridColDef[] = [
    {
      field: "idReferencial",
      headerName: "ID",
      flex: 1,
      align: "center",
      renderCell: (params: GridRenderCellParams<any, any, any>) => (
        <D.GridField $alignCenter={true}>
          {params.value}
        </D.GridField>
      )
    },
    {
      field: "nome",
      headerName: "Nome operadora",
      flex: 1,
      minWidth: 100,
      align: "center",
    },
    {
      field: "apelido",
      headerName: "Apelido",
      flex: 1,
      minWidth: 100,
      align: "center",
    },
    {
      field: "cnpj",
      headerName: "CNPJ",
      flex: 1,
      minWidth: 100,
      align: "center",
      renderCell: (params: GridRenderCellParams<any, any, any>) => (
        <D.GridField $alignCenter={true}>
          {params.value}
        </D.GridField>
      )
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      align: "center",
      minWidth: 110,
      renderCell: (params: GridRenderCellParams<any, any, any>) => {
        const type: ColorType = params.value == 1 ? "green" : "red";
        return (
          <D.GridField $alignCenter={true}>
            <D.Circle color={type}/>
          </D.GridField>
        )
      }
    },
    {
      field: "outras",
      headerName: "Outras",
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params: GridRenderCellParams<any, any, any>) => (
        <D.GridField $alignCenter>
          <D.DefaultGridButton onClick={() => {
            handleOpen(params.row.id)
          }}>
            Ver mais
          </D.DefaultGridButton>
        </D.GridField>
      ),
    },
  ];
  //endregion

  //region Services
  const getOperadoras: (queryString?: string) => Promise<void> = async (queryString?: string): Promise<void> => {
    try {
      const {data}: AxiosResponse<IPaginatedList<IOperadora>, any> = await GetPaginatedOperadora(page, pageSize, queryString);
      setTotalPages(data?.totalPages);
      setOperadora(data?.data);
    } catch (e: unknown) {
      toastMessage("error", "Erro ao listar as operadoras");
    } finally {
      setLoading(false);
    }
  }

  const getdashboardOperadora: () => Promise<void> = async (): Promise<void> => {
    try {
      const {data}: AxiosResponse<any, IOperadoraDashboard> = await GetDashboardOperadora();
      setdashboardOperadora({...data});
    } catch (e: unknown) {
      toastMessage("error", "Erro ao listar o dashboard");
    }
  }

  const geraRelatorio: () => Promise<void> = async () => {
    try {
      const response: AxiosResponse<File, any> = await GetOperadoraReport();

      const blob: File = response.data;

      const link: HTMLAnchorElement = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = `operadora_${new Date()
        .toISOString()
        .slice(0, 19)
        .replace(/[-:T]/g, "")}.xlsx`;
      link.click();

      URL.revokeObjectURL(link.href);
    } catch (e) {
      toastMessage("error", "Erro ao tentar criar o relatório.");
    }
  }
  //endregion

  //region UI

  const handleModalClose = () => {
    setModalOpen(!modalOpen);
    setId(undefined);
  }

  const handleOpen: (id: string) => void = (id: string) => {
    setId(id);
    setModalOpen(!modalOpen);
  }

  const handleChangePage: (value: number) => void = (value: number): void => {
    setPage(value);
  };

  const handleChangePageSize: (value: number) => void = (value: number): void => {
    setPageSize(value);
  };

  const handleSearch: (value: string) => Promise<void> = async (value: string): Promise<void> => {
    getOperadoras(value);
  };

  useEffect((): () => void => {
    const handleResize: () => void = (): void => {
      setIsMobile(window.innerWidth < 1024);
    };

    window.addEventListener("resize", handleResize);
    Promise.all([getOperadoras(), getdashboardOperadora()] as Promise<void>[]);
    return (): void => window.removeEventListener("resize", handleResize);
  }, []);

  //endregion

  return (
    <>
      {
        loading ? (<SkeletonDefaultPage/>) : (
          <>
            <C.Container>
              <C.Title>
                Status
                {isMobile && (
                  <>
                    {!statusOpen ? (
                      <KeyboardArrowDownRoundedIcon/>
                    ) : (
                      <KeyboardArrowUpIcon/>
                    )}
                  </>
                )}
              </C.Title>

              {(!isMobile || (statusOpen && isMobile)) && (
                <C.StatusArea>
                  <C.StatusWrapperOrdemPagamento>
                    <StatusCard
                      color="green"
                      text="Ativos"
                      number={dashboardOperadora.ativos ?? "0"}
                    />
                  </C.StatusWrapperOrdemPagamento>
                  <C.StatusWrapperOrdemPagamento>
                    <StatusCard color="red" text="Inativa"
                                number={dashboardOperadora.inativos ?? "0"}/>
                  </C.StatusWrapperOrdemPagamento>
                </C.StatusArea>
              )}

              <D.DataArea $align="right">
                <Datatable
                  handleButton={handleModalClose}
                  hasButton={true}
                  titleButton="Inserir operadora"
                  columns={columns}
                  rows={operadora}
                  pageNumber={page}
                  pageSize={pageSize}
                  onChangePage={handleChangePage}
                  onChangePageSize={handleChangePageSize}
                  totalPages={totalPages}
                  onSearch={handleSearch}
                />
                <C.LineBreak/>
                <C.ExportButton
                  onClick={geraRelatorio}
                  variant="contained"
                  startIcon={
                    <img
                      style={{width: "20px", color: "white", fill: "white"}}
                      src={ExcelIcon}
                      alt="excelIcon"
                    />
                  }
                >
                  Gerar relatório
                </C.ExportButton>
              </D.DataArea>
            </C.Container>

            <ModalDistribuidora
              callback={() => {
                getdashboardOperadora();
                getOperadoras();
              }}
              onClose={handleModalClose}
              modalOpen={modalOpen}
              id={id}
            />
          </>
        )
      }
    </>
  );
}

export default Operadora;