import React, {useEffect, useState} from 'react';
import {CircularProgress, Modal} from "@mui/material";
import * as C from "../../style";
import CloseIcon from "@mui/icons-material/Close";
import CircleCheckbox from "../../../../../components/CircleCheckBox";
import {EnumStatusLead, EnumStatusLeadNumericMap} from "../../../../../enums/EnumStatusLead.enum";
import HistoricoCard from "../../../../../components/HistoricoCard";
import {ICreateLog, ILog} from "../../../../../models/Log";
import * as logServices from "../../../../../services/api/LogService";
import {AxiosError} from "axios";
import {IFullLead, IUpdateLead} from "../../../../../models/Lead";
import * as services from "../../../../../services/api/LeadService";
import {useNavigate} from "react-router-dom";
import {toastMessage} from "../../../../../utils/toastMessage";
import MaskDefaultInput from "../../../../../components/MasDefaultinput/MaskDefaultInput";

type LeadModalProps = {
  id: string | undefined;
  openNewLead: boolean;
  callback?: () => void;
  onClose?: VoidFunction;
}

enum LeadModalEnum {
  ID = "id",
  IDREFERENCIAL = "idReferencial",
  CELULAR = "celular",
  EMAIL = "email",
  NOME = "nome",
  NOMEBACKOFFICE = "nomeBackoffice",
  NOMECAPTADOR = "nomeCaptador",
  STATUS = "status",
  VALORMEDIOCONTA = "valorMedioConta",
  CONTA = "conta",
}

const LeadModal: (props: LeadModalProps) => React.JSX.Element = (props: LeadModalProps): React.JSX.Element => {
  //region Variáveis
  let {openNewLead, callback, onClose, id} = props;
  const navigate = useNavigate();
  const [loadingSaveEdit, setLoadingSaveEdit] = useState(false);
  const [editar, setEditar] = useState<boolean>(false);
  const [selectedId, setSelectedId] = useState<number | null>(null);
  const [historicoLeads, setHistoricoLeads] = useState<ILog[]>([]);
  const [historicoAssunto, setHistoricoAssunto] = useState<string>("");
  const [historicoDescricao, setHistoricoDescricao] = useState<string>("");
  const [historicoAnexo, setHistoricoAnexo] = useState<File | null>(null);
  const [leadModal, setLeadModal] = useState<IFullLead>({
    id: "",
    idReferencial: "",
    celular: "",
    email: "",
    nome: "",
    nomeBackoffice: "",
    nomeCaptador: "",
    status: EnumStatusLead.leads,
    valorMedioConta: "",
    conta: null,
  });
  //endregion

  //region Services
  const getLeadModalData: (id: string) => Promise<void> = async (id: string) => {
    await services
      .GetLeadById(id)
      .then((response) => {
        setLeadModal(response.data);
        setSelectedId(response.data.status as unknown as number);
      })
      .catch((e: AxiosError) => {
        toastMessage("error", e.response
          ? String(e.response?.data)
          : "Houve um erro ao pegar os dados.");
      });

    await getLogsByLeadId(id);
  };

  const getLogsByLeadId: (id: string) => Promise<void> = async (id: string) => {
    await logServices
      .GetLogsByItemId(id)
      .then((response) => {
        setHistoricoLeads(response.data);
      })
      .catch((e: AxiosError) => {
        toastMessage("error", e.response
          ? String(e.response?.data)
          : "Houve um erro ao pegar os dados.");
      });
  };

  const createNewLog: () => Promise<void> = async () => {
    if (historicoAssunto == "") {
      toastMessage("warning", "Assunto não pode ser vazio.");
      return;
    }

    const historicoData: ICreateLog = {
      gdLeadId: leadModal.id,
      gdPropostaId: null,
      gdContratoId: null,
      assunto: historicoAssunto,
      descricao: historicoDescricao,
      anexo: historicoAnexo,
    };

    await logServices
      .CreateLog(historicoData)
      .then(() => {
        getLeadModalData(leadModal.id);
        toastMessage("success", "Criado com sucesso");
      })
      .catch((e: AxiosError) => {
        toastMessage("error", e.response
          ? String(e.response?.data)
          : "Houve um erro ao pegar os dados.");
      });
  };

  const handleUpdateLead: () => Promise<void> = async () => {
    await setLoadingSaveEdit(true);
    var uploadLeadData: IUpdateLead = {
      id: leadModal.id,
      celular: leadModal.celular,
      email: leadModal.email,
      nome: leadModal.nome,
      status: selectedId,
      valor: leadModal.valorMedioConta,
    };

    await services
      .UpdateLead(uploadLeadData)
      .then(() => {
        getLeadModalData(leadModal.id);
        setEditar(false);

        setLoadingSaveEdit(false);
        toastMessage("success", "Editado com sucesso");
      })
      .catch((e: AxiosError) => {
        setLoadingSaveEdit(false);
        toastMessage("error", e.response
          ? String(e.response?.data)
          : "Houve um erro ao pegar os dados.");
      });
  };

  const handleNewHistoricoChange = (
    assunto: string,
    descricao: string,
    anexo: File | null
  ) => {
    setHistoricoAssunto(assunto);
    setHistoricoDescricao(descricao);
    setHistoricoAnexo(anexo);
  };
  //endregion9

  //region UI
  const handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const name: string = event.target.name;
    const value: string = event.target.value;

    setLeadModal((state: IFullLead) => {
      const updatedState = {
        ...state,
        [name]: value,
      };
      return updatedState;
    });
  }

  const handleSelect = (id: number) => {
    if (editar) {
      setSelectedId(id);
    }
  };

  const handleCloseLeadModal = () => {
    setLeadModal({
      id: "",
      idReferencial: "",
      celular: "",
      email: "",
      nome: "",
      nomeBackoffice: "",
      nomeCaptador: "",
      status: EnumStatusLead.leads,
      valorMedioConta: "",
      conta: null,
    });
    setHistoricoLeads([])
    if(callback)
      callback()
  }

  useEffect(() => {
    if (id) {
      getLeadModalData(id);
    }
  }, [id]);
  //endregion

  return (
    <Modal
      sx={{width: "100%", zIndex: 10}}
      open={openNewLead}
      aria-labelledby="modal-details"
      aria-describedby="modal-details"
    >
      <C.ModalContainer>
        <C.ModalArea>
          <C.ModalCard>
            <C.ModalHeader>
              <h2>
                {leadModal.nome} - {leadModal.idReferencial}
              </h2>
              <C.CloseButton aria-label="close" onClick={handleCloseLeadModal}>
                <CloseIcon/>
              </C.CloseButton>
            </C.ModalHeader>
            <h4>Status atual:</h4>
            <C.ModalStatusArea>
              <CircleCheckbox
                id={EnumStatusLeadNumericMap[EnumStatusLead.leads]}
                label="Leads"
                color="blue"
                selected={
                  selectedId ===
                  EnumStatusLeadNumericMap[EnumStatusLead.leads]
                }
                onSelect={handleSelect}
              />
              <CircleCheckbox
                id={
                  EnumStatusLeadNumericMap[
                    EnumStatusLead.aguardandoEnvioConta
                    ]
                }
                label="Aguardando envio de conta"
                color="yellow"
                selected={
                  selectedId ===
                  EnumStatusLeadNumericMap[
                    EnumStatusLead.aguardandoEnvioConta
                    ]
                }
                onSelect={handleSelect}
              />
              <CircleCheckbox
                id={EnumStatusLeadNumericMap[EnumStatusLead.contaAnexada]}
                label="Conta anexada"
                color="green"
                selected={
                  selectedId ===
                  EnumStatusLeadNumericMap[EnumStatusLead.contaAnexada]
                }
                onSelect={handleSelect}
              />
              <CircleCheckbox
                id={EnumStatusLeadNumericMap[EnumStatusLead.propostaGerada]}
                label="Proposta Gerada"
                color="purple"
                selected={
                  selectedId ===
                  EnumStatusLeadNumericMap[EnumStatusLead.propostaGerada]
                }
                onSelect={handleSelect}
              />
              <CircleCheckbox
                id={EnumStatusLeadNumericMap[EnumStatusLead.cancelada]}
                label="Cancelada"
                color="red"
                selected={
                  selectedId ===
                  EnumStatusLeadNumericMap[EnumStatusLead.cancelada]
                }
                onSelect={handleSelect}
              />
            </C.ModalStatusArea>
            <C.ModalInputsArea>
              <C.ModalInput
                label="Nome"
                value={leadModal.nome}
                name={LeadModalEnum.NOME}
                onChange={handleInputChange}
                inputProps={{readOnly: !editar}}
              />
              <MaskDefaultInput
                label="Telefone"
                type={"CELULAR"}
                value={leadModal.celular}
                sx={{width: "inherit", flex: 1, flexBasis: '49%'}}
                onChange={(rawValue) => {
                  const value =
                    typeof rawValue === "string"
                      ? rawValue
                      : (rawValue as { value?: string })?.value ?? "";

                  handleInputChange({
                    target: {
                      name: LeadModalEnum.CELULAR,
                      value: value
                    }
                  } as React.ChangeEvent<HTMLInputElement>)
                }}
                readonly={!editar}
              />
              <C.ModalInput
                label="Email"
                name={LeadModalEnum.EMAIL}
                value={leadModal.email ? leadModal.email : ""}
                onChange={handleInputChange}
                inputProps={{readOnly: !editar}}
              />
              <C.ModalInput
                label="Parceiro"
                value={leadModal.nomeCaptador}
                inputProps={{readOnly: true}}
              />
              <C.ModalInput
                label="Backoffice"
                value={leadModal.nomeBackoffice}
                inputProps={{readOnly: true}}
              />
              <MaskDefaultInput
                type={"REAL"}
                label="Valor"
                sx={{width: "inherit", flex: 1, flexBasis: '49%'}}
                value={leadModal.valorMedioConta}
                onChange={(value) => {
                  handleInputChange({
                    target: {
                      name: LeadModalEnum.VALORMEDIOCONTA,
                      value: value
                    }
                  } as React.ChangeEvent<HTMLInputElement>)
                }}
                readonly={!editar}
              />
            </C.ModalInputsArea>
            <C.ModalButtonsArea>
              <C.ModalProposalButton
                variant="contained"
                onClick={() =>
                  navigate(`/Parceiro/Novo/Proposta/${leadModal.id}`)
                }
              >
                Gerar proposta
              </C.ModalProposalButton>
              <C.ModalSaveButton
                variant="contained"
                disabled={editar}
                onClick={() => setEditar(true)}
              >
                Editar
              </C.ModalSaveButton>
              <C.ModalSaveButton
                variant="contained"
                disabled={!editar}
                onClick={() => handleUpdateLead()}
              >
                {loadingSaveEdit ? (
                  <CircularProgress size={20} color="secondary"/>
                ) : (
                  <>Salvar</>
                )}
              </C.ModalSaveButton>
            </C.ModalButtonsArea>
          </C.ModalCard>
          <HistoricoCard
            tableData={historicoLeads}
            getNewHistoricData={handleNewHistoricoChange}
            saveFunction={createNewLog}
            reloadTable={() => getLeadModalData(leadModal.id)}
          />
        </C.ModalArea>
      </C.ModalContainer>
    </Modal>
  );
};

export default LeadModal;