//https://flagcdn.com/en/codes.json
export const countryCodes = [
  { code: "+55", name: "Brasil", img: "https://flagcdn.com/br.svg" },
];

export abstract class IMask {
  execute(value: any): any {
    throw new Error("Method not implemented.");
  }
}

export class CEP implements IMask {
  execute(value: any): any {
    return value
      .replace(/\D/g, "")
      .replace(/^(\d{5})(\d{3})/, "$1-$2")
      .slice(0, 9);
  }
}

export class CPF implements IMask {
  execute(value: any): any {
    return value
      .replace(/\D/g, "")
      .replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4")
      .slice(0, 14);
  }
}

export class CNPJ implements IMask {
  execute(value: any): any {
    return value
      .replace(/\D/g, "")
      .replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5")
      .slice(0, 18);
  }
}

export class RG implements IMask {
  execute(value: any): any {
    return value
      .replace(/\D/g, "")
      .replace(/(\d{2})(\d{3})(\d{3})(\d{1})/, "$1.$2.$3-$4")
      .slice(0, 12);
  }
}

export class CELULAR implements IMask {
  execute(value: any): any {
    return value
      .replace(/\D/g, "")
      .replace(/^(\d{2})(\d{5})(\d{4}).*/, "($1) $2-$3")
      .replace(/^(\d{2})(\d{4})(\d{4}).*/, "($1) $2-$3")
      .slice(0, 15);
  }
}

export class REAL implements IMask {
  execute(value: any): any {
    const numericValue = value.replace(/\D/g, "");

    if (!numericValue) return "";

    const floatValue = parseFloat(numericValue) / 100;

    if (isNaN(floatValue)) return "";

    return floatValue.toLocaleString("pt-br", { minimumFractionDigits: 2 });
  }
}

export class DECIMAL implements IMask {
  execute(value: any): any {
    const numericValue = value.replace(/\D/g, "");

    if (!numericValue) return "";

    const floatValue = parseFloat(numericValue) / 100;

    if (isNaN(floatValue)) return "";

    return floatValue.toLocaleString("pt-br", { minimumFractionDigits: 2 });
  }
}

export class PORCENTAGEM implements IMask {
  execute(value: any): any {
    return value.replace(/\D/g, "").slice(0, 3).replace(/^0+/, "");
  }
}

export class KWH implements IMask {
  execute(value: any): any {
    return value.replace(/\D/g, "").slice(0, 8).replace(/^0+/, "");
  }
}
