export enum DadosPagamentoGeradorEnum {
  TIPOCONEXAO = "TIPOCONEXAO",
  DESCONTOTARIFAPERCENTUAL = "DESCONTOTARIFAPERCENTUAL",
  TARIFAGERADOR = "TARIFAGERADOR",
  TARIFAVIGENTE = "TARIFAVIGENTE",
  DATAINICIAL = "DATAINICIAL",
  DATAFINAL = "DATAFINAL",
  AGENCIAPAGAMENTOGERADOR = "AGENCIAPAGAMENTOGERADOR",
  CNPJFAVORECIDOPAGAMENTOGERADOR = "CNPJFAVORECIDOPAGAMENTOGERADOR",
  BANCOPAGAMENTOGERADOR = "BANCOPAGAMENTOGERADOR",
  TIPOCHAVEPIXPAGAMENTOGERADOR = "TIPOCHAVEPIXPAGAMENTOGERADOR",
  FAVORECIDOPAGAMENTOGERADOR = "FAVORECIDOPAGAMENTOGERADOR",
  CONTAPAGAMENTOGERADOR = "CONTAPAGAMENTOGERADOR",
  CHAVEPIXPAGAMENTOGERADOR = "CHAVEPIXPAGAMENTOGERADOR",
  ADDNEWFORM = "ADDNEWFORM",
  REMOVEFORM = "REMOVEFORM",
}