import React, { useEffect, useState } from "react";
import {
  TextField,
  MenuItem,
  Select,
  InputAdornment,
  Box,
  FormControl,
  InputLabel,
  Input,
  Stack,
} from "@mui/material";
import { InputTypes } from "../../types/InputType";
import {
  CELULAR,
  CEP,
  CNPJ,
  countryCodes,
  CPF,
  DECIMAL,
  KWH,
  PORCENTAGEM,
  REAL,
} from "../../utils/masInputs";
import { handleTypeMasks } from "./handleTypeMasks";

type MaskDefaultInputProps = {
  type: InputTypes;
  label: string;
  onChange?: (rawValue: string | Object) => void;
  value?: string;
  sx?: any;
  readonly?: boolean;
};

const MaskDefaultInput: React.FC<MaskDefaultInputProps> = ({
  type,
  label,
  onChange,
  value,
  sx,
  readonly,
}) => {
  const [valueInput, setValueInput] = useState<string>("");
  const [rawValue, setRawValue] = useState<string>("");
  const [selectedCountryCode, setSelectedCountryCode] = useState<string>("+55");

  const typeMask = handleTypeMasks.find((x) => x.type === type);

  const handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const inputValue: string = e.target.value;
    let numericValue: string = inputValue.replace(/\D/g, "");

    const maskedValue: string = typeMask?.applyMask(numericValue);
    numericValue = typeMask?.newValue(numericValue);
    typeMask?.applyChanges(
      numericValue,
      onChange,
      selectedCountryCode,
      getViaCep
    );

    setRawValue(numericValue);
    setValueInput(maskedValue);
  };

  const handleChangeSelectZipCode: (e: any) => void = (e: any) => {
    setSelectedCountryCode(e.target.value as string);
    onChange?.({
      value: rawValue,
      zipCode: e.target.value,
    });
  };

  const getViaCep: (cep: string) => Promise<void> = async (cep: string) => {
    fetch(`https://viacep.com.br/ws/${cep}/json/`)
      .then((json) => json.json())
      .then((res) =>
        onChange?.({
          value: cep,
          ...res,
        })
      )
      .catch((x) =>
        onChange?.({
          value: cep,
        })
      );
  };

  useEffect(() => {
    if (value) {
      const valueString: string = typeof(value) != "string" ?  `${value}` : value;
      const maskedValue: string = typeMask?.applyMask(valueString.replace(/\D/g, ""));
      setValueInput(maskedValue);
      setRawValue(valueString.replace(/\D/g, ""));
    } else {
      setValueInput("");
    }
  }, [value]);

  return (
    <Stack direction={"row"} sx={sx ? sx : { width: "100%" }}>
      {type === "CELULAR" && (
        <TextField
          value={selectedCountryCode}
          onChange={handleChangeSelectZipCode}
          sx={{
            minWidth: "110px",
            padding: 0,
            whiteSpace: "nowrap",
            "& .fbEKzQ": {
              borderBottomRightRadius: 0,
              borderTopRightRadius: 0,
            },
          }}
          select
        >
          {countryCodes.map((country) => (
            <MenuItem key={country.code} value={country.code}>
              <img src={country.img} width="20" />
              {country.code}
            </MenuItem>
          ))}
        </TextField>
      )}

      <TextField
        label={label}
        value={valueInput}
        onChange={handleChange}
        required
        fullWidth
        inputProps={{ readOnly: readonly }}
        InputProps={
          type === "REAL"
            ? {
                startAdornment: (
                  <InputAdornment position="start">R$</InputAdornment>
                ),
              }
            : type === "PORCENTAGEM"
            ? {
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
              }
            : type === "KWH"
            ? {
                endAdornment: (
                  <InputAdornment position="end">kWh</InputAdornment>
                ),
              }
            : {}
        }
        sx={
          type === "CELULAR"
            ? {
                ...sx,
                "& .fbEKzQ": {
                  borderBottomLeftRadius: 0,
                  borderTopLeftRadius: 0,
                },
              }
            : sx
        }
      />
    </Stack>
  );
};

export default MaskDefaultInput;
