import { FC, RefObject, useEffect, useReducer, useState } from "react";
import { ErrorMessage } from "../../../../../utils/AbstractValidation";
import { IResponsavelLegal } from "../../../../../models/Gerador/Cadastro/ResponsavelLegal";
import * as C from "../style";
import * as D from "../../../../../styles/appComponents";
import { Stack, TextField } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RegisterCard from "../../../../../components/RegisterCard";
import { UsinaTipoPessoaJuridica } from "../../../../../models/Gerador/Usina";
import {
  ICreateResponsavelLegal,
  IFormResponsavelLegal,
} from "../../../../../models/ResponsavelLegal";
import ScroolRef from "../../../../../components/ScrollRef";
import { IResponsavelComunicacao } from "../../../../../models/Gerador/Cadastro/ResponsavelComunicacao";
import { handleStyleFieldError } from "../../../../../utils/verifyFieldError";
import { SkeletonSectionForm } from "../../../../../components/SkeletonLoads/SectionForm";
import MaskDefaultInput from "../../../../../components/MasDefaultinput/MaskDefaultInput";
import { ResponsavelLegalEnum } from "../../../../../enums/Gerador/EnumInputModel.ts/ResponsavelLegalEnum";
import { ResponsavelComunicacaoEnum } from "../../../../../enums/Gerador/EnumInputModel.ts/ResponsavelComunicacaoEnum";
export interface PropsResponsavelLegal {
  onSave: (data: IResponsavelComunicacao) => void;
  onChange: (data: IResponsavelComunicacao) => void;
  onUpdate: (data: IResponsavelComunicacao) => void;
  buttonSave: boolean;
  fieldErros: ErrorMessage[] | null;
  geradorId: string | null | undefined;
  usina: UsinaTipoPessoaJuridica | undefined;
  scrollRef: RefObject<HTMLDivElement> | null;
  load: { status: boolean; section: string };
}

export const ResponsavelLegal: FC<PropsResponsavelLegal> = ({
  onSave,
  onChange,
  onUpdate,
  buttonSave,
  fieldErros,
  geradorId,
  usina,
  scrollRef,
  load,
}) => {
  const formResponsavelComunicacaoModel: IResponsavelComunicacao = {
    nomeresponsavelcomunicacao: null,
    celularresponsavelcomunicacao: null,
    emailresponsavelcomunicacao: null,
    dadosResponsavelLegal: [],
  };
  const [responsavelComunicacao, setResposavelComunicacao] =
    useState<IResponsavelComunicacao>(formResponsavelComunicacaoModel);

  const formResponsavelLegalModel: ICreateResponsavelLegal = {
    nome: null,
    enumEstadoCivil: null,
    nacionalidade: null,
    profissao: null,
    email: null,
    celular: null,
    cpf: null,
    cep: null,
    uf: null,
    cidade: null,
    bairro: null,
    numero: null,
    complemento: null,
    logradouro: null,
    datanascimento: null,
  };
  const [dadosResponsavelLegal, setDadosResponsavelLegal] = useState<
    ICreateResponsavelLegal[]
  >([formResponsavelLegalModel]);

  const sendOnChange = (update: IResponsavelComunicacao): void => {
    onChange(update);
  };
  const sendOnSave = () =>
    onSave({ ...responsavelComunicacao, dadosResponsavelLegal });
  const sendOnUpdate = () =>
    onUpdate({ ...responsavelComunicacao, dadosResponsavelLegal });

  const handleInputChange =
    (index: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
      const name: string = event.target.name.toLocaleLowerCase();
      const value = event.target.value;

      const newForms = [...dadosResponsavelLegal];
      newForms[index] = { ...newForms[index], [name]: value };

      setDadosResponsavelLegal(newForms);
      sendOnChange({
        ...responsavelComunicacao,
        dadosResponsavelLegal: newForms,
      });
    };

  const handleInputChangeResponsavelComunicacao = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const name = event.target.name.toLocaleLowerCase();
    const value = event.target.value;

    setResposavelComunicacao((state: any) => {

      if(name === ResponsavelLegalEnum.NUMERO.toLocaleLowerCase()){
        sendOnChange({ ...state, [name]: value === null || value === ""? "S/N": value });
        return { ...state, [name]: value === null || value === ""? "S/N": value };
      }

      sendOnChange({ ...state, [name]: value });
      return { ...state, [name]: value };
    });
  };

  useEffect(() => {
    if (geradorId) {

      const formResponsavelComunicacaoModel: IResponsavelComunicacao = {
        nomeresponsavelcomunicacao: geradorId
          ? usina?.nomeResponsavelComunicacao
          : null,
        celularresponsavelcomunicacao: geradorId
          ? usina?.celularResponsavelComunicacao
          : null,
        emailresponsavelcomunicacao: geradorId
          ? usina?.emailResponsavelComunicacao
          : null,
        dadosResponsavelLegal: [],
      };
      setResposavelComunicacao(formResponsavelComunicacaoModel);
      setDadosResponsavelLegal(
        (usina?.responsavelLegal ?? []).length === 0
          ? [formResponsavelLegalModel]
          : (usina?.responsavelLegal ?? []).map((x: any) => {
              return { ...x, datanascimento: x.dataNascimento, numero: x?.numero === null || x?.numero === ""? "S/N": x?.numero };
            }) ?? []
      );
    }
  }, [geradorId, usina]);

  const addForm = () => {
    setDadosResponsavelLegal((state: any) => [
      ...state,
      formResponsavelLegalModel,
    ]);
  };

  return (
    <RegisterCard title="Informações do responsavel legal">
      {load.status && load.section === "responsavelLegal" ? (
        <SkeletonSectionForm />
      ) : (
        <>
          {(dadosResponsavelLegal || []).map((x, index) => (
            <>
              <C.FWStack
                direction={"row"}
                spacing={2}
                key={index}
                marginTop={index > 0 ? "30px" : "none"}
              >
                <TextField
                  inputProps={{ readOnly: buttonSave }}
                  name={ResponsavelLegalEnum.NOME}
                  onChange={handleInputChange(index)}
                  value={x?.nome || ""}
                  label="Nome do responsável legal"
                  required
                  sx={
                    index === 0
                      ? handleStyleFieldError(
                          ResponsavelLegalEnum.NOME,
                          fieldErros,
                          { width: "100%" }
                        )
                      : { width: "100%" }
                  }
                />
                <TextField
                  inputProps={{ readOnly: buttonSave }}
                  name={ResponsavelLegalEnum.EMAIL}
                  onChange={handleInputChange(index)}
                  value={x?.email || ""}
                  label="E-mail"
                  required
                  sx={
                    index === 0
                      ? handleStyleFieldError(
                          ResponsavelLegalEnum.EMAIL,
                          fieldErros,
                          { width: "100%" }
                        )
                      : { width: "100%" }
                  }
                />
                <TextField
                  inputProps={{ readOnly: buttonSave }}
                  name={ResponsavelLegalEnum.CELULAR}
                  onChange={handleInputChange(index)}
                  value={x?.celular || ""}
                  label="Celular"
                  required
                  sx={
                    index === 0
                      ? handleStyleFieldError(
                          ResponsavelLegalEnum.EMAIL,
                          fieldErros,
                          { width: "70%" }
                        )
                      : { width: "70%" }
                  }
                />
                <MaskDefaultInput
                  type="CPF"
                  label="CPF"
                  value={x?.cpf ?? ""}
                  onChange={(value) =>
                    handleInputChange(index)({
                      target: { name: ResponsavelLegalEnum.CPF, value },
                    } as React.ChangeEvent<HTMLInputElement>)
                  }
                  sx={
                    index === 0
                      ? handleStyleFieldError(
                          ResponsavelLegalEnum.CPF,
                          fieldErros,
                          { width: "100%" }
                        )
                      : { width: "100%" }
                  }
                />
              </C.FWStack>
              <C.FWStack direction={"row"} spacing={2} key={index}>
                <D.DateTextfield
                  inputProps={{ readOnly: buttonSave }}
                  name={ResponsavelLegalEnum.DATANASCIMENTO}
                  onChange={handleInputChange(index)}
                  value={(x?.datanascimento || "")?.toString()?.split("T")[0]}
                  type="date"
                  label="Data de Nascimento"
                  required
                  InputLabelProps={{
                    shrink: true,
                  }}
                  sx={
                    index === 0
                      ? handleStyleFieldError(
                          ResponsavelLegalEnum.DATANASCIMENTO,
                          fieldErros,
                          { width: "80%" }
                        )
                      : { width: "80%" }
                  }
                />
                <MaskDefaultInput
                  type="CEP"
                  label="CEP"
                  value={x?.cep ?? ""}
                  onChange={(value:any) => handleInputChange(index)(
                      { 
                        target:{ name:ResponsavelLegalEnum.CEP, value: value?.value  } 
                      } as React.ChangeEvent<HTMLInputElement>
                    )
                  }
                  sx={
                    index === 0
                      ? handleStyleFieldError(
                          ResponsavelLegalEnum.CEP,
                          fieldErros,
                          { width: "100%" }
                        )
                      : { width: "100%" }
                  }
                />
                <TextField
                  inputProps={{ readOnly: buttonSave }}
                  name={ResponsavelLegalEnum.UF}
                  onChange={handleInputChange(index)}
                  value={x?.uf || ""}
                  label="UF"
                  required
                  onKeyDown={(event:any) => {
                    const regex = /[0-9]/;
                    if (regex.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  sx={
                    index === 0
                      ? handleStyleFieldError(
                          ResponsavelLegalEnum.UF,
                          fieldErros,
                          { width: "40%" }
                        )
                      : { width: "40%" }
                  }
                />
                <TextField
                  inputProps={{ readOnly: buttonSave }}
                  name={ResponsavelLegalEnum.CIDADE}
                  onChange={handleInputChange(index)}
                  value={x?.cidade || ""}
                  label="Cidade"
                  required
                  onKeyDown={(event:any) => {
                    const regex = /[0-9]/;
                    if (regex.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  sx={
                    index === 0
                      ? handleStyleFieldError(
                          ResponsavelLegalEnum.CIDADE,
                          fieldErros,
                          { width: "100%" }
                        )
                      : { width: "100%" }
                  }
                />
                <TextField
                  inputProps={{ readOnly: buttonSave }}
                  name={ResponsavelLegalEnum.BAIRRO}
                  onChange={handleInputChange(index)}
                  value={x?.bairro || ""}
                  label="Bairro"
                  required
                  sx={
                    index === 0
                      ? handleStyleFieldError(
                          ResponsavelLegalEnum.BAIRRO,
                          fieldErros,
                          { width: "100%" }
                        )
                      : { width: "100%" }
                  }
                />
                <TextField
                  inputProps={{ readOnly: buttonSave }}
                  name={ResponsavelLegalEnum.NUMERO}
                  onChange={handleInputChange(index)}
                  value={x?.numero || ""}
                  label="Número"
                  required
                  sx={
                    index === 0
                      ? handleStyleFieldError(
                          ResponsavelLegalEnum.NUMERO,
                          fieldErros,
                          { width: "40%" }
                        )
                      : { width: "40%" }
                  }
                />
              </C.FWStack>
              <C.FWStack direction={"row"} spacing={2} key={index}>
                <TextField
                  inputProps={{ readOnly: buttonSave }}
                  name={ResponsavelLegalEnum.LOGRADOURO}
                  onChange={handleInputChange(index)}
                  value={x?.logradouro || ""}
                  label="Logradouro"
                  required
                  sx={
                    index === 0
                      ? handleStyleFieldError(
                          ResponsavelLegalEnum.LOGRADOURO,
                          fieldErros,
                          { width: "30%" }
                        )
                      : { width: "30%" }
                  }
                />
                <TextField
                  inputProps={{ readOnly: buttonSave }}
                  name={ResponsavelLegalEnum.COMPLEMENTO}
                  onChange={handleInputChange(index)}
                  value={x?.complemento || ""}
                  label="Complemento"
                  required
                  sx={
                    index === 0
                      ? handleStyleFieldError(
                          ResponsavelLegalEnum.COMPLEMENTO,
                          fieldErros,
                          { width: "30%" }
                        )
                      : { width: "30%" }
                  }
                />
              </C.FWStack>
            </>
          ))}
          <C.FWStack direction={"row"} spacing={2} justifyContent={"flex-end"}>
            <D.ContainedButton
              disabled={buttonSave}
              startIcon={<AddIcon />}
              onClick={addForm}
              $color="lightpink"
            >
              Adicionar outra forma de contato
            </D.ContainedButton>
          </C.FWStack>
          <Stack>
            <Stack>
              <C.TitleArea>
                <h2>Responsável pela comunicação</h2>
              </C.TitleArea>
              <C.FWStack direction={"row"} paddingY={2} spacing={2}>
                <TextField
                  inputProps={{ readOnly: buttonSave }}
                  sx={handleStyleFieldError(
                    ResponsavelComunicacaoEnum.NOMERESPONSAVELCOMUNICACAO,
                    fieldErros
                  )}
                  name={ResponsavelComunicacaoEnum.NOMERESPONSAVELCOMUNICACAO}
                  onChange={handleInputChangeResponsavelComunicacao}
                  value={
                    responsavelComunicacao?.nomeresponsavelcomunicacao || ""
                  }
                  label="Nome"
                  required
                  fullWidth
                />
                <TextField
                  inputProps={{ readOnly: buttonSave }}
                  sx={handleStyleFieldError(
                    ResponsavelComunicacaoEnum.EMAILRESPONSAVELCOMUNICACAO,
                    fieldErros
                  )}
                  name={ResponsavelComunicacaoEnum.EMAILRESPONSAVELCOMUNICACAO}
                  onChange={handleInputChangeResponsavelComunicacao}
                  value={
                    responsavelComunicacao?.emailresponsavelcomunicacao || ""
                  }
                  label="Email"
                  required
                  fullWidth
                />
                <TextField
                  inputProps={{ readOnly: buttonSave }}
                  sx={handleStyleFieldError(
                    ResponsavelComunicacaoEnum.CELULARRESPONSAVELCOMUNICACAO,
                    fieldErros
                  )}
                  name={
                    ResponsavelComunicacaoEnum.CELULARRESPONSAVELCOMUNICACAO
                  }
                  onChange={handleInputChangeResponsavelComunicacao}
                  value={
                    responsavelComunicacao?.celularresponsavelcomunicacao || ""
                  }
                  type="number"
                  label="Celular"
                  required
                  fullWidth
                />
              </C.FWStack>
            </Stack>

            <C.FWStack
              direction={"row"}
              spacing={2}
              justifyContent={"flex-end"}
            >
              <D.ContainedButton
                disabled={!buttonSave}
                onClick={sendOnUpdate}
                $color="yellow"
              >
                Editar
              </D.ContainedButton>
              <D.ContainedButton disabled={buttonSave} onClick={sendOnSave}>
                Salvar
              </D.ContainedButton>
            </C.FWStack>
          </Stack>
        </>
      )}
    </RegisterCard>
  );
};
