export enum DadosUsinaEnum {
  RAZAOSOCIALNOME = "RAZAOSOCIALNOME",
  NOMEUSINA = "NOMEUSINA",
  CPFCNPJ = "CPFCNPJ",
  CPF = "CPF",
  INSTALACAO = "INSTALACAO",
  SIGLA = "SIGLA",
  CEP = "CEP",
  UF = "UF",
  CIDADE = "CIDADE",
  BAIRRO = "BAIRRO",
  LOGRADOURO = "LOGRADOURO",
  NUMERO = "NUMERO",
  COMPLEMENTO = "COMPLEMENTO",
}