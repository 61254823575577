import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { FC } from "react";
import dayjs from "dayjs";

interface IDataInputProps {
  label: string;
  value: Date | null;
  onChange: (newValue: Date | null) => void;
  maxDate?: Date;
  readOnly?: boolean;
}

const DataInput: FC<IDataInputProps> = ({
  label,
  value,
  onChange,
  maxDate,
  readOnly,
}) => {
  const dayjsValue = value && dayjs(value);
  const dayjsMaxDate = maxDate && dayjs(maxDate);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-br">
      <DatePicker
        label={label}
        value={dayjsValue}
        onChange={(newValue) => {
          const date = newValue ? newValue.toDate() : null;
          onChange(date);
        }}
        maxDate={dayjsMaxDate}
        slotProps={{ textField: { fullWidth: true } }}
        readOnly={readOnly}
      />
    </LocalizationProvider>
  );
};

export default DataInput;
