import { CELULAR, CEP, CNPJ, CPF, DECIMAL, KWH, PORCENTAGEM, REAL, RG } from "../../utils/masInputs";


export const handleTypeMasks = [
    {
        type: "CPF",
        applyMask: (value:any) => new CPF().execute(value),
        applyChanges: (value:any, onChange:any) => onChange?.(value),
        newValue: (value:any) => value = value.slice(0, 11)
    },
    {
        type: "RG",
        applyMask: (value:any) => new RG().execute(value),
        applyChanges: (value:any, onChange:any) => onChange?.(value),
        newValue: (value:any) => value = value.slice(0, 11)
    },
    {
        type: "CNPJ",
        applyMask: (value:any) => new CNPJ().execute(value),
        applyChanges: (value:any, onChange:any) => onChange?.(value),
        newValue: (value:any) => value = value.slice(0, 14)
    },
    {
        type: "CELULAR",
        applyMask: (value:any) => new CELULAR().execute(value),
        applyChanges: (value:any, onChange:any, newValue?:any) => onChange?.({value: `${value}`, zipCode: newValue}),
        newValue: (value:any) => value = value.slice(0, 11)
    },
    {
        type: "REAL",
        applyMask: (value:any) => new REAL().execute(value),
        applyChanges: (value:any, onChange:any, newValue?:any) => onChange?.((value as unknown as number) ? "0" : value),
        newValue: (value:any) => value?.(parseFloat(value) / 100).toString()
    },
    {
        type: "CEP",
        applyMask: (value:any) => new CEP().execute(value),
        applyChanges: (value:any, onChange:any, newValue?:any, func?:any) => {
            if (value.length === 8) {
                return func?.(value);
            } else {
                return onChange?.({value: `${value}`});
            }
        },
        newValue: (value:any) => value.slice(0, 8),
    },
    {
        type: "PORCENTAGEM",
        applyMask: (value:any) => new PORCENTAGEM().execute(value),
        applyChanges: (value:any, onChange:any, newValue?:any) => onChange?.(value),
        newValue: (value:any) => value.slice(0, 3)

    },
    {
        type: "DECIMAL",
        applyMask: (value:any) => new DECIMAL().execute(value),
        applyChanges: (value:any, onChange:any, newValue?:any) => onChange?.(value),
        newValue: (value:any) => value.slice(0, 3)

    },
    {
        type: "KWH",
        applyMask: (value:any) => new KWH().execute(value),
        applyChanges: (value:any, onChange:any, newValue?:any) => onChange?.(value),
        newValue: (value:any) => value.slice(0, 3)

    },
    
]