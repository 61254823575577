import { IDadosUsinaPessoaJuridica } from "../../../../../models/Gerador/Cadastro/DadosUsina";
import { AbstractValidation, Validate } from "../../../../../utils/AbstractValidation";

export class DadosUsinaPessoaJuridicaValidate extends AbstractValidation<IDadosUsinaPessoaJuridica>{
    getValidate(): Validate<IDadosUsinaPessoaJuridica>[] {
        return[
            {
                field:"razaosocialnome",
                validate: (value, model:IDadosUsinaPessoaJuridica, addError:(message:string) => void) => {
                    if(!value) addError("A Razão social/nome deve ser informada")
                }
            },
            {
                field:"razaosocialnome",
                validate: (value, model:IDadosUsinaPessoaJuridica, addError:(message:string) => void) => {
                    if(value?.length > 150) addError("A Razão social Não pode ultrapssar 150 caracteres")
                }
            },
            {
                field:"razaosocialnome",
                validate: (value, model:IDadosUsinaPessoaJuridica, addError:(message:string) => void) => {
                    if(value && value?.split(" ")?.length === 1) addError("A Razão social deve conter dois nomes no mínimo")
                }
            },
            {
                field:"cpfcnpj",
                validate: (value, model:IDadosUsinaPessoaJuridica, addError:(message:string) => void) => {
                    if(!value) addError("O Cpf/Cnpj deve ser informada")
                }
            },
            {
                field:"cpfcnpj",
                validate: (value, model:IDadosUsinaPessoaJuridica, addError:(message:string) => void) => {
                    if(value?.length !== 14) addError("O Cpf/Cnpj deve possuir 14 dígitos")
                }
            },
            {
                field:"cep",
                validate: (value, model:IDadosUsinaPessoaJuridica, addError:(message:string) => void) => {
                    if(!value) addError("O cep deve ser informada")
                }
            },
            {
                field:"cep",
                validate: (value, model:IDadosUsinaPessoaJuridica, addError:(message:string) => void) => {
                    if(value?.length !== 8) addError("O cep deve ser conter 8 dígitos")
                }
            },
            {
                field:"instalacao",
                validate: (value, model:IDadosUsinaPessoaJuridica, addError:(message:string) => void) => {
                    if(!value) addError("A Instalação deve ser informada")
                }
            }
        ];
    }
}