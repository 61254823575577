import { TextField } from "@mui/material";
import RegisterCard from "../../../../../components/RegisterCard";
import * as D from "../../../../../styles/appComponents";
import { useEffect, useState } from "react";
import { IContaCooperativaProposta } from "../../../../../models/GDProposta";
import { moneyFormatter } from "../../../../../utils/moneyFormatter";

interface IContaCooperativaGerarPropostaProps {
  data: IContaCooperativaProposta;
}

const ContaCooperativaGerarProposta: React.FC<
  IContaCooperativaGerarPropostaProps
> = ({ data }) => {
  return (
    <RegisterCard title="O que virá na conta da cooperativa">
      <D.FWStack direction={"row"} spacing={2}>
        <TextField
          label="Energia injetada (Kwh)"
          required
          fullWidth
          value={data.energiaInjetadakWh}
          inputProps={{ readOnly: true }}
          InputProps={{
            endAdornment: data.energiaInjetadakWh ? <>kWh</> : <></>,
          }}
        />
        <TextField
          label="Energia injetada (R$)"
          required
          fullWidth
          value={
            data.energiaInjetadaReais
              ? moneyFormatter.format(Number(data.energiaInjetadaReais))
              : ""
          }
          inputProps={{ readOnly: true }}
        />
        <TextField
          label="Valor a ser pago na fatura da cooperativa"
          required
          fullWidth
          value={
            data.valorCooperativa
              ? moneyFormatter.format(Number(data.valorCooperativa))
              : ""
          }
          inputProps={{ readOnly: true }}
        />
      </D.FWStack>
    </RegisterCard>
  );
};

export default ContaCooperativaGerarProposta;
