import { AbstractValidation, Validate } from "../../../../../../../utils/AbstractValidation";
import { IDocumentoForm } from "../Documentos";

export class DocumentoValidate extends AbstractValidation<IDocumentoForm>{
    getValidate(): Validate<IDocumentoForm>[] {
        return[
            {
                field:"cpf",
                validate: (value, data, addError: (message:string, ref:string) => void) => {
                    if(!value) addError("O CPF deve ser informado","cpf")
                }
            },
            {
                field:"rg",
                validate: (value, data, addError: (message:string, ref:string) => void) => {
                    if(!value) addError("O RG deve ser informado","rg")
                }
            },
            {
                field:"pis",
                validate: (value, data, addError: (message:string, ref:string) => void) => {
                    if(!value) addError("O PIS deve ser informado","pis")
                }
            },
            {
                field:"numero",
                validate: (value, data, addError: (message:string, ref:string) => void) => {
                    if(!value) addError("O Numero deve ser informado","numero")
                }
            },
            {
                field:"uf",
                validate: (value, data, addError: (message:string, ref:string) => void) => {
                    if(!value) addError("O UF deve ser informado","uf")
                }
            },
            {
                field:"uf",
                validate: (value, data, addError: (message:string, ref:string) => void) => {
                    if(value.length !== 2) addError("O Estado deve ter apenas dois caracters","uf")
                }
            },
            {
                field:"serie",
                validate: (value, data, addError: (message:string, ref:string) => void) => {
                    if(!value) addError("A Série deve ser informado","serie")
                }
            }
        ];
    }
}