import * as D from "../../styles/appComponents";
import { CircularProgress, TextField } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { ILog, IUpdateLog } from "../../models/Log";
import { DataGrid, GridCellParams, GridColDef } from "@mui/x-data-grid";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import { ptBR } from "@mui/x-data-grid/locales";
import PublishIcon from "@mui/icons-material/Publish";
import ClearIcon from "@mui/icons-material/Clear";
import DeleteIcon from "@mui/icons-material/Delete";
import DownloadIcon from "@mui/icons-material/Download";
import EditIcon from "@mui/icons-material/Edit";

import { AxiosError } from "axios";
import Swal from "sweetalert2";
import * as anexoServices from "../../services/api/AnexoService";
import * as services from "../../services/api/LogService";

interface HistoricoCardProps {
  tableData: ILog[];
  saveFunction: () => Promise<void>;
  getNewHistoricData: (
    assunto: string,
    descricao: string,
    file: File | null
  ) => void;
  reloadTable: () => void;
}

const HistoricoCard: React.FC<HistoricoCardProps> = ({
  tableData,
  saveFunction,
  getNewHistoricData,
  reloadTable,
}) => {
  const [addHistorico, setAddHistorico] = useState<boolean>(false);
  const [assunto, setAssunto] = useState<string>("");
  const [descricao, setDescricao] = useState<string>("");
  const [archive, setArchive] = useState<File | null>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [anexoName, setAnexoName] = useState<string>("");

  const [idLog, setIdLog] = useState<string | null>(null);

  const downloadConta = async (id: string) => {
    await anexoServices
      .GetUrl(id)
      .then((response) => {
        const fileUrl = response.data;

        const link = document.createElement("a");
        link.href = fileUrl;
        link.target = "_blank";
        link.rel = "noopener noreferrer";

        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
      })
      .catch((e: AxiosError) => {
        var errorMessage: string = e.response
          ? String(e.response?.data)
          : "Houve um erro ao pegar os dados.";

        Swal.fire({
          position: "top-end",
          toast: true,
          icon: "warning",
          title: errorMessage,
          showConfirmButton: false,
          showCloseButton: true,
          timer: 3000,
        });
      });
  };

  const deleteLog = async (id: string) => {
    await services
      .DeleteLog(id)
      .then(() => {
        reloadTable();
        Swal.fire({
          position: "top-end",
          toast: true,
          icon: "success",
          title: "Deletado com sucesso.",
          showConfirmButton: false,
          showCloseButton: true,
          timer: 3000,
        });
      })
      .catch((e: AxiosError) => {
        var errorMessage: string = e.response
          ? String(e.response?.data)
          : "Houve um erro ao deletar o log.";

        Swal.fire({
          position: "top-end",
          toast: true,
          icon: "warning",
          title: errorMessage,
          showConfirmButton: false,
          showCloseButton: true,
          timer: 3000,
        });
      });
  };

  const handleDeleteLog = async (id: string) => {
    Swal.fire({
      title: "Você tem certeza?",
      text: "Você não podera reverter esta ação!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sim, deletar!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        await deleteLog(id);
        reloadTable();
      }
    });
  };

  const editLog = async () => {
    var data: IUpdateLog = {
      id: idLog!,
      assunto: assunto,
      descricao: descricao,
      anexo: archive,
    };

    if (assunto === "") {
      Swal.fire({
        position: "top-end",
        toast: true,
        icon: "warning",
        title: "Assunto/descrição não podem ser vazios.",
        showConfirmButton: false,
        showCloseButton: true,
        timer: 3000,
      });

      return;
    }

    await services
      .UpdateLog(data)
      .then(() => {
        cancelAddHistorico();
        reloadTable();
        Swal.fire({
          position: "top-end",
          toast: true,
          icon: "success",
          title: "Editado com sucesso!",
          showConfirmButton: false,
          showCloseButton: true,
          timer: 3000,
        });
      })
      .catch((e: AxiosError) => {
        var errorMessage: string = e.response
          ? String(e.response?.data)
          : "Houve um erro ao buscar os dados.";

        Swal.fire({
          position: "top-end",
          toast: true,
          icon: "warning",
          title: errorMessage,
          showConfirmButton: false,
          showCloseButton: true,
          timer: 3000,
        });
      });
  };

  const getLogData = async (id: string) => {
    await services
      .GetLogById(id)
      .then((response) => {
        setAssunto(response.data.assunto);
        setDescricao(response.data.descricao);
        setAnexoName(response.data.anexoName ?? "");
      })
      .catch((e: AxiosError) => {
        var errorMessage: string = e.response
          ? String(e.response?.data)
          : "Houve um erro ao buscar os dados.";

        Swal.fire({
          position: "top-end",
          toast: true,
          icon: "warning",
          title: errorMessage,
          showConfirmButton: false,
          showCloseButton: true,
          timer: 3000,
        });
      });
  };

  const handleEditButton = async (id: string) => {
    setIdLog(id);
    getLogData(id);
  };

  const columns: GridColDef[] = [
    {
      field: "createdAt",
      headerName: "Data",
      renderCell: (params: GridCellParams<ILog>) => (
        <D.GridField>
          {new Date(params.row.createdAt).toLocaleDateString()}
        </D.GridField>
      ),
    },
    { field: "criadoPor", headerName: "Responsável", flex: 1 },
    {
      field: "assunto",
      headerName: "Assunto",
      flex: 1,
      renderCell: (params: GridCellParams<ILog>) => (
        <D.GridField>
          {params.row.assunto}
          <Tooltip title={params.row.descricao} arrow>
            <IconButton>
              <InfoOutlinedIcon />
            </IconButton>
          </Tooltip>
        </D.GridField>
      ),
    },
    {
      field: "opcoes",
      headerName: "Opções",
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params: GridCellParams<ILog>) => (
        <D.GridField style={{ justifyContent: "flex-end" }}>
          {params.row.anexoId && (
            <D.ModalGridDownloadButton
              startIcon={<DownloadIcon />}
              size="small"
              variant="outlined"
              onClick={() => downloadConta(params.row.anexoId!)}
            >
              Baixar
            </D.ModalGridDownloadButton>
          )}
          <D.ModalGridDeleteButton
            variant="outlined"
            size="small"
            startIcon={<DeleteIcon />}
            onClick={() => handleDeleteLog(params.row.id)}
          >
            Deletar
          </D.ModalGridDeleteButton>
          <D.ModalGridEditButton
            variant="outlined"
            size="small"
            startIcon={<EditIcon />}
            onClick={() => handleEditButton(params.row.id)}
          >
            Editar
          </D.ModalGridEditButton>
        </D.GridField>
      ),
    },
  ];

  useEffect(() => {
    getNewHistoricData(assunto, descricao, archive);
  }, [assunto, descricao, archive]);

  useEffect(() => {
    cancelAddHistorico();
  }, [tableData]);

  const cancelAddHistorico = () => {
    setAssunto("");
    setDescricao("");
    setArchive(null);
    setLoading(false);
    setAddHistorico(false);
    setIdLog(null);
  };

  const handleSaveClick = async () => {
    setLoading(true);
    await saveFunction();
    setLoading(false);
  };

  const paginationModel = { page: 0, pageSize: 5 };

  return (
    <D.ModalCard>
      {idLog ? (
        <>
          <D.FWStack direction={"row"} justifyContent={"flex-end"} spacing={2}>
            <IconButton onClick={cancelAddHistorico}>
              <ClearIcon />
            </IconButton>
          </D.FWStack>
          <D.FWStack direction={"column"} spacing={2}>
            <TextField
              label={"Assunto"}
              fullWidth
              value={assunto}
              onChange={(e) => setAssunto(e.target.value)}
            />
            <TextField
              label={"Descrição do documento"}
              fullWidth
              multiline
              rows={3}
              value={descricao}
              onChange={(e) => setDescricao(e.target.value)}
            />
            <D.FWStack direction={"row"} spacing={2}>
              <D.ContainedButton
                variant="contained"
                sx={{ width: "50%" }}
                startIcon={<PublishIcon />}
                onClick={() => inputRef.current?.click()}
              >
                Anexar arquivo
              </D.ContainedButton>
              <D.VisuallyHiddenInput
                ref={inputRef}
                type="file"
                onChange={(e) => setArchive(e.target.files![0])}
              />
              {archive ? (
                <TextField
                  label="Nome do arquivo"
                  fullWidth
                  value={archive ? archive.name : ""}
                  inputProps={{ readOnly: true }}
                />
              ) : (
                <TextField
                  label="Nome do arquivo"
                  fullWidth
                  value={anexoName}
                  inputProps={{ readOnly: true }}
                />
              )}

              {archive != null && (
                <IconButton onClick={() => setArchive(null)}>
                  <ClearIcon />
                </IconButton>
              )}
            </D.FWStack>
            <D.FWStack
              direction={"row"}
              justifyContent={"flex-end"}
              spacing={2}
            >
              <D.ContainedButton $color="yellow" onClick={editLog}>
                Salvar
              </D.ContainedButton>
            </D.FWStack>
          </D.FWStack>
        </>
      ) : (
        <>
          <D.FWStack direction={"row"} justifyContent={"flex-end"} spacing={2}>
            <D.ContainedButton
              $color="yellow"
              onClick={() => setAddHistorico(true)}
              disabled={addHistorico}
            >
              Incluir ao histórico
            </D.ContainedButton>
            {addHistorico && (
              <D.OutlineButton
                $color="pink"
                onClick={cancelAddHistorico}
                disabled={!addHistorico}
              >
                Cancelar
              </D.OutlineButton>
            )}
          </D.FWStack>
          {addHistorico && (
            <D.FWStack direction={"column"} spacing={2}>
              <TextField
                label={"Assunto"}
                fullWidth
                value={assunto}
                onChange={(e) => setAssunto(e.target.value)}
              />
              <TextField
                label={"Descrição do documento"}
                fullWidth
                multiline
                rows={3}
                value={descricao}
                onChange={(e) => setDescricao(e.target.value)}
              />
              <D.FWStack direction={"row"} spacing={2}>
                <D.ContainedButton
                  variant="contained"
                  sx={{ width: "50%" }}
                  startIcon={<PublishIcon />}
                  onClick={() => inputRef.current?.click()}
                >
                  Anexar arquivo
                </D.ContainedButton>
                <D.VisuallyHiddenInput
                  ref={inputRef}
                  type="file"
                  onChange={(e) => setArchive(e.target.files![0])}
                />
                <TextField
                  label="Nome do arquivo"
                  fullWidth
                  value={archive ? archive.name : ""}
                  inputProps={{ readOnly: true }}
                />

                {archive != null && (
                  <IconButton onClick={() => setArchive(null)}>
                    <ClearIcon />
                  </IconButton>
                )}
              </D.FWStack>
              <D.FWStack direction={"row"} justifyContent={"flex-end"}>
                <D.ContainedButton onClick={handleSaveClick}>
                  {loading ? (
                    <CircularProgress size={20} color="secondary" />
                  ) : (
                    <>Salvar</>
                  )}
                </D.ContainedButton>
              </D.FWStack>
            </D.FWStack>
          )}
          <D.FWStack>
            {tableData.length > 0 ? (
              <DataGrid
                rows={tableData}
                disableColumnSelector
                disableRowSelectionOnClick
                localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
                columns={columns}
                initialState={{ pagination: { paginationModel } }}
                pageSizeOptions={[5, 10, 25, 50, 100]}
                sx={{ border: 0 }}
              />
            ) : (
              <>Não há dados.</>
            )}
          </D.FWStack>
        </>
      )}
    </D.ModalCard>
  );
};

export default HistoricoCard;
