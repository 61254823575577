import { Box } from "@mui/material";
import { AxiosError } from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import DefaultModal from "../../../../../components/Modal";
import { ICreateGDContratoCliente } from "../../../../../models/GDContrato";
import * as services from "../../../../../services/api/GDContratoClienteService";
import * as D from "../../../../../styles/appComponents";

type PropsModalContratar = {
  openModalContratar: boolean;
  handleCloseModalContratar: () => void;
  uuid?: string;
};

const ModalContratar = (props: PropsModalContratar) => {
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState<boolean>(false);

  let { openModalContratar, handleCloseModalContratar, uuid } = props;

  const handleClick = async (type: "Fisica" | "Juridica") => {
    if (!uuid) {
      Swal.fire({
        position: "top-end",
        toast: true,
        icon: "warning",
        title: "Id não encontrado.",
        showConfirmButton: false,
        showCloseButton: true,
        timer: 3000,
      });

      return;
    }

    var isPF: boolean = type == "Fisica";

    var data: ICreateGDContratoCliente = {
      isPF: isPF,
      propostaId: uuid!,
    };

    await services
      .CreateGDContrato(data)
      .then((response) => {
        navigate(`/Parceiro/Editar/Contrato/${response.data}`);
      })
      .catch((e: AxiosError) => {
        var errorMessage: string = e.response
          ? String(e.response?.data)
          : "Houve um erro ao pegar os dados.";

        Swal.fire({
          position: "top-end",
          toast: true,
          icon: "warning",
          title: errorMessage,
          showConfirmButton: false,
          showCloseButton: true,
          timer: 3000,
        });
      });
  };

  useEffect(() => {
    setOpenModal(openModalContratar);
  }, [openModalContratar]);

  return (
    <>
      <DefaultModal
        handleCloseModal={handleCloseModalContratar}
        openModal={openModalContratar}
      >
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: "20px",
            textAlign: "center",
            width: "100%",
            height: "100%",
            marginTop: "-50px",
          }}
        >
          <h1 style={{ fontWeight: 500, fontSize: "1.8rem", margin: "0" }}>
            Quem está contratando o serviço?
          </h1>
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              gap: "15px",
            }}
          >
            <D.OutlineButton
              $color="lightpink"
              onClick={() => handleClick("Fisica")}
              style={{
                padding: "12px 24px",
                fontSize: "1.2rem",
              }}
            >
              Pessoa física
            </D.OutlineButton>

            <D.ContainedButton
              $color="lightpink"
              onClick={() => handleClick("Juridica")}
              style={{
                padding: "12px 24px",
                fontSize: "1.2rem",
              }}
            >
              Pessoa jurídica
            </D.ContainedButton>
          </Box>
        </Box>
      </DefaultModal>
    </>
  );
};

export default ModalContratar;
