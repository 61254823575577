export enum EnumHorasSemanais {
    quarentaEQuatroHoras = 0,
    trintaHoras = 1,
    vinteHoras = 2,
}

export const enumHorasSemanaisMap = [
    {
        name:"44 horas",
        value: EnumHorasSemanais.quarentaEQuatroHoras
    },
    {
        name:"30 horas",
        value: EnumHorasSemanais.trintaHoras
    },
    {
        name:"20 horas",
        value: EnumHorasSemanais.vinteHoras
    }
];
  