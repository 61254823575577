export const convertEmptyStringsToNullAsync = async <
  T extends Record<string, any>
>(
  obj: T
): Promise<T> => {
  const updatedObj = { ...obj };

  await Promise.all(
    (Object.keys(updatedObj) as (keyof T)[]).map(async (key) => {
      const value = updatedObj[key];
      if (typeof value === "string" && value === "") {
        updatedObj[key] = null as T[keyof T]; // Garante que `null` seja atribuído de forma segura.
      }
    })
  );

  return updatedObj;
};
