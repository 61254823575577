import { InformacaoContratoGenerate } from "../../../../../models/Gerador/Cadastro/InformacaoContratoGenerate";
import { AbstractValidation, Validate } from "../../../../../utils/AbstractValidation";

export class GenerateContratoValidate 
extends AbstractValidation<InformacaoContratoGenerate>{
    getValidate(): Validate<InformacaoContratoGenerate>[] {
        return[
            {
                field:"tipotarifa",
                validate(value, model:InformacaoContratoGenerate, addError: (message:string) => void) {
                    if(value === null) addError("Primeiro deve selecionar a tarifa")
                },
            },
            {
                field:"porcentagemdescontotarifa",
                validate(value, model:InformacaoContratoGenerate, addError: (message:string) => void) {
                    if(!value) addError("O Valor de percentual de desconto deve ser informado")
                },
            },
            {
                field:"tarifavigentecontrato",
                validate(value, model:InformacaoContratoGenerate, addError: (message:string) => void) {
                    if(!value) addError("O Valor de tarifa vigente deve ser informado")
                },
            },
            {
                field:"potencia",
                validate(value, model:InformacaoContratoGenerate, addError: (message:string) => void) {
                    if(!value) addError("O Valor de potência deve ser informado")
                },
            },
            {
                field:"performacealvo",
                validate(value, model:InformacaoContratoGenerate, addError: (message:string) => void) {
                    if(!value) addError("O Valor de performance alvo deve ser informado")
                },
            },
            {
                field:"vigenciacontratual",
                validate(value, model:InformacaoContratoGenerate, addError: (message:string) => void) {
                    if(!value) addError("A Vigência do contrato deve ser informado")
                },
            },
            {
                field:"datalimiteconexao",
                validate(value, model:InformacaoContratoGenerate, addError: (message:string) => void) {
                    if(!value) addError("A Data de Limite de Conexão deve ser informado")
                },
            },
            {
                field:"tipoconexao",
                validate(value, model:InformacaoContratoGenerate, addError: (message:string) => void) {
                    if(value === null) addError("O Tipo de Conexão deve ser informado")
                },
            },
            {
                field:"nomeresponsavel",
                validate(value, model:InformacaoContratoGenerate, addError: (message:string) => void) {
                    if(!value) addError("O Nome do responsavel deve ser informado")
                },
            },
            {
                field:"celularresponsavel",
                validate(value, model:InformacaoContratoGenerate, addError: (message:string) => void) {
                    if(!value) addError("O Celular do responsavel deve ser informado")
                },
            },
            {
                field:"cpfresponsavel",
                validate(value, model:InformacaoContratoGenerate, addError: (message:string) => void) {
                    if(!value) addError("O CPF do responsavel deve ser informado")
                },
            },
            {
                field:"datanascimentoresponsavel",
                validate(value, model:InformacaoContratoGenerate, addError: (message:string) => void) {
                    if(!value) addError("A Data de Nascimento do responsavel deve ser informado")
                },
            },
            {
                field:"datanascimentoresponsavel",
                validate(value, model:InformacaoContratoGenerate, addError: (message:string) => void) {
                    const now = new Date();
                    const limitDate = new Date(now.getFullYear() - 18, now.getMonth(), now.getDate());
                    if(value && (new Date(value) > limitDate)) addError("A idade deve ser de pelo menos 18 anos.")
                },
            },
            {
                field:"cepresponsavel",
                validate(value, model:InformacaoContratoGenerate, addError: (message:string) => void) {
                    if(!value) addError("O CEP do responsavel deve ser informado")
                },
            },
            {
                field:"ufresponsavel",
                validate(value, model:InformacaoContratoGenerate, addError: (message:string) => void) {
                    if(!value) addError("O Estado do responsavel deve ser informado")
                },
            },
            {
                field:"cidaderesponsavel",
                validate(value, model:InformacaoContratoGenerate, addError: (message:string) => void) {
                    if(!value) addError("A Cidade do responsavel deve ser informado")
                },
            },
            {
                field:"bairroresponsavel",
                validate(value, model:InformacaoContratoGenerate, addError: (message:string) => void) {
                    if(!value) addError("O Bairro do responsavel deve ser informado")
                },
            },
            {
                field:"numeroresponsavel",
                validate(value, model:InformacaoContratoGenerate, addError: (message:string) => void) {
                    if(!value) addError("O Numero de endereço do responsavel deve ser informado")
                },
            },
            {
                field:"logradouroresponsavel",
                validate(value, model:InformacaoContratoGenerate, addError: (message:string) => void) {
                    if(!value) addError("O Logradouro do responsavel deve ser informado")
                },
            }
        ];
    }
}