import RegisterCard from "../../../../../../../components/RegisterCard";
import React, { ChangeEvent, FC, useEffect, useReducer, useState } from "react";
import * as G from "../../style";
import { Checkbox, MenuItem, Stack, TextField } from "@mui/material";
import * as D from "../../../../../../../styles/appComponents";
import * as C from "../Situacao/style";
import {EnumIncidencia} from "../../../../../../../enums/EnumIncidencia";
import { ErrorMessage } from "../../../../../../../utils/AbstractValidation";
import { handleStyleFieldError } from "../../../../../../../utils/verifyFieldError";
import { EnumContribuicaoSindical, enumContribuicaoSindicalMap } from "../../../../../../../enums/Colaborador/EnumContribuicaoSindical.enum";
import { Console } from "console";
import { EnumTipoVaga, enumTipoVagaMap } from "../../../../../../../enums/Colaborador/EnumTipoVaga.enum";
import { EnumHorasSemanais, enumHorasSemanaisMap } from "../../../../../../../enums/Colaborador/EnumHorasSemanais.enum";
import MaskDefaultInput from "../../../../../../../components/MasDefaultinput/MaskDefaultInput";
import { EnumFuncaoColaborador, enumFuncaoColaboradorMap } from "../../../../../../../enums/Colaborador/EnumFuncaoColaborador.enum";
import { ColaboradorByIdResponse } from "../../../../../../../models/Colaborador/Colaborador";
import { MaskCurrencyInput } from "../../../../../../../components/MakCurrencyInput";

enum FormSalarioFuncao {
  SALARIOBASE = "SALARIOBASE",
  FUNCAO = "FUNCAO",
  PARTIDOSINDICATO = "PARTIDOSINDICATO",
  BOOLPERIODOEXPERIENCIA = "BOOLPERIODOEXPERIENCIA",
  INICIOPERIODOEXPERIENCIA = "INICIOPERIODOEXPERIENCIA",
  FIMPERIODOEXPERIENCIA = "FIMPERIODOEXPERIENCIA",
  BOOLINCIDEINSS = "BOOLINCIDEINSS",
  BOOLINCIDEIRF = "BOOLINCIDEIRF",
  BOOLINCIDEFGTS = "BOOLINCIDEFGTS",
  ENUMCONTRIBUICAOSINDICAL = "ENUMCONTRIBUICAOSINDICAL",
  ENUMTIPOVAGA = "ENUMTIPOVAGA",
  ENUMHORASSEMANAIS = "ENUMHORASSEMANAIS",
}

export interface IFormSalarioFuncao {
  salariobase: any,
  funcao: EnumFuncaoColaborador,
  partidosindicato: string | null | undefined,
  boolperiodoexperiencia: Boolean | undefined,
  inicioperiodoexperiencia: Date | null | undefined,
  fimperiodoexperiencia: Date | null | undefined,
  boolincideinss: Boolean | undefined,
  boolincideirf: Boolean | undefined,
  boolincidefgts: Boolean | undefined,
  enumcontribuicaosindical: EnumContribuicaoSindical | undefined,
  enumtipovaga: EnumTipoVaga | undefined,
  enumhorassemanais: EnumHorasSemanais | undefined,
}

type PropsSalarioFuncao = {
  onSave: (data: IFormSalarioFuncao) => void;
  onUpdate: (data: IFormSalarioFuncao) => void;
  onChange: (data: IFormSalarioFuncao) => void;
  fieldErros: ErrorMessage[] | null;
  buttonSave: boolean;
  data?: object | null;
  colaboradorId: string | undefined;
  colaborador: ColaboradorByIdResponse | undefined;
  load: { status:boolean, section:string };
};

const incidenciaMapping = {
  [EnumIncidencia.incidencia]: "BOOLINCIDEINSS",
  [EnumIncidencia.incidencia_aceptada]: "BOOLINCIDEIRF",
  [EnumIncidencia.incidencia_rechazada]: "BOOLINCIDEFGTS",
};

const SalarioFuncao:FC<PropsSalarioFuncao> = (
  {
    onSave,
    onUpdate,
    onChange,
    data,
    buttonSave,
    fieldErros,
    colaborador,
    colaboradorId,
    load
  }
) => {
  const salarioFuncaoFormModel:IFormSalarioFuncao = {
    salariobase: null,
    funcao: EnumFuncaoColaborador.backoffice,
    partidosindicato: null,
    boolperiodoexperiencia: false,
    inicioperiodoexperiencia: null,
    fimperiodoexperiencia: null,
    boolincideinss: false,
    boolincideirf: false,
    boolincidefgts: false,
    enumcontribuicaosindical: EnumContribuicaoSindical.isento,
    enumtipovaga: EnumTipoVaga.tempoIntegral,
    enumhorassemanais: EnumHorasSemanais.quarentaEQuatroHoras,
  };
  const [dadosSalarioFuncao,setDadosSalarioFuncao] = useState<IFormSalarioFuncao>(salarioFuncaoFormModel)
  const sendOnSave = (): void => onSave(dadosSalarioFuncao);
  const sendOnUpdate = (): void => onUpdate(dadosSalarioFuncao);
  const sendOnChange = (update: IFormSalarioFuncao): void => onChange(update);

  useEffect(() => {
    const salarioFuncaoFormModel:IFormSalarioFuncao = {
      salariobase: colaborador?.salarioBase,
      funcao: colaborador?.funcao as EnumFuncaoColaborador,
      partidosindicato: colaborador?.partidoSindicato,
      boolperiodoexperiencia: colaborador?.boolPeriodoExperiencia,
      inicioperiodoexperiencia: colaborador?.inicioPeriodoExperiencia,
      fimperiodoexperiencia: colaborador?.fimPeriodoExperiencia,
      boolincideinss: colaborador?.boolIncideINSS,
      boolincideirf: colaborador?.boolIncideIRF,
      boolincidefgts: colaborador?.boolIncideFGTS,
      enumcontribuicaosindical: colaborador?.enumContribuicaoSindical as EnumContribuicaoSindical | undefined,
      enumtipovaga: colaborador?.enumTipoVaga as EnumTipoVaga | undefined,
      enumhorassemanais: colaborador?.enumHorasSemanais as EnumHorasSemanais | undefined,
    };
    setDadosSalarioFuncao(salarioFuncaoFormModel);

    setSelectedIncidencias((prev:any) => ({
      ...prev,
      ["BOOLINCIDEINSS"]: colaborador?.boolIncideINSS,
    }));

    setSelectedIncidencias((prev:any) => ({
      ...prev,
      ["BOOLINCIDEIRF"]: colaborador?.boolIncideIRF,
    }));

    setSelectedIncidencias((prev:any) => ({
      ...prev,
      ["BOOLINCIDEFGTS"]: colaborador?.boolIncideFGTS,
    }));

  }, [colaboradorId, colaborador])

  const [selectedIncidencias, setSelectedIncidencias] = useState<{ [key: string]: boolean }>({
    BOOLINCIDEINSS: false,
    BOOLINCIDEIRF: false,
    BOOLINCIDEFGTS: false,
  });

  const handleInputChange = ({target}: React.ChangeEvent<HTMLInputElement>) => {
        const name = target.name.toLocaleLowerCase();
        const value = target.value;
    
        setDadosSalarioFuncao((state:IFormSalarioFuncao) => {
          sendOnChange({...state, [name]: value});
          return {...state, [name]: value};
    
        });
  }

  const handleChangeSelectIncidencia = (event: React.ChangeEvent<HTMLInputElement>, index: number): void => {
    const { value, checked } = event.target;
    const field = incidenciaMapping[value as keyof typeof incidenciaMapping];
    if (field) {
      setSelectedIncidencias(prev => ({
        ...prev,
        [field]: checked,
      }));

      handleInputChange(
        {
          target:{
            name:FormSalarioFuncao[field as keyof typeof FormSalarioFuncao],
            value: checked
          }
        } as any
      );
    }
  };

  return (
    <RegisterCard title="Salário/função">
      <G.FWStack direction={"row"} spacing={2}>
        <MaskCurrencyInput
          sx={handleStyleFieldError(FormSalarioFuncao.SALARIOBASE, fieldErros, { width:"100%" })}
          name={FormSalarioFuncao.SALARIOBASE}
          label="Salário base"
          placeholder="R$ 00,00"
          defaultValue={0}
          decimalsLimit={2}
          value={dadosSalarioFuncao?.salariobase ?? ""}
          prefixo="R$ "
          disabled={buttonSave}
          onValueChange={(newValue, name, values) => handleInputChange(
            { target: { name, value: newValue } } as React.ChangeEvent<HTMLInputElement>
          )}
        />
        <TextField
          select
          sx={handleStyleFieldError(FormSalarioFuncao.FUNCAO, fieldErros)}
          name={FormSalarioFuncao.FUNCAO}
          onChange={handleInputChange}
          value={dadosSalarioFuncao?.funcao ?? ""}
          inputProps={{ readOnly: buttonSave }} 
          label="Função" 
          fullWidth
        >
          {
            enumFuncaoColaboradorMap.map((x, index) => (
              <MenuItem key={index} value={x.value}>
                {x.name}
              </MenuItem>
            ))
          }
        </TextField>
        <TextField
          sx={handleStyleFieldError(FormSalarioFuncao.PARTIDOSINDICATO, fieldErros)}
          name={FormSalarioFuncao.PARTIDOSINDICATO}
          onChange={handleInputChange}
          value={dadosSalarioFuncao?.partidosindicato ?? ""}
          inputProps={{ readOnly: buttonSave }} 
          label="Partido sindicato" 
          fullWidth 
        />
        <TextField
          sx={handleStyleFieldError(FormSalarioFuncao.PARTIDOSINDICATO, fieldErros)}
          name={FormSalarioFuncao.PARTIDOSINDICATO}
          onChange={(e:React.ChangeEvent<HTMLInputElement>) => handleInputChange(
            {
              target:{
                name:FormSalarioFuncao.BOOLPERIODOEXPERIENCIA,
                value:e.target.value === "true"
              }
            } as any
          )}
          value={dadosSalarioFuncao?.boolperiodoexperiencia ?? ""}
          inputProps={{ readOnly: buttonSave }} 
          fullWidth
          select
          label="Periodo de experiência"
        >
          <MenuItem   value={"true"}>Sim</MenuItem>
          <MenuItem  value={"false"}>Não</MenuItem>
        </TextField>
      </G.FWStack>
      <G.FWStack direction={"row"} spacing={2}>
        {dadosSalarioFuncao?.boolperiodoexperiencia && (
          <>
            <D.DateTextfield
            sx={handleStyleFieldError(FormSalarioFuncao.INICIOPERIODOEXPERIENCIA, fieldErros, { width: "20%" })}
            name={FormSalarioFuncao.INICIOPERIODOEXPERIENCIA}
            onChange={handleInputChange}
            value={
              ((dadosSalarioFuncao?.inicioperiodoexperiencia ?? "")
              .toString())?.split("T")[0]
            }
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{ readOnly: buttonSave }} 
            type="date" 
            label="Inicio" 
          />

            <D.DateTextfield
            sx={handleStyleFieldError(FormSalarioFuncao.FIMPERIODOEXPERIENCIA, fieldErros, { width: "20%" })}
            name={FormSalarioFuncao.FIMPERIODOEXPERIENCIA}
            onChange={handleInputChange}
            inputProps={{ readOnly: buttonSave }}
            value={
              ((dadosSalarioFuncao?.fimperiodoexperiencia ?? "")
              .toString())?.split("T")[0]
            }
            InputLabelProps={{
              shrink: true,
            }}
            type="date" 
            label="Fim" 
          />

          </>
        )}
      </G.FWStack>
      <G.FWStack direction={"row"} spacing={8}>
        <Stack direction={"column"} spacing={2} alignItems={"flex-start"}>
          <G.Title>Incidência</G.Title>
          {Object.values(EnumIncidencia).map((value, index) => (
            <G.CheckboxWrapper key={value}>
              <Checkbox
                disabled={buttonSave}
                checked={selectedIncidencias[incidenciaMapping[value]]}
                value={value}
                onChange={(e:React.ChangeEvent<HTMLInputElement>) => handleChangeSelectIncidencia(e, index)}
              />
              <p>{value}</p>
            </G.CheckboxWrapper>
          ))}
        </Stack>
        <Stack direction={"column"} spacing={2} alignItems={"flex-start"}>
          <G.Title>Contribuição sindical</G.Title>
          {enumContribuicaoSindicalMap.map((x, index) => (
            <G.CheckboxWrapper >
              <Checkbox
                disabled={buttonSave}
                checked={dadosSalarioFuncao?.enumcontribuicaosindical === x.value}
                value={x.value}
                onChange={(e) => handleInputChange(
                  {
                    target: {
                      name: FormSalarioFuncao.ENUMCONTRIBUICAOSINDICAL,
                      value: parseInt(e.target.value)
                    }
                  } as any
                )}
              />
              <p>{x.name}</p>
            </G.CheckboxWrapper>
          ))}
        </Stack>
        <Stack direction={"column"} spacing={2} alignItems={"flex-start"}>
          <G.Title>Tipo de vaga</G.Title>
          {enumTipoVagaMap.map((x, index) => (
            <G.CheckboxWrapper key={index}>
              <Checkbox
                disabled={buttonSave}
                checked={dadosSalarioFuncao?.enumtipovaga === x.value}
                value={x.value}
                onChange={({target}) => handleInputChange(
                  {
                    target:{
                      name:FormSalarioFuncao.ENUMTIPOVAGA,
                      value:parseInt(target.value) 
                    }
                  } as any
                )}
              />
              <p>{x.name}</p>
            </G.CheckboxWrapper>
          ))}
        </Stack>
        <Stack direction={"column"} spacing={2} alignItems={"flex-start"}>
          <G.Title>Horas semanais</G.Title>
          {enumHorasSemanaisMap.map((x, index) => (
            <G.CheckboxWrapper key={index}>
              <Checkbox
                disabled={buttonSave}
                checked={dadosSalarioFuncao?.enumhorassemanais === x.value}
                value={x.value}
                onChange={({target}) => handleInputChange(
                  {
                    target:{
                      name:FormSalarioFuncao.ENUMHORASSEMANAIS,
                      value:parseInt(target.value) 
                    }
                  } as any
                )}
              />
              <p>{x.name}</p>
            </G.CheckboxWrapper>
          ))}
        </Stack>
      </G.FWStack>
      <C.FWStack direction={"row"} spacing={2} justifyContent={"flex-end"}>
        <D.ContainedButton disabled={!buttonSave} $color="yellow" onClick={sendOnUpdate}>Editar</D.ContainedButton>
        <D.ContainedButton disabled={buttonSave} onClick={sendOnSave}>Salvar</D.ContainedButton>
      </C.FWStack>
    </RegisterCard>
  );
};

export default SalarioFuncao;
