import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import ReadonlyArray from "../../types/ReadonlyArray";

interface ISelectInputProps<T, A extends string | number> {
  idSelect?: string;
  label: string;
  values: ReadonlyArray<T>;
  getValue: (dados: T) => A;
  getRender?: (dados: T) => JSX.Element;
  value: A | null;
  onChange: (newValue: A | null) => void;
  readOnly?: boolean;
}

function SelectInput<T, A extends string | number>({
  label,
  idSelect = "id-select",
  value,
  values,
  getValue,
  getRender = (dados) => <>{getValue(dados)}</>,
  onChange,
  readOnly,
}: ISelectInputProps<T, A>): JSX.Element {
  const idLabel = `${idSelect}-label`;

  return (
    <FormControl fullWidth>
      <InputLabel id={idLabel}>{label}</InputLabel>
      <Select
        labelId={idLabel}
        id={idSelect}
        label={label}
        value={value ?? ""}
        onChange={(e) => onChange(e.target.value as A)}
        readOnly={readOnly}
      >
        {values.map((v) => {
          const valueMenu = getValue(v);
          return (
            <MenuItem key={valueMenu} value={valueMenu}>
              {getRender(v)}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}
export default SelectInput;
