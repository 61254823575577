import http from "../common/http-common";
import {IPaginatedList} from "../../models/PaginatedList";
import {AxiosResponse} from "axios";
import {
  IDashboardOrdemPagamento,
  INovaOrdem,
  INovoPagamento,
  IOrdemPagamento,
  IReportOrdemPagamento
} from "../../models/OrdemPagamento";
import {IContaOrdemPagamento} from "../../models/Conta";


export const GetOrdensPagamento: (pageNumber: number, pageSize: number, search?: string) => Promise<AxiosResponse<IPaginatedList<IOrdemPagamento>, any>> = (
  pageNumber: number,
  pageSize: number,
  search?: string
): Promise<AxiosResponse<IPaginatedList<IOrdemPagamento>, any>> => {
  var searchString: string = "";

  if (search) {
    searchString = `&Search=${search}`;
  }

  return http.get<IPaginatedList<IOrdemPagamento>>(
    `/OrdemPagamento?pageNumber=${pageNumber}&pageSize=${pageSize}${searchString}`
  );
};

export const GetConta: (id: string) => Promise<AxiosResponse<INovoPagamento, any>> = (
  id: string,
): Promise<AxiosResponse<INovoPagamento, any>> => {
  return http.get(
    `/conta/${id}`
  );
};

export const GetOrdemPagamento: (id: string) => Promise<AxiosResponse<IContaOrdemPagamento, any>> = (
  id: string,
): Promise<AxiosResponse<IContaOrdemPagamento, any>> => {
  return http.get(
    `/ordemPagamento/getById?id=${id}`
  );
};

export const GetOrdemPagamentoReport = (
  filters: IReportOrdemPagamento,
) => {
  const queryParams: string[] = [];

  if(filters.stausContrato) {
    queryParams.push(`stausContrato=${filters.stausContrato}`);
  }
  if(filters.gerador) {
    queryParams.push(`gerador=${filters.gerador}`);
  }
  if(filters.dataInicial) {
    queryParams.push(`dataInicial=${filters.dataInicial}`);
  }
  if(filters.dataFinal) {
    queryParams.push(`dataFinal=${filters.dataFinal}`);
  }

  const queryString = queryParams.length > 0 ? `?${queryParams.join("&")}` : "";

  return http.get<File>(`/OrdemPagamento/BuscaRelatorioOrdemPagamento${queryString}`, {
    responseType: "blob",
  });
};


export const GetDashboardOrdemPagamento: () => Promise<AxiosResponse<any, IDashboardOrdemPagamento>> = (): Promise<AxiosResponse<any, IDashboardOrdemPagamento>> => {
  return http.get(`/OrdemPagamento/Dashboard`);
};

export const SetOrdemPagamento: (data: INovaOrdem) => Promise<AxiosResponse<INovaOrdem, any>> = (data: INovaOrdem): Promise<AxiosResponse<INovaOrdem, any>> => {
  const formData = new FormData();

  if (data.dataReferencia) {
    formData.append("dataReferencia", data.dataReferencia as unknown as string)
  }
  if (data.instalacao) {
    formData.append("instalacao", data.instalacao)
  }
  if (data.sigla) {
    formData.append("sigla", data.sigla)
  }
  if (data.descontoTarifa) {
    formData.append("descontoTarifa", data.descontoTarifa as unknown as string)
  }
  if (data.energiaDistribuidora) {
    formData.append("energiaDistribuidora", data.energiaDistribuidora as unknown as string)
  }
  if (data.energiaGerador) {
    formData.append("energiaGerador", data.energiaGerador as unknown as string)
  }
  if (data.tarifaDistribuidora) {
    formData.append("tarifaDistribuidora", data.tarifaDistribuidora as unknown as string)
  }
  if (data.tarifaFioB) {
    formData.append("tarifaFioB", data.tarifaFioB as unknown as string)
  }
  if (typeof(data.tipoConexao) !== "undefined") {
    formData.append("tipoConexao", data.tipoConexao as unknown as string)
  }
  if (data.dataLimiteQuitacao) {
    formData.append("dataLimiteQuitacao", data.dataLimiteQuitacao as unknown as string)
  }
  if (data.status) {
    formData.append("status", data.status as unknown as string)
  }
  if (data.comprovante) {
    formData.append("comprovante", data.comprovante)
  }
  if (data.usinaId) {
    formData.append("usinaId", data.usinaId)
  }

  return http.post<INovaOrdem>(`/OrdemPagamento`, formData);
};
export const updateOrdemPagamento: (data: INovaOrdem) => Promise<AxiosResponse<INovaOrdem, any>> = (data: INovaOrdem): Promise<AxiosResponse<INovaOrdem, any>> => {
  const formData = new FormData();

  if (data.id) {
    formData.append("id", data.id as unknown as string)
  }
  if (data.dataReferencia) {
    formData.append("dataReferencia", data.dataReferencia as unknown as string)
  }
  if (data.instalacao) {
    formData.append("instalacao", data.instalacao)
  }
  if (data.sigla) {
    formData.append("sigla", data.sigla)
  }
  if (data.descontoTarifa) {
    formData.append("descontoTarifa", data.descontoTarifa as unknown as string)
  }
  if (data.energiaDistribuidora) {
    formData.append("energiaDistribuidora", data.energiaDistribuidora as unknown as string)
  }
  if (data.energiaGerador) {
    formData.append("energiaGerador", data.energiaGerador as unknown as string)
  }
  if (data.tarifaDistribuidora) {
    formData.append("tarifaDistribuidora", data.tarifaDistribuidora as unknown as string)
  }
  if (data.tarifaFioB) {
    formData.append("tarifaFioB", data.tarifaFioB as unknown as string)
  }
  if (typeof(data.tipoConexao) !== "undefined") {
    formData.append("tipoConexao", data.tipoConexao as unknown as string)
  }
  if (data.dataLimiteQuitacao) {
    formData.append("dataLimiteQuitacao", data.dataLimiteQuitacao as unknown as string)
  }
  if (data.status) {
    formData.append("status", data.status as unknown as string)
  }
  if (data.comprovante) {
    formData.append("comprovante", data.comprovante)
  }
  if (data.usinaId) {
    formData.append("usinaId", data.usinaId)
  }
  if (data.active != undefined) {
    formData.append("active", data.active as unknown as string)
  }
  return http.put<INovaOrdem>(`/OrdemPagamento`, formData);
};