import { IDadosUsinaPessoaFisica, IDadosUsinaPessoaJuridica } from "../../../../../models/Gerador/Cadastro/DadosUsina";
import { AbstractValidation, Validate } from "../../../../../utils/AbstractValidation";

export class RequiredFieldsPessoaJuridicaValidate extends AbstractValidation<IDadosUsinaPessoaJuridica>
{
    getValidate(): Validate<IDadosUsinaPessoaJuridica>[] {
        return [
            {
                field:"razaosocialnome",
                validate(value, model:IDadosUsinaPessoaJuridica, addError:(message:string) => void) {
                    if(!value) addError("Primeiro deve salvar a razão social da usina")
                },
            },
            {
                field:"instalacao",
                validate(value, model:IDadosUsinaPessoaJuridica, addError:(message:string) => void) {
                    if(!value) addError("Primeiro deve salvar a instalação da usina")
                },
            }
        ]
    }
}