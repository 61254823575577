import React, {useEffect, useRef, useState} from "react";
import * as D from "../../styles/appComponents";
import ListItem from "@mui/material/ListItem";
import List from "@mui/material/List";
import {Box, ListItemText, Typography} from "@mui/material";
import FileUploadIcon from '@mui/icons-material/FileUpload';

interface ButtonFileProps {
    multiple?: boolean;
    onFileChange?: (files: File) => void;
    acceptTypes?: string;
    setTitle?: string;
}

const ButtonFile: React.FC<ButtonFileProps> = (props: ButtonFileProps) => {
    const [selectedFiles, setSelectedFiles] = useState<File>()
    const {multiple, onFileChange, acceptTypes, setTitle} = props;
    const inputRef: React.RefObject<HTMLInputElement> = useRef<HTMLInputElement>(null);

    const handleFileChange: any = (event: React.ChangeEvent<HTMLInputElement>): any => {
        const files: FileList | null = event.target.files;
        if (files) {
            const fileArray: File[] | File = Array.from(files);
            setSelectedFiles(fileArray[0]);

            if (fileArray[0]) {
                if (onFileChange) {
                    onFileChange(fileArray[0]);
                }
            }
        }
    };

    const handleUploadClick: () => void = (): void => {
        inputRef.current?.click();
    };

    useEffect(() => {
        if(setTitle != undefined)
            setSelectedFiles({name: setTitle ?? "Nenhum selecionado"} as File)
    }, [setTitle])

    return (
        <div>
            <D.VisuallyHiddenInput
                ref={inputRef}
                type="file"
                multiple={multiple}
                accept={acceptTypes ?? "image/*"}
                onChange={handleFileChange}
            />
            <D.ContainedButton startIcon={<FileUploadIcon/>} $color={"blue"} onClick={handleUploadClick}
                               sx={{marginTop: 3}}>
                Anexar documento
            </D.ContainedButton>

            <Box>
                {selectedFiles ? (
                    <List sx={{padding: 0, margin: 0}}>
                        <ListItem sx={{margin: 0}} disablePadding>
                            <ListItemText sx={{margin: 0}}
                                          primary={selectedFiles.name.substring(0, 22) + (selectedFiles.name.length > 22 ? "..." : "")}/>
                        </ListItem>
                    </List>
                ) : (
                    <Typography color="textSecondary">
                        Nenhum selecionado
                    </Typography>
                )}
            </Box>
        </div>
    );
};

export default ButtonFile;
