import { Card, Stack } from "@mui/material";
import styled from "styled-components";

export const LoadContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const TitleArea = styled.div`
  display: flex;
  gap: 40px;

  h2 {
    margin: 0;
    font-size: 18px;
    font-weight: 400;
  }
`;

export const StatusArea = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  label {
    margin: 0;
    font-size: 14px;
    font-weight: 400;
  }
`;

export const FWStack = styled(Stack)`
  width: 100%;
`;
export const FWDiv = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 20px;

  img {
    max-width: 200px;
  }
`;

export const Subtitle = styled.h4`
  margin: 0;
  font-weight: 500;
  font-size: 14px;
`;

export const Text = styled.p`
  margin: 0;
  font-weight: 400;
  font-size: 14px;
`;

export const MWDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  gap: 20px;
`;

export const DivChild = styled.div`
  display: inherit;
  flex-direction: inherit;
  gap: inherit;
  width: 100%;
`;

export const BorderFWDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 10px;
  border: 1px solid #6b767f;
  margin-top: 50px;
`;

export const GridTitleArea = styled.div`
  padding: 20px;
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  gap: 13px;

  h2 {
    margin: 0;
    font-weight: 400;
  }

  svg {
  }
`;

interface ArchiveAreaProps {
  readOnly?: boolean;
}

export const ArchiveArea = styled(Card)<ArchiveAreaProps>(({ readOnly }) => ({
  borderRadius: "5px",
  background: "#f4f4f4",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  aspectRatio: "1/1",
  cursor: readOnly ? "default" : "pointer",
  transition: "box-shadow 0.3s ease-in-out, background 0.3s ease-in-out",
  "&:hover": {
    background: readOnly ? "#f4f4f4" : "#e0e0e0",
  },
  "& img": { width: "50%" },
  "& p": { margin: 0, color: "#262626", fontSize: "14px", fontWeight: 500 },
}));

interface LightBWAreaProps {
  minHeight?: string;
}

export const LightBWArea = styled("div")<LightBWAreaProps>(
  ({ minHeight = "auto" }) => ({
    borderRadius: "10px",
    background: "#f8f8f8",
    width: "100%",
    display: "flex",
    padding: "10px",
    minHeight: minHeight,
  })
);

export const StartTextTextfield = styled.span`
  color: #b3b3b3;
  margin-right: 10px;
`;

export const QRCodeWrapper = styled.div`
  width: 150px;
`;
