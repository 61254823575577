import { useNavigate, useParams } from "react-router-dom";
import * as C from "../style";
import StatusPropostaGerarProposta from "./StatusProposta";
import * as propostaService from "../../../../services/api/PropostaService";
import * as D from "../../../../styles/appComponents";
import { useEffect, useState } from "react";
import {
  IConexaoContaProposta,
  IContaCooperativaProposta,
  IContaDistribuidoraProposta,
  IDadosPessoaisEditarProposta,
  IGetCalculoResponse,
  IResumoFaturaProposta,
  IUpdateGDProposta,
} from "../../../../models/GDProposta";
import { AxiosError } from "axios";
import Swal from "sweetalert2";
import { EnumTipoConexaoValueMap } from "../../../../enums/EnumTipoConexao.enum";
import ConexaoContaEditarProposta from "./ConexaoConta";
import ContaCooperativaGerarProposta from "../../Leads/GerarProposta/ContaCooperativa";
import ContaDistribuidoraGerarProposta from "../../Leads/GerarProposta/ContaDistribuidora";
import ResumoFaturaGerarProposta from "../../Leads/GerarProposta/ResumoFatura";
import DadosPessoaisEditarProposta from "./DadosPessoais";
import * as services from "../../../../services/api/PropostaService";
import { SkeletonCreationCards } from "../../../../components/SkeletonLoads/CreationCards";

const EditarProposta = () => {
  const { idProposta } = useParams();
  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(true);

  const [status, setStatus] = useState<number | null>(null);
  const [initialStatus, setInitialStatus] = useState<number>(1);

  const [dadosPessoaisData, setDadosPessoaisData] =
    useState<IDadosPessoaisEditarProposta>({
      nome: "",
      cpf: "",
      celular: "",
      email: "",
      cooperativaId: "",
      leadId: "",
    });

  const [initialData, setInitialData] = useState<IDadosPessoaisEditarProposta>({
    nome: "",
    cpf: "",
    celular: "",
    email: "",
    cooperativaId: "",
    leadId: "",
  });

  const [conexaoContaData, setConexaoContaData] =
    useState<IConexaoContaProposta>({
      classe: "",
      conexao: "",
      consumoMensalkWh: "",
      iluminacaoPublica: "",
      numeroInstalacao: "",
      operadoraId: "",
      tarifaDistribuidora: "",
      valorConta: "",
      valorDesconto: "",
      tributosPisCofins: "",
    });

  const [conexaoContaInitialData, setConexaoContaInitialData] =
    useState<IConexaoContaProposta>({
      classe: "",
      conexao: "",
      consumoMensalkWh: "",
      iluminacaoPublica: "",
      numeroInstalacao: "",
      operadoraId: "",
      tarifaDistribuidora: "",
      valorConta: "",
      valorDesconto: "",
      tributosPisCofins: "",
    });

  const [contaCooperativaData, setContaCooperativaData] =
    useState<IContaCooperativaProposta>({
      energiaInjetadakWh: "",
      energiaInjetadaReais: "",
      valorCooperativa: "",
    });

  const [contaDistribuidoraData, setContaDistribuidoraData] =
    useState<IContaDistribuidoraProposta>({
      impostoTotalDistribuidora: "",
      taxaDisponibilidadekWh: "",
      taxaDisponibilidadeReais: "",
      totalContaDistribuidora: "",
    });

  const [resumoFaturaData, setResumoFaturaData] =
    useState<IResumoFaturaProposta>({
      contasEconomizadasAnualmente: "",
      economiaAnual: "",
      economiaMensalBruta: "",
      economiaMensalLiquida: "",
      tarifaCooperativa: "",
      valorTotal: "",
    });

  const getCalculoResponse = (data: IGetCalculoResponse) => {
    setContaCooperativaData({
      energiaInjetadakWh: data.kwhInjetado.toString(),
      energiaInjetadaReais: data.energiaInjetada.toString(),
      valorCooperativa: data.valorContaCooperativa.toString(),
    });
    setContaDistribuidoraData({
      impostoTotalDistribuidora: data.valorImpostosDistribuidora.toString(),
      taxaDisponibilidadekWh:
        EnumTipoConexaoValueMap[
          Number(
            conexaoContaData.conexao
          ) as keyof typeof EnumTipoConexaoValueMap
        ],
      taxaDisponibilidadeReais: data.valorTaxaDisponibilidade.toString(),
      totalContaDistribuidora: data.valorContaDistribuidora.toString(),
    });
    setResumoFaturaData({
      contasEconomizadasAnualmente: data.contasEconomizadas.toString(),
      economiaAnual: data.economiaAnual.toString(),
      economiaMensalBruta: data.economiaMensalBruta.toString(),
      economiaMensalLiquida: data.economiaMensalLiquida.toString(),
      tarifaCooperativa: data.tarifaUsina.toString(),
      valorTotal: data.valorTotalFatura.toString(),
    });
  };

  const [updateGdProposta, setUpdateGdProposta] = useState<IUpdateGDProposta>();

  const receiveDataDadosPessoais = (data: IDadosPessoaisEditarProposta) => {
    setDadosPessoaisData(data);
  };

  const receiveDataConexaoConta = (data: IConexaoContaProposta) => {
    setConexaoContaData(data);
  };

  const receiveDataStatus = (data: number) => {
    setStatus(data);
  };

  const stopLoading = () => {
    setLoading(false);
  };

  useEffect(() => {
    services
      .GetFullPropostaById(idProposta!)
      .then(async (response) => {
        setInitialData(response.data);
        setConexaoContaInitialData(response.data);
        setContaCooperativaData(response.data);
        setContaDistribuidoraData(response.data);
        setResumoFaturaData(response.data);
        setInitialStatus(response.data.status!);
        setLoading(false);
      })
      .catch((e: AxiosError) => {
        var errorMessage: string = e.response
          ? String(e.response?.data)
          : "Houve um erro ao buscar os dados.";

        Swal.fire({
          position: "top-end",
          toast: true,
          icon: "warning",
          title: errorMessage,
          showConfirmButton: false,
          showCloseButton: true,
          timer: 3000,
        });
      });
  }, []);

  const createUpdatePropostaPropostaAsync = async () => {
    const data: IUpdateGDProposta = {
      ...conexaoContaData,
      ...contaCooperativaData,
      ...contaDistribuidoraData,
      ...resumoFaturaData,
      ...dadosPessoaisData,
      id: idProposta ?? null,
      status: status,
    };

    var adjustedData = await convertEmptyStringsToNullAsync(data);

    setUpdateGdProposta(adjustedData);
  };

  useEffect(() => {
    createUpdatePropostaPropostaAsync();
  }, [
    dadosPessoaisData,
    conexaoContaData,
    contaCooperativaData,
    contaDistribuidoraData,
    status,
  ]);

  const editaProposta = () => {
    updateGdProposta
      ? propostaService
          .UpdateGDProposta(updateGdProposta)
          .then(async () => {
            navigate("/Parceiro/Propostas");
            Swal.fire({
              position: "top-end",
              toast: true,
              icon: "success",
              title: "Editado com sucesso!",
              showConfirmButton: false,
              showCloseButton: true,
              timer: 3000,
            });
          })
          .catch((e: AxiosError) => {
            var errorMessage: string = e.response
              ? String(e.response?.data)
              : "Houve um erro ao editar a proposta.";
            Swal.fire({
              position: "top-end",
              toast: true,
              icon: "warning",
              title: errorMessage,
              showConfirmButton: false,
              showCloseButton: true,
              timer: 3000,
            });
          })
      : Swal.fire({
          position: "top-end",
          toast: true,
          icon: "warning",
          title: "Create proposta não pode ser nulo.",
          showConfirmButton: false,
          showCloseButton: true,
          timer: 3000,
        });
  };

  const convertEmptyStringsToNullAsync = async (
    obj: IUpdateGDProposta
  ): Promise<IUpdateGDProposta> => {
    const updatedObj = { ...obj };

    await Promise.all(
      (Object.keys(updatedObj) as (keyof IUpdateGDProposta)[]).map(
        async (key) => {
          const value = updatedObj[key];
          if (typeof value === "string" && value === "") {
            updatedObj[key] = null;
          }
        }
      )
    );

    return updatedObj;
  };

  return loading ? (
    <SkeletonCreationCards />
  ) : (
    <C.Container>
      <DadosPessoaisEditarProposta
        initialData={initialData}
        onSendData={receiveDataDadosPessoais}
      />
      <ConexaoContaEditarProposta
        initialData={conexaoContaInitialData}
        data={conexaoContaData}
        onSendData={receiveDataConexaoConta}
        sendCalculoProposta={getCalculoResponse}
      />
      <ContaCooperativaGerarProposta data={contaCooperativaData} />
      <ContaDistribuidoraGerarProposta
        data={contaDistribuidoraData}
        iluPub={conexaoContaData.iluminacaoPublica!.toString()}
      />
      <ResumoFaturaGerarProposta data={resumoFaturaData} />
      <StatusPropostaGerarProposta
        initialStatus={initialStatus}
        onSendData={receiveDataStatus}
      />
      <D.FWStack justifyContent={"flex-end"} direction={"row"} spacing={2}>
        <D.ContainedButton $color="lightpink" onClick={editaProposta}>
          Salvar proposta
        </D.ContainedButton>
      </D.FWStack>
    </C.Container>
  );
};

export default EditarProposta;
