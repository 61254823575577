export enum EnumEstadoCivil {
  solteiro = "Solteiro",
  casado = "Casado",
  separado = "Separado",
  divorciado = "Divorciado",
  viúvo = "Viúvo",
}

export const EnumEstadoCivilNumericMap: Record<EnumEstadoCivil, number> = {
  [EnumEstadoCivil.solteiro]: 0,
  [EnumEstadoCivil.casado]: 1,
  [EnumEstadoCivil.separado]: 2,
  [EnumEstadoCivil.divorciado]: 3,
  [EnumEstadoCivil.viúvo]: 4,
};
