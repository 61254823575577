import { EnumSituacaoColaborador } from "../../enums/EnumSituacaoColaborador.enum";
import { ColaboradorByIdResponse, ColaboradorResponse, IBackofficeListItem, ICreateColaborador, IUpdateColaborador } from "../../models/Colaborador/Colaborador";
import { IPaginatedList } from "../../models/PaginatedList";
import { formDataTransform } from "../../utils/formDataTransform";
import http from "../common/http-common";

export const GetAll = (
  pageSize: number | null = null,
  pageNumber: number | null = null,
  search: string | null = null,
  isNotSendClickSign: boolean | null = null
) => {
  let url = "/Colaborador";

  const params = [];

  if (pageSize) params.push(`PageSize=${pageSize}`);
  if (pageNumber) params.push(`PageNumber=${pageNumber}`);
  if (search) params.push(`Search=${search}`);

  if (params.length > 0) {
    url += `?${params.join("&")}`;
  }

  return http.get<IPaginatedList<ColaboradorResponse>>(url);
};

export const GetBackoffices = () => {
  return http.get<IBackofficeListItem[]>(`/Colaborador/Backoffice`);
};

export const CreateColaborador = (createColaborador:ICreateColaborador) => {
  const formData = new FormData();

  formDataTransform(createColaborador, formData)
  
  return http.post<ICreateColaborador>("/Colaborador", createColaborador)
}

export const UpdateColaborador = (updateColaborador:IUpdateColaborador) => {
  const formData = new FormData();

 formDataTransform(updateColaborador, formData)
  
  return http.put<IUpdateColaborador>("/Colaborador", updateColaborador)
}

export const UploadFoto = (dadosUploadFoto:{ colaboradorId:string, foto:File | string }) => {
  const formData = new FormData();

  formDataTransform(dadosUploadFoto, formData)
  
  return http.post<boolean>("/Colaborador/UploadFoto", formData)
}

export const GetColaboradorById = (id:string) => {
  return http.get<ColaboradorByIdResponse>(`/Colaborador/${id}`)
}
