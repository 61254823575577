export enum EnumSituacaoColaborador {
  ativo = 0,
  desligado = 1,
  afastado = 2,
}


export const enumSituacaoColaboradorMapping = [
  {
    name: "Ativo",
    value: EnumSituacaoColaborador.ativo
  },
  {
    name: "Desligado",
    value: EnumSituacaoColaborador.desligado
  },
  {
    name: "Afastado",
    value: EnumSituacaoColaborador.afastado
  }
];