import { MenuItem, TextField } from "@mui/material";
import { AxiosError } from "axios";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import RegisterCard from "../../../../../../../../components/RegisterCard";
import { EnumTipoConexao } from "../../../../../../../../enums/EnumTipoConexao.enum";
import { useObjectState } from "../../../../../../../../hooks/useObjectState";
import { IFormInformacoesInformacoesContratoPJ } from "../../../../../../../../models/Contratar";
import {
  IFullGDContratoCliente,
  IInformacoesContrato,
  IUpdateInformacoesContrato,
} from "../../../../../../../../models/GDContrato";
import * as service from "../../../../../../../../services/api/GDContratoClienteService";
import * as D from "../../../../../../../../styles/appComponents";
import { convertEmptyStringsToNullAsync } from "../../../../../../../../utils/convertEmptyStringsToNull";
import MaskDefaultInput from "../../../../../../../../components/MasDefaultinput/MaskDefaultInput";
import { FormatDate } from "../../../../../../../../utils/dateFormatter";

type PropsInformacoesContratoContratar = {
  id: string;
  type: string;
  dataContrato?: IInformacoesContrato;
  reload: () => void;
};

const InformacoesContratoContratar = (
  props: PropsInformacoesContratoContratar
) => {
  const { dataContrato, id, reload } = props;

  const [editar, setEditar] = useState<boolean>(false);

  const { state, setObject, updateObject, resetObject } =
    useObjectState<IFormInformacoesInformacoesContratoPJ>({
      numeroCliente: "",
      dataVencimento: "",
      conexao: "",
      tarifaDistribuidora: "",
      descontoTarifaDistribuidora: "",
      taxaDisponibilidade: "",
      media12meses: "",
      franquia: "",
      valorAssinatura: "",
      tarifaUsina: "",
      potencia: "",
      lote: "",
      economiaMensal: "",
      economiaAnual: "",
    });

  useEffect((): void => {
    if (dataContrato) {
      setObject({
        numeroCliente: dataContrato.numeroCliente ?? "",
        conexao: dataContrato.conexao?.toString() ?? "",
        dataVencimento:
          dataContrato?.dataVencimento != null
            ? FormatDate(new Date(dataContrato?.dataVencimento!)) ?? ""
            : "",
        descontoTarifaDistribuidora:
          dataContrato.descontoTarifaDistribuidora?.toString() ?? "",
        economiaAnual: dataContrato.economiaAnual?.toString() ?? "",
        economiaMensal: dataContrato.economiaMensal?.toString() ?? "",
        franquia: dataContrato.franquia?.toString() ?? "",
        lote: dataContrato.franquia?.toString() ?? "",
        media12meses: dataContrato.mediaDozeMeses?.toString() ?? "",
        potencia: dataContrato.potencia?.toString() ?? "",
        tarifaDistribuidora: dataContrato.tarifaDistribuidora?.toString() ?? "",
        tarifaUsina: dataContrato.tarifaUsina?.toString() ?? "",
        taxaDisponibilidade: dataContrato.taxaDisponibilidade?.toString() ?? "",
        valorAssinatura: dataContrato.valorAssinatura?.toString() ?? "",
      });
    }
  }, [dataContrato]);

  const Update = async () => {
    var rawData: IUpdateInformacoesContrato = {
      id: id,
      numCliente: state.numeroCliente,
      numInstalacao: "",
    };

    var data = await convertEmptyStringsToNullAsync(rawData);

    await service
      .UpdateInformacoesContrato(data)
      .then(() => {
        setEditar(false);
        reload();
        Swal.fire({
          position: "top-end",
          toast: true,
          icon: "success",
          title: "Atualizado com sucesso!",
          showConfirmButton: false,
          showCloseButton: true,
          timer: 3000,
        });
      })
      .catch((e: AxiosError) => {
        var errorMessage: string = e.response
          ? String(e.response?.data)
          : "Houve um erro ao pegar os dados.";

        Swal.fire({
          position: "top-end",
          toast: true,
          icon: "warning",
          title: errorMessage,
          showConfirmButton: false,
          showCloseButton: true,
          timer: 3000,
        });
      });
  };

  return (
    <RegisterCard title="Informações do contrato">
      <D.FWStack direction={"row"} spacing={2}>
        <TextField
          label="Número cliente"
          required
          fullWidth
          value={state.numeroCliente}
          onChange={(e) => updateObject({ numeroCliente: e.target.value })}
          inputProps={{ readOnly: !editar }}
        />
        <D.DateTextfield
          type="date"
          label="Data de vencimento"
          fullWidth
          value={state.dataVencimento}
          inputProps={{ readOnly: true }}
        />
        <TextField
          label="Conexão"
          select
          required
          fullWidth
          value={state.conexao}
          inputProps={{ readOnly: true }}
        >
          {Object.values(EnumTipoConexao).map((value, index) => (
            <MenuItem key={value} value={index}>
              {value}
            </MenuItem>
          ))}
        </TextField>
        <MaskDefaultInput
          label="Tarifa distribuidora"
          type="REAL"
          value={state.tarifaDistribuidora}
          readonly={true}
        />
        <MaskDefaultInput
          label="Desconto tarifa distribuidora"
          type="PORCENTAGEM"
          value={state.descontoTarifaDistribuidora}
          readonly={true}
        />
      </D.FWStack>

      <D.FWStack direction={"row"} spacing={2}>
        <MaskDefaultInput
          label="Taxa de disponibilidade"
          type="KWH"
          value={state.taxaDisponibilidade}
          readonly={true}
        />
        <MaskDefaultInput
          label="Média 12 meses"
          type="KWH"
          value={state.media12meses}
          readonly={true}
        />
        <MaskDefaultInput
          label="Franquia"
          type="KWH"
          value={state.franquia}
          readonly={true}
        />
        <MaskDefaultInput
          label="Valor assinatura"
          readonly={true}
          value={state.valorAssinatura}
          type="REAL"
        />
        <MaskDefaultInput
          label="Tarifa usina"
          value={state.tarifaUsina}
          type="REAL"
          readonly={true}
        />
      </D.FWStack>

      <D.FWStack direction={"row"} spacing={2}>
        <TextField
          label="Potencia"
          required
          fullWidth
          value={state.potencia}
          inputProps={{ readOnly: true }}
        />
        <MaskDefaultInput
          label="Lote (fração da usina)"
          type="KWH"
          value={state.lote}
          readonly={true}
        />
        <MaskDefaultInput
          label="Economia mensal"
          readonly={true}
          value={state.economiaMensal}
          type="REAL"
        />
        <MaskDefaultInput
          label="Economia anual"
          readonly={true}
          value={state.economiaAnual}
          type="REAL"
        />
      </D.FWStack>
      <D.FWStack direction={"row"} spacing={2} justifyContent={"flex-end"}>
        <D.ContainedButton
          $color="yellow"
          disabled={editar}
          onClick={() => setEditar(true)}
        >
          Editar
        </D.ContainedButton>
        <D.ContainedButton disabled={!editar} onClick={Update}>
          Salvar
        </D.ContainedButton>
      </D.FWStack>
    </RegisterCard>
  );
};

export default InformacoesContratoContratar;
