import BeneficiosComissao, {IFormBeneficiosComissao, IFormInputLinhasDeOnibus} from "./components/BeneficiosComissao";
import CRM from "./components/CRM";
import DadosAcesso from "./components/DadosAcesso";
import DadosPessoais from "./components/DadosPessoais";
import Documentos, {IDocumentoForm} from "./components/Documentos";
import Ferias from "./components/Ferias";
import InformacoesMedicas, {IInformacoesMedicasForm} from "./components/InformacoesMedicas";
import SalarioFuncao, {IFormSalarioFuncao} from "./components/SalarioFuncao";
import {useEffect, useState} from "react";
import { Button } from "@mui/material";
import { formatValueStringInFloat, formatValues } from "../../../../../utils/moneyFormatter";
import * as service from "../../../../../services/api/ColaboradorService";
import { toastMessage } from "../../../../../utils/toastMessage";
import { dataCreateColaborador, dataUpdateColaborador } from "../../../../../utils/returnNewData";
import { useAuth } from "../../../../../hooks/useAuth";
import { IBeneficioComissaoCreate } from "../../../../../models/Colaborador/cadastros/BeneficioComissao";
import { SituacaoValidate } from "./components/validation/SituacaoValidate";
import { DadosPessoaisValidate } from "./components/validation/DadosPessoaisValidate";
import ibgeService from "../../../../../services/api/IbgeService";
import { SalarioFuncaoValidate } from "./components/validation/SalarioFuncaoValidate";
import { IFormSituacao } from "../../../../../models/Colaborador/cadastros/Situacao";
import Situacao from "./components/Situacao";
import { IFormDadosPessoais } from "../../../../../models/Colaborador/cadastros/DadosPessoais";
import { ErrorMessage } from "../../../../../utils/AbstractValidation";
import { BeneficioComissaoValidate } from "./components/validation/BeneficioComissaoValidate";
import { DocumentoValidate } from "./components/validation/DocumentoValidate";
import { iDadosAcesso, iDadosAcessoColaborador } from "../../../../../models/Colaborador/cadastros/DadosAcesso";
import { DadosAcessoValidate } from "./components/validation/DadosAcessoValidate";
import { useNavigate, useParams } from "react-router-dom";
import { ColaboradorByIdResponse, ICreateColaborador, IUpdateColaborador } from "../../../../../models/Colaborador/Colaborador";
import { HandleObjects } from "../../../../../utils/HandleObjects";
import { AxiosError } from "axios";
import { IAnexoDTO } from "../../../../../models/Anexo";
import { GetAllAnexos } from "../../../../../services/api/AnexoService";
import { IFeriasForm } from "../../../../../models/Colaborador/cadastros/Ferias";
import { DadosAcessoColaborador } from "./components/DadosAcessoColaborador";
import { ResetSenha } from "../../../../../services/api/UsuarioService";

const CadastroColaborador = () => {
  const [situacao, setSituacao] = useState<IFormSituacao | undefined>(undefined);
  const [dadosPessoais, setDadosPessoais] = useState<IFormDadosPessoais | undefined>(undefined);
  const [salarioFuncao, setSalarioFuncao] = useState<IFormSalarioFuncao | undefined>(undefined);
  const [beneficiosComissao, setBeneficiosComissao] = useState<IFormBeneficiosComissao | undefined>(undefined);
  const [documentos, setDocumentos] = useState<IDocumentoForm[]>([]);
  const [dadosAcesso, setDadosAcesso] = useState<iDadosAcessoColaborador | undefined>(undefined)
  const [informacoesMedicas, setInformacoesMedicas] = useState<IInformacoesMedicasForm[]>([]);
  const [ferias, setFerias] = useState<IFeriasForm[]>([]);
  const [linhaOnibus, setLinhaOnibus] = useState<IFormInputLinhasDeOnibus[]>([]);
  const [valorDiaVT, setValorDiaVT] = useState<number | undefined>(0);
  const [colaborador, setColaborador] = useState<ColaboradorByIdResponse | undefined>(undefined);

  const [ buttonSaveSituacao, setButtonSaveSituacao ] = useState<boolean>(false)
  const [ buttonSaveDadosPessoais, setButtonSaveDadosPessoais ] = useState<boolean>(false)
  const [ buttonSaveSalarioFuncao, setButtonSaveSalarioFuncao ] = useState<boolean>(false)
  const [ buttonSaveBeneficioComissao, setButtonSaveBeneficioComissao ] = useState<boolean>(false)
  const [ buttonSaveDocumentos, setButtonSaveDocumentos ] = useState<boolean>(false)
  const [ buttonSaveDadosAcesso, setButtonSaveDadosAcesso ] = useState<boolean>(false)
  const [ buttonSaveInformacoesMedica, setButtonSaveInformacoesMedica ] = useState<boolean>(false)
  const [ buttonSaveFerias, setButtonSaveFerias ] = useState<boolean>(false)

  const [ salarioFuncaoFieldErros, setSalarioFuncaoFieldErros ] = useState<ErrorMessage[] | null>([])
  const [ beneficioComissaoFieldErros, setBeneficioComissaoFieldErros ] = useState<ErrorMessage[] | null>([])
  const [ dadosPessaoisFieldErros, setDadosPessoaisFieldErros ] = useState<ErrorMessage[] | null>([])
  const [ situacaoFieldErros, setSituacaoFieldErros ] = useState<ErrorMessage[] | null>([])
  const [ documentosFieldErros, setDocumentosFieldErros ] = useState<ErrorMessage[] | null>([])
  const [ dadosAcessoFieldsErros, setDadosAcessoFieldsErros ] = useState<ErrorMessage[] | null>([])

  const [fieldsErros, setFieldsErros] = useState<ErrorMessage[] | null>([])

  const [load, setLoad] = useState<{ status:boolean, section:string }>({ status:false, section:"" })
  const [colaboradorSaved, setColaboradorSaved] = useState<boolean>(false)
  
  const [ cities, setCities ] = useState<string[]>([])
  const [dataAnexoUpdated, setDataAnexoUpdated] = useState<boolean>(false)
  const [anexos, setAnexos] = useState<IAnexoDTO[]>([]);
  const {user} = useAuth()
  const {colaboradorId} = useParams()
  const navigate = useNavigate()

  useEffect(() => {
    const loadCities = async () => {
      try {
        if(dadosPessoais?.uf){
          const { data } = await ibgeService.getCities(dadosPessoais?.uf)
          setCities((data || []).map((x:any) => x.nome))
        }

      } catch (error) {
        console.error(`Erro ao listar cidades: ${error}`)
      }
    }
    loadCities()

  }, [dadosPessoais?.uf])

  useEffect(() => {
    getColaboradorById();
    loadAnexos(colaboradorId);

    if(colaboradorId){
      setButtonSaveSituacao(true);
      setButtonSaveDadosPessoais(true);
      setButtonSaveSalarioFuncao(true);
      setButtonSaveBeneficioComissao(true);
      setButtonSaveDocumentos(true);
      setButtonSaveDadosAcesso(true);
      setButtonSaveFerias(true);
    }

  }, [
    dataAnexoUpdated,
    colaboradorId,
    colaboradorSaved
  ])

  const loadAnexos = async (id:string | null | undefined) => {
    try {
      if(id){
        const { data } = await GetAllAnexos(id);
        setAnexos(data);

      }else setAnexos([]);

    } catch (error) {
      toastMessage("error","Erro ao listar Arquivos");
    }
  };

  const onChangeSituacao = (data: IFormSituacao): void => {
    setSituacao(data);

  };

  const getColaboradorById = async () => {
    try {
      if(colaboradorId){
        const { data } = await service.GetColaboradorById(colaboradorId);
        setColaborador(data);
      };

    } catch (error) {
      console.error(error);
      toastMessage("error", "Erro ao buscar colaborador");
    }
  };

  const onSaveSituacao = async (data: IFormSituacao): Promise<void> => {
    setFieldsErros([]);
    setColaboradorSaved(false);
    setSituacao(data);
    setLoad({ status: true, section:"situacao" });

    if(!colaborador?.nome){
      setFieldsErros([{ ref:"nome", message:"" }]);
      toastMessage("warning","Primeiro deve preencher o nome do colaborador");
      setLoad({ status: false, section:"situacao" });

      return;
    }

    const resultValidation = new SituacaoValidate();
    const errors:ErrorMessage[] | null | undefined = resultValidation.errorMessage(data);

    if(errors){
      setFieldsErros(errors);
      errors.forEach((error:ErrorMessage) => {
        toastMessage("warning",error.message);
      })

      setLoad({ status: false, section:"situacao" });

      return;
    }

    if(colaboradorId){
      await updateColaborador(data)
      .then((x) => {
        if(x === "Este usuário já existe"){
          toastMessage("warning", x);
          setLoad({ status: false, section:"situacao" });

        }else if(x?.id){
          toastMessage("success","Dados salvos com sucesso!");
          setLoad({ status: false, section:"situacao" });
          setButtonSaveSituacao(true);
          setColaboradorSaved(true)
        }
      });

    }else{
      await createColaborador()
      .then((x) => {
        if(x === "Este usuário já existe"){
          toastMessage("warning",x);
          setLoad({ status: false, section:"situacao" });
          
        }else if(x?.id){
          navigate(`/Editar/Colaborador/${x?.id}`);
          setLoad({ status: false, section:"situacao" });
          setButtonSaveSituacao(true);
          setColaboradorSaved(true);
        };

      });
    }

  };

  const onUpdateSituacao = () => {
    setButtonSaveSituacao(false)
  }

  const onSaveDadosPessoais = async (data: IFormDadosPessoais): Promise<void> => {
    setFieldsErros([]);
    setColaboradorSaved(false);
    setDadosPessoais(data);
    setLoad({ status: true, section:"dadosPessoais" });

    const resutlValidation = new DadosPessoaisValidate();
    const erroMessages = resutlValidation.errorMessage(data);

    if(erroMessages){
      setFieldsErros(erroMessages);
      setLoad({ status: false, section:"dadosPessoais" });

      erroMessages.forEach((error:ErrorMessage) => {
        toastMessage("warning", error.message)
      })

      return;
    }

    if(colaboradorId){
      await updateColaborador(data)
      .then((x) => {
        if(x === "Este usuário já existe"){
          toastMessage("warning", x);
          setLoad({ status: false, section:"dadosPessoais" });

        }else if(x?.id){
          toastMessage("success","Dados salvos com sucesso!");
          setLoad({ status: false, section:"dadosPessoais" });
          setButtonSaveDadosPessoais(true);
          setColaboradorSaved(true)
        }
      });

    }else{
      await createColaborador()
      .then((x) => {
        if(x === "Este usuário já existe"){
          toastMessage("warning",x);
          setLoad({ status: false, section:"dadosPessoais" });
          
        }else if(x?.id){
          navigate(`/Editar/Colaborador/${x?.id}`);
          setLoad({ status: false, section:"dadosPessoais" });
          setButtonSaveDadosPessoais(true);
          setColaboradorSaved(true)
        };
      });
    }

    setButtonSaveDadosPessoais(true)
    setFieldsErros([])

  };

  const onChangeDadosPessoais = (data: IFormDadosPessoais):void => {
    setDadosPessoais(data);
  }

  const onUpdateDadosPessoais = () => {
    setButtonSaveDadosPessoais(false)
  }

  const onSaveSalarioFuncao = async (data: IFormSalarioFuncao): Promise<void> => {
      setFieldsErros([]);
      setColaboradorSaved(false);
      setSalarioFuncao(data);
      setLoad({ status: true, section:"salarioFuncao" });

      const resultValidate = new SalarioFuncaoValidate();
      const errorMessages = resultValidate.errorMessage(data);

      
    if(!colaborador?.nome){
      setFieldsErros([{ ref:"nome", message:"" }]);
      toastMessage("warning","Primeiro deve preencher o nome do colaborador");
      setLoad({ status: false, section:"salarioFuncao" })

      return;
    }

      if(errorMessages){
        setFieldsErros(errorMessages);
        setLoad({ status: false, section:"salarioFuncao" })

        errorMessages.forEach((error) => {
          toastMessage("warning", error.message)
        });

        return;

      }

      if(colaboradorId){
        await updateColaborador(data)
        .then((x) => {
          if(x === "Este usuário já existe"){
            toastMessage("warning", x);
            setLoad({ status: false, section:"salarioFuncao" });
  
          }else if(x?.id){
            toastMessage("success","Dados salvos com sucesso!");
            setLoad({ status: false, section:"salarioFuncao" });
            setButtonSaveSalarioFuncao(true);
            setColaboradorSaved(true)
          }
        });
  
      }else{
        await createColaborador()
        .then((x) => {
          if(x === "Este usuário já existe"){
            toastMessage("warning",x);
            setLoad({ status: false, section:"salarioFuncao" });
            
          }else if(x?.id){
            navigate(`/Editar/Colaborador/${x?.id}`);
            setLoad({ status: false, section:"salarioFuncao" });
            setButtonSaveSalarioFuncao(true);
            setColaboradorSaved(true);
          };
        });
      }

      setFieldsErros([])
      setButtonSaveSalarioFuncao(true);
      
  };

  const onChangeSalarioFuncao = (data: IFormSalarioFuncao): void => {
    setSalarioFuncao(data);
  };

  const onUpdateSalarioFuncao = (): void => {
    setButtonSaveSalarioFuncao(false)
  };

  const getData = (data:IFormInputLinhasDeOnibus[]) => {
    setLinhaOnibus(data);
  };

  const getValorTotalVt = (data:number) => {
    setValorDiaVT(data);
  };

  const onSaveBeneficiosComissao = async (data: IFormBeneficiosComissao): Promise<void> => {
    setFieldsErros([]);
    setColaboradorSaved(false);
    setBeneficiosComissao(data);
    setLoad({ status: true, section:"beneficioComissao" });

    const validationResult = new BeneficioComissaoValidate();
    const errorMessages = validationResult.errorMessage(data);

    if(!colaborador?.nome){
      setFieldsErros([{ ref:"nome", message:"" }]);
      toastMessage("warning","Primeiro deve preencher o nome do colaborador");
      setLoad({ status: false, section:"beneficioComissao" });

      return;
    }

    if(errorMessages){
      setFieldsErros(errorMessages);
      setLoad({ status: false, section:"beneficioComissao" });

      errorMessages.forEach((error) => {
        toastMessage("warning",error.message);
      })

      return;

    }

    if(colaboradorId){
      await updateColaborador(data)
      .then((x) => {
        if(x === "Este usuário já existe"){
          toastMessage("warning", x);
          setLoad({ status: false, section:"beneficioComissao" });

        }else if(x?.id){
          toastMessage("success","Dados salvos com sucesso!");
          setLoad({ status: false, section:"beneficioComissao" });
          setButtonSaveBeneficioComissao(true);
          setColaboradorSaved(true)
        }
      });

    }else{
      await createColaborador()
      .then((x) => {
        if(x === "Este usuário já existe"){
          toastMessage("warning",x);
          setLoad({ status: false, section:"beneficioComissao" });
          
        }else if(x?.id){
          navigate(`/Editar/Colaborador/${x?.id}`);
          setLoad({ status: false, section:"beneficioComissao" });
          setButtonSaveBeneficioComissao(true);
          setColaboradorSaved(true);
        };
      });
    }

    setFieldsErros([])
    setButtonSaveBeneficioComissao(true)
  };

  const onChangeBeneficioComissao = (data: IFormBeneficiosComissao): void => {
    setBeneficiosComissao(data);
  }

  const onUpdateBeneficioComissao = () => {
    setButtonSaveBeneficioComissao(false)
  }

  const onSaveDocumentos = async (data: IDocumentoForm[]): Promise<void> => {
    setFieldsErros([]);
    setColaboradorSaved(false);
    setLoad({ status: true, section:"documentos" });
    setDocumentos(data);

    const validationResult = new DocumentoValidate()

    if(!colaborador?.nome){
      setFieldsErros([{ ref:"nome", message:"" }]);
      toastMessage("warning","Primeiro deve preencher o nome do colaborador");
      setLoad({ status: false, section:"documentos" });

      return;
    }

    data.forEach((documento:IDocumentoForm, index:number | null) => {
      const errorMessages = validationResult.errorMessage(documento, index);

      if(errorMessages){
        setFieldsErros(errorMessages)

        errorMessages.forEach((error:ErrorMessage) => {
          toastMessage("warning", error.message)
        });

        return;
      }

    });

    if(colaboradorId){
      await updateColaborador({documentos:data})
      .then((x) => {
        if(x === "Este usuário já existe"){
          toastMessage("warning", x);
          setLoad({ status: false, section:"documentos" });

        }else if(x?.id){
          toastMessage("success","Dados salvos com sucesso!");
          setLoad({ status: false, section:"documentos" });
          setButtonSaveDocumentos(true);
          setColaboradorSaved(true)
        }
      });

    }else{
      await createColaborador()
      .then((x) => {
        if(x === "Este usuário já existe"){
          toastMessage("warning",x);
          setLoad({ status: false, section:"documentos" });
          
        }else if(x?.id){
          navigate(`/Editar/Colaborador/${x?.id}`);
          setLoad({ status: false, section:"documentos" });
          setButtonSaveDocumentos(true);
          setColaboradorSaved(true);
        };
      });
    }

  };

  const onChangeDocumentos = (data: IDocumentoForm[]): void => {
    setDocumentos(data);
  };

  const onUpdateDocumentos = () => [
    setButtonSaveDocumentos(false)
  ]

  const onSaveInformacoesMedicas = async (data: IInformacoesMedicasForm[]): Promise<void> => {
    setFieldsErros([]);
    setColaboradorSaved(false);
    setInformacoesMedicas(data);
    setLoad({ status: true, section:"informacoeMedicas" });

    if(!colaborador?.nome){
      setFieldsErros([{ ref:"nome", message:"" }]);
      toastMessage("warning","Primeiro deve preencher o nome do colaborador");
      setLoad({ status: false, section:"informacoeMedicas" });

      return;
    }

    if(colaboradorId){
      await updateColaborador(data)
      .then((x) => {
        if(x === "Este usuário já existe"){
          toastMessage("warning", x);
          setLoad({ status: false, section:"informacoeMedicas" });

        }else if(x?.id){
          toastMessage("success","Dados salvos com sucesso!");
          setLoad({ status: false, section:"informacoeMedicas" });
          setButtonSaveInformacoesMedica(true);
          setColaboradorSaved(true)
        }
      });

    }else{
      await createColaborador()
      .then((x) => {
        if(x === "Este usuário já existe"){
          toastMessage("warning",x);
          setLoad({ status: false, section:"beneficioComissao" });
          
        }else if(x?.id){
          navigate(`/Editar/Colaborador/${x?.id}`);
          setLoad({ status: false, section:"beneficioComissao" });
          setButtonSaveInformacoesMedica(true);
          setColaboradorSaved(true);
        };
      });
    }
  };

  const onChangeInformacoesMedicas = async (data: IInformacoesMedicasForm[]): Promise<void> => {
    setInformacoesMedicas(data);
  }

  const onUpdateInformacoesMedicas = (): void => {
    setButtonSaveInformacoesMedica(false);
  };

  const onSaveFerias = async (data: IFeriasForm[]): Promise<void> => {
    setFieldsErros([]);
    setColaboradorSaved(false)
    setFerias(data);
    setLoad({ status: true, section:"ferias" });

    if(!colaborador?.nome){
      setFieldsErros([{ ref:"nome", message:"" }]);
      toastMessage("warning","Primeiro deve preencher o nome do colaborador");
      setLoad({ status: false, section:"ferias" });

      return;
    }

    if(colaboradorId){
      await updateColaborador({ferias:data})
      .then((x) => {
        if(x === "Este usuário já existe"){
          toastMessage("warning", x);
          setLoad({ status: false, section:"ferias" });

        }else if(x?.id){
          toastMessage("success","Dados salvos com sucesso!");
          setLoad({ status: false, section:"ferias" });
          setButtonSaveFerias(true);
          setColaboradorSaved(true)
        }
      });

    }else{
      await createColaborador()
      .then((x) => {
        if(x === "Este usuário já existe"){
          toastMessage("warning",x);
          setLoad({ status: false, section:"ferias" });
          
        }else if(x?.id){
          navigate(`/Editar/Colaborador/${x?.id}`);
          setLoad({ status: false, section:"ferias" });
          setButtonSaveFerias(true);
          setColaboradorSaved(true);
        };
      });
    }

  };

  const onChangeFerias = (data: IFeriasForm[]) => {
    setFerias(data)
  };

  const onUpdateFerias = () => {
    setButtonSaveFerias(false)
  };

  const onSaveDadosAcesso = async (data: iDadosAcessoColaborador) :Promise<void> => {
    setFieldsErros([]);
    setColaboradorSaved(false);
    setLoad({ status: true, section:"dadosAcesso" });
    setDadosAcesso(data);

    const resultValidation = new DadosAcessoValidate();
    const errorMessages = resultValidation.errorMessage(data);

    if(!colaborador?.nome){
      setFieldsErros([{ ref:"nome", message:"" }]);
      toastMessage("warning","Primeiro deve preencher o nome do colaborador");
      setLoad({ status: false, section:"dadosAcesso" });

      return;
    }

    if(colaboradorId){
      await updateColaborador(data)
      .then((x) => {
        if(x === "Este usuário já existe"){
          toastMessage("warning", x);
          setLoad({ status: false, section:"dadosAcesso" });

        }else if(x?.id){
          toastMessage("success","Dados salvos com sucesso!");
          setLoad({ status: false, section:"dadosAcesso" });
          setButtonSaveDadosAcesso(true);
          setColaboradorSaved(true)
        }
      });

    }else{
      await createColaborador()
      .then((x) => {
        if(x === "Este usuário já existe"){
          toastMessage("warning",x);
          setLoad({ status: false, section:"dadosAcesso" });
          
        }else if(x?.id){
          navigate(`/Editar/Colaborador/${x?.id}`);
          setLoad({ status: false, section:"dadosAcesso" });
          setButtonSaveDadosAcesso(true);
          setColaboradorSaved(true);
        };
      });
    }

    setFieldsErros([]);
    setButtonSaveDadosAcesso(true);

  }

  const onChangeDadosAcesso = (data: iDadosAcessoColaborador) => {
    setDadosAcesso(data)
  }

  const onUpdateDadosAcesso = () => {
    setButtonSaveDadosAcesso(false);
  }

  const onResetSenha = async (usuarioId:string | null | undefined) => {
    if(usuarioId){
      try {
        await ResetSenha(usuarioId);
        toastMessage("success","Senha resetada com sucesso!");

      } catch (error) {
        toastMessage("warning","Erro ao resetar a senha!");
      }
    }

  }

  const data:any = {
    ...situacao,
    ...dadosPessoais,
    ...salarioFuncao,
    ...beneficiosComissao,
    documentos,
    consultas:informacoesMedicas,
    ferias,
    ...dadosAcesso
  };

  const createColaborador = async () => {

    const newData = dataCreateColaborador(data);

    try {

      const { foto, ...bodyColaborador } = newData;

      const {data} = await service.CreateColaborador(bodyColaborador);
      const colaboradorId = data?.id;

      if(colaboradorId && foto) 
        await service.UploadFoto({colaboradorId, foto});

      toastMessage("success","Dados salvos com sucesso");
      return data;
      
    } catch (error:any) {
      var errorMessage: string = error?.response
      ? String(error?.response?.data)
      : "Houve um erro ao pegar os backoffices.";

      toastMessage("warning", errorMessage);
      return error?.response?.data
    }
  }

  const updateColaborador = async (internalData:unknown | null=null) => {
    try {
      let newDataUpdate:IUpdateColaborador;

      newDataUpdate = internalData? 
      dataUpdateColaborador(data?{...data, ...internalData}:{}): 
      dataUpdateColaborador(data);

      if(colaboradorId){
        const newColaborador = { ...colaborador }

        HandleObjects.Copy(newDataUpdate, newColaborador);

        const { foto, ...bodyColaborador } = newColaborador;

        const {data} = await service
        .UpdateColaborador(bodyColaborador as IUpdateColaborador);

        const colaboradorId = data?.id;

        if(colaboradorId && foto instanceof File) 
          await service.UploadFoto({colaboradorId, foto});

        toastMessage("success","Dados salvos com sucesso")
        return data;
      }
      
    } catch (error:any) {
      toastMessage("error","Erro ao criar colaborador")
      return error?.response?.data
    }
  }

  return (
    <>
      <Situacao
        onChange={onChangeSituacao}
        onSave={onSaveSituacao}
        onUpdate={onUpdateSituacao}
        buttonSave={buttonSaveSituacao}
        fieldErros={fieldsErros}
        colaboradorId={colaboradorId}
        colaborador={colaborador}
        load={load}
      />
      <DadosPessoais
        onChange={onChangeDadosPessoais}
        onSave={onSaveDadosPessoais}
        onUpdate={onUpdateDadosPessoais}
        buttonSave={buttonSaveDadosPessoais}
        fieldErros={fieldsErros}
        colaborador={colaborador}
        colaboradorId={colaboradorId}
        load={load}
        cities={cities}
      />
      <SalarioFuncao
        onChange={onChangeSalarioFuncao}
        onSave={onSaveSalarioFuncao}
        onUpdate={onUpdateSalarioFuncao}
        buttonSave={buttonSaveSalarioFuncao}
        fieldErros={fieldsErros}
        colaboradorId={colaboradorId}
        colaborador={colaborador}
        load={load}
      />
      <BeneficiosComissao
        onChange={onChangeBeneficioComissao}
        onSave={onSaveBeneficiosComissao}
        onUpdate={onUpdateBeneficioComissao}
        getData={getData}
        getValorTotalVt={getValorTotalVt}
        fieldErros={fieldsErros}
        buttonSave={buttonSaveBeneficioComissao}
        colaboradorId={colaboradorId}
        colaborador={colaborador}
        load={load}
      />
      <Documentos
        onChange={onChangeDocumentos}
        onSave={onSaveDocumentos}
        onUpdate={onUpdateDocumentos}
        buttonSave={buttonSaveDocumentos}
        fieldErros={fieldsErros}
        colaboradorId={colaboradorId}
        colaborador={colaborador}
        load={load}
        anexos={anexos}
        dataUpdated={setDataAnexoUpdated}
      />
      <InformacoesMedicas
        onChange={onChangeInformacoesMedicas}
        onSave={onSaveInformacoesMedicas}
        onUpdate={onSaveInformacoesMedicas}
        buttonSave={buttonSaveInformacoesMedica}
        fieldErros={fieldsErros}
        colaboradorId={colaboradorId}
        colaborador={colaborador}
        load={load}
        anexos={anexos}
        dataUpdated={setDataAnexoUpdated}
      />
      <Ferias
        onChange={onChangeFerias}
        onSave={onSaveFerias}
        onUpdate={onUpdateFerias}
        buttonSave={buttonSaveFerias}
        fieldErros={fieldsErros}
        colaboradorId={colaboradorId}
        colaborador={colaborador}
        load={load}
      />
      <DadosAcessoColaborador
        onChange={onChangeDadosAcesso}
        onSave={onSaveDadosAcesso}
        onUpdate={onUpdateDadosAcesso}
        buttonSave={buttonSaveDadosAcesso}
        fieldErros={fieldsErros}
        colaboradorId={colaboradorId}
        colaborador={colaborador}
        load={load}
        onReset={onResetSenha}
      />
    </>
  );
};

export default CadastroColaborador;
