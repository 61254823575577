import extenso from "extenso"

export const moneyFormatter = new Intl.NumberFormat("pt-BR", {
  style: "currency",
  currency: "BRL",
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

export const numberFormatter = new Intl.NumberFormat("pt-BR", {
  currency: "BRL",
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

export const formatValues = (value: number | string): string => {
  let numericValue: number;

  if (typeof value === 'string') {
      numericValue = parseFloat(value.replace(',', '.'));
  } else {
      numericValue = value;
  }

  if (isNaN(numericValue)) {
      return 'Valor inválido';
  }

  const formattedValue = numericValue.toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
  })

  return formattedValue;
}

export const formatValueStringInFloat = (value:string | null, fixed:number | null=null):number => {
  if(fixed)
    return typeof value === "string"? parseFloat(parseFloat(value?.replace(/,/g, '.')).toExponential(fixed)): (value ?? 0)
  
  else return typeof value === "string"? parseFloat(value?.replace(/,/g, '.')): (value ?? 0)
}

export const formatValueFloatInString = (value:number | null | undefined):string => {

  if(value)
   return value?.toFixed(2).replace(/\./g, ',')
  else return "0"
}

export const calcValues = (
  value:number | string | null | undefined, 
  newValue:number | string | null | undefined, 
  type:"%" | "*" | "+" | "-" | "/",
  fixed:number | null=null

) => {
  const valueVerified = typeof value === "string"? 
  formatValueStringInFloat(value): value

  const newValueVerified = typeof newValue === "string"? 
  formatValueStringInFloat(newValue): newValue

  switch(type){
    case "%":
      return fixed? parseFloat(((valueVerified ?? 0) * ((newValueVerified ?? 0) / 100)).toExponential(fixed)):
      ((valueVerified ?? 0) * ((newValueVerified ?? 0) / 100))
    case "*":
      return fixed? parseFloat(((valueVerified ?? 0) * ((newValueVerified ?? 0))).toExponential(fixed)):
      ((valueVerified ?? 0) * (newValueVerified ?? 0))
    case "+":
      return fixed? parseFloat(((valueVerified ?? 0) + ((newValueVerified ?? 0))).toExponential(fixed)):
      (valueVerified ?? 0) + (newValueVerified ?? 0)
    case "-":
      return fixed? parseFloat(((valueVerified ?? 0) - ((newValueVerified ?? 0))).toExponential(fixed)):
      (valueVerified ?? 0) - (newValueVerified ?? 0)
    case "/":
      return fixed? parseFloat(((valueVerified ?? 0) / ((newValueVerified ?? 0))).toExponential(fixed)):
        (valueVerified ?? 0) / (newValueVerified ?? 0)
  }
  
}

export const numberForExtensive = (value: number | string | null | undefined, type:"$" | null=null) => {
  if(value && typeof value === "number")
    return extenso(formatValueFloatInString(value), type === "$"? { mode: 'currency', currency: { type: 'BRL' } }: { locale: 'br' })

  else return extenso(formatValueStringInFloat(value as string), type === "$"? { mode: 'currency', currency: { type: 'BRL' } }: { locale: 'br' })
}






