import { AxiosError } from "axios";
import { toastMessage } from "./toastMessage";

export const handleError = (error: unknown, message: string | null = null) => {
  if (error instanceof AxiosError) {
    message ??= `Erro de rede: ${error.message}`;
  } else if (error instanceof Error) {
    message ??= `Erro: ${error.message}`;
  } else {
    message ??= "Ocorreu um erro desconhecido.";
  }
  toastMessage("error", message);
};
