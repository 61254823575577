import { IUpdateResponsavelLegal } from "../../../../../models/ResponsavelLegal";
import * as captadorService from "../../../../../services/api/CaptadorService";
import * as associadoServices from "../../../../../services/api/AssociadoService";
import * as anexoServices from "../../../../../services/api/AnexoService";
import {
  ICaptador,
  ICaptadorDadosContrato,
  ICaptadorResponsavelUpdateRequest,
} from "../../../../../models/Captador";
import ReadonlyArray from "../../../../../types/ReadonlyArray";
import { IUpdateResponsavelComunicacao } from "../../../../../models/ResponsavelComunicacao";
import { IAssociadoStats } from "../../../../../models/Stats";
import { EnumEstadoCivilNumericMap } from "../../../../../enums/EnumEstadoCivil.enum";
import { ObterEnumEstadoCivil } from "../../../../../utils/Utils";
import { toastMessage } from "../../../../../utils/toastMessage";
import {
  DicLogo,
  FormatoLogo,
  IDadosLogo,
  IUpdateLogoReq,
  LISTA_FORMATO_LOGO,
  UpdateDetailsLogo,
} from "../../../../../models/Logo";
import { handleError } from "../../../../../utils/handleError";
import { IAnexoDTO } from "../../../../../models/Anexo";

class AssociadoManager {
  async updateResponsavelLegal(
    captadorId: string,
    responsaveisLegais: ReadonlyArray<IUpdateResponsavelLegal>,
    responsavelComunicacao: IUpdateResponsavelComunicacao
  ): Promise<boolean> {
    const req: ICaptadorResponsavelUpdateRequest = {
      captadorId,
      responsaveisLegais: responsaveisLegais.map((x) => ({
        ...x,
        dataNascimento: x.dataNascimento?.toISOString() ?? null,
        estadoCivil:
          x.enumEstadoCivil && EnumEstadoCivilNumericMap[x.enumEstadoCivil],
      })),
      responsavelComunicacao,
    };

    try {
      await captadorService.UpdateResponsavelLegal(req);
      toastMessage("success", "Dados salvo com sucesso!");
      return true;
    } catch (error) {
      handleError(error, "Houve um erro ao atualizar os daods");
      return false;
    }
  }

  async updateAssociadoDadosContrato(
    updateDadosContrato: ICaptadorDadosContrato
  ): Promise<boolean> {
    try {
      await captadorService.UpdateAssociadoDadosContrato(updateDadosContrato);
      toastMessage("success", "Dados salvo com sucesso!");
      return true;
    } catch (error) {
      handleError(error, "Houve um erro ao atualizar os daods");
      return false;
    }
  }

  async getCaptadorById(captadorId: string): Promise<ICaptador | null> {
    try {
      const { data } = await captadorService.GetById(captadorId);
      const captador: ICaptador = {
        ...data,
        responsaveisLegais: data.responsaveisLegais.map((x) => ({
          ...x,
          dataNascimento: x.dataNascimento ? new Date(x.dataNascimento) : null,
          enumEstadoCivil: ObterEnumEstadoCivil(x.enumEstadoCivil),
        })),
      };
      return captador;
    } catch (error) {
      handleError(error, "Houve um erro ao pegar os dados.");
      return null;
    }
  }

  async getAssociadoStatus(): Promise<IAssociadoStats | null> {
    try {
      const { data } = await associadoServices.GetStats();
      return data;
    } catch (error) {
      handleError(error, "Houve um erro ao pegar os dados.");
      return null;
    }
  }

  async updateCaptadorLogo(captadorId: string, dicLogo: DicLogo) {
    const updateDicLogo = Object.fromEntries(
      LISTA_FORMATO_LOGO.map((formato) => {
        const detalhes = dicLogo[formato];
        if (detalhes && "id" in detalhes) {
          return [formato, { id: detalhes.id }];
        } else {
          return [formato, detalhes];
        }
      })
    ) as Record<FormatoLogo, UpdateDetailsLogo>;

    const req: IUpdateLogoReq = { captadorId, dicLogo: updateDicLogo };

    try {
      const { data } = await captadorService.UpdateCaptadorLogo(req);
      return Object.fromEntries(
        LISTA_FORMATO_LOGO.map((formato) => [
          formato,
          this.converterAnexoDtoEmIDadosLogo(data[formato]),
        ])
      ) as Record<FormatoLogo, IDadosLogo | null>;
    } catch (error) {
      handleError(error, "Houve um erro ao atualizar os daods");
      return null;
    }
  }

  async getLogo(captadorId: string) {
    try {
      const { data } = await captadorService.GetLogo(captadorId);
      return Object.fromEntries(
        LISTA_FORMATO_LOGO.map((formato) => [
          formato,
          this.converterAnexoDtoEmIDadosLogo(data[formato]),
        ])
      ) as Record<FormatoLogo, IDadosLogo | null>;
    } catch (error) {
      handleError(error, "Houve um erro ao pegar os dados.");
      return null;
    }
  }

converterAnexoDtoEmIDadosLogo(anexo: IAnexoDTO | null): IDadosLogo | null {
    if (!anexo) return null;
    return {
      id: anexo.id ?? "",
      descricao: anexo.descricao ?? "",
      dataUpload: new Date(anexo.createdAt),
      usuarioAnexoNome: anexo.nomeResponsavel ?? "",
    };
  }

  async dowloadAnexo(anexoId: string) {
    try {
      const { data } = await anexoServices.GetUrl(anexoId);
      return data;
    } catch (error) {
      handleError(error, "Erro ao baixar o arquivo");
      return null;
    }
  }
}

const associadoManager = new AssociadoManager();
export default associadoManager;
