import { IInformacaoContrato } from "../../../../../models/Gerador/Cadastro/InformacaoContrato";
import { AbstractValidation, Validate } from "../../../../../utils/AbstractValidation";

export class InformacaoContratoValidate extends AbstractValidation<IInformacaoContrato>{
    getValidate(): Validate<IInformacaoContrato>[] {
        return[
            {
                field:"tipotarifa",
                validate(value, model:IInformacaoContrato, addError: (message:string) => void) {
                    if(value === null) addError("Primeiro deve selecionar a tarifa")
                },
            },
            {
                field:"porcentagemdescontotarifa",
                validate(value, model:IInformacaoContrato, addError: (message:string) => void) {
                    if(!value) addError("O Valor de percentual de desconto deve ser informado")
                },
            },
            {
                field:"tarifavigentecontrato",
                validate(value, model:IInformacaoContrato, addError: (message:string) => void) {
                    if(!value) addError("O Valor de tarifa vigente deve ser informado")
                },
            },
            {
                field:"potencia",
                validate(value, model:IInformacaoContrato, addError: (message:string) => void) {
                    if(!value) addError("O Valor de potência deve ser informado")
                },
            },
            {
                field:"performacealvo",
                validate(value, model:IInformacaoContrato, addError: (message:string) => void) {
                    if(!value) addError("O Valor de performance alvo deve ser informado")
                },
            },
            {
                field:"vigenciacontratual",
                validate(value, model:IInformacaoContrato, addError: (message:string) => void) {
                    if(!value) addError("A Vigência do contrato deve ser informado")
                },
            },
            {
                field:"datalimiteconexao",
                validate(value, model:IInformacaoContrato, addError: (message:string) => void) {
                    if(!value) addError("A Data de Limite de Conexão deve ser informado")
                },
            },
            {
                field:"tipoconexao",
                validate(value, model:IInformacaoContrato, addError: (message:string) => void) {
                    if(value === null) addError("O Tipo de Conexão deve ser informado")
                },
            }
        ];
    }
}