import {InputAdornment, MenuItem, Stack, TextField} from "@mui/material"
import RegisterCard from "../../../../components/RegisterCard"
import * as C from "./style";
import * as D from "../../../../styles/appComponents";
import TotalCardOrdem from "../../../../components/TotalCardOrdem";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import {MonthYearCalendar} from "../Components/MonthYearCalendar";
import dayjs, {Dayjs} from 'dayjs';
import React, {ChangeEvent, Dispatch, SetStateAction, useEffect, useState} from "react";
import ButtonFile from "../../../../components/ButtonFile";
import {AxiosResponse} from "axios";
import {
  GetDashboardOrdemPagamento,
  GetOrdemPagamento,
  SetOrdemPagamento,
  updateOrdemPagamento
} from "../../../../services/api/OrdemPagamentoService";
import {toastMessage} from "../../../../utils/toastMessage";
import SkeletonLoadOrdemPagamento from "../../../../components/SkeletonLoads/OrdemPagamento";
import {GetOnePessaoFisica, GetOnePessaoJuridica, GetTodasUsinas} from "../../../../services/api/GeradorService";
import {EnumTipoPessoa} from "../../../../enums/Gerador/EnumTipoPessoa.enum";
import {UsinaTipoPessoaFisica, UsinaTipoPessoaJuridica} from "../../../../models/Gerador/Usina";
import {EnumTipoConexao} from "../../../../enums/Gerador/EnumTipoConexao.enum";
import {MaskCurrencyInput} from "../../../../components/MakCurrencyInput";
import {calcValues, moneyFormatter} from "../../../../utils/moneyFormatter";
import {getOrdemPagamentoByDateAndTipoConexao} from "../../../../services/api/TarifaFioBService";
import {IDashboardOrdemPagamento, INovaOrdem} from "../../../../models/OrdemPagamento";
import {IBuscaTodasUsinasPorNome, ITarifaFioB} from "../../../../models/TarifaFioB";
import {IContaOrdemPagamento} from "../../../../models/Conta";
import {NovaOrdemEnum} from "../../../../enums/EnumOrdemPagamento";

export const CadastroNovaOrdem = () => {
  //region Variáveis
  const {ordemId} = useParams();
  const navigate = useNavigate();
  const [dashboardOrdemDePagamento, setDashboardOrdemDePagamento] = useState<IDashboardOrdemPagamento>({
    totalStatusAguardandoPagamento: 0,
    totalStatusQuitada: 0,
    valorTotal: 0,
    energiaTotalInjetada: 0,
  });
  const [dataForm, setDataForm] = useState<INovaOrdem>({
    id: null,
    dataReferencia: null,
    instalacao: null,
    sigla: null,
    descontoTarifa: null,
    energiaDistribuidora: null,
    energiaGerador: null,
    assunto: null,
    descricao: null,
    tarifaDistribuidora: null,
    tipoConexao: null,
    dataLimiteQuitacao: null,
    status: null,
    comprovante: null,
    usinaId: null,
    tarifaBase: null,
    valorBase: null,
    valorCredito: null,
    tarifaFioB: null,
    saldo: null,
    diferenca: null,
    active: null,
  });
  const [searchParams] = useSearchParams();
  const [dateSelected, setDateSelected] = useState<Dayjs | null>(null);
  const [toggleVisibility, setToggleVisibility] = useState<boolean>(false);
  const [usinas, setUsinas] = useState<IBuscaTodasUsinasPorNome[]>([]);
  const [loading, setLoading]: (boolean | Dispatch<SetStateAction<boolean>>)[] = useState<boolean>(true);
  //endregion

  //region Services
  const getUsinas: () => Promise<void> = async (): Promise<void> => {
    try {
      const response: AxiosResponse<any, IBuscaTodasUsinasPorNome> = await GetTodasUsinas();
      setUsinas(response.data);
    } catch (e: unknown) {
      toastMessage("error", "Erro ao listar as usinas");
    }
  }

  const getUsina: (id: string) => Promise<void> = async (id: string): Promise<void> => {
    try {
      const usina: IBuscaTodasUsinasPorNome[] = usinas.filter((value: IBuscaTodasUsinasPorNome) => value.id === id);

      if (!Object.values(EnumTipoPessoa).includes(usina[0].tipoUsina)) {
        toastMessage("error", "Erro ao listar a usina selecionada");
        return;
      }

      const guidGerador: string = usina[0].id;

      const {data} = usina[0].tipoUsina as EnumTipoPessoa === EnumTipoPessoa.pessoaFisica
        ? await GetOnePessaoFisica(guidGerador)
        : await GetOnePessaoJuridica(guidGerador);

      setDataFromUsina(data);
    } catch (e: unknown) {

    }
  }

  const getDashboardOrdemPagamentos: () => Promise<void> = async (): Promise<void> => {
    try {
      const {data}: AxiosResponse<any, IDashboardOrdemPagamento> = await GetDashboardOrdemPagamento();
      setDashboardOrdemDePagamento(data);
    } catch (e: unknown) {
      toastMessage("error", "Erro ao listar o dashboard");
    }
  }

  const setOrdemPagamento: () => Promise<void> = async (): Promise<void> => {
    try {
      const response: AxiosResponse<INovaOrdem, any> = !ordemId ? await SetOrdemPagamento(dataForm) : await updateOrdemPagamento(dataForm);

      if (response) {
        toastMessage("success", "Ordem de pagamento criada com sucesso");
        navigate(-1);
      }
    } catch (e: unknown) {
      toastMessage("error", "Erro ao criar a ordem de pagamento");
    }
  }

  const getTarifaFioB: (date: string, tipoConexao: number) => Promise<void> = async (date: string, tipoConexao: number) => {
    try {
      let year: number = new Date(date).getFullYear();

      const response: AxiosResponse<ITarifaFioB, any> = await getOrdemPagamentoByDateAndTipoConexao(year, tipoConexao);

      setDataForm({...dataForm, tarifaFioB: response.data?.aliquota ?? 0});

    } catch (e: any) {
      setDataForm({...dataForm, tarifaFioB: 0});
    }
  }

  const getOrdemPagamento: (id: string) => Promise<void> = async (id: string) => {
    try {
      const response: AxiosResponse<IContaOrdemPagamento, any> = await GetOrdemPagamento(id);

      if (!response.data) {
        throw new Error();
      }
      const ordemPagamento: IContaOrdemPagamento = response.data;

      if (ordemPagamento.dataReferencia) {
        setDateSelected(dayjs(ordemPagamento.dataReferencia));
      }

      setDataInFields(ordemPagamento);
    } catch (e: unknown) {
      console.error(e)
      toastMessage("error", "Erro ao tentarmos buscar a ordem de pagamento");
    }
  }

  const handleExcluir: () => Promise<void> = async () => {
    const obj = {...dataForm, active: false};

    try {
      const response: AxiosResponse<INovaOrdem, any> = await updateOrdemPagamento(obj);

      if (response.data) {
        toastMessage("success", "Ordem de pagamento excluida com sucesso");
        navigate(-1);
      }
    } catch (e: unknown) {
      toastMessage("error", "Erro ao criar a ordem de pagamento");
    }
  }
  //endregion

  //region UI

  const setDataInFields: (ordemPagamento: IContaOrdemPagamento) => void = (ordemPagamento: IContaOrdemPagamento) => {
    setDataForm({
      id: ordemPagamento.id,
      dataReferencia: ordemPagamento.dataReferencia ? ordemPagamento.dataReferencia.toString().split('T')[0] : null,
      instalacao: ordemPagamento.instalacao,
      sigla: ordemPagamento.sigla,
      descontoTarifa: ordemPagamento.descontoTarifa ?? 0,
      energiaDistribuidora: ordemPagamento.energiaDistribuidora,
      energiaGerador: ordemPagamento.energiaGerador ?? 0,
      assunto: ordemPagamento.contas?.[0]?.assunto || null,
      descricao: ordemPagamento.contas?.[0]?.descricao || null,
      tarifaDistribuidora: ordemPagamento.tarifaDistribuidora ?? 0,
      tipoConexao: ordemPagamento.tipoConexao as EnumTipoConexao ?? null,
      dataLimiteQuitacao: ordemPagamento.dataLimiteQuitacao,
      status: ordemPagamento.status,
      comprovante: null,
      usinaId: ordemPagamento.usina?.id || null,
      tarifaBase: ordemPagamento.tarifaBase ?? 0,
      valorBase: ordemPagamento.valorBase ?? 0,
      valorCredito: ordemPagamento.valorCredito,
      tarifaFioB: null,
      saldo: ordemPagamento.saldo,
      diferenca: ordemPagamento.diferenca,
      active: ordemPagamento.active,
    });
  }

  const setDataFromUsina: (data: UsinaTipoPessoaFisica | UsinaTipoPessoaJuridica) => void = (data: UsinaTipoPessoaFisica | UsinaTipoPessoaJuridica): void => {
    setDataForm({
      ...dataForm,
      tarifaBase: data.operadora?.tarifaBase || 0,
      valorBase: (data.operadora?.tarifa ?? 0) * ((data.operadora?.percentualDesontoB1 ?? 0) / 100),
      tarifaDistribuidora: data.operadora?.tarifa || 0,
      descontoTarifa: data.operadora?.percentualDesontoB1 || 0,
      tipoConexao: data.tipoConexao as EnumTipoConexao,
      instalacao: data.instalacao || "",
      sigla: data.sigla || "",
    });
  }

  const handleChangeFile: (file: File) => void = (file: File): void => {
    setDataForm({...dataForm, comprovante: file});
  }

  const handleDateChange: (date: (Dayjs | null)) => void = (date: Dayjs | null): void => {
    setDataForm((state: INovaOrdem) => {
      return {...state, dataReferencia: date?.toISOString() ? new Date(date.toISOString()).toISOString() : null}
    })
    setDateSelected(date)
  }

  const handleInputChange: (event: ChangeEvent<HTMLInputElement>) => void = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const name: string = event.target.name;
    const value: string = event.target.value;

    setDataForm((state: INovaOrdem) => {
      return {
        ...state,
        [name]: value,
      };
    });
  }

  const handleCalcDiferenca: (energiaDistribuidora: number, energiaGerador: number) => void = (energiaDistribuidora: number, energiaGerador: number): void => {
    setDataForm((state: INovaOrdem) => {
      return {
        ...state,
        diferenca: calcValues(energiaDistribuidora, energiaGerador, "-", 2),
        saldo: calcValues(energiaDistribuidora, energiaGerador, "-", 2)
      };
    })
  }

  const handleCalcValorCredito: (energiaDistribuidora: number, valorBase: number) => void = (energiaDistribuidora: number, valorBase: number): void => {
    setDataForm((state: INovaOrdem) => {
      return {
        ...state,
        valorCredito: calcValues(energiaDistribuidora, valorBase, "*", 2),
      };
    })
  }

  const handleAbrirOrdem: () => void = () => {
    setToggleVisibility(false);
  }

  const handleFecharOrdem: () => void = () => {
    setToggleVisibility(true);
  }

  const handleLimparVisualizacao: () => void = () => {
    setDateSelected(null);
    setDataForm({
      ...dataForm,
      dataReferencia: null,
      instalacao: null,
      sigla: null,
      descontoTarifa: null,
      energiaDistribuidora: null,
      energiaGerador: null,
      assunto: null,
      descricao: null,
      tarifaDistribuidora: null,
      tipoConexao: null,
      dataLimiteQuitacao: null,
      status: null,
      comprovante: null,
      usinaId: null,
      tarifaBase: null,
      valorBase: null,
      valorCredito: null,
      tarifaFioB: null,
      saldo: null,
      diferenca: null,
    });
  }

  useEffect(() => {
    if (dataForm.dataReferencia != null && dataForm.tipoConexao != null) {
      getTarifaFioB(dataForm.dataReferencia, dataForm.tipoConexao);
    }
  }, [dataForm.tipoConexao, dataForm.dataReferencia]);

  useEffect(() => {
    if (![null, 0].includes(dataForm.energiaDistribuidora) && ![null, 0].includes(dataForm.energiaGerador)) {
      handleCalcDiferenca(dataForm.energiaDistribuidora as number, dataForm.energiaGerador as number);
    }
  }, [dataForm.energiaGerador, dataForm.energiaDistribuidora]);

  useEffect(() => {
    if (dataForm.energiaDistribuidora != null && dataForm.valorBase != null) {
      handleCalcValorCredito(dataForm.energiaDistribuidora as number, dataForm.valorBase as number);
    }
  }, [dataForm.energiaGerador, dataForm.valorBase]);

  useEffect(() => {
    if (dataForm.usinaId)
      getUsina(dataForm.usinaId);
  }, [dataForm.usinaId]);

  useEffect(() => {
    Promise.all([getUsinas(), getDashboardOrdemPagamentos()]).then((_: Awaited<void>[]) => setLoading(false))
  }, []);

  useEffect(() => {
    if (ordemId)
      getOrdemPagamento(ordemId);
  }, [ordemId])

  //endregion

  return (
    <>
      {
        loading ? (<SkeletonLoadOrdemPagamento/>) : (
          <>
            <Stack>
              <Stack sx={{flexDirection: "row", gap: 2}}>
                <C.StatusWrapper>
                  <TotalCardOrdem
                    colorText="green"
                    colorTitle="yellow"
                    color="yellow"
                    title="Valor total"
                    text={moneyFormatter.format(dashboardOrdemDePagamento.valorTotal)}
                    evolutionNumber="+8%"
                    evolution={true}
                  />
                </C.StatusWrapper>
                <C.StatusWrapper>
                  <TotalCardOrdem
                    colorText="blue"
                    colorTitle="blue"
                    color="green"
                    title="Energia total injetada"
                    text={dashboardOrdemDePagamento.energiaTotalInjetada + " KWH"}
                    evolutionNumber="-8%"
                    evolution={false}
                  />
                </C.StatusWrapper>
              </Stack>
            </Stack>
            <RegisterCard title={ordemId ? "Editar ordem" : "Inserir ordem"}>
              <C.FWStack direction={"row"} spacing={2}>
                <MonthYearCalendar
                  readOnly={toggleVisibility}
                  handleDateChange={handleDateChange}
                  dateSelected={dateSelected}
                />
                <TextField
                  sx={{maxWidth: "30%"}}
                  select
                  label="Usina"
                  required
                  fullWidth
                  name={NovaOrdemEnum.USINA}
                  onChange={handleInputChange}
                  value={dataForm.usinaId ?? ""}
                  inputProps={{readOnly: toggleVisibility}}
                >
                  {usinas.map((usina: IBuscaTodasUsinasPorNome, i: number) => (
                    <MenuItem key={i} value={usina.id}>
                      {usina.nome}
                    </MenuItem>
                  ))}
                </TextField>
                <MaskCurrencyInput
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        Kwh
                      </InputAdornment>
                    ),
                  }}
                  sx={{maxWidth: "15%"}}
                  label="Energia Distribuidora"
                  name={NovaOrdemEnum.ENERGIADISTRIBUIDORA}
                  onValueChange={(newValue, name, values) => {
                    handleInputChange({target: {name, value: newValue}} as unknown as ChangeEvent<HTMLInputElement>);
                  }}
                  value={dataForm.energiaDistribuidora ?? ""}
                  placeholder="00,00"
                  defaultValue={0}
                  decimalsLimit={2}
                />
                <MaskCurrencyInput
                  sx={{maxWidth: "15%"}}
                  label="Energia Gerador"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        Kwh
                      </InputAdornment>
                    ),
                  }}
                  name={NovaOrdemEnum.ENERGIAGERADOR}
                  onValueChange={(newValue, name, values) => {
                    handleInputChange({target: {name, value: newValue}} as unknown as ChangeEvent<HTMLInputElement>);
                  }}
                  value={dataForm.energiaGerador ?? ""}
                  placeholder="00,00"
                  defaultValue={0}
                  decimalsLimit={2}
                />
                <MaskCurrencyInput
                  sx={{maxWidth: "15%"}}
                  label="Diferença"
                  value={dataForm.diferenca ?? ""}
                  readonly={true}
                  defaultValue={0}
                  decimalsLimit={2}
                />
              </C.FWStack>
              <C.FWStack direction={"row"} spacing={2}>
                <TextField
                  name={NovaOrdemEnum.SALDO}
                  onChange={handleInputChange}
                  value={dataForm.saldo ?? ""}
                  sx={{maxWidth: "10%"}}
                  inputProps={{readOnly: "true"}}
                  label="Saldo"
                  type="number"
                  required
                  fullWidth
                />
                <TextField
                  name={NovaOrdemEnum.TARIFABASE}
                  inputProps={{readOnly: "true"}}
                  onChange={handleInputChange}
                  value={dataForm.tarifaBase ?? ""}
                  sx={{maxWidth: "20%"}}
                  label="Tarifa base"
                  required
                  fullWidth
                />
                <D.DateTextfield
                  inputProps={{readOnly: toggleVisibility}}
                  name={NovaOrdemEnum.DATALIMITEQUITACAO}
                  onChange={handleInputChange}
                  value={
                    ((dataForm.dataLimiteQuitacao ?? "")
                      .toString())?.split("T")[0]
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                  label="Data limite de quitação"
                  type="date"
                  required
                  fullWidth
                />
                <TextField
                  name={NovaOrdemEnum.TARIFADISTRIBUIDORA}
                  onChange={handleInputChange}
                  value={dataForm.tarifaDistribuidora ?? ""}
                  sx={{maxWidth: "20%"}}
                  inputProps={{readOnly: "true"}}
                  label="Tarifa distribuidora"
                  required
                  fullWidth
                />
                <TextField
                  name={NovaOrdemEnum.DESCONTOTARIFA}
                  onChange={handleInputChange}
                  inputProps={{readOnly: "true"}}
                  value={dataForm.descontoTarifa ?? ""}
                  sx={{maxWidth: "20%"}}
                  label="Desconto tarifa"
                  required
                  fullWidth
                />
                <TextField
                  name={NovaOrdemEnum.VALORBASE}
                  onChange={handleInputChange}
                  inputProps={{readOnly: "true"}}
                  value={dataForm.valorBase ?? ""}
                  sx={{maxWidth: "10%"}}
                  label="Valor base"
                  type="number"
                  required
                  fullWidth
                />
              </C.FWStack>
              <C.FWStack direction={"row"} spacing={2}>
                <TextField
                  inputProps={{readOnly: "true"}}
                  name={NovaOrdemEnum.VALORCREDITO}
                  onChange={handleInputChange}
                  value={dataForm.valorCredito ?? 0}
                  sx={{maxWidth: "15%"}}
                  label="Valor crédito"
                  type="number"
                  required
                  fullWidth
                />
                <TextField
                  inputProps={{readOnly: true}}
                  onChange={handleInputChange}
                  sx={{maxWidth: "30%"}}
                  select
                  label="Tipo conexão"
                  name={NovaOrdemEnum.TIPOCONEXAO}
                  value={dataForm.tipoConexao ?? ""}
                  required
                  fullWidth>
                  {Object.keys(EnumTipoConexao).filter((key: string) => isNaN(Number(key))).map((key: string, i: number) => (
                    <MenuItem key={key} value={i}>
                      {key}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  inputProps={{readOnly: "true"}}
                  name={NovaOrdemEnum.TARIFAFIOB}
                  onChange={handleInputChange}
                  value={dataForm.tarifaFioB ?? ""}
                  sx={{maxWidth: "15%"}}
                  label="Tarifa fio B"
                  required
                  fullWidth
                />
              </C.FWStack>
              <C.FWStack direction={"row"} spacing={2}
                         justifyContent={ordemId ? "space-between" : "flex-end"}>
                {
                  ordemId &&
                    <>
                        <D.ContainedButton onClick={handleLimparVisualizacao} $color="green">Limpar
                            visualização</D.ContainedButton>
                        <D.ContainedButton onClick={handleAbrirOrdem} $color="blue">Abrir ordem</D.ContainedButton>
                        <D.ContainedButton onClick={handleFecharOrdem} $color="purple">Fechar ordem</D.ContainedButton>
                        <D.ContainedButton onClick={handleExcluir} $color="red">Excluir</D.ContainedButton>
                    </>
                }
                {
                  !ordemId && <ButtonFile multiple={false} onFileChange={handleChangeFile}/>
                }
                <D.ContainedButton onClick={setOrdemPagamento} style={{
                  marginTop: 'auto',
                  marginBottom: 'auto',
                  height: 0
                }}>Salvar</D.ContainedButton>
              </C.FWStack>
            </RegisterCard>
          </>
        )
      }
    </>
  )
}