import { Id } from "./Id";

export interface ICreateLogo {
  arquivo: File;
  descricao: string;
  usuarioAnexouId: string;
  usuarioAnexoNome: string;
}

export interface IDadosLogo {
  id: string;
  descricao: string;
  dataUpload: Date;
  usuarioAnexoNome: string;
}

export type DetailsLogo = ICreateLogo | IDadosLogo | null;

export const LISTA_FORMATO_LOGO = ["svg", "png", "pdf"] as const;

export type FormatoLogo = (typeof LISTA_FORMATO_LOGO)[number];

export type DicLogo = Record<FormatoLogo, DetailsLogo>;

export type UpdateDetailsLogo = Id | ICreateLogo | null;

export interface IUpdateLogoReq {
  captadorId: string;
  dicLogo: Record<FormatoLogo, UpdateDetailsLogo>;
}
