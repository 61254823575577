import * as C from "../style";
import { TextField } from "@mui/material";
import { FC } from "react";
import { IUpdateResponsavelComunicacao } from "../../../../../../models/ResponsavelComunicacao";
import MaskDefaultInput from "../../../../../../components/MasDefaultinput/MaskDefaultInput";
import { ICelularInputReturn } from "../../../../../../models/Celular";

type PropsReponsavelComunicacao = {
  responsavel: IUpdateResponsavelComunicacao;
  setResponsavel: (novoResponsavel: IUpdateResponsavelComunicacao) => void;
  readOnly: boolean;
};

export const ResponsavelComunicacao: FC<PropsReponsavelComunicacao> = ({
  responsavel,
  setResponsavel,
  readOnly,
}) => {
  return (
    <>
      <C.TitleArea>
        <h2>Responsável pela comunicação</h2>
      </C.TitleArea>
      <C.FWStack direction={"row"} spacing={2}>
        <TextField
          label="Nome"
          fullWidth
          onChange={(e) =>
            setResponsavel({ ...responsavel, nome: e.target.value })
          }
          value={responsavel.nome}
          inputProps={{ readOnly }}
        />
        <TextField
          label="Email"
          fullWidth
          onChange={(e) =>
            setResponsavel({ ...responsavel, email: e.target.value })
          }
          value={responsavel.email}
          inputProps={{ readOnly }}
        />
        <MaskDefaultInput
          label="Celular"
          type="CELULAR"
          onChange={(e) => {
            const celular = e as ICelularInputReturn;
            setResponsavel({
              ...responsavel,
              celular: celular.value,
              countryCode: celular.zipCode,
            });
          }}
          value={responsavel.celular}
          readonly={readOnly}
        />
      </C.FWStack>
    </>
  );
};
