import RegisterCard from "../../../../../../../components/RegisterCard";
import React, {Dispatch, SetStateAction, useEffect, useReducer, useState} from "react";
import * as G from "../../style";
import {
  Checkbox,
  Chip,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import * as D from "../../../../../../../styles/appComponents";
import { ptBR } from "@mui/x-data-grid/locales";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import DeleteIcon from "@mui/icons-material/Delete";
import { GridColDef } from "@mui/x-data-grid";
import DownloadIcon from "@mui/icons-material/Download";
import {IDocumentoForm} from "../Documentos";
import { ColaboradorByIdResponse } from "../../../../../../../models/Colaborador/Colaborador";
import { ErrorMessage } from "../../../../../../../utils/AbstractValidation";
import { handleStyleFieldError } from "../../../../../../../utils/verifyFieldError";
import AddIcon from "@mui/icons-material/Add";
import { IAnexoDTO } from "../../../../../../../models/Anexo";
import { ArquivoSection } from "../../../../../../../components/ArquivoSection";
import { EnumTipoAnexo } from "../../../../../../../enums/EnumTipoAnexo.enum";

enum informacoesMedicasEnum {
  TIPOCONSULTA="TIPOCONSULTA",
  EXAME="EXAME",
  DATA="DATA"
}

export interface IInformacoesMedicasForm {
  id?: string | null;
  tipoconsulta: string | null;
  exame: string | null;
  data: Date | null;
}


type PropsInformacoesMedicas = {
  onSave: (data: IInformacoesMedicasForm[]) => void;
  onUpdate: (data: IInformacoesMedicasForm[]) => void;
  onChange: (data: IInformacoesMedicasForm[]) => void;
  buttonSave: boolean;
  data?: object | null;
  fieldErros: ErrorMessage[] | null;
  colaboradorId: string | undefined;
  colaborador: ColaboradorByIdResponse | undefined;
  load: { status:boolean, section:string };
  anexos: IAnexoDTO[];
  dataUpdated: Dispatch<SetStateAction<boolean>>;
};

const InformacoesMedicas:React.FC<PropsInformacoesMedicas> = (
  {
    onSave,
    onUpdate,
    onChange,
    data,
    fieldErros,
    colaboradorId,
    colaborador,
    load,
    buttonSave,
    anexos,
    dataUpdated
  }
) => {
  const dadosInformacoesMedicasFormModel:IInformacoesMedicasForm = {
    tipoconsulta: null,
    exame: null,
    data: null
  };
  const [dadosInformacoesMedicas, setDadosInformacoesMedicas] = useState<IInformacoesMedicasForm[]>([dadosInformacoesMedicasFormModel])
  const sendOnSave = (): void => onSave(dadosInformacoesMedicas);
  const sendOnUpdate = (): void => onUpdate(dadosInformacoesMedicas);
  const sendOnChange = (update: IInformacoesMedicasForm[]): void => onChange(update);

  const handleInputChange = (index: number) => ({target}: React.ChangeEvent<HTMLInputElement>) => {
    const name = target?.name?.toLocaleLowerCase();
    const value = target?.value;

    const newForm = [...dadosInformacoesMedicas]
    newForm[index] = { ...newForm[index], [name]: value }

    setDadosInformacoesMedicas(newForm);
    sendOnChange(newForm)
  };

  const addNewForm = () => {
    setDadosInformacoesMedicas((x) => [...x, dadosInformacoesMedicasFormModel])
  }

  useEffect(() => {
    if(colaboradorId){
      const dadosInformacoesMedicasFormModel:IInformacoesMedicasForm = {
        id: null,
        tipoconsulta: null,
        exame: null,
        data: null
      };

      const newDadosInformacoesMedicas:IInformacoesMedicasForm[] = (colaborador?.consultas || []).map((x) => {
        return {
          id: x?.id || null,
          tipoconsulta: x?.tipoConsulta || null,
          exame: x?.exame || null,
          data: x.data || null
        }
      })


      setDadosInformacoesMedicas(
        newDadosInformacoesMedicas.length === 0? 
        [dadosInformacoesMedicasFormModel]: 
        newDadosInformacoesMedicas
      )
    }
  }, 
  [
    colaboradorId,
    colaborador
  ])

  const columns: GridColDef[] = [
    {
      field: "tipo",
      headerName: "Tipo",
      flex: 1,
    },
    {
      field: "exame",
      headerName: "Exame",
      flex: 1,
    },
    { field: "data", headerName: "Data assinatura", flex: 1 },
  ];

  const rows = [
    {
      id: 1,
      tipo: "Pré-admissional",
      exame: "Avaliação clinica",
      data: "05/11/2013",
    },
    {
      id: 2,
      tipo: "Pré-admissional",
      exame: "Avaliação clinica",
      data: "05/11/2013",
    },
    {
      id: 3,
      tipo: "Pré-admissional",
      exame: "Avaliação clinica",
      data: "05/11/2013",
    },
    {
      id: 4,
      tipo: "Pré-admissional",
      exame: "Avaliação clinica",
      data: "05/11/2013",
    },
    {
      id: 5,
      tipo: "Pré-admissional",
      exame: "Avaliação clinica",
      data: "05/11/2013",
    },
    {
      id: 6,
      tipo: "Pré-admissional",
      exame: "Avaliação clinica",
      data: "05/11/2013",
    },
  ];

  const columns2: GridColDef[] = [
    {
      field: "responsavel",
      headerName: "Responsável",
      flex: 1,
    },
    { field: "desc", headerName: "Decrição", flex: 1 },
    {
      field: "data",
      headerName: "Data Upload",
      flex: 1,
    },
    {
      field: "anexo",
      headerName: "Anexo",
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <D.GridField>
          <D.OutlineButton
            sx={{ fontSize: "10px" }}
            startIcon={<DownloadIcon />}
            $color="blue"
          >
            Baixar
          </D.OutlineButton>
        </D.GridField>
      ),
    },
    {
      field: "remover",
      headerName: "Remover",
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <D.GridField>
          <D.OutlineButton
            sx={{ fontSize: "10px" }}
            startIcon={<DeleteIcon />}
            $color="red"
          >
            Deletar
          </D.OutlineButton>
        </D.GridField>
      ),
    },
  ];

  const rows2 = [
    {
      id: 1,
      responsavel: "Thaynara",
      desc: "RG",
      data: "01/01/2024",
    },
    {
      id: 2,
      responsavel: "Thaynara",
      desc: "CPF",
      data: "01/01/2024",
    },
    {
      id: 3,
      responsavel: "Thaynara",
      desc: "Carteira de trabalho",
      data: "01/01/2024",
    },
  ];

  const newAnexos = anexos
    .filter(x => x.enumTipoAnexo === EnumTipoAnexo.documentosConsultasColaborador)

  return (
    <RegisterCard title="Documentos">
      <G.FWStack direction={"row"} spacing={2}>
        <RegisterCard title="Consultas/exames">
            {
              (dadosInformacoesMedicas || []).map((x:IInformacoesMedicasForm, index:number) => (
                <G.FWStack direction={"row"} spacing={2}>
                <TextField
                  inputProps={{ readOnly: buttonSave }}
                  sx={handleStyleFieldError(informacoesMedicasEnum.TIPOCONSULTA, fieldErros)} 
                  name={informacoesMedicasEnum.TIPOCONSULTA}
                  value={x?.tipoconsulta ?? ""}
                  label="Tipo" 
                  fullWidth 
                  onChange={handleInputChange(index)}
                  InputLabelProps={{
                    shrink: true,
                  }} 
                />
                <TextField
                  inputProps={{ readOnly: buttonSave }}
                  sx={handleStyleFieldError(informacoesMedicasEnum.EXAME, fieldErros)} 
                  name={informacoesMedicasEnum.EXAME}
                  value={x?.exame ?? ""}
                  type="text"
                  label="Exame" 
                  fullWidth 
                  onChange={handleInputChange(index)}
 
                />
                <TextField
                  inputProps={{ readOnly: buttonSave }}
                  type="date"
                  sx={handleStyleFieldError(informacoesMedicasEnum.DATA, fieldErros)} 
                  name={informacoesMedicasEnum.DATA}
                  value={
                    ((x?.data ?? "")
                    .toString())?.split("T")[0]
                  }
                  label="Data assinatura" 
                  fullWidth 
                  onChange={handleInputChange(index)}
                  InputLabelProps={{
                    shrink: true,
                  }} 
                />
                </G.FWStack>
              ))
            }
          <G.FWStack direction={"row"} spacing={2} justifyContent={"flex-end"}>
          <D.ContainedButton
            startIcon={<AddIcon />}
            onClick={addNewForm}
            $color="pink"
          >
            Nova tarifa
          </D.ContainedButton>
        </G.FWStack>
        </RegisterCard>
      </G.FWStack>
      {
        colaboradorId &&
        <ArquivoSection 
          anexos={newAnexos}
          colaboradorId={colaboradorId}
          dataUpdated={dataUpdated}
          tipoAnexo={EnumTipoAnexo.documentosConsultasColaborador}
        />
      }
      <G.FWStack direction={"row"} spacing={2} justifyContent={"flex-end"}>
        <D.ContainedButton $color="yellow">Editar</D.ContainedButton>
        <D.ContainedButton onClick={sendOnSave}>Salvar</D.ContainedButton>
      </G.FWStack>
    </RegisterCard>
  );
};

export default InformacoesMedicas;
