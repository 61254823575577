import { ICreateResponsavelLegal } from "../../../../../models/ResponsavelLegal";
import {
  AbstractValidation,
  Validate,
} from "../../../../../utils/AbstractValidation";

export class ResponsavelLegalValidate extends AbstractValidation<ICreateResponsavelLegal> {
  getValidate(): Validate<ICreateResponsavelLegal>[] {
    return [
      {
        field: "nome",
        validate: (
          value,
          model: ICreateResponsavelLegal,
          addError: (message: string) => void
        ) => {
          if (!value)
            addError("O nome do Responsavel Legal deve ser informado");
        },
      },
      {
        field: "email",
        validate: (
          value,
          model: ICreateResponsavelLegal,
          addError: (message: string) => void
        ) => {
          if (!value) addError("O Email deve ser informado");
        },
      },
      {
        field: "cpf",
        validate: (
          value,
          model: ICreateResponsavelLegal,
          addError: (message: string) => void
        ) => {
          if (!value) addError("O Cpf deve ser informado");
        },
      },
      {
        field: "cpf",
        validate: (
          value,
          model: ICreateResponsavelLegal,
          addError: (message: string) => void
        ) => {
          if (value?.length !== 11) addError("O CPF deve possuir 11 dígitos");
        },
      },
      {
        field: "datanascimento",
        validate: (
          value,
          model: ICreateResponsavelLegal,
          addError: (message: string) => void
        ) => {
          if (!value) addError("A Data de Nascimento deve ser informada");
        },
      },
      {
        field: "datanascimento",
        validate: (
          value,
          model: ICreateResponsavelLegal,
          addError: (message: string) => void
        ) => {
          const now = new Date();
          const limitDate = new Date(
            now.getFullYear() - 18,
            now.getMonth(),
            now.getDate()
          );
          if (value && new Date(value) > limitDate)
            addError("A idade deve ser de pelo menos 18 anos.");
        },
      },
      {
        field: "cep",
        validate: (
          value,
          model: ICreateResponsavelLegal,
          addError: (message: string) => void
        ) => {
          if (!value) addError("O Cep deve ser informado");
        },
      },
      {
        field: "cep",
        validate: (
          value,
          model: ICreateResponsavelLegal,
          addError: (message: string) => void
        ) => {
          if (value?.length !== 8) addError("O Cep deve ser conter 8 dígitos");
        },
      },
    ];
  }
}
