import {AxiosResponse} from "axios";
import http from "../common/http-common";
import {IContaOrdemPagamento} from "../../models/Conta";
import {INovoPagamento, IStatusOrdemPagamento} from "../../models/OrdemPagamento";

export const GetContas: (id: string, pageNumber: number, pageSize: number) => Promise<AxiosResponse<IContaOrdemPagamento, any>> = (
  id: string,
  pageNumber: number,
  pageSize: number
): Promise<AxiosResponse<IContaOrdemPagamento, any>> => {
  return http.get(
    `/ordemPagamento/getById?id=${id}&pageSize=${pageSize}&pageNumber=${pageNumber}`
  );
};

export const GetConta: (id: string) => Promise<AxiosResponse<INovoPagamento, any>> = (
  id: string,
): Promise<AxiosResponse<INovoPagamento, any>> => {
  return http.get(
    `/conta/${id}`
  );
};

export const SetConta: (data: INovoPagamento) => Promise<AxiosResponse<INovoPagamento, any>> = (
  data: INovoPagamento
): Promise<AxiosResponse<INovoPagamento, any>> => {
  const formData = new FormData();

  if(data.ordemPagamentoId) {
    formData.append("ordemPagamentoId", data.ordemPagamentoId);
  }
  if(data.data) {
    formData.append("data", data.data);
  }
  if(data.tipoPagamento) {
    formData.append("tipoPagamento", data.tipoPagamento);
  }
  if(data.valor) {
    formData.append("valor", data.valor as unknown as string);
  }
  if(data.energiaTotalGd) {
    formData.append("energiaTotalGd", data.energiaTotalGd as unknown as string);
  }
  if(data.assunto) {
    formData.append("assunto", data.assunto);
  }
  if(data.descricao) {
    formData.append("descricao", data.descricao);
  }
  if(data.comprovante) {
    formData.append("comprovante", data.comprovante);
  }
  return http.post(`/conta/`, formData);
};

export const UpdateConta: (data: INovoPagamento) => Promise<AxiosResponse<INovoPagamento, any>> = (
  data: INovoPagamento
): Promise<AxiosResponse<INovoPagamento, any>> => {
  const formData = new FormData();

  if(data.id) {
    formData.append("id", data.id);
  }
  if(data.ordemPagamentoId) {
    formData.append("ordemPagamentoId", data.ordemPagamentoId);
  }
  if(data.data) {
    formData.append("data", data.data);
  }
  if(data.tipoPagamento) {
    formData.append("tipoPagamento", data.tipoPagamento);
  }
  if(data.valor) {
    formData.append("valor", data.valor as unknown as string);
  }
  if(data.energiaTotalGd) {
    formData.append("energiaTotalGd", data.energiaTotalGd as unknown as string);
  }
  if(data.assunto) {
    formData.append("assunto", data.assunto);
  }
  if(data.descricao) {
    formData.append("descricao", data.descricao);
  }
  if(data.comprovante) {
    formData.append("comprovante", data.comprovante);
  }
  return http.put(`/conta/`, formData);
};

export const GetStatusConta: (id: string) => Promise<AxiosResponse<IStatusOrdemPagamento, any>> = (
  id: string,
): Promise<AxiosResponse<IStatusOrdemPagamento, any>> => {
  return http.get(
    `/conta/dashboard/${id}`
  );
};

export const DisableContaComprovante: (id: string) => Promise<AxiosResponse<any, any>> = (
  id: string,
): Promise<AxiosResponse<any, any>> => {
  return http.post(
    `/conta/disableComprovante/${id}`
  );
};