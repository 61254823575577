import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import CloseIcon from "@mui/icons-material/Close";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Modal, Typography } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { AxiosError } from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import ExcelIcon from "../../../../assets/Plataforma/excelIcon.svg";
import Datatable from "../../../../components/Datatable";
import StatusCard from "../../../../components/StatusCard";
import { ConvenioNumberMap } from "../../../../enums/EnumConvenio.enum";
import {
  StatusContratoParceiroColorMap,
  StatusContratoParceiroNumberMap,
} from "../../../../enums/EnumStatusContratoParceiro.enum";

import { TipoChavePixNumberMap } from "../../../../enums/EnumTipoChavePix.enum";
import { TipoDocumentoNumberMap } from "../../../../enums/EnumTipoDocumento.enum";
import { IAssociado, IAssociadoListItem } from "../../../../models/Associado";
import { IAssociadoStats } from "../../../../models/Stats";
import * as services from "../../../../services/api/AssociadoService";
import * as D from "../../../../styles/appComponents";
import * as C from "./style";
import { ICreateLog, ILog } from "../../../../models/Log";
import HistoricoCard from "../../../../components/HistoricoCard";
import * as logServices from "../../../../services/api/LogService";
import {
  CREATE_ASSOCIADO_DEFAUL,
  CREATE_LOG_DEFAULT,
} from "../../../../utils/Constants";
import { CreateAssociado } from "../../../../services/api/CaptadorService";
import { useAuth } from "../../../../hooks/useAuth";
import associadoManager from "./Util/AssociadoManager";

const ParceiroAssociados: React.FC = () => {
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [search, setSearch] = useState("");
  const [open, setOpen] = useState(false);
  const [statusOpen, setStatusOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1024);
  const [stats, setStats] = useState<IAssociadoStats>({
    leads: 0,
    aguardandoAssinaturaContrato: 0,
    contratoAssinado: 0,
    aguardandoAssinaturaDistrato: 0,
    cancelado: 0,
  });
  const [associadosRows, setAssociadosRows] = useState<IAssociadoListItem[]>(
    []
  );
  const [modalId, setModalId] = useState<string>("");
  const [historico, setHistorico] = useState<ILog[]>([]);
  const [novoHistorico, setNovoHistorico] =
    useState<ICreateLog>(CREATE_LOG_DEFAULT);
  const { user } = useAuth();

  const navigate = useNavigate();

  const [associadoModal, setAssociadoModal] = useState<IAssociado | null>(null);

  const handleChangePage = (value: number) => {
    setPage(value);
  };

  const handleChangePageSize = (value: number) => {
    setPageSize(value);
  };

  const handleChangeSearch = (value: string) => {
    setSearch(value);
  };

  const getAssociadoModalData = async (id: string) => {
    await services
      .GetById(id)
      .then((response) => {
        setAssociadoModal(response.data);
      })
      .catch((e: AxiosError) => {
        const errorMessage: string = e.response
          ? String(e.response?.data)
          : "Houve um erro ao pegar os dados.";
        Swal.fire({
          position: "top-end",
          toast: true,
          icon: "warning",
          title: errorMessage,
          showConfirmButton: false,
          showCloseButton: true,
          timer: 3000,
        });
      });
  };

  const getLogsByAssoicadoId = async (id: string) => {
    logServices
      .GetLogsByItemId(id)
      .then((response) => {
        setHistorico(response.data);
      })
      .catch((e: AxiosError) => {
        const errorMessage: string = e.response
          ? String(e.response?.data)
          : "Houve um erro ao pegar os dados.";
        setHistorico([]);
        Swal.fire({
          position: "top-end",
          toast: true,
          icon: "warning",
          title: errorMessage,
          showConfirmButton: false,
          showCloseButton: true,
          timer: 3000,
        });
      });
  };

  const getModalData = async (id: string) => {
    await Promise.all([getAssociadoModalData(id), getLogsByAssoicadoId(id)]);
  };

  const handleOpen = async (id: string) => {
    setModalId(id);
    await getModalData(id);
    setOpen(true);
  };

  const handleClose = () => {
    setModalId("");
    setOpen(false);
    setAssociadoModal(null);
    setHistorico([]);
  };

  const createNewLog = async () => {
    if (!associadoModal?.id) return;
    if (!novoHistorico.assunto) {
      Swal.fire({
        position: "top-end",
        toast: true,
        icon: "warning",
        title: "Assunto não pode ser vazio.",
        showConfirmButton: false,
        showCloseButton: true,
        timer: 3000,
      });

      return;
    }

    const novoHistoricoData: ICreateLog = {
      ...novoHistorico,
      associadoId: associadoModal?.id,
    };

    await logServices
      .CreateLog(novoHistoricoData)
      .then(() => {
        Swal.fire({
          position: "top-end",
          toast: true,
          icon: "success",
          title: "Criado com sucesso!",
          showConfirmButton: false,
          showCloseButton: true,
          timer: 3000,
        });
        getModalData(modalId);
      })
      .catch((e: AxiosError) => {
        const errorMessage: string = e.response
          ? String(e.response?.data)
          : "Houve um erro ao pegar os dados.";

        Swal.fire({
          position: "top-end",
          toast: true,
          icon: "warning",
          title: errorMessage,
          showConfirmButton: false,
          showCloseButton: true,
          timer: 3000,
        });
      });
  };

  const handleNewHistoricoChange = (
    assunto: string,
    descricao: string,
    anexo: File | null
  ) => {
    setNovoHistorico((x) => ({
      ...x,
      assunto: assunto,
      descricao: descricao,
      anexo: anexo,
    }));
  };

  const handleStatusOpen = () => {
    setStatusOpen(!statusOpen);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1024);
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleNovoAssociado = () => {
    if (!user) return;

    CreateAssociado({
      ...CREATE_ASSOCIADO_DEFAUL,
      captadorMasterId: user.idCaptador,
    })
      .then((res) => {
        navigate(`/Parceiro/Editar/Associado/${res.data.id}`);
      })
      .catch((e) => console.error(e));
  };

  useEffect(() => {
    const buscarDados = async () => {
      const novoAssociadoStatus = await associadoManager.getAssociadoStatus();
      if (novoAssociadoStatus) setStats(novoAssociadoStatus);
    };

    buscarDados();
  }, []);

  useEffect(() => {
    services
      .GetAll(page, pageSize, search)
      .then((response) => {
        setAssociadosRows(response.data.data);
        setTotalPages(response.data.totalPages);
      })
      .catch((e: AxiosError) => {
        var errorMessage: string = e.response
          ? String(e.response?.data)
          : "Houve um erro ao pegar os dados.";

        Swal.fire({
          position: "top-end",
          toast: true,
          icon: "warning",
          title: errorMessage,
          showConfirmButton: false,
          showCloseButton: true,
          timer: 3000,
        });
      });
  }, [page, pageSize, search]);

  const columns: GridColDef[] = [
    {
      field: "idReferencial",
      headerName: "ID",
      renderCell: (params) => (
        <D.GridField>
          <C.Links
            to={`/Parceiro/Editar/Associado/${
              associadosRows.find((x) => x.idReferencial === params.value)
                ?.captadorId
            }`}
          >
            {params.value}
          </C.Links>
        </D.GridField>
      ),
      width: 64,
    },
    {
      field: "responsavelLegal",
      headerName: "Responsável legal",
      flex: 1,
      align: "center",
      renderCell: (params) => (
        <D.GridField>
          <Typography
            style={{
              whiteSpace: "normal",
              fontSize: 14,
            }}
          >
            {params.value}
          </Typography>
        </D.GridField>
      ),
    },
    {
      field: "celular",
      headerName: "Celular",
      flex: 1,
      renderCell: (params) => <D.GridField>{params.value}</D.GridField>,
    },
    {
      field: "prazoContratual",
      headerName: "Prazo contratual",
      flex: 1,
      renderCell: (params) => (
        <D.GridField>
          {params.value
            ? new Date(String(params.value)).toLocaleDateString()
            : "Indeterminado"}
        </D.GridField>
      ),
    },
    {
      field: "backoffice",
      headerName: "Backoffice",
      flex: 1,
      align: "center",
      renderCell: (params) => (
        <D.GridField>
          <Typography
            style={{
              whiteSpace: "normal",
              fontSize: 14,
            }}
          >
            {params.value}
          </Typography>
        </D.GridField>
      ),
    },
    {
      field: "status",
      headerName: "Status",
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <D.GridField $alignCenter>
          <D.Circle color={StatusContratoParceiroColorMap[params.value]} />
        </D.GridField>
      ),
    },
    {
      field: "outras",
      headerName: "Outras",
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <D.GridField $alignCenter>
          <D.DefaultGridButton onClick={() => handleOpen(params.row.id)}>
            Ver mais
          </D.DefaultGridButton>
        </D.GridField>
      ),
    },
  ];

  return (
    <>
      <C.Container>
        <C.Title onClick={() => handleStatusOpen()}>
          Status
          {isMobile && (
            <>
              {!statusOpen ? (
                <KeyboardArrowDownRoundedIcon />
              ) : (
                <KeyboardArrowUpIcon />
              )}
            </>
          )}
        </C.Title>

        {(!isMobile || (statusOpen && isMobile)) && (
          <C.StatusArea>
            <C.StatusWrapper>
              <StatusCard color="yellow" text="Lead" number={stats.leads} />
            </C.StatusWrapper>
            <C.StatusWrapper>
              <StatusCard
                color="blue"
                text="Aguardando assinatura do contrato"
                number={stats.aguardandoAssinaturaContrato}
              />
            </C.StatusWrapper>
            <C.StatusWrapper>
              <StatusCard
                color="green"
                text="Contrato assinado"
                number={stats.contratoAssinado}
              />
            </C.StatusWrapper>
            <C.StatusWrapper>
              <StatusCard
                color="red"
                text="Aguardando assinatura de distrato"
                number={stats.aguardandoAssinaturaDistrato}
              />
            </C.StatusWrapper>
            <C.StatusWrapper>
              <StatusCard
                color="purple"
                text="Cancelado"
                number={stats.cancelado}
              />
            </C.StatusWrapper>
          </C.StatusArea>
        )}
        <D.DataArea $align="right">
          <D.ContainedButton
            startIcon={<AddCircleOutlineOutlinedIcon />}
            onClick={handleNovoAssociado}
            style={{ display: "none" }}
          >
            Novo associado
          </D.ContainedButton>
          <Datatable
            columns={columns}
            rows={associadosRows}
            pageNumber={page}
            pageSize={pageSize}
            onChangePage={handleChangePage}
            onChangePageSize={handleChangePageSize}
            onSearch={handleChangeSearch}
            totalPages={totalPages}
          />
          <C.LineBreak />
          <C.ExportButton
            variant="contained"
            startIcon={
              <img
                style={{ width: "20px", color: "white", fill: "white" }}
                src={ExcelIcon}
                alt="excelIcon"
              />
            }
          >
            Gerar relatório
          </C.ExportButton>
        </D.DataArea>
      </C.Container>
      <Modal
        sx={{ width: "100%", zIndex: 10 }}
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-details"
        aria-describedby="modal-details"
      >
        <D.ModalContainer>
          <D.ModalArea>
            {associadoModal == null ? (
              <></>
            ) : (
              <>
                <D.ModalCard>
                  <D.ModalHeader>
                    <h2>{associadoModal.nomeAssociado}</h2>
                    <D.CloseButton aria-label="close" onClick={handleClose}>
                      <CloseIcon />
                    </D.CloseButton>
                  </D.ModalHeader>
                  <D.ModalLine>
                    <D.ModalTextIcon>
                      <D.Circle
                        color={
                          StatusContratoParceiroColorMap[
                            Number(associadoModal.status)
                          ]
                        }
                      />
                      <p>
                        {
                          StatusContratoParceiroNumberMap[
                            Number(associadoModal.status)
                          ]
                        }
                      </p>
                    </D.ModalTextIcon>
                  </D.ModalLine>
                  <D.ModalLine>
                    <D.ModalItem>
                      <label>Nome do associado:</label>
                      <p>{associadoModal.nomeAssociado}</p>
                    </D.ModalItem>
                    <D.ModalItem>
                      <label>Celular do associado:</label>
                      <p>{associadoModal.celularAssociado}</p>
                    </D.ModalItem>
                    <D.ModalItem>
                      <label>Nome do master:</label>
                      <p>{associadoModal.nomeMaster}</p>
                    </D.ModalItem>
                    <D.ModalItem>
                      <label>Celular do master:</label>
                      <p>{associadoModal.celularMaster}</p>
                    </D.ModalItem>
                  </D.ModalLine>
                  <D.ModalLine>
                    <D.ModalItem>
                      <label>Backoffice:</label>
                      <p>{associadoModal.backoffice}</p>
                    </D.ModalItem>
                    <D.ModalItem>
                      <label>Convênio:</label>
                      <p>
                        {ConvenioNumberMap[Number(associadoModal.convenio)]}
                      </p>
                    </D.ModalItem>
                    <D.ModalItem>
                      <label>Vencimento do contrato:</label>
                      <p>
                        {associadoModal.vigenciaContrato
                          ? new Date(
                              String(associadoModal.vigenciaContrato)
                            ).toLocaleDateString()
                          : "Indeterminado"}
                      </p>
                    </D.ModalItem>
                    <D.ModalItem></D.ModalItem>
                  </D.ModalLine>
                  <D.ModalLine>
                    <D.ModalItem>
                      <label>Dados para pagamento de comissão</label>
                    </D.ModalItem>
                  </D.ModalLine>
                  <D.ModalLine>
                    <D.ModalItem>
                      <label>Banco:</label>
                      <p>{associadoModal?.dadosBancario.dadosDeposito.banco}</p>
                    </D.ModalItem>
                    <D.ModalItem>
                      <label>Agência:</label>
                      <p>
                        {associadoModal?.dadosBancario.dadosDeposito.agencia}
                      </p>
                    </D.ModalItem>
                    <D.ModalItem>
                      <label>Conta:</label>
                      <p>{associadoModal?.dadosBancario.dadosDeposito.conta}</p>
                    </D.ModalItem>
                    <D.ModalItem>
                      <label>
                        {associadoModal?.dadosBancario.dadosDeposito.documento
                          .tipoDocumento &&
                          TipoDocumentoNumberMap[
                            Number(
                              associadoModal?.dadosBancario.dadosDeposito
                                .documento.tipoDocumento
                            )
                          ]}
                        :
                      </label>
                      <p>
                        {
                          associadoModal?.dadosBancario.dadosDeposito.documento
                            .numero
                        }
                      </p>
                    </D.ModalItem>
                    <D.ModalItem>
                      <label>Favorecido:</label>
                      <p>
                        {associadoModal?.dadosBancario.dadosDeposito.favorecido}
                      </p>
                    </D.ModalItem>
                  </D.ModalLine>
                  <D.ModalLine>
                    <D.ModalItem>
                      <label>Pix:</label>
                      <p>
                        {associadoModal?.dadosBancario.dadosPix.tipoChavePix &&
                          TipoChavePixNumberMap[
                            Number(
                              associadoModal?.dadosBancario.dadosPix
                                .tipoChavePix
                            )
                          ]}
                      </p>
                    </D.ModalItem>
                    <D.ModalItem>
                      <label>Chave:</label>
                      <p>{associadoModal?.dadosBancario.dadosPix.chavePix}</p>
                    </D.ModalItem>
                    <D.ModalItem></D.ModalItem>
                    <D.ModalItem></D.ModalItem>
                    <D.ModalItem></D.ModalItem>
                  </D.ModalLine>
                  <D.ModalLine>
                    <HistoricoCard
                      tableData={historico}
                      saveFunction={createNewLog}
                      getNewHistoricData={handleNewHistoricoChange}
                      reloadTable={() => getModalData(modalId)}
                    />
                  </D.ModalLine>
                </D.ModalCard>
              </>
            )}
          </D.ModalArea>
        </D.ModalContainer>
      </Modal>
    </>
  );
};

export default ParceiroAssociados;
