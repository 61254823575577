import {
  ICreateGDContratoCliente,
  IFullGDContratoCliente,
  IGDContrato,
  IGDContratoPaged,
  IGDContratoStats,
  IUpdateEndereco,
  IUpdateInformacoesContrato,
  IUpdateInformacoesTitularPF,
  IUpdateInformacoesTitularPJ,
  IUpdateOutrasInformacoes,
  IUpdatePreferencias,
  IUpdateResponsavelLegal
} from "../../models/GDContrato";
import { IPaginatedList } from "../../models/PaginatedList";
import http from "../common/http-common";

export const CreateGDContrato = (data: ICreateGDContratoCliente) => {
  return http.post("/GDContratoCliente", data);
};

//#region - Update

export const UpdateResponsavelLegal = (data: IUpdateResponsavelLegal) => {
  return http.put("/GDContratoCliente/PessoaJuridica/ResponsavelLegal", data);
};

export const UpdateEndereco = (data: IUpdateEndereco) => {
  return http.put("/GDContratoCliente/PessoaFisica/Endereco", data);
};

export const UpdateInformacoesTitularPF = (data: IUpdateInformacoesTitularPF) => {
  return http.put("/GDContratoCliente/PessoaFisica/InformacoesTitular", data);
};

export const UpdateInformacoesTitularPJ = (data: IUpdateInformacoesTitularPJ) => {
  return http.put("/GDContratoCliente/PessoaJuridica/InformacoesTitular", data);
};

export const UpdateInformacoesContrato = (data: IUpdateInformacoesContrato) => {
  return http.put("/GDContratoCliente/InformacoesContrato", data);
};

export const UpdateOutrasInformacoes = (data: IUpdateOutrasInformacoes) => {
  return http.put("/GDContratoCliente/OutrasInformacoes", data);
};

export const UpdatePreferencias = (data: IUpdatePreferencias) => {
  return http.put("/GDContratoCliente/Preferencias", data);
};
//#endregion

//#region - Querys

export const EnviaContratoParaClickSign = (id: string) => {
  return http.post(
    `/GDContratoCliente/EnviaContratoParaAssinaturaDigital/${id}`
  );
};

export const VerificaPodeEnviarContratoParaClickSign = (id: string) => {
  return http.get<boolean>(
    `/GDContratoCliente/VerifyCanCreateIndividualSignature/${id}`
  );
};

export const GetStats = () => {
  return http.get<IGDContratoStats[]>("/GDContratoCliente/Stats");
};

export const GetContratos = (
  pageNumber: number,
  pageSize: number,
  search?: string
) => {
  var searchString: string = "";

  if (search) {
    searchString = `&Search=${search}`;
  }

  return http.get<IPaginatedList<IGDContratoPaged>>(
    `/GDContratoCliente?pageNumber=${pageNumber}&pageSize=${pageSize}${searchString}`
  );
};

export const GetContratoById = (id: string) => {
  return http.get<IGDContrato>(`/GDContratoCliente/${id}`);
};

export const GetFullContratoById = (id: string) => {
  return http.get<IFullGDContratoCliente>(
    `/GDContratoCliente/GetFullGDContratoCliente/${id}`
  );
};
//#endregion
