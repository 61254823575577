export enum EnumTipoVaga {
    tempoIntegral = 0,
    estagio = 1,
    aprendiz = 2,
    PJ = 3,
}

export const enumTipoVagaMap = [
    {
        name:"Tempo integral",
        value:EnumTipoVaga.tempoIntegral
    },
    {
        name:"Estágio",
        value:EnumTipoVaga.estagio
    },
    {
        name:"PJ",
        value:EnumTipoVaga.PJ
    }
];
  