import { CircularProgress } from "@mui/material"

export const CircularSpinner = (
    { size, color }:
    {size?:string, color?:"pink" | "lightpink" | "purple" | null}
) => {
    let typeColor;
    const customColors = [
        {
            name:"pink",
            hex:"#D24293"
        },
        {
            name:"lightpink",
            hex:"#F36A7B"
        },
        {
            name:"purple",
            hex:"#540c74"
        }
    ]

    if(color)
        typeColor = customColors.find(x => x.name === color)?.hex

    return(
        <CircularProgress size={size} sx={{ color: color? typeColor: {} }} />
    )
}