export enum EnumGrauInstrucao {
    ensinoFundamentalCompleto = 0,
    ensinoFundamentalIncompleto = 1,
    ensinoMedioCompleto = 2,
    ensinoMedioIncompleto = 3,
    ensinoSuperiorCompleto = 4,
    ensinoSuperiorIncompleto = 5,
}

export const enumGrauInstrucaoMap = [
    {
        name: "Ensino Fundamental Completo",
        value: EnumGrauInstrucao.ensinoFundamentalCompleto
    },
    {
        name: "Ensino Fundamental Incompleto",
        value: EnumGrauInstrucao.ensinoFundamentalIncompleto
    },
    {
        name: "Ensino Médio Completo",
        value: EnumGrauInstrucao.ensinoMedioCompleto
    },
    {
        name: "Ensino Médio Incompleto",
        value: EnumGrauInstrucao.ensinoMedioIncompleto
    },
    {
        name: "Ensino Superior Completo",
        value: EnumGrauInstrucao.ensinoSuperiorCompleto
    },
    {
        name: "Ensino Superior Incompleto",
        value: EnumGrauInstrucao.ensinoSuperiorIncompleto
    }
];
  