import {IOperadora, IOperadoraDashboard, IOperadoraListItem} from "../../models/Operadora";
import http from "../common/http-common";
import {AxiosResponse} from "axios";
import {IPaginatedList} from "../../models/PaginatedList";

export const GetOperadoras = () => {
  return http.get<IOperadoraListItem[]>(`/Operadora/BuscaTodasOperadoras`);
};

export const GetOperadoraById: (id: string) => Promise<AxiosResponse<IOperadora>> = (id: string) => {
  return http.get<IOperadora>(`/Operadora/${id}`);
};

export const GetPaginatedOperadora: (pageNumber: number, pageSize: number, search?: string) => Promise<AxiosResponse<IPaginatedList<IOperadora>, any>> = (
  pageNumber: number,
  pageSize: number,
  search?: string
): Promise<AxiosResponse<IPaginatedList<IOperadora>, any>> => {
  var searchString: string = "";

  if (search) {
    searchString = `&Search=${search}`;
  }

  return http.get<IPaginatedList<IOperadora>>(
    `/Operadora?pageNumber=${pageNumber}&pageSize=${pageSize}${searchString}`
  );
};

export const GetDashboardOperadora: () => Promise<AxiosResponse<any, IOperadoraDashboard>> = (): Promise<AxiosResponse<any, IOperadoraDashboard>> => {
  return http.get(`/Operadora/OperadoraDashboard`);
};

export const GetOperadoraReport: () => Promise<AxiosResponse<File, any>> = () => {
  return http.get<File>(`/Operadora/BuscaRelatorioOperadora`, {
    responseType: "blob",
  });
};

export const setOperadora: (data: IOperadora) => Promise<AxiosResponse<any>> = (data: IOperadora) => {
  return http.post(`/Operadora`, data);
};

export const updateOperadora: (data: IOperadora) => Promise<AxiosResponse<any>> = (data: IOperadora) => {
  return http.put(`/Operadora`, data);
};