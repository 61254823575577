export enum InformacaoContratoEnum {
  TIPOPESSOA = "TIPOPESSOA",
  OPERADOR = "OPERADOR",
  TIPOTARIFA = "TIPOTARIFA",
  PORCENTAGEMDESCONTOTARIFA = "PORCENTAGEMDESCONTOTARIFA",
  TARIFAVIGENTECONTRATO = "TARIFAVIGENTECONTRATO",
  TARIFAGERADOR = "TARIFAGERADOR",
  POTENCIA = "POTENCIA",
  POTENCIAEXTENSO = "POTENCIAEXTENSO",
  MEDIDACORRENTE = "MEDIDACORRENTE",
  PERFORMACEALVO = "PERFORMACEALVO",
  CUSTOFIXO = "CUSTOFIXO",
  CUSTOFIXOEXTENSO = "CUSTOFIXOEXTENSO",
  VIGENCIACONTRATUAL = "VIGENCIACONTRATUAL",
  DATALIMITECONEXAO = "DATALIMITECONEXAO",
  TIPOCONEXAO = "TIPOCONEXAO",
  STATUSCONTRATOGERADOR = "STATUSCONTRATOGERADOR",
  DATAASSINATURACONTRATO = "DATAASSINATURACONTRATO",
  UUIDCONTRATO = "UUIDCONTRATO",
  AGENCIARESPONSAVELINDICACAO = "AGENCIARESPONSAVELINDICACAO",
  CONTARESPONSAVELINDICACAO = "CONTARESPONSAVELINDICACAO",
  FAVORECIDORESPONSAVELINDICACAO = "FAVORECIDORESPONSAVELINDICACAO",
  BANCORESPONSAVELINDICACAO = "BANCORESPONSAVELINDICACAO",
  CNPJFAVORECIDORESPONSAVELINDICACAO = "CNPJFAVORECIDORESPONSAVELINDICACAO",
  CHAVEPIXRESPONSAVELINDICACAO = "CHAVEPIXRESPONSAVELINDICACAO",
  TIPOCHAVEPIXRESPONSAVELINDICACAO = "TIPOCHAVEPIXRESPONSAVELINDICACAO",
  CUSTOFIXOPOREXTENSO = "CUSTOFIXOPOREXTENSO",
  POTENCIAPOREXTENSO = "POTENCIAPOREXTENSO"
}