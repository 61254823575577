import * as C from "./style";

const SkeletonLoadOrdemPagamento = () => {
    return (
        <>
            <C.StatusArea>
                <C.SkeletonStatusWrapper animation="wave" variant="rounded" />
                <C.SkeletonStatusWrapper animation="wave" variant="rounded" />
            </C.StatusArea>
            <C.SkeletonCard animation="wave" variant="rounded" />
        </>
    );
};

export default SkeletonLoadOrdemPagamento;
