import { TextField } from "@mui/material";
import { AxiosError } from "axios";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import MaskDefaultInput from "../../../../../../../../components/MasDefaultinput/MaskDefaultInput";
import RegisterCard from "../../../../../../../../components/RegisterCard";
import { useObjectState } from "../../../../../../../../hooks/useObjectState";
import {
  IEndereco,
  IUpdateEndereco,
} from "../../../../../../../../models/GDContrato";
import { ICepReturn } from "../../../../../../../../models/IbgeService";
import * as service from "../../../../../../../../services/api/GDContratoClienteService";
import * as D from "../../../../../../../../styles/appComponents";
import { convertEmptyStringsToNullAsync } from "../../../../../../../../utils/convertEmptyStringsToNull";

type PropsEnderecoContratar = {
  id: string;
  type: string;
  dataContrato?: IEndereco;
  reload: () => void;
};

const EnderecoContratar = (props: PropsEnderecoContratar) => {
  const { dataContrato, id, reload } = props;

  const [editar, setEditar] = useState<boolean>(false);

  const { state, setObject, updateObject } = useObjectState<IUpdateEndereco>({
    id: id,
    cep: "",
    uf: "",
    cidade: "",
    bairro: "",
    logradouro: "",
    numero: "",
    complemento: "",
  });

  useEffect((): void => {
    if (dataContrato) {
      setObject({
        id: id,
        cep: dataContrato.cep ?? "",
        bairro: dataContrato.bairro ?? "",
        cidade: dataContrato.cidade ?? "",
        complemento: dataContrato.complemento ?? "",
        logradouro: dataContrato.logradouro ?? "",
        numero: dataContrato.numero ?? "",
        uf: dataContrato.uf ?? "",
      });
    }
  }, [dataContrato]);

  const Update = async () => {
    var data = await convertEmptyStringsToNullAsync(state);

    await service
      .UpdateEndereco(data)
      .then(() => {
        setEditar(false);
        reload();
        Swal.fire({
          position: "top-end",
          toast: true,
          icon: "success",
          title: "Atualizado com sucesso!",
          showConfirmButton: false,
          showCloseButton: true,
          timer: 3000,
        });
      })
      .catch((e: AxiosError) => {
        var errorMessage: string = e.response
          ? String(e.response?.data)
          : "Houve um erro ao pegar os dados.";

        Swal.fire({
          position: "top-end",
          toast: true,
          icon: "warning",
          title: errorMessage,
          showConfirmButton: false,
          showCloseButton: true,
          timer: 3000,
        });
      });
  };

  const BuscaCep = async (cepReturn: ICepReturn) => {
    updateObject({
      cep: cepReturn.value ?? "",
      bairro: cepReturn.bairro ?? "",
      cidade: cepReturn.localidade ?? "",
      logradouro: cepReturn.logradouro ?? "",
      uf: cepReturn.uf ?? "",
    });
  };

  return (
    <RegisterCard title="Endereço">
      <D.FWStack direction={"row"} spacing={2}>
        <MaskDefaultInput
          label="CEP"
          type="CEP"
          value={state.cep}
          onChange={(e) => BuscaCep(e as ICepReturn)}
          readonly={!editar}
        />
        <TextField
          label="UF"
          required
          fullWidth
          value={state.uf}
          onChange={(e) => updateObject({ uf: e.target.value })}
          inputProps={{ readOnly: !editar }}
        />
        <TextField
          label="Cidade"
          required
          fullWidth
          value={state.cidade}
          onChange={(e) => updateObject({ cidade: e.target.value })}
          inputProps={{ readOnly: !editar }}
        />
        <TextField
          label="Bairro"
          required
          fullWidth
          value={state.bairro}
          onChange={(e) => updateObject({ bairro: e.target.value })}
          inputProps={{ readOnly: !editar }}
        />
      </D.FWStack>
      <D.FWStack direction={"row"} spacing={2}>
        <TextField
          label="Logradouro"
          required
          fullWidth
          value={state.logradouro}
          onChange={(e) => updateObject({ logradouro: e.target.value })}
          inputProps={{ readOnly: !editar }}
        />
        <TextField
          label="Número"
          required
          fullWidth
          value={state.numero}
          onChange={(e) => updateObject({ numero: e.target.value })}
          inputProps={{ readOnly: !editar }}
        />
        <TextField
          label="Complemento"
          fullWidth
          value={state.complemento}
          onChange={(e) => updateObject({ complemento: e.target.value })}
          inputProps={{ readOnly: !editar }}
        />
      </D.FWStack>
      <D.FWStack direction={"row"} spacing={2} justifyContent={"flex-end"}>
        <D.ContainedButton
          $color="yellow"
          disabled={editar}
          onClick={() => setEditar(true)}
        >
          Editar
        </D.ContainedButton>
        <D.ContainedButton disabled={!editar} onClick={Update}>
          Salvar
        </D.ContainedButton>
      </D.FWStack>
    </RegisterCard>
  );
};

export default EnderecoContratar;
