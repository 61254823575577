import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {Autocomplete, MenuItem, TextField,} from "@mui/material";
import {GridColDef, GridRenderCellParams, GridTreeNodeWithRender} from "@mui/x-data-grid";
import React, {Dispatch, SetStateAction, useEffect, useState} from "react";
import {NavigateFunction, useNavigate} from "react-router-dom";
import StatusCard from "../../../components/StatusCard";
import * as D from "../../../styles/appComponents";
import * as C from "../Colaborador/Gerador/style";
import TotalCardOrdem from "../../../components/TotalCardOrdem";
import Datatable from "../../../components/Datatable";
import SkeletonDefaultPage from "../../../components/SkeletonLoads/DefaultPage";
import {toastMessage} from "../../../utils/toastMessage";
import {
  GetDashboardOrdemPagamento,
  GetOrdemPagamentoReport,
  GetOrdensPagamento
} from "../../../services/api/OrdemPagamentoService";
import conta from "../../../assets/Plataforma/conta.svg";
import {AxiosResponse} from "axios";
import {IPaginatedList} from "../../../models/PaginatedList";
import {EnumStatusOrdemPagamento} from "../../../enums/EnumStatusOrdemPagamento";
import {ColorType} from "../../../types/ColorType";
import {moneyFormatter} from "../../../utils/moneyFormatter";
import {EnumMeses} from "../../../enums/EnumMeses";
import ExcelIcon from "../../../assets/Plataforma/excelIcon.svg";
import {GetTodasUsinas} from "../../../services/api/GeradorService";
import {IDashboardOrdemPagamento, IOrdemPagamento, IReportOrdemPagamento} from "../../../models/OrdemPagamento";
import {IBuscaTodasUsinasPorNome} from "../../../models/TarifaFioB";
import {ReportOrdemPagamento} from "../../../enums/EnumOrdemPagamento";


const OrdemPagamento = () => {
  //region Variáveis
  const [loading, setLoading]: (boolean | Dispatch<SetStateAction<boolean>>)[] = useState<boolean>(true);
  const [ordemDePagamento, setOrdemDePagamento]: (IOrdemPagamento[] | Dispatch<SetStateAction<IOrdemPagamento[]>>)[] = useState<IOrdemPagamento[]>([]);
  const [dashboardOrdemDePagamento, setDashboardOrdemDePagamento]: [IDashboardOrdemPagamento, (value: (((prevState: IDashboardOrdemPagamento) => IDashboardOrdemPagamento) | IDashboardOrdemPagamento)) => void] = useState<IDashboardOrdemPagamento>({
    totalStatusAguardandoPagamento: 0,
    totalStatusQuitada: 0,
    valorTotal: 0,
    energiaTotalInjetada: 0,
  });
  const [reportOrdemPagamento, setReportOrdemPagamento] = useState<IReportOrdemPagamento>({
    stausContrato: undefined,
    gerador: undefined,
    dataInicial: undefined,
    dataFinal: undefined,
  })
  const [isMobile, setIsMobile]: (boolean | Dispatch<SetStateAction<boolean>>)[] = useState(window.innerWidth < 1024);
  const [page, setPage]: (number | Dispatch<SetStateAction<number>>)[] = useState(1);
  const [usinas, setUsinas] = useState<IBuscaTodasUsinasPorNome[]>([]);
  const [pageSize, setPageSize] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [statusOpen] = useState(false);
  const navigate: NavigateFunction = useNavigate();
  const columns: GridColDef[] = [
    {
      field: "idReferencial",
      headerName: "ID",
      flex: 1,
      align: "center",
      renderCell: (params: GridRenderCellParams<any, any, any, GridTreeNodeWithRender>) => (
        <D.GridField $alignCenter={true}>
          {params.value}
        </D.GridField>
      )
    },
    {
      field: "referente",
      headerName: "Referente",
      flex: 1,
      minWidth: 100,
      align: "center",
      renderCell: (params: GridRenderCellParams<any, any, any, GridTreeNodeWithRender>) => {
        return <D.GridField $alignCenter={true}>
          {EnumMeses[params.row.mes as number] ?? "Mês"}/{params.row.ano == "0" ? "Ano" : params.row.ano}
        </D.GridField>
      }
    },
    {
      field: "instalacao",
      headerName: "Instalação",
      flex: 1,
      minWidth: 100,
      align: "center",
      renderCell: (params: GridRenderCellParams<any, any, any, GridTreeNodeWithRender>) => (
        <D.GridField $alignCenter={true}>
          {params.value}
        </D.GridField>
      )
    },
    {
      field: "sigla",
      headerName: "sigla",
      flex: 1,
      renderCell: (params: GridRenderCellParams<any, any, any, GridTreeNodeWithRender>) => (
        <D.GridField $alignCenter={true}>
          {params.value}
        </D.GridField>
      )
    },
    {
      field: "kwhInjetado",
      headerName: "Kwh injetado",
      flex: 1,
      align: "center",
      minWidth: 110,
      renderCell: (params: GridRenderCellParams<any, any, any, GridTreeNodeWithRender>) => (
        <D.GridField $alignCenter={true}>
          {params.value ?? "0 Kwh"}
        </D.GridField>
      )
    },
    {
      field: "percentualDescontoTarifa",
      headerName: "Desc tarifa",
      flex: 1,
      align: "center",
      minWidth: 110,
      renderCell(params: GridRenderCellParams<any, any, any, GridTreeNodeWithRender>) {
        return (
          <D.GridField $alignCenter={true}>
            {params.value ?? "00,00%"}
          </D.GridField>
        );
      },
    },
    {
      field: "valor",
      headerName: "Valor",
      flex: 1,
      renderCell: (params: GridRenderCellParams<any, any, any, GridTreeNodeWithRender>) => (
        <D.GridField $alignCenter={true}>
          {params.value ?? "R$ 0,00"}
        </D.GridField>
      )
    },
    {
      field: "saldo",
      headerName: "Saldo",
      flex: 1,
      renderCell(params: GridRenderCellParams<any, any, any, GridTreeNodeWithRender>) {
        return (
          <D.GridField $alignCenter={true}>
            {params.value ?? "0,00"}
          </D.GridField>
        );
      },
    },
    {
      field: "conta",
      headerName: "Conta",
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params: GridRenderCellParams<any, any, any, GridTreeNodeWithRender>) => (
        <D.GridField
          $alignCenter
          onClick={() => navigate(`/Cadastro/OrdemPagamento/conta/${params?.row?.id}`)}
        >
          <D.GridImage src={conta} alt="conta"/>
        </D.GridField>
      )
    },
    {
      align: "center",
      field: "status",
      headerAlign: "center",
      headerName: "Status",
      flex: 1,
      renderCell: (params: GridRenderCellParams<any, any, any, GridTreeNodeWithRender>) => {

        let color: string = "";

        switch (params.value) {
          case 0:
            color = "yellow";
            break;
          case 1:
            color = "green";
            break;
        }

        return <D.GridField $alignCenter={true}>
          <D.Circle color={color as ColorType}/>
        </D.GridField>;
      },
    },
    {
      field: "outras",
      headerName: "Outras",
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params: GridRenderCellParams<any, any, any, GridTreeNodeWithRender>) => (
        <D.GridField $alignCenter={true}>
          <D.DefaultGridButton
            onClick={() => navigate(`/Cadastro/OrdemPagamento/${params?.row?.id}`)}>
            Editar
          </D.DefaultGridButton>
        </D.GridField>
      )
    },
  ];
  //endregion

  //region Services
  const getOrdemPagamentos: (queryString?: string) => Promise<void> = async (queryString?: string): Promise<void> => {
    try {
      const {data}: AxiosResponse<IPaginatedList<IOrdemPagamento>, any> = await GetOrdensPagamento(page, pageSize, queryString);
      setTotalPages(data?.totalPages);
      setOrdemDePagamento(data?.data);
    } catch (e: unknown) {
      toastMessage("error", "Erro ao listar as ordens de pagamento");
    } finally {
      setLoading(false);
    }
  }

  const getDashboardOrdemPagamentos: () => Promise<void> = async (): Promise<void> => {
    try {
      const {data}: AxiosResponse<any, IDashboardOrdemPagamento> = await GetDashboardOrdemPagamento();
      setDashboardOrdemDePagamento(data);
    } catch (e: unknown) {
      toastMessage("error", "Erro ao listar o dashboard");
    }
  }

  const getUsinas: () => Promise<void> = async (): Promise<void> => {
    try {
      const response: AxiosResponse<any, IBuscaTodasUsinasPorNome> = await GetTodasUsinas();
      setUsinas(response.data);
    } catch (e: unknown) {
      toastMessage("error", "Erro ao listar as usinas");
    }
  }

  const geraRelatorio: (filters: IReportOrdemPagamento) => Promise<void> = async (filters: IReportOrdemPagamento) => {
    try {
      const response = await GetOrdemPagamentoReport(filters);

      const blob: File = response.data;

      const link: HTMLAnchorElement = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = `ordemPagamento_${new Date()
        .toISOString()
        .slice(0, 19)
        .replace(/[-:T]/g, "")}.xlsx`;
      link.click();

      URL.revokeObjectURL(link.href);
    } catch (e) {
      toastMessage("error", "Erro ao tentar criar o relatório.");
    }
  }
  //endregion

  //region UI

  const handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const name: string = event.target.name;
    const value: string = event.target.value;

    setReportOrdemPagamento((state: IReportOrdemPagamento) => {
      const updatedState = {
        ...state,
        [name]: value,
      };
      return updatedState;
    });
  }

  const handleChangePage: (value: number) => void = (value: number): void => {
    setPage(value);
  };

  const handleChangePageSize: (value: number) => void = (value: number): void => {
    setPageSize(value);
  };

  const handleSearch: (value: string) => Promise<void> = async (value: string): Promise<void> => {
    getOrdemPagamentos(value);
  };

  useEffect((): () => void => {
    const handleResize: () => void = (): void => {
      setIsMobile(window.innerWidth < 1024);
    };

    window.addEventListener("resize", handleResize);
    Promise.all([getUsinas(), getDashboardOrdemPagamentos(), getOrdemPagamentos()] as Promise<void>[]);
    return (): void => window.removeEventListener("resize", handleResize);
  }, []);
  //endregion

  return (
    <>
      {
        loading ? (<SkeletonDefaultPage/>) : (
          <>
            <C.Container>
              <C.Title>
                Status
                {isMobile && (
                  <>
                    {!statusOpen ? (
                      <KeyboardArrowDownRoundedIcon/>
                    ) : (
                      <KeyboardArrowUpIcon/>
                    )}
                  </>
                )}
              </C.Title>

              {(!isMobile || (statusOpen && isMobile)) && (
                <C.StatusArea>
                  <C.StatusWrapperOrdemPagamento>
                    <StatusCard
                      color="yellow"
                      text="Aguardando assinatura"
                      number={dashboardOrdemDePagamento.totalStatusAguardandoPagamento}
                    />
                    <TotalCardOrdem
                      colorText="green"
                      colorTitle="yellow"
                      color="yellow"
                      title="Valor total"
                      text={moneyFormatter.format(dashboardOrdemDePagamento.valorTotal)}
                      evolutionNumber="+8%"
                      evolution={true}
                    />
                  </C.StatusWrapperOrdemPagamento>
                  <C.StatusWrapperOrdemPagamento>
                    <StatusCard color="green" text="Quitada"
                                number={dashboardOrdemDePagamento.totalStatusQuitada}/>
                    <TotalCardOrdem
                      colorText="blue"
                      colorTitle="blue"
                      color="green"
                      title="Energia total injetada"
                      text={dashboardOrdemDePagamento.energiaTotalInjetada + " KWH"}
                      evolutionNumber="-8%"
                      evolution={false}
                    />
                  </C.StatusWrapperOrdemPagamento>
                </C.StatusArea>
              )}

              <D.DataArea $align="right">
                <Datatable
                  handleButton={(): void => navigate('/Cadastro/OrdemPagamento')}
                  hasButton={true}
                  titleButton="Inserir nova ordem"
                  columns={columns}
                  rows={ordemDePagamento}
                  pageNumber={page}
                  pageSize={pageSize}
                  onChangePage={handleChangePage}
                  onChangePageSize={handleChangePageSize}
                  totalPages={totalPages}
                  onSearch={handleSearch}
                />
                <C.LineBreak/>
                <C.ReportsArea>
                  <C.SelectArea>
                    <C.ReportsLabel>Status do contrato</C.ReportsLabel>
                    <C.ReportsSelect
                      onChange={(e) => handleInputChange({
                        target: {
                          name: ReportOrdemPagamento.STAUSCONTRATO,
                          value: e.target.value
                        }
                      } as unknown as React.ChangeEvent<HTMLInputElement>)}
                      displayEmpty
                      inputProps={{"aria-label": "Without label"}}
                      defaultValue={""}
                    >
                      <MenuItem value="">
                        Todos os status
                      </MenuItem>
                      {Object.values(EnumStatusOrdemPagamento).map((key: string, i: number) => (
                        <MenuItem key={key} value={i}>
                          {key}
                        </MenuItem>
                      ))}
                    </C.ReportsSelect>
                  </C.SelectArea>
                  <C.SelectArea>
                    <C.ReportsLabel>Selecione um gerador</C.ReportsLabel>
                    <Autocomplete
                      onChange={(e, newValue: IBuscaTodasUsinasPorNome | null) => handleInputChange({
                        target: {
                          name: ReportOrdemPagamento.GERADOR,
                          value: newValue?.id as string ?? "",
                        }
                      } as unknown as React.ChangeEvent<HTMLInputElement>)}
                      options={usinas}
                      fullWidth
                      noOptionsText="Nenhum captador encontrado"
                      getOptionLabel={(option) => option.nome}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Todos os geradores"
                          inputProps={{
                            ...params.inputProps,
                            "aria-label": "Without label",
                          }}
                        />
                      )}
                      defaultValue={null}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                    />
                  </C.SelectArea>
                  <C.SelectArea>
                    <C.ReportsLabel>Data da movimentação</C.ReportsLabel>
                    <D.DateTextfield
                      name={ReportOrdemPagamento.DATAINICIAL}
                      onChange={handleInputChange}
                      type="date"
                      label="Data início"
                      fullWidth
                    />
                  </C.SelectArea>
                  <C.SelectArea>
                    <C.ReportsLabel>Data da ultima movimentação</C.ReportsLabel>
                    <D.DateTextfield
                      name={ReportOrdemPagamento.DATAFINAL}
                      onChange={handleInputChange}
                      type="date"
                      label="Data fim"
                      fullWidth
                    />
                  </C.SelectArea>
                </C.ReportsArea>
                <C.ExportButton
                  onClick={() => geraRelatorio(reportOrdemPagamento)}
                  variant="contained"
                  startIcon={
                    <img
                      style={{width: "20px", color: "white", fill: "white"}}
                      src={ExcelIcon}
                      alt="excelIcon"
                    />
                  }
                >
                  Gerar relatório
                </C.ExportButton>
              </D.DataArea>
            </C.Container>
          </>
        )
      }
    </>
  );
};

export default OrdemPagamento;
