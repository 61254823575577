import React, { FC, useState } from "react";
import RegisterCard from "../../../../../../components/RegisterCard";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import AddIcon from "@mui/icons-material/Add";
import { Grid, TextField } from "@mui/material";
import * as C from "../style";
import { IUpdateResponsavelLegal } from "../../../../../../models/ResponsavelLegal";
import { CREATE_RESPONSAVEL_LEGAL_DEFAULT } from "../../../../../../utils/Constants";
import { ICaptador } from "../../../../../../models/Captador";
import MaskDefaultInput from "../../../../../../components/MasDefaultinput/MaskDefaultInput";
import { ICelularInputReturn } from "../../../../../../models/Celular";
import { ICepReturn } from "../../../../../../models/IbgeService";
import { ResponsavelComunicacao } from "./ResponsavelComunicacao";
import useArray from "../../../../../../hooks/useArray";
import ReadonlyArray from "../../../../../../types/ReadonlyArray";
import { IUpdateResponsavelComunicacao } from "../../../../../../models/ResponsavelComunicacao";
import { ContainedButton } from "../../../../../../styles/appComponents";
import DataInput from "../../../../../../components/DataInput/DataInput";
import SelectEstadoCivil from "../../../../../../components/Select/SelectEstadoCivil";

type PropsReponsavelLegal = {
  captador: Partial<ICaptador>;
  updateResponsavelLegal: (
    captadorId: string,
    responsaveisLegais: ReadonlyArray<IUpdateResponsavelLegal>,
    responsavelComunicacao: IUpdateResponsavelComunicacao
  ) => Promise<boolean>;
};

const obterResponavelLegalInicial = (
  captador: Partial<ICaptador>
): IUpdateResponsavelLegal[] =>
  captador?.responsaveisLegais?.length
    ? captador.responsaveisLegais.map((x) => ({
        id: x.id,
        nome: x.nome ?? "",
        enumEstadoCivil: x.enumEstadoCivil,
        nacionalidade: x.nacionalidade ?? "",
        profissao: x.profissao ?? "",
        email: x.email ?? "",
        celular: x.celular ?? "",
        countryCode: x.countryCode ?? "",
        cpf: x.cpf ?? "",
        cep: x.cep ?? "",
        uf: x.uf ?? "",
        cidade: x.cidade ?? "",
        bairro: x.bairro ?? "",
        numero: x.numero ?? "",
        complemento: x.complemento ?? "",
        logradouro: x.logradouro ?? "",
        dataNascimento: x.dataNascimento,
        ativo: x.ativo,
      }))
    : [{ ...CREATE_RESPONSAVEL_LEGAL_DEFAULT }];

const obterResponsavelComunicacaoInicial = (
  captador: Partial<ICaptador>
): IUpdateResponsavelComunicacao => ({
  id: captador?.id,
  nome: captador?.responsavelComunicacao?.nome ?? "",
  email: captador?.responsavelComunicacao?.email ?? "",
  celular: captador?.responsavelComunicacao?.celular ?? "",
  countryCode: captador?.responsavelComunicacao?.countryCode ?? "",
});

const ReponsavelLegal: FC<PropsReponsavelLegal> = ({
  captador,
  updateResponsavelLegal,
}) => {
  const [editar, setEditar] = useState<boolean>(false);
  const [salvandoDados, setSalvandoDados] = useState<boolean>(false);
  const [responsaveisLegais, metodosResponsaveisLegais] =
    useArray<IUpdateResponsavelLegal>(obterResponavelLegalInicial(captador));

  const [responsavelComunicacao, setResponsavelComunicacao] =
    useState<IUpdateResponsavelComunicacao>(
      obterResponsavelComunicacaoInicial(captador)
    );

  const editarResponsavisLegais = <K extends keyof IUpdateResponsavelLegal>(
    index: number,
    chave: K,
    valor: IUpdateResponsavelLegal[K]
  ) => {
    if (!editar) return;
    metodosResponsaveisLegais.updateChaveValor(index, chave, valor);
  };

  const editarCelularResponsaveisLegais = (
    index: number,
    valor: ICelularInputReturn
  ) => {
    if (!editar) return;
    metodosResponsaveisLegais.updateParcialValor(index, {
      celular: valor.value,
      countryCode: valor.zipCode,
    });
  };

  const editarCepResponsavelLegal = (index: number, valor: ICepReturn) => {
    if (!editar) return;
    metodosResponsaveisLegais.updateParcialValor(index, {
      ...valor,
      cidade: valor.localidade ?? responsaveisLegais[index].cidade,
    });
  };

  const handleEditeResponsaveisLegais = async () => {
    setSalvandoDados(true);
    if (captador.id) {
      await updateResponsavelLegal(
        captador.id,
        responsaveisLegais,
        responsavelComunicacao
      );
    }
    setEditar(false);
    setSalvandoDados(false);
  };

  return (
    <RegisterCard title="Informações do responsável legal">
      {responsaveisLegais
        .filter((responsavel) => responsavel.ativo)
        .map((responsavel, index) => (
          <Grid container spacing={2} key={index}>
            <Grid item xs={12} sm={4}>
              <TextField
                label="Nome do responsável legal"
                fullWidth
                value={responsavel.nome}
                onChange={(e) =>
                  editarResponsavisLegais(index, "nome", e.target.value)
                }
                inputProps={{ readOnly: !editar }}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                label="E-mail"
                fullWidth
                value={responsavel.email}
                onChange={(e) =>
                  editarResponsavisLegais(index, "email", e.target.value)
                }
                inputProps={{ readOnly: !editar }}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <MaskDefaultInput
                label="Celular"
                type="CELULAR"
                onChange={(e) =>
                  editarCelularResponsaveisLegais(
                    index,
                    e as ICelularInputReturn
                  )
                }
                value={responsavel.celular}
                readonly={!editar}
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <MaskDefaultInput
                label="CPF"
                type="CPF"
                value={responsavel.cpf}
                onChange={(e) =>
                  editarResponsavisLegais(index, "cpf", e as string)
                }
                readonly={!editar}
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <DataInput
                label="Data nascimento"
                value={responsavel.dataNascimento}
                onChange={(e) =>
                  editarResponsavisLegais(index, "dataNascimento", e)
                }
                maxDate={new Date()}
                readOnly={!editar}
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <SelectEstadoCivil
                value={responsavel.enumEstadoCivil}
                onChange={(e) =>
                  editarResponsavisLegais(index, "enumEstadoCivil", e)
                }
                readOnly={!editar}
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <MaskDefaultInput
                label="CEP"
                type="CEP"
                value={responsavel.cep}
                onChange={(e) =>
                  editarCepResponsavelLegal(index, e as ICepReturn)
                }
                readonly={!editar}
              />
            </Grid>
            <Grid item xs={12} sm={1}>
              <TextField
                label="UF"
                fullWidth
                value={responsavel.uf}
                onChange={(e) =>
                  editarResponsavisLegais(index, "uf", e.target.value)
                }
                inputProps={{ readOnly: !editar }}
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <TextField
                label="Cidade"
                fullWidth
                value={responsavel.cidade}
                onChange={(e) =>
                  editarResponsavisLegais(index, "cidade", e.target.value)
                }
                inputProps={{ readOnly: !editar }}
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <TextField
                label="Bairro"
                fullWidth
                value={responsavel.bairro}
                onChange={(e) =>
                  editarResponsavisLegais(index, "bairro", e.target.value)
                }
                inputProps={{ readOnly: !editar }}
              />
            </Grid>
            <Grid item xs={12} sm={1}>
              <TextField
                label="Número"
                fullWidth
                value={responsavel.numero}
                onChange={(e) =>
                  editarResponsavisLegais(index, "numero", e.target.value)
                }
                inputProps={{ readOnly: !editar }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                label="Logradouro"
                fullWidth
                value={responsavel.logradouro}
                onChange={(e) =>
                  editarResponsavisLegais(index, "logradouro", e.target.value)
                }
                inputProps={{ readOnly: !editar }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Complemento"
                fullWidth
                value={responsavel.complemento}
                onChange={(e) =>
                  editarResponsavisLegais(index, "complemento", e.target.value)
                }
                inputProps={{ readOnly: !editar }}
              />
            </Grid>
            {responsaveisLegais.length > 1 && (
              <Grid item xs={12} display="flex" justifyContent="flex-end">
                <ContainedButton
                  startIcon={<RemoveCircleOutlineIcon />}
                  onClick={() => metodosResponsaveisLegais.removeIndex(index)}
                  variant="contained"
                  $color="blue"
                  disabled={!editar}
                >
                  Remover responsável legal
                </ContainedButton>
              </Grid>
            )}
          </Grid>
        ))}
      <Grid spacing={2} alignSelf="self-end">
        <ContainedButton
          startIcon={<AddIcon />}
          onClick={() =>
            metodosResponsaveisLegais.push(CREATE_RESPONSAVEL_LEGAL_DEFAULT)
          }
          disabled={!editar}
          $color="lightpink"
        >
          Adicionar outro responsável legal
        </ContainedButton>
      </Grid>
      <ResponsavelComunicacao
        responsavel={responsavelComunicacao}
        setResponsavel={setResponsavelComunicacao}
        readOnly={!editar}
      />
      <C.FWStack direction={"row"} spacing={2} justifyContent={"flex-end"}>
        <ContainedButton
          $color="yellow"
          onClick={() => setEditar(true)}
          disabled={editar || salvandoDados}
        >
          Editar
        </ContainedButton>
        <ContainedButton
          $color="pink"
          onClick={handleEditeResponsaveisLegais}
          disabled={!editar || salvandoDados}
        >
          Salvar
        </ContainedButton>
      </C.FWStack>
    </RegisterCard>
  );
};

export default ReponsavelLegal;
