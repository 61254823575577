import RegisterCard from "../../../../../../../components/RegisterCard";
import React, { useEffect, useState } from "react";
import * as G from "../../style";
import { Checkbox, Chip, MenuItem, Stack, TextField } from "@mui/material";
import { ptBR } from "@mui/x-data-grid/locales";
import * as D from "../../../../../../../styles/appComponents";
import { GridColDef } from "@mui/x-data-grid";
import { IDocumentoForm } from "../Documentos";
import * as C from "../Situacao/style";
import { ErrorMessage } from "../../../../../../../utils/AbstractValidation";
import { ColaboradorByIdResponse } from "../../../../../../../models/Colaborador/Colaborador";
import { handleStyleFieldError } from "../../../../../../../utils/verifyFieldError";
import { IFeriasForm, IFeriasResponse } from "../../../../../../../models/Colaborador/cadastros/Ferias";
import AddIcon from "@mui/icons-material/Add";

export enum FeriasFormnum {
  PERIODOCOMPETENCIA = "PERIODOCOMPETENCIA",
  AQUISITIVOINICIO = "AQUISITIVOINICIO",
  AQUISITIVOFIM = "AQUISITIVOFIM",
  GOZOINICIO = "GOZOINICIO",
  GOZOFIM = "GOZOFIM",
  DIASGOZO = "DIASGOZO",
  SOLICITACAOABONO = "SOLICITACAOABONO",
  ABONOINICIO = "ABONOINICIO",
  ABONOFIM = "ABONOFIM",
  DIASABONO = "DIASABONO"
}

export interface PropsFerias {
  onSave: (data: IFeriasForm[]) => void;
  onUpdate: (data: IFeriasForm[]) => void;
  onChange: (data: IFeriasForm[]) => void;
  buttonSave: boolean;
  fieldErros: ErrorMessage[] | null;
  data?: object | null;
  colaboradorId: string | undefined;
  colaborador: ColaboradorByIdResponse | undefined;
  load: { status: boolean, section: string };
};

const Ferias: React.FC<PropsFerias> = (
  {
    onSave,
    onUpdate,
    onChange,
    buttonSave,
    fieldErros,
    colaboradorId,
    colaborador,
    load
  }
) => {
  const dadosFeriasFormModel: IFeriasForm = {
    periodocompetencia: null,
    aquisitivoinicio: null,
    aquisitivofim: null,
    gozoinicio: null,
    gozofim: null,
    diasgozo: null,
    solicitacaoabono: null,
    abonoinicio: null,
    abonofim: null,
    diasabono: null
  };
  const [dadosFerias, setDadosFerias] = useState<IFeriasForm[]>([dadosFeriasFormModel])
  const sendOnSave = () => onSave(dadosFerias);
  const sendOnUpdate = () => onUpdate(dadosFerias);
  const sendOnChange = (update: IFeriasForm[]) => onChange(update);

  const CalcDayDifferences = (startDate:string | null | undefined, endDate:string | null | undefined) => {

    if(endDate && startDate){
      const differenceInMs = new Date(endDate || "").getTime() - new Date(startDate || "").getTime();

      return differenceInMs / (1000 * 60 * 60 * 24);

    }else return 0;

  }

  const listDiasGozo = [0,10,15,20,30]

  useEffect(() => {
    const ferias:IFeriasResponse[] = (colaborador?.feriasColaborador || []);

    const dadosFeriasFormModel: IFeriasForm = {
      periodocompetencia: null,
      aquisitivoinicio: null,
      aquisitivofim: null,
      gozoinicio: null,
      gozofim: null,
      diasgozo: null,
      solicitacaoabono: null,
      abonoinicio: null,
      abonofim: null,
      diasabono: null
    };

    const newDadosFeriasForm: IFeriasForm[] = ferias?.map((x) => {
      return {
        id: x?.id,
        periodocompetencia: x?.periodoCompetencia,
        aquisitivoinicio: x?.aquisitivoInicio,
        aquisitivofim: x?.aquisitivoFim,
        gozoinicio: x?.gozoInicio,
        gozofim: x?.gozoFim,
        diasgozo: x.diasGozo,
        solicitacaoabono: x?.solicitacaoAbono,
        abonoinicio: x?.abonoInicio,
        abonofim: x?.abonoFim,
        diasabono: x?.diasAbono
      }
    });

    setDadosFerias(ferias.length === 0? [dadosFeriasFormModel]: newDadosFeriasForm)

  }, [
    colaboradorId,
    colaborador
  ])

  const handleInputChange = (index: number) => ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    const name = target?.name.toLocaleLowerCase();
    const value = target?.value;

    const newForm = [...dadosFerias]
    newForm[index] = { ...newForm[index], [name]: value }

    setDadosFerias(newForm);
    sendOnChange(newForm);
  }

  const addNewForm = () => {
    setDadosFerias((x) => [...x, dadosFeriasFormModel])
  }

  return (
    <RegisterCard title="Férias">
      {
        (dadosFerias || []).map((x: IFeriasForm, index: number) => (
          <>
            <G.FWStack direction={"row"} spacing={2}>
              <TextField
                inputProps={{ readOnly: buttonSave }}
                sx={handleStyleFieldError(FeriasFormnum.PERIODOCOMPETENCIA, fieldErros)}
                name={FeriasFormnum.PERIODOCOMPETENCIA}
                value={
                  ((x?.periodocompetencia ?? "")
                    .toString())?.split("T")[0]
                }
                type="date"
                label="Período de Competência"
                fullWidth
                onChange={handleInputChange(index)}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                inputProps={{ readOnly: buttonSave }}
                sx={handleStyleFieldError(FeriasFormnum.AQUISITIVOINICIO, fieldErros)}
                name={FeriasFormnum.AQUISITIVOINICIO}
                value={
                  ((x?.aquisitivoinicio ?? "")
                    .toString())?.split("T")[0]
                }
                type="date"
                label="Aquisitivo Início"
                fullWidth
                onChange={handleInputChange(index)}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                inputProps={{ readOnly: buttonSave }}
                sx={handleStyleFieldError(FeriasFormnum.AQUISITIVOFIM, fieldErros)}
                name={FeriasFormnum.AQUISITIVOFIM}
                value={
                  ((x?.aquisitivofim ?? "")
                    .toString())?.split("T")[0]
                }
                type="date"
                label="Aquisitivo Fim"
                fullWidth
                onChange={handleInputChange(index)}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                inputProps={{ readOnly: buttonSave }}
                sx={handleStyleFieldError(FeriasFormnum.GOZOINICIO, fieldErros)}
                name={FeriasFormnum.GOZOINICIO}
                value={
                  ((x?.gozoinicio ?? "")
                    .toString())?.split("T")[0]
                }
                type="date"
                label="Gozo Início"
                fullWidth
                onChange={handleInputChange(index)}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                inputProps={{ readOnly: buttonSave }}
                sx={handleStyleFieldError(FeriasFormnum.GOZOFIM, fieldErros)}
                name={FeriasFormnum.GOZOFIM}
                value={
                  ((x?.gozofim ?? "")
                    .toString())?.split("T")[0]
                }
                type="date"
                label="Gozo Fim"
                fullWidth
                onChange={handleInputChange(index)}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                inputProps={{ readOnly: buttonSave }}
                name={FeriasFormnum.DIASGOZO}
                value={x?.diasgozo}
                label="Dias de Gozo"
                select
                fullWidth
                onChange={handleInputChange(index)}
                InputLabelProps={{
                  shrink: true,
                }}
              >
                {
                  listDiasGozo.map((dia, index) => (
                    <MenuItem key={index} value={dia}>
                    {dia}
                    </MenuItem>
                  ))
                }
              </TextField>
            </G.FWStack>
            <G.FWStack direction={"row"} spacing={2}>
              <TextField
                inputProps={{ readOnly: buttonSave }}
                sx={handleStyleFieldError(FeriasFormnum.SOLICITACAOABONO, fieldErros)}
                name={FeriasFormnum.SOLICITACAOABONO}
                value={
                  ((x?.solicitacaoabono ?? "")
                    .toString())?.split("T")[0]
                }
                type="date"
                label="Solicitação de Abono"
                fullWidth
                onChange={handleInputChange(index)}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                inputProps={{ readOnly: buttonSave }}
                sx={handleStyleFieldError(FeriasFormnum.ABONOINICIO, fieldErros)}
                name={FeriasFormnum.ABONOINICIO}
                value={
                  ((x?.abonoinicio ?? "")
                    .toString())?.split("T")[0]
                }
                type="date"
                label="Abono Início"
                fullWidth
                onChange={handleInputChange(index)}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                inputProps={{ readOnly: buttonSave }}
                sx={handleStyleFieldError(FeriasFormnum.ABONOFIM, fieldErros)}
                name={FeriasFormnum.ABONOFIM}
                value={
                  ((x?.abonofim ?? "")
                    .toString())?.split("T")[0]
                }
                type="date"
                label="Abono Fim"
                fullWidth
                onChange={handleInputChange(index)}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                sx={handleStyleFieldError(FeriasFormnum.DIASABONO, fieldErros)}
                name={FeriasFormnum.DIASABONO}
                inputProps={{ readOnly: buttonSave }}
                value={x?.diasabono}
                label="Dias de Abono"
                fullWidth
                onChange={handleInputChange(index)}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </G.FWStack>
          </>
        ))
      }
      <G.FWStack direction={"row"} spacing={2} justifyContent={"flex-end"}>
          <D.ContainedButton
            startIcon={<AddIcon />}
            onClick={addNewForm}
            $color="pink"
          >
            Nova Lista
          </D.ContainedButton>
        </G.FWStack>

      <G.FWStack direction={"row"} spacing={2} justifyContent={"flex-end"}>
        <D.ContainedButton $color="lightpink">Gerara recibo</D.ContainedButton>
        <D.ContainedButton onClick={sendOnUpdate} $color="yellow">Editar</D.ContainedButton>
        <D.ContainedButton onClick={sendOnSave} >Salvar</D.ContainedButton>
      </G.FWStack>
    </RegisterCard>
  );
};

export default Ferias;
