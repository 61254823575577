import { CircularProgress, TextField } from "@mui/material";
import { AxiosError } from "axios";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import MaskDefaultInput from "../../../../../../../components/MasDefaultinput/MaskDefaultInput";
import RegisterCard from "../../../../../../../components/RegisterCard";
import { useObjectState } from "../../../../../../../hooks/useObjectState";
import { ICelularInputReturn } from "../../../../../../../models/Celular";
import { IFormInformacoesContato } from "../../../../../../../models/Contratar";
import { IDadosContato } from "../../../../../../../models/GDContrato";
import {
  ICreateUsuario,
  IUpdateUsuario,
} from "../../../../../../../models/Usuario";
import * as service from "../../../../../../../services/api/UsuarioService";
import * as D from "../../../../../../../styles/appComponents";

type PropsDadosContato = {
  type: string;
  id: string;
  dataContrato?: IDadosContato;
  reload: () => void;
};

const DadosContato = (props: PropsDadosContato) => {
  const { id, dataContrato, reload, type } = props;

  const [insideLoading, setInsideLoading] = useState<boolean>(false);
  const [editar, setEditar] = useState<boolean>(false);

  const { state, setObject, updateObject } =
    useObjectState<IFormInformacoesContato>({
      usuarioId: "",
      nome: "",
      countryCode: "",
      celular: "",
      email: "",
      login: "",
      wppCelular: "",
      wppCountryCode: "",
    });

  useEffect((): void => {
    if (dataContrato) {
      setObject({
        usuarioId: dataContrato.usuarioId ?? "",
        nome: dataContrato.nome ?? "",
        countryCode: dataContrato.countryCode ?? "",
        celular: dataContrato.celular ?? "",
        email: dataContrato.email ?? "",
        login: dataContrato.login ?? "",
        wppCelular: dataContrato.whatsapp ? dataContrato.whatsapp.slice(2) : "",
        wppCountryCode: "+55",
      });
    }
  }, [dataContrato]);

  const createClienteUser = async () => {
    setInsideLoading(true);
    if (state.celular == "" || state.email == "") {
      setInsideLoading(false);
      Swal.fire({
        position: "top-end",
        toast: true,
        icon: "warning",
        title: "Celular/Email não podem ser nulos.",
        showConfirmButton: false,
        showCloseButton: true,
        timer: 3000,
      });
      return;
    }

    var data: ICreateUsuario = {
      contratoClienteId: id!,
      countryCode: state.countryCode,
      celular: state.celular,
      email: state.email,
    };

    await service
      .CreateCliente(data)
      .then(async () => {
        setInsideLoading(false);
        reload();
        Swal.fire({
          position: "top-end",
          toast: true,
          icon: "success",
          title: "Criado com sucesso!",
          showConfirmButton: false,
          showCloseButton: true,
          timer: 3000,
        });
      })
      .catch((e: AxiosError) => {
        setInsideLoading(false);
        var errorMessage: string = e.response
          ? String(e.response?.data)
          : "Houve um erro ao gerar o contrato.";

        Swal.fire({
          position: "top-end",
          toast: true,
          icon: "warning",
          title: errorMessage,
          showConfirmButton: false,
          showCloseButton: true,
          timer: 3000,
        });
      });
  };

  const handleChangeCelular = async (e: ICelularInputReturn) => {
    updateObject({ celular: e.value });
    updateObject({ countryCode: e.zipCode });
  };

  const handleChangeWhatsapp = async (e: ICelularInputReturn) => {
    updateObject({ wppCelular: e.value });
    updateObject({ wppCountryCode: e.zipCode });
  };

  const Update = async () => {
    setInsideLoading(true);
    if (state.celular == "" || state.email == "") {
      setInsideLoading(false);
      Swal.fire({
        position: "top-end",
        toast: true,
        icon: "warning",
        title: "Celular/Email não podem ser vazios.",
        showConfirmButton: false,
        showCloseButton: true,
        timer: 3000,
      });
      return;
    }

    var data: IUpdateUsuario = {
      contratoClienteId: id,
      countryCode: state.countryCode,
      celular: state.celular,
      email: state.email,
      wppCelular: state.wppCelular,
      wppCountryCode: state.wppCountryCode,
    };

    await service
      .UpdateCliente(data)
      .then(async () => {
        setInsideLoading(false);
        setEditar(false);
        reload();
        Swal.fire({
          position: "top-end",
          toast: true,
          icon: "success",
          title: "Atualizado com sucesso!",
          showConfirmButton: false,
          showCloseButton: true,
          timer: 3000,
        });
      })
      .catch((e: AxiosError) => {
        setInsideLoading(false);
        var errorMessage: string = e.response
          ? String(e.response?.data)
          : "Houve um erro ao gerar o contrato.";

        Swal.fire({
          position: "top-end",
          toast: true,
          icon: "warning",
          title: errorMessage,
          showConfirmButton: false,
          showCloseButton: true,
          timer: 3000,
        });
      });
  };

  const ResetSenha = async () => {
    await service
      .ResetSenha(state.usuarioId)
      .then(async () => {
        reload();
        Swal.fire({
          position: "center",
          toast: true,
          icon: "success",
          title: "Sucesso!",
          text: "Foi enviado para o whatsapp do usuário a sua nova senha temporária.",
          showConfirmButton: false,
          showCloseButton: true,
          timer: 3000,
        });
      })
      .catch((e: AxiosError) => {
        var errorMessage: string = e.response
          ? String(e.response?.data)
          : "Houve um erro ao gerar o contrato.";

        Swal.fire({
          position: "top-end",
          toast: true,
          icon: "warning",
          title: errorMessage,
          showConfirmButton: false,
          showCloseButton: true,
          timer: 3000,
        });
      });
  };

  return (
    <RegisterCard title="Dados de contato">
      <D.FWStack direction={"row"} spacing={2}>
        {state.login && (
          <TextField
            label="Nome"
            required
            fullWidth
            value={state.nome}
            inputProps={{ readOnly: true }}
          />
        )}
        <MaskDefaultInput
          label="Celular"
          value={state.celular}
          readonly={state.login != "" && !editar}
          type="CELULAR"
          onChange={(e) => handleChangeCelular(e as ICelularInputReturn)}
        />
        <TextField
          label="E-mail"
          required
          fullWidth
          type="email"
          value={state.email}
          inputProps={{ readOnly: state.login != "" && !editar }}
          onChange={(e) => updateObject({ email: e.target.value })}
        />
      </D.FWStack>
      {state.login ? (
        <>
          <D.FWStack direction={"row"} spacing={2}>
            <MaskDefaultInput
              label="Whatsapp"
              value={state.wppCelular}
              readonly={state.login != "" && !editar}
              type="CELULAR"
              onChange={(e) => handleChangeWhatsapp(e as ICelularInputReturn)}
            />
            <TextField
              label="Login"
              required
              fullWidth
              value={state.login}
              inputProps={{ readOnly: true }}
            />
            <D.FWStack></D.FWStack>
          </D.FWStack>
          <D.FWStack direction={"row"} spacing={2} justifyContent={"flex-end"}>
            <D.ContainedButton
              $color="lightpink"
              variant="contained"
              disabled={!editar}
              onClick={ResetSenha}
            >
              Reset senha
            </D.ContainedButton>
            <D.ContainedButton
              $color="yellow"
              disabled={editar}
              onClick={() => setEditar(true)}
            >
              Editar
            </D.ContainedButton>
            <D.ContainedButton disabled={!editar} onClick={Update}>
              {insideLoading ? <CircularProgress size={25} /> : <>Salvar</>}
            </D.ContainedButton>
          </D.FWStack>
        </>
      ) : (
        <D.FWStack direction={"row"} spacing={2} justifyContent={"flex-end"}>
          <D.ContainedButton
            $color="pink"
            variant="contained"
            onClick={createClienteUser}
          >
            {insideLoading ? (
              <CircularProgress size={25} />
            ) : (
              <>Criar usuário</>
            )}
          </D.ContainedButton>
        </D.FWStack>
      )}
    </RegisterCard>
  );
};

export default DadosContato;
