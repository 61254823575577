import { CircularProgress } from "@mui/material";
import { AxiosError } from "axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { SkeletonCreationCards } from "../../../../../components/SkeletonLoads/CreationCards";
import { IFullGDContratoCliente } from "../../../../../models/GDContrato";
import * as service from "../../../../../services/api/GDContratoClienteService";
import * as D from "../../../../../styles/appComponents";
import * as C from "./style";
import InformacoesContratoPJContratar from "./components/cnpj/InformacoesContratoPJ";
import InformacoesResponsavelLegal from "./components/cnpj/informacoesResponsavelLegal";
import EnderecoContratar from "./components/cpf/Endereco";
import InformacoesContratoPFContratar from "./components/cpf/InformacoesContratoPF";
import DadosContato from "./components/DadosContato";
import InformacoesTitularContratar from "./components/InformacoesTitular";
import OutrasInformacoesContratar from "./components/OutrasInformacoes";
import PreferenciaContratar from "./components/Preferencia";

const EditarContrato = () => {
  const { idContrato } = useParams();

  const [clickSignLoading, setClickSignLoading] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);

  const [data, setData] = useState<IFullGDContratoCliente>({
    isPF: false,
    id: "",
    idReferencial: 0,
    createdAt: new Date(),
    updatedAt: new Date(),
    assinaturaDigitalId: "",
    dadosContato: undefined,
    endereco: undefined,
    informacoesContrato: undefined,
    informacoesTitularPF: undefined,
    informacoesTitularPJ: undefined,
    outrasInformacoes: undefined,
    preferencias: undefined,
    responsavelLegal: undefined,
  });

  const [tipo, setTipo] = useState<"Fisica" | "Juridica" | undefined>(
    undefined
  );

  const reload = () => {
    getFullContrato(idContrato!);
  };

  const getFullContrato = async (id: string): Promise<void> => {
    try {
      const [response] = await Promise.all([service.GetFullContratoById(id)]);
      setTipo(response.data.isPF ? "Fisica" : "Juridica");
      setData(response.data);
      setLoading(false);
    } catch (e: unknown) {
      Swal.fire({
        position: "top-end",
        toast: true,
        icon: "warning",
        title: "Ocorreu um erro ao buscar o contrato",
        showConfirmButton: false,
        showCloseButton: true,
        timer: 3000,
      });
    }
  };

  useEffect((): void => {
    getFullContrato(idContrato!);
  }, []);

  const EnviaContratoPraAssinaturaDigital = async () => {
    setClickSignLoading(true);

    await service
      .VerificaPodeEnviarContratoParaClickSign(idContrato!)
      .then(async () => {
        EnviaContratoPraClickSign();
      })
      .catch((e: AxiosError) => {
        setClickSignLoading(false);
        var errorMessage: string = e.response
          ? String(e.response?.data)
          : "Houve um erro ao enviar o contrato para a ClickSign.";

        Swal.fire({
          position: "top-end",
          toast: true,
          icon: "warning",
          title: errorMessage,
          showConfirmButton: false,
          showCloseButton: true,
          timer: 3000,
        });
      });
  };

  const EnviaContratoPraClickSign = async () => {
    await service
      .EnviaContratoParaClickSign(idContrato!)
      .then(async () => {
        setClickSignLoading(false);
        Swal.fire({
          position: "top-end",
          toast: true,
          icon: "success",
          title: "Enviado com sucesso!",
          showConfirmButton: false,
          showCloseButton: true,
          timer: 3000,
        });
      })
      .catch((e: AxiosError) => {
        setClickSignLoading(false);
        var errorMessage: string = e.response
          ? String(e.response?.data)
          : "Houve um erro ao enviar o contrato para a ClickSign.";

        Swal.fire({
          position: "top-end",
          toast: true,
          icon: "warning",
          title: errorMessage,
          showConfirmButton: false,
          showCloseButton: true,
          timer: 3000,
        });
      });
  };

  return (
    <>
      {loading ? (
        <SkeletonCreationCards />
      ) : (
        <C.Container>
          <InformacoesTitularContratar
            id={idContrato!}
            type={tipo as string}
            dataContrato={data}
            reload={reload}
          />
          {tipo === "Fisica" ? (
            <>
              <EnderecoContratar
                id={idContrato!}
                type={tipo as string}
                dataContrato={data.endereco}
                reload={reload}
              />
              <InformacoesContratoPFContratar
                id={idContrato!}
                type={tipo as string}
                dataContrato={data.informacoesContrato}
                reload={reload}
              />
            </>
          ) : (
            <>
              <InformacoesContratoPJContratar
                id={idContrato!}
                type={tipo as string}
                dataContrato={data.informacoesContrato}
                reload={reload}
              />
              <InformacoesResponsavelLegal
                id={idContrato!}
                type={tipo as string}
                dataContrato={data.responsavelLegal}
                reload={reload}
              />
            </>
          )}
          <OutrasInformacoesContratar
            id={idContrato!}
            type={tipo as string}
            dataContrato={data.outrasInformacoes}
            reload={reload}
          />
          <PreferenciaContratar
            id={idContrato!}
            type={tipo as string}
            dataContrato={data.preferencias}
            reload={reload}
          />
          <DadosContato
            type={tipo as string}
            reload={reload}
            id={idContrato!}
            dataContrato={data.dadosContato}
          />
          <D.FWStack justifyContent={"flex-end"} direction={"row"} spacing={2}>
            <D.ContainedButton
              $color="lightpink"
              onClick={EnviaContratoPraAssinaturaDigital}
            >
              {clickSignLoading ? (
                <CircularProgress size={25} />
              ) : (
                <>Gerar contrato</>
              )}
            </D.ContainedButton>
          </D.FWStack>
        </C.Container>
      )}
    </>
  );
};

export default EditarContrato;
