import React, {Dispatch, SetStateAction, useEffect, useState} from 'react';
import {ITarifaDistribuidora, ITarifaDistribuidoraDashboard} from "../../../../models/TarifaDistribuidora";
import {GridColDef, GridRenderCellParams} from "@mui/x-data-grid";
import * as D from "../../../../styles/appComponents";
import {AxiosResponse} from "axios";
import {IPaginatedList} from "../../../../models/PaginatedList";
import {
  GetDashboardTarifaDistribuidora,
  GetPaginatedTarifaDistribuidora,
  GetTarifaDistribuidoraReport
} from "../../../../services/api/TarifaDistribuidoraService";
import {toastMessage} from "../../../../utils/toastMessage";
import SkeletonDefaultPage from "../../../../components/SkeletonLoads/DefaultPage";
import * as C from "../Gerador/style";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import StatusCard from "../../../../components/StatusCard";
import Datatable from "../../../../components/Datatable";
import ExcelIcon from "../../../../assets/Plataforma/excelIcon.svg";
import {ColorType} from "../../../../types/ColorType";
import {formatValueFloatInString} from "../../../../utils/moneyFormatter";
import {EnumTipoTarifa} from "../../../../enums/Gerador/EnumTipoTarifa.enum";

const TarifaDistribuidora: () => React.JSX.Element = (): React.JSX.Element => {
  //region Variáveis
  const [loading, setLoading]: (boolean | Dispatch<SetStateAction<boolean>>)[] = useState<boolean>(true);
  const [tarifaDistribuidora, setTarifaDistribuidora] = useState<ITarifaDistribuidora[]>([]);
  const [isMobile, setIsMobile]: (boolean | Dispatch<SetStateAction<boolean>>)[] = useState(window.innerWidth < 1024);
  const [page, setPage]: (number | Dispatch<SetStateAction<number>>)[] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [statusOpen] = useState(false);
  const [dashboardTarifaDistribuidora, setdashboardTarifaDistribuidora] = useState<ITarifaDistribuidoraDashboard>({
    ativos: 0,
    inativos: 0,
  });
  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "ID",
      maxWidth: 50,
      align: "center",
      renderCell: (params: GridRenderCellParams<any, any, any>) => (
        <D.GridField $alignCenter={true}>
          {params.value}
        </D.GridField>
      )
    },
    {
      field: "nome",
      headerName: "Nome",
      align: "center",
    },
    {
      field: "tipo",
      headerName: "Tipo",
      align: "center",
      renderCell: (params: GridRenderCellParams<any, any, any>) => (
        <D.GridField $alignCenter={true}>
          {EnumTipoTarifa[params.value as keyof typeof EnumTipoTarifa]}
        </D.GridField>
      )
    },
    {
      field: "tarifa",
      headerName: "Tarifa",
      align: "center",
    },
    {
      field: "desconto",
      headerName: "Desconto gerador",
      align: "center",
    },
    {
      field: "tarifa",
      headerName: "Tarifa gerador",
      align: "center",
    },
    {
      field: "desconto",
      headerName: "Desconto cliente",
      align: "center",
    },
    {
      field: "tarifa",
      headerName: "Tarifa cliente",
      align: "center",
    },
    {
      field: "periodoInicial",
      headerName: "Periodo Inicial",
      align: "center",
      renderCell: (params: GridRenderCellParams<any, any, any>) => (
        <D.GridField $alignCenter={true}>
          {new Date(String(params.value)).toLocaleDateString() === "Invalid Date" ? "" : new Date(String(params.value)).toLocaleDateString()}
        </D.GridField>
      )
    },
    {
      field: "periodoFinal",
      headerName: "Periodo Final",
      align: "center",
      headerAlign: "center",
      renderCell: (params: GridRenderCellParams<any, any, any>) => (
        <D.GridField $alignCenter={true}>
          {new Date(String(params.value)).toLocaleDateString() === "Invalid Date" ? "" : new Date(String(params.value)).toLocaleDateString()}
        </D.GridField>
      )
    },
    {
      field: "reajuste",
      headerName: "Reajuste",
      align: "center",
      headerAlign: "center",
      renderCell: (params: GridRenderCellParams<any, any, any>) => (
        <D.GridField $alignCenter={true}>
          {formatValueFloatInString(params.value)}
        </D.GridField>
      )
    },
    {
      field: "status",
      headerName: "Status",
      align: "center",
      headerAlign: "center",
      renderCell: (params: GridRenderCellParams<any, any, any>) => {
        const type: ColorType = params.value == 1 ? "green" : "red";
        return (
          <D.GridField $alignCenter={true}>
            <D.Circle color={type}/>
          </D.GridField>
        )
      }
    }
  ];
  //endregion

  //region Services
  const getTarifaDistribuidoras: (queryString?: string) => Promise<void> = async (queryString?: string): Promise<void> => {
    try {
      const {data}: AxiosResponse<IPaginatedList<ITarifaDistribuidora>, any> = await GetPaginatedTarifaDistribuidora(page, pageSize, queryString);
      setTotalPages(data?.totalPages);
      setTarifaDistribuidora(data?.data);
    } catch (e: unknown) {
      toastMessage("error", "Erro ao listar as tarifas");
    } finally {
      setLoading(false);
    }
  }

  const getdashboardTarifaDistribuidora: () => Promise<void> = async (): Promise<void> => {
    try {
      const {data}: AxiosResponse<any, ITarifaDistribuidoraDashboard> = await GetDashboardTarifaDistribuidora();
      setdashboardTarifaDistribuidora({...data});
    } catch (e: unknown) {
      toastMessage("error", "Erro ao listar o dashboard");
    }
  }

  const geraRelatorio: () => Promise<void> = async () => {
    try {
      const response: AxiosResponse<File, any> = await GetTarifaDistribuidoraReport();

      const blob: File = response.data;

      const link: HTMLAnchorElement = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = `tarifaDistribuidora_${new Date()
        .toISOString()
        .slice(0, 19)
        .replace(/[-:T]/g, "")}.xlsx`;
      link.click();

      URL.revokeObjectURL(link.href);
    } catch (e) {
      toastMessage("error", "Erro ao tentar criar o relatório.");
    }
  }
  //endregion

  //region UI
  const handleChangePage: (value: number) => void = (value: number): void => {
    setPage(value);
  };

  const handleChangePageSize: (value: number) => void = (value: number): void => {
    setPageSize(value);
  };

  const handleSearch: (value: string) => Promise<void> = async (value: string): Promise<void> => {
    getTarifaDistribuidoras(value);
  };

  useEffect((): () => void => {
    const handleResize: () => void = (): void => {
      setIsMobile(window.innerWidth < 1024);
    };

    window.addEventListener("resize", handleResize);
    Promise.all([getTarifaDistribuidoras(), getdashboardTarifaDistribuidora()] as Promise<void>[]);
    return (): void => window.removeEventListener("resize", handleResize);
  }, []);
  //endregion

  return (
    <>
      {
        loading ? (<SkeletonDefaultPage/>) : (
          <>
            <C.Container>
              <C.Title>
                Status
                {isMobile && (
                  <>
                    {!statusOpen ? (
                      <KeyboardArrowDownRoundedIcon/>
                    ) : (
                      <KeyboardArrowUpIcon/>
                    )}
                  </>
                )}
              </C.Title>

              {(!isMobile || (statusOpen && isMobile)) && (
                <C.StatusArea>
                  <C.StatusWrapperOrdemPagamento>
                    <StatusCard
                      color="green"
                      text="Ativos"
                      number={dashboardTarifaDistribuidora.ativos ?? "0"}
                    />
                  </C.StatusWrapperOrdemPagamento>
                  <C.StatusWrapperOrdemPagamento>
                    <StatusCard color="red" text="Inativa"
                                number={dashboardTarifaDistribuidora.inativos ?? "0"}/>
                  </C.StatusWrapperOrdemPagamento>
                </C.StatusArea>
              )}

              <D.DataArea $align="right">
                <Datatable
                  hasButton={false}
                  titleButton="Inserir tarifaDistribuidora"
                  columns={columns}
                  rows={tarifaDistribuidora}
                  pageNumber={page}
                  pageSize={pageSize}
                  onChangePage={handleChangePage}
                  onChangePageSize={handleChangePageSize}
                  totalPages={totalPages}
                  onSearch={handleSearch}
                />
                <C.LineBreak/>
                <C.ExportButton
                  onClick={geraRelatorio}
                  variant="contained"
                  startIcon={
                    <img
                      style={{width: "20px", color: "white", fill: "white"}}
                      src={ExcelIcon}
                      alt="excelIcon"
                    />
                  }
                >
                  Gerar relatório
                </C.ExportButton>
              </D.DataArea>
            </C.Container>
          </>
        )
      }
    </>
  );
};

export default TarifaDistribuidora;