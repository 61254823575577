import { IAnexoCreate, IAnexoDTO } from "../../models/Anexo";
import { objetoParaFormData } from "../../utils/Utils";
import http from "../common/http-common";
import {AxiosResponse} from "axios";

export const GetUrl: (id: string) => Promise<AxiosResponse<string, any>> = (id: string) => {
  return http.get<string>(`/Anexo/${id}`);
};

export const Disable = (id: string) => {
  return http.patch(`/Anexo/Disable/${id}`);
};

export const CreateAnexo = (data: IAnexoCreate) => {
  const formData = objetoParaFormData(data);

  return http.post<IAnexoDTO>(`/Anexo`, formData);
};

export const GetAllAnexos = (id:string) => {
  return http.get<IAnexoDTO[]>(`/Anexo/GetAllByItem/${id}`);
};
