import { IDadosUsinaPessoaFisica, IDadosUsinaPessoaJuridica } from "../../../../../models/Gerador/Cadastro/DadosUsina";
import { AbstractValidation, Validate } from "../../../../../utils/AbstractValidation";

export class DadosUsinaPessoaFisicaValidate extends AbstractValidation<IDadosUsinaPessoaFisica>{
    getValidate(): Validate<IDadosUsinaPessoaFisica>[] {
        return[
            {
                field:"nomeusina",
                validate: (value, model:IDadosUsinaPessoaFisica, addError:(message:string) => void) => {
                    if(!value) addError("O Nome deve ser informada")
                }
            },
            {
                field:"nomeusina",
                validate: (value, model:IDadosUsinaPessoaFisica, addError:(message:string) => void) => {
                    if(value?.length > 150) addError("O Nome Não pode ultrapssar 150 caracteres")
                }
            },
            {
                field:"nomeusina",
                validate: (value, model:IDadosUsinaPessoaFisica, addError:(message:string) => void) => {
                    if(value && value?.split(" ")?.length === 1) addError("O Nome deve conter dois nomes no mínimo")
                }
            },
            {
                field:"cpf",
                validate: (value, model:IDadosUsinaPessoaFisica, addError:(message:string) => void) => {
                    if(!value) addError("O Cnpj deve ser informado")
                }
            },
            {
                field:"cpf",
                validate: (value, model:IDadosUsinaPessoaFisica, addError:(message:string) => void) => {
                    if(value?.length !== 11) addError("O CPF deve possuir 11 dígitos")
                }
            },
            {
                field:"cep",
                validate: (value, model:IDadosUsinaPessoaFisica, addError:(message:string) => void) => {
                    if(!value) addError("O Cep deve ser informado")
                }
            },
            {
                field:"cep",
                validate: (value, model:IDadosUsinaPessoaFisica, addError:(message:string) => void) => {
                    if(value?.length !== 8) addError("O Cep deve ser conter 8 dígitos")
                }
            },
            {
                field:"instalacao",
                validate: (value, model:IDadosUsinaPessoaFisica, addError:(message:string) => void) => {
                    if(!value) addError("A Instalação deve ser informada")
                }
            }
        ];
    }
}