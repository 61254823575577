import * as G from "./style";
import * as D from "../../styles/appComponents"
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { TextField } from "@mui/material";
import React, { Dispatch, SetStateAction, useRef, useState } from "react";
import { ptBR } from "@mui/x-data-grid/locales";
import { ICreateLog, IFullLog, ILog } from "../../models/Log";
import { toastMessage } from "../../utils/toastMessage";
import { CreateLog, DeleteLog } from "../../services/api/LogService";
import Swal from "sweetalert2";
import { GridColDef } from "@mui/x-data-grid";
import DownloadIcon from "@mui/icons-material/Download";
import DeleteIcon from "@mui/icons-material/Delete";
import { IAnexoCreate, IAnexoDTO } from "../../models/Anexo";
import { CreateAnexo, Disable, GetUrl } from "../../services/api/AnexoService";
import { AxiosResponse } from "axios";
import { EnumTipoAnexo } from "../../enums/EnumTipoAnexo.enum";

export interface ArquivoSectionProps{
    anexos:IAnexoDTO[];
    callbackFunc?: (data:IAnexoCreate, response:AxiosResponse<IAnexoDTO, any>) => void;
    captadorId?: string | null;
    cooperativaId?: string | null;
    usuarioId?: string | null;
    usuarioAnexouId?: string | null;
    colaboradorId?: string | null;
    dataUpdated: Dispatch<SetStateAction<boolean>>;
    tipoAnexo: EnumTipoAnexo;
};

export const ArquivoSection:React.FC<ArquivoSectionProps> = (
  {
    anexos, 
    callbackFunc=null,
    captadorId=null,
    cooperativaId=null,
    usuarioId=null,
    usuarioAnexouId=null,
    colaboradorId=null,
    dataUpdated,
    tipoAnexo
  }
) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const dataBodyFormModel:IAnexoCreate = {
      captadorId:null,
      cooperativaId:null,
      usuarioId:null,
      usuarioAnexouId:null,
      colaboradorId: null,
      titulo: null,
      enumTipoAnexo: null,
      descricao: null,
      file: null
    };
    const [dataBody, setDataBody] = useState<IAnexoCreate>(dataBodyFormModel);

    const createAnexo = async (): Promise<void> => {
        dataUpdated(false);

        if (!dataBody?.titulo) {
          toastMessage("warning","Assunto não pode ser vazio.");
          return;

        }else if(!dataBody.file){
          toastMessage("warning","Primeiro deve selecionar um arquivo.")
          return;
        }

        const anexoData:IAnexoCreate = {
          captadorId:captadorId || null,
          cooperativaId:cooperativaId || null,
          usuarioId:usuarioId || null,
          usuarioAnexouId:usuarioAnexouId || null,
          colaboradorId:colaboradorId || null,
          titulo: dataBody?.titulo || null,
          enumTipoAnexo: tipoAnexo || null,
          descricao: dataBody?.descricao || null,
          file: dataBody?.file || null
        }

        CreateAnexo(anexoData).then((x) => {
          if(callbackFunc) 
              callbackFunc(anexoData, x);

          dataUpdated(true);
          setDataBody(dataBodyFormModel);
          toastMessage("success","Anexo adicionado com sucesso!")
        })
        .catch(() => {
          dataUpdated(true);
          toastMessage("error","Erro ao criar um anexo")
        })
    };

    const downloadConta = async (id: string | null) => {
      if(id)
        try {
          const {data} = await GetUrl(id);
          const fileUrl = data;
  
          const link = document.createElement("a");
          link.href = fileUrl;
          link.target = "_blank";
          link.rel = "noopener noreferrer";
  
          document.body.appendChild(link);
          link.click();
  
          document.body.removeChild(link);
          
        } catch (error) {
          toastMessage("warning","Erro ao baixar o anexo")
        }
    };
    
    const deleteAnexo = async (id: string) => {
      dataUpdated(false);
  
      try {
        await Disable(id);
        dataUpdated(true);
        setDataBody(dataBodyFormModel);
        toastMessage("success","Deletado com sucesso.");
        
      } catch (error) {
        dataUpdated(true);
        toastMessage("error","Houve um erro ao deletar o arquivo.");
      }
  
    };

    const handleInputChange = ({target}:React.ChangeEvent<HTMLInputElement>) => {
      const name = target?.name;
      const value = target?.value;
  
      setDataBody((state:IAnexoCreate) => {
        return { ...state, [name]: value }
      })
    }

    const dataColumns: GridColDef[] = [
        {
          field: "responsavel",
          headerName: "Responsável",
          flex: 1,
        },
        {field: "desc", headerName: "Decrição", flex: 1},
        {
          field: "data",
          headerName: "Data Upload",
          flex: 1,
        },
        {
          field: "anexo",
          headerName: "Anexo",
          align: "center",
          headerAlign: "center",
          renderCell: (params) => (
            <D.GridField>
              <D.OutlineButton
                sx={{fontSize: "10px"}}
                startIcon={<DownloadIcon/>}
                $color="blue"
                onClick={() => downloadConta(params?.row?.id)}
              >
                Baixar
              </D.OutlineButton>
            </D.GridField>
          ),
        },
        {
          field: "remover",
          headerName: "Remover",
          align: "center",
          headerAlign: "center",
          renderCell: (params) => (
            <D.GridField>
              <D.OutlineButton
                sx={{fontSize: "10px"}}
                startIcon={<DeleteIcon/>}
                $color="red"
                onClick={() => deleteAnexo(params?.row?.id)}
              >
                Deletar
              </D.OutlineButton>
            </D.GridField>
          ),
        },
    ];
    
    const dataRows = (anexos || []).map((anexo:IAnexoDTO) => {
      return {
          id: anexo?.id,
          responsavel: anexo?.nomeResponsavel,
          desc: anexo?.titulo,
          data: anexo?.createdAt
      }
    });

    return(
        <G.FWStack direction={"row"} spacing={8}>
          <G.MWDiv>
            <G.ArchiveArea>
              <G.Title>
                <FileUploadIcon/>
                <h2>Upload de arquivos</h2>
              </G.Title>
              <G.FWStack>
                <TextField
                  sx={{backgroundColor: "white"}}
                  name="titulo"
                  label="Descrição do documento"
                  multiline
                  rows={4}
                  onChange={handleInputChange}
                />
              </G.FWStack>
              <G.FWStack direction={"row"} justifyContent={"space-between"}>
                <D.ContainedButton 
                  $color="blue" 
                  startIcon={<FileUploadIcon/>}
                  onClick={() => inputRef.current?.click()}
                >
                  Anexar documento
                </D.ContainedButton>
                <D.VisuallyHiddenInput
                      ref={inputRef}
                      type="file"
                      onChange={
                        (e:any) => handleInputChange(
                          { 
                            target:{ name:"file", value:e.target.files![0] } 
                          } as React.ChangeEvent<HTMLInputElement>
                        )
                      }
                    />
                <D.ContainedButton 
                  onClick={createAnexo}
                >
                  Salvar Documento
                </D.ContainedButton>
              </G.FWStack>
            </G.ArchiveArea>
          </G.MWDiv>
          <G.MWDiv>
            <G.LightBWArea>
              <D.DefaultTable
                disableColumnResize
                disableRowSelectionOnClick
                localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
                rows={dataRows}
                columns={dataColumns}
                hideFooter
                disableColumnFilter
                disableColumnSelector
                disableColumnMenu
              />
            </G.LightBWArea>
          </G.MWDiv>
        </G.FWStack>
    )
}