import { AxiosError } from "axios";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { EnumTipoConexaoValueMap } from "../../../../enums/EnumTipoConexao.enum";
import {
  IConexaoContaProposta,
  IContaCooperativaProposta,
  IContaDistribuidoraProposta,
  ICreateGDProposta,
  IDadosPessoaisProposta,
  IGetCalculoResponse,
  IResumoFaturaProposta,
} from "../../../../models/GDProposta";
import * as propostaService from "../../../../services/api/PropostaService";
import * as D from "../../../../styles/appComponents";
import * as C from "../style";
import ConexaoContaGerarProposta from "./ConexaoConta";
import ContaCooperativaGerarProposta from "./ContaCooperativa";
import ContaDistribuidoraGerarProposta from "./ContaDistribuidora";
import DadosPessoaisGerarProposta from "./DadosPessoais";
import ResumoFaturaGerarProposta from "./ResumoFatura";
import StatusPropostaGerarProposta from "./StatusProposta";
import { CircularProgress } from "@mui/material";
import {jwtDecode} from "jwt-decode";

const GerarProposta = () => {
  //region Variáveis
  const { idLead } = useParams();
  const navigate = useNavigate();
  const [insideLoading, setInsideLoading] = useState<boolean>(false);
  const [dadosPessoaisData, setDadosPessoaisData] =
    useState<IDadosPessoaisProposta>({
      nome: null,
      cpf: null,
      celular: null,
      email: null,
      cooperativaId: null,
    });
  const [conexaoContaData, setConexaoContaData] =
    useState<IConexaoContaProposta>({
      classe: "",
      conexao: "",
      consumoMensalkWh: "",
      iluminacaoPublica: "",
      numeroInstalacao: "",
      operadoraId: "",
      tarifaDistribuidora: "",
      valorConta: "",
      valorDesconto: "",
      tributosPisCofins: "3.69",
    });
  const [contaCooperativaData, setContaCooperativaData] =
    useState<IContaCooperativaProposta>({
      energiaInjetadakWh: "",
      energiaInjetadaReais: "",
      valorCooperativa: "",
    });
  const [contaDistribuidoraData, setContaDistribuidoraData] =
    useState<IContaDistribuidoraProposta>({
      impostoTotalDistribuidora: "",
      taxaDisponibilidadekWh: "",
      taxaDisponibilidadeReais: "",
      totalContaDistribuidora: "",
    });
  const [resumoFaturaData, setResumoFaturaData] =
    useState<IResumoFaturaProposta>({
      contasEconomizadasAnualmente: "",
      economiaAnual: "",
      economiaMensalBruta: "",
      economiaMensalLiquida: "",
      tarifaCooperativa: "",
      valorTotal: "",
    });
  const [createProposta, setCreateProposta] = useState<ICreateGDProposta>();
  //endregion

  //region Services
  const createPropostaAsync = async () => {
    const data: ICreateGDProposta = {
      ...dadosPessoaisData,
      ...conexaoContaData,
      ...contaCooperativaData,
      ...contaDistribuidoraData,
      ...resumoFaturaData,
      leadId: idLead ?? null,
      status: 1,
    };

    var adjustedData = await convertEmptyStringsToNullAsync(data);

    setCreateProposta(adjustedData);
  };

  const geraProposta = async () => {
    await setInsideLoading(true);

    createProposta
      ? await propostaService
        .CreateGDProposta(createProposta)
        .then(async () => {
          const jwt = jwtDecode(localStorage.getItem("token")!) as { captadorId?: string };

          if(jwt.captadorId)
            navigate("/Parceiro/Propostas");
          else
            navigate("/Colaborador/Propostas");

          setInsideLoading(false);
          Swal.fire({
            position: "top-end",
            toast: true,
            icon: "success",
            title: "Criado com sucesso!",
            showConfirmButton: false,
            showCloseButton: true,
            timer: 3000,
          });
        })
        .catch((e: AxiosError) => {
          setInsideLoading(false);
          var errorMessage: string = e.response
            ? String(e.response?.data)
            : "Houve um erro ao gerar a proposta.";

          Swal.fire({
            position: "top-end",
            toast: true,
            icon: "warning",
            title: errorMessage,
            showConfirmButton: false,
            showCloseButton: true,
            timer: 3000,
          });
        })
      : Swal.fire({
        position: "top-end",
        toast: true,
        icon: "warning",
        title: "Create proposta não pode ser nulo.",
        showConfirmButton: false,
        showCloseButton: true,
        timer: 3000,
      });
  };
  //endregion

  //region UI
  const getCalculoResponse = (data: IGetCalculoResponse) => {
    setContaCooperativaData({
      energiaInjetadakWh: data.kwhInjetado.toString(),
      energiaInjetadaReais: data.energiaInjetada.toString(),
      valorCooperativa: data.valorContaCooperativa.toString(),
    });
    setContaDistribuidoraData({
      impostoTotalDistribuidora: data.valorImpostosDistribuidora.toString(),
      taxaDisponibilidadekWh:
        EnumTipoConexaoValueMap[
          Number(
            conexaoContaData.conexao
          ) as keyof typeof EnumTipoConexaoValueMap
          ],
      taxaDisponibilidadeReais: data.valorTaxaDisponibilidade.toString(),
      totalContaDistribuidora: data.valorContaDistribuidora.toString(),
    });
    setResumoFaturaData({
      contasEconomizadasAnualmente: data.contasEconomizadas.toString(),
      economiaAnual: data.economiaAnual.toString(),
      economiaMensalBruta: data.economiaMensalBruta.toString(),
      economiaMensalLiquida: data.economiaMensalLiquida.toString(),
      tarifaCooperativa: data.tarifaUsina.toString(),
      valorTotal: data.valorTotalFatura.toString(),
    });
  };

  const receiveDataDadosPessoais = (data: IDadosPessoaisProposta) => {
    setDadosPessoaisData(data);
  };

  const receiveDataConexaoConta = (data: IConexaoContaProposta) => {
    setConexaoContaData(data);
  };

  useEffect(() => {
    createPropostaAsync();
  }, [
    dadosPessoaisData,
    conexaoContaData,
    contaCooperativaData,
    contaDistribuidoraData,
  ]);

  const convertEmptyStringsToNullAsync = async (
    obj: ICreateGDProposta
  ): Promise<ICreateGDProposta> => {
    const updatedObj = { ...obj };

    await Promise.all(
      (Object.keys(updatedObj) as (keyof ICreateGDProposta)[]).map(
        async (key) => {
          const value = updatedObj[key];
          if (typeof value === "string" && value === "") {
            updatedObj[key] = null;
          }
        }
      )
    );

    return updatedObj;
  };
  //endregion

  return (
    <C.Container>
      <DadosPessoaisGerarProposta
        leadId={idLead!}
        onSendData={receiveDataDadosPessoais}
      />
      <ConexaoContaGerarProposta
        onSendData={receiveDataConexaoConta}
        sendCalculoProposta={getCalculoResponse}
      />
      <ContaCooperativaGerarProposta data={contaCooperativaData} />
      <ContaDistribuidoraGerarProposta
        data={contaDistribuidoraData}
        iluPub={conexaoContaData.iluminacaoPublica!.toString()}
      />
      <ResumoFaturaGerarProposta data={resumoFaturaData} />
      <StatusPropostaGerarProposta
        economiaMensalLiquida={resumoFaturaData.economiaMensalLiquida}
      />
      {/* <EnviarPropostaGerarProposta /> */}
      <D.FWStack justifyContent={"flex-end"} direction={"row"} spacing={2}>
        {/* <D.ContainedButton $color="yellow">Limpar campos</D.ContainedButton> */}
        <D.ContainedButton $color="lightpink" onClick={geraProposta}>
          {insideLoading ? (
            <CircularProgress size={22} />
          ) : (
            <>Gravar proposta</>
          )}
        </D.ContainedButton>
      </D.FWStack>
    </C.Container>
  );
};

export default GerarProposta;
