import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {Autocomplete, MenuItem, TextField} from "@mui/material";
import {GridCellParams, GridColDef} from "@mui/x-data-grid";
import {AxiosError} from "axios";
import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import ContractIcon from "../../../assets/Plataforma/contractIcon.svg";
import ExcelIcon from "../../../assets/Plataforma/excelIcon.svg";
import PourposeIcon from "../../../assets/Plataforma/pourposeIcon.svg";
import Datatable from "../../../components/Datatable";
import SkeletonDefaultPage from "../../../components/SkeletonLoads/DefaultPage";
import StatusCard from "../../../components/StatusCard";
import TotalCard from "../../../components/TotalCard";
import {
  EnumStatusGDProposta,
  EnumStatusGDPropostaNumericMap,
  StatusPropostaColorMap,
} from "../../../enums/EnumStatusGDProposta.enum";
import {ICaptadorListItem} from "../../../models/Captador";
import {IBackofficeListItem} from "../../../models/Colaborador/Colaborador";
import {
  IGDProposta,
  IGDPropostaPaged,
  IGDPropostaRelatoriosFilters,
  IStatusDashboardProposta,
} from "../../../models/GDProposta";
import * as captadorServices from "../../../services/api/CaptadorService";
import * as colaboradorServices from "../../../services/api/ColaboradorService";
import * as services from "../../../services/api/PropostaService";
import * as D from "../../../styles/appComponents";
import {moneyFormatter} from "../../../utils/moneyFormatter";
import ModalContratar from "./modais/contratar";
import * as C from "./style";
import PropostasModal from "./modais/PropostasModal";
import {toastMessage} from "../../../utils/toastMessage";
import {jwtDecode} from "jwt-decode";

const Parceiro: React.FC = () => {
  //region Variáveis
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(true);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [search, setSearch] = useState("");
  const [totalPages, setTotalPages] = useState(0);
  const [statusOpen, setStatusOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1024);
  const [openModalContratar, setOpenModalContratar] = useState<boolean>(false);
  const [uuidModalContratar, setUuidModalContratar] = useState<undefined | string>(undefined);
  const [openModalSeeMoreId, setOpenModalSeeMoreId] = useState<string | undefined>(undefined);
  const [selectedBackoffice, setSelectedBackoffice] = useState<string | undefined>();
  const [selectedCaptador, setSelectedCaptador] = useState<string | undefined>();
  const [selectedStatus, setSelectedStatus] = useState<string | undefined>();
  const [openProposta, setOpenProposta] = useState<boolean>(false);
  const [dataInicio, setDataInicio] = useState<string | undefined>();
  const [dataFim, setDataFim] = useState<string | undefined>();
  const [backoffices, setBackoffices] = useState<IBackofficeListItem[]>([]);
  const [captadores, setCaptadores] = useState<ICaptadorListItem[]>([]);
  const [statsDashboard, setStatsDashboard] = useState<IStatusDashboardProposta[]>([
    {
      status: EnumStatusGDProposta.naoTeveInteresse,
      quantidade: 0,
      totalkWh: 0,
      totalReais: 0,
    },
    {
      status: EnumStatusGDProposta.emAnalise,
      quantidade: 0,
      totalkWh: 0,
      totalReais: 0,
    },
    {
      status: EnumStatusGDProposta.contratoGerado,
      quantidade: 0,
      totalkWh: 0,
      totalReais: 0,
    },
    {
      status: EnumStatusGDProposta.reprovada,
      quantidade: 0,
      totalkWh: 0,
      totalReais: 0,
    },
    {
      status: EnumStatusGDProposta.cancelada,
      quantidade: 0,
      totalkWh: 0,
      totalReais: 0,
    },
    {
      status: EnumStatusGDProposta.aprovada,
      quantidade: 0,
      totalkWh: 0,
      totalReais: 0,
    },
  ]);
  const [rows, setRows] = useState<IGDPropostaPaged[]>([]);
  const columns: GridColDef[] = [
    {
      field: "idReferencial",
      headerName: "Id",
      renderCell: (params: GridCellParams<IGDProposta>) => {
        const jwt = jwtDecode(localStorage.getItem("token")!) as { captadorId?: string };

        return (
          <D.GridField
            onClick={() => jwt.captadorId ? navigate(`/Parceiro/Editar/Proposta/${params.row.id}`) : navigate(`/Colaborador/Editar/Proposta/${params.row.id}`)}
          >
            <D.LinkClick
              style={{
                whiteSpace: "normal",
                fontSize: 14,
              }}
            >
              {params.row.idReferencial}
            </D.LinkClick>
          </D.GridField>
        )
      },
    },
    {
      field: "cliente",
      headerName: "Cliente",
      flex: 1,
      align: "center",
      renderCell: (params: any) => <D.GridField>{params.value}</D.GridField>,
    },
    {
      field: "instalacao",
      headerName: "Instalação",
      flex: 1,
      renderCell: (params: any) => <D.GridField>{params.value}</D.GridField>,
    },
    {
      field: "parceiro",
      headerName: "Parceiro",
      flex: 1,
      align: "center",
      renderCell: (params: any) => <D.GridField>{params.value}</D.GridField>,
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params: any) => (
        <D.GridField $alignCenter>
          <D.Circle color={StatusPropostaColorMap[params.value]} />
        </D.GridField>
      ),
    },
    {
      field: "proposta",
      headerName: "Proposta",
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params: any) => {
        return (
          <D.GridField $alignCenter>
            <D.GridImage
              $disable={!params.row.temProposta}
              onClick={(): void =>
                handleOpenViewProposta(params.row.id, !params.row.temProposta)
              }
              src={PourposeIcon}
              alt="pourposeIcon"
            />
          </D.GridField>
        );
      },
    },
    {
      field: "contratar",
      headerName: "Contratar",
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params: GridCellParams<IGDPropostaPaged>) => (
        <D.GridField $alignCenter>
          <D.GridImage
            $disable={
              params.row.status !=
              EnumStatusGDPropostaNumericMap[EnumStatusGDProposta.aprovada]
            }
            src={ContractIcon}
            alt="contractIcon"
            onClick={(): void => {
              if (
                params.row.status ==
                EnumStatusGDPropostaNumericMap[EnumStatusGDProposta.aprovada]
              ) {
                setUuidModalContratar(params.row.id);
                setOpenModalContratar(true);
              }
            }}
          />
        </D.GridField>
      ),
    },
    {
      field: "outras",
      headerName: "Outras",
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params: any) => (
        <D.GridField $alignCenter>
          <D.DefaultGridButton
            onClick={() => handleOpenModalSeeMore(params.row.id)}
          >
            Ver mais
          </D.DefaultGridButton>
        </D.GridField>
      ),
    },
  ];
  //endregion

  //region Services
  const getBackoffices: () => void = () => {
    colaboradorServices
      .GetBackoffices()
      .then((response: any) => {
        setBackoffices(response.data);
      })
      .catch((e: AxiosError) => {
        toastMessage("error", e.response
          ? String(e.response?.data)
          : "Houve um erro ao pegar os backoffices.");
      });
  };

  const getCaptadores: () => void = () => {
    captadorServices
      .GetCaptadores()
      .then((response: any) => {
        setCaptadores(response.data);
      })
      .catch((e: AxiosError) => {
        toastMessage("error", e.response
          ? String(e.response?.data)
          : "Houve um erro ao pegar os captadores.");
      });
  };

  const geraRelatorio: () => void = () => {
    var filters: IGDPropostaRelatoriosFilters = {
      status: selectedStatus,
      backofficeId: selectedBackoffice,
      captadorId: selectedCaptador,
      dataInicio: dataInicio,
      dataFim: dataFim,
    };
    services
      .GeraRelatorio(filters)
      .then(async (response: any) => {
        const blob = response.data;
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = `propostas_${new Date()
          .toISOString()
          .slice(0, 19)
          .replace(/[-:T]/g, "")}.xlsx`;
        link.click();
        URL.revokeObjectURL(link.href);
      })
      .catch((e: AxiosError) => {
        toastMessage("error", e.response
          ? String(e.response?.data)
          : "Houve um erro ao pegar o relatório.");
      });
  };

  const getStats: () => void = () => {
    services
      .GetStats()
      .then((response: {
          data: React.SetStateAction<IStatusDashboardProposta[]>;
        }) => {
          setStatsDashboard(response.data);
        })
      .catch((e: AxiosError) => {
        toastMessage("error", e.response
          ? String(e.response?.data)
          : "Houve um erro ao pegar os status.");
      });
  };

  const getPropostas: () => Promise<void> = async () => {
    await getStats();

    services
      .GetPropostas(page, pageSize, search)
      .then((response: any) => {
        setRows(response.data.data);
        setTotalPages(response.data.totalPages);
        setLoading(false);
      })
      .catch((e: AxiosError) => {
        toastMessage("error", e.response
          ? String(e.response?.data)
          : "Houve um erro ao pegar as propostas.");
      });
  };
  //endregion

  //region UI

  const callbackPropostasModal = () => {
    setOpenProposta(false);
  }

  const handleSearch: (value: string) => Promise<void> = async (value: string) => {
    await setPage(1);
    await setSearch(value);
  };

  const handleChangePage: (value: number) => void = (value: number) => {
    setPage(value);
  };

  const handleChangePageSize: (value: number) => void = (value: number) => {
    setPageSize(value);
  };

  const handleCloseModalContratar: () => void = () => {
    setOpenModalContratar(false);
  };

  const handleStatusOpen: () => void = () => {
    setStatusOpen(!statusOpen);
  };

  const handleOpenModalSeeMore: (id: string) => Promise<void> = async (id: string) => {
    setOpenModalSeeMoreId(id);
    setOpenProposta(true);
  };

  const handleOpenViewProposta: (id: string, disabled: boolean) => void = (id: string, disabled: boolean): void => {
    if (disabled) return;

    const jwt = jwtDecode(localStorage.getItem("token")!) as { captadorId?: string };

    if(jwt.captadorId)
      window.open("/Parceiro/Documento/Proposta/" + id, "_blank");
    else
      window.open("/Colaborador/Documento/Proposta/" + id, "_blank");
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1024);
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    getBackoffices();
    getCaptadores();
  }, []);

  useEffect(() => {
    getPropostas();
  }, [page, pageSize, search]);
  //endregion

  return (
    <>
      {loading ? (
        <SkeletonDefaultPage />
      ) : (
        <C.Container>
          <C.Title onClick={() => handleStatusOpen()}>
            Status
            {isMobile && (
              <>
                {!statusOpen ? (
                  <KeyboardArrowDownRoundedIcon />
                ) : (
                  <KeyboardArrowUpIcon />
                )}
              </>
            )}
          </C.Title>

          {(!isMobile || (statusOpen && isMobile)) && (
            <C.StatusArea>
              <C.StatusWrapper>
                <StatusCard
                  color="yellow"
                  text="Não teve interesse"
                  number={statsDashboard[0].quantidade}
                />
                <TotalCard
                  color="yellow"
                  title="Valor total das propostas"
                  text={`${moneyFormatter.format(
                    statsDashboard[0].totalReais
                  )}`}
                  evolutionNumber="-8%"
                  evolution={false}
                />
                <TotalCard
                  color="yellow"
                  title="Valor total de kwh"
                  text={`${statsDashboard[0].totalkWh} kwh`}
                  evolutionNumber="+8%"
                  evolution={true}
                />
              </C.StatusWrapper>
              <C.StatusWrapper>
                <StatusCard
                  color="blue"
                  text="Em análise"
                  number={statsDashboard[1].quantidade}
                />
                <TotalCard
                  color="blue"
                  title="Valor total das propostas"
                  text={`${moneyFormatter.format(
                    statsDashboard[1].totalReais
                  )}`}
                  evolutionNumber="-8%"
                  evolution={false}
                />
                <TotalCard
                  color="blue"
                  title="Valor total de kwh"
                  text={`${statsDashboard[1].totalkWh} kwh`}
                  evolutionNumber="+8%"
                  evolution={true}
                />
              </C.StatusWrapper>
              <C.StatusWrapper>
                <StatusCard
                  color="purple"
                  text="Aprovado"
                  number={statsDashboard[5].quantidade}
                />
                <TotalCard
                  color="purple"
                  title="Valor total das propostas"
                  text={`${moneyFormatter.format(
                    statsDashboard[5].totalReais
                  )}`}
                  evolutionNumber="-8%"
                  evolution={false}
                />
                <TotalCard
                  color="purple"
                  title="Valor total de kwh"
                  text={`${statsDashboard[5].totalkWh} kwh`}
                  evolutionNumber="-8%"
                  evolution={true}
                />
              </C.StatusWrapper>
              <C.StatusWrapper>
                <StatusCard
                  color="green"
                  text="Contrato Gerado"
                  number={statsDashboard[2].quantidade}
                />
                <TotalCard
                  color="green"
                  title="Valor total das propostas"
                  text={`${moneyFormatter.format(
                    statsDashboard[2].totalReais
                  )}`}
                  evolutionNumber="-8%"
                  evolution={false}
                />
                <TotalCard
                  color="green"
                  title="Valor total de kwh"
                  text={`${statsDashboard[2].totalkWh} kwh`}
                  evolutionNumber="+8%"
                  evolution={true}
                />
              </C.StatusWrapper>
              <C.StatusWrapper>
                <StatusCard
                  color="red"
                  text="Reprovado"
                  number={statsDashboard[3].quantidade}
                />
                <TotalCard
                  color="red"
                  title="Valor total das propostas"
                  text={`${moneyFormatter.format(
                    statsDashboard[3].totalReais
                  )}`}
                  evolutionNumber="-8%"
                  evolution={false}
                />
                <TotalCard
                  color="red"
                  title="Valor total de kwh"
                  text={`${statsDashboard[3].totalkWh} kwh`}
                  evolutionNumber="+8%"
                  evolution={true}
                />
              </C.StatusWrapper>
            </C.StatusArea>
          )}

          <D.DataArea $align="right">
            <Datatable
              hasButton={false}
              columns={columns}
              rows={rows}
              pageNumber={page}
              pageSize={pageSize}
              onChangePage={(e) => handleChangePage(e)}
              onChangePageSize={(e) => handleChangePageSize(e)}
              totalPages={totalPages}
              onSearch={handleSearch}
            />

            <C.LineBreak />

            <C.ReportsArea>
              <C.SelectArea>
                <C.ReportsLabel>Status de propostas</C.ReportsLabel>
                <C.ReportsSelect
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  defaultValue={""}
                  onChange={(e: any) =>
                    setSelectedStatus(e.target.value as string)
                  }
                >
                  <MenuItem value="">
                    <D.DefaultColorText>Todos os status</D.DefaultColorText>
                  </MenuItem>
                  {Object.keys(EnumStatusGDProposta).map((key, index) => (
                    <MenuItem
                      key={key}
                      value={
                        EnumStatusGDPropostaNumericMap[
                          EnumStatusGDProposta[
                            key as keyof typeof EnumStatusGDProposta
                          ]
                        ]
                      }
                    >
                      {
                        EnumStatusGDProposta[
                          key as keyof typeof EnumStatusGDProposta
                        ]
                      }
                    </MenuItem>
                  ))}
                </C.ReportsSelect>
              </C.SelectArea>
              <C.SelectArea>
                <C.ReportsLabel>Selecione um captador</C.ReportsLabel>
                <Autocomplete
                  options={captadores}
                  fullWidth
                  noOptionsText="Nenhum captador encontrado"
                  getOptionLabel={(option: any) => option.nome}
                  renderInput={(params: any) => (
                    <TextField
                      {...params}
                      placeholder="Todos os captadores"
                      inputProps={{
                        ...params.inputProps,
                        "aria-label": "Without label",
                      }}
                    />
                  )}
                  defaultValue={null}
                  onChange={(event: any, newValue: any) => {
                    setSelectedCaptador(newValue?.id);
                  }}
                  isOptionEqualToValue={(option: any, value: any) =>
                    option.id === value.id
                  }
                />
              </C.SelectArea>
              <C.SelectArea>
                <C.ReportsLabel>Selecione o backoffice</C.ReportsLabel>
                <Autocomplete
                  options={backoffices}
                  fullWidth
                  noOptionsText="Nenhum backoffice encontrado"
                  getOptionLabel={(option: any) => option.nome}
                  renderInput={(params: any) => (
                    <TextField
                      {...params}
                      placeholder="Todos os backoffices"
                      inputProps={{
                        ...params.inputProps,
                        "aria-label": "Without label",
                      }}
                    />
                  )}
                  defaultValue={null}
                  onChange={(event: any, newValue: any) => {
                    setSelectedBackoffice(newValue?.id);
                  }}
                  isOptionEqualToValue={(option: any, value: any) =>
                    option.id === value.id
                  }
                />
              </C.SelectArea>
              <C.SelectArea>
                <C.ReportsLabel>Data da ultima movimentação</C.ReportsLabel>
                <D.DateTextfield
                  type="date"
                  label="Data início"
                  fullWidth
                  value={dataInicio}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setDataInicio(e.target.value)
                  }
                />
              </C.SelectArea>
              <C.SelectArea>
                <C.ReportsLabel>Data da ultima movimentação</C.ReportsLabel>
                <D.DateTextfield
                  type="date"
                  label="Data fim"
                  fullWidth
                  value={dataFim}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setDataFim(e.target.value)
                  }
                />
              </C.SelectArea>
            </C.ReportsArea>
            <C.ExportButton
              onClick={geraRelatorio}
              variant="contained"
              startIcon={
                <img
                  style={{ width: "20px", color: "white", fill: "white" }}
                  src={ExcelIcon}
                  alt="excelIcon"
                />
              }
            >
              Gerar relatório
            </C.ExportButton>
          </D.DataArea>
        </C.Container>
      )}

      <ModalContratar
        handleCloseModalContratar={handleCloseModalContratar}
        openModalContratar={openModalContratar}
        uuid={uuidModalContratar}
      />

      <PropostasModal
        id={openModalSeeMoreId}
        openPropostasModal={openProposta}
        callback={callbackPropostasModal}
      />
    </>
  );
};

export default Parceiro;
