export enum DadosAcessoEnum {
  NOMEDADOSACESSO = "NOMEDADOSACESSO",
  LOGIN = "LOGIN",
  EMAIL = "EMAIL",
  SENHA = "SENHA",
  TELEFONE = "TELEFONE",
  WHATSAPP = "WHATSAPP"
}

export enum DadosAcessoColaboradorEnum {
  NOMEDADOSACESSO = "NOMEDADOSACESSO",
  LOGIN = "LOGIN",
  EMAIL = "EMAIL",
  SENHA = "SENHA",
  TELEFONE = "TELEFONE",
  WHATSAPPDADOSACESSO = "WHATSAPPDADOSACESSO"
}