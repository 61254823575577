import { styleError } from "../styles/stylesCustom";
import { ErrorMessage } from "./AbstractValidation";

export const handleStyleFieldError = (field:string, fieldErros:ErrorMessage[] | null, sx?:any | null, index?:number | null) => {
    let error;

    if(index){
        error = fieldErros?.some((x) => x.index === index && x.ref === field?.toLocaleLowerCase());

    }else{
        error = fieldErros?.some((x) => x.ref === field?.toLocaleLowerCase());
    }
        
    if(error) return {...styleError, ...sx}
    else return sx || {}
};