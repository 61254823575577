import {
  EnumEstadoCivil,
  EnumEstadoCivilNumericMap,
} from "../enums/EnumEstadoCivil.enum";

export const objetoParaFormData = (obj: Object): FormData => {
  const formData = new FormData();
  for (const [chave, valor] of Object.entries(obj)) {
    if (valor !== null && valor !== undefined) {
      if (typeof valor === "string") {
        formData.append(chave, valor);
      } else if (valor instanceof Blob) {
        formData.append(chave, valor);
      } else {
        formData.append(chave, valor.toString());
      }
    }
  }
  return formData;
};

export const ObterEnumEstadoCivil = (
  id: number | null
): EnumEstadoCivil | null => {
  if (id === null) return null;
  const estadoCivil = Object.entries(EnumEstadoCivilNumericMap)
    .map(([chave, valor]) => ({ chave: chave as EnumEstadoCivil, valor }))
    .find((y) => y.valor === id)?.chave;

  if (estadoCivil) return estadoCivil;
  return null;
};

export const obterNomeArquivo = () =>
  new Date().toISOString().slice(0, 19).replace(/[-:T]/g, "");

export const downloadFile = (
  arquivo: File,
  nomeArquivo: string = arquivo.name
) => {
  const url = window.URL.createObjectURL(arquivo);
  const a = document.createElement("a");
  a.href = url;
  a.download = nomeArquivo;
  a.click();

  if (a.parentNode) {
    a.parentNode.removeChild(a);
  }
  window.URL.revokeObjectURL(url);
};

export const dowloadUrl = (fileUrl: string) => {
  const link = document.createElement("a");
  link.href = fileUrl;
  link.target = "_blank";
  link.rel = "noopener noreferrer";

  document.body.appendChild(link);
  link.click();

  document.body.removeChild(link);
};
