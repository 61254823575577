import { FC } from "react";
import SelectInput from "./SelectInput";
import { EnumEstadoCivil } from "../../enums/EnumEstadoCivil.enum";

interface ISelectEstadoCivilProps {
  value: EnumEstadoCivil | null;
  onChange: (newValue: EnumEstadoCivil | null) => void;
  readOnly?: boolean;
}

const SelectEstadoCivil: FC<ISelectEstadoCivilProps> = ({
  value,
  onChange,
  readOnly,
}) => {
  const estadoCivilValues = Object.values(EnumEstadoCivil);
  return (
    <SelectInput
      idSelect="estado-civil-select"
      label="Estado civil"
      values={estadoCivilValues}
      value={value}
      getValue={(v: EnumEstadoCivil) => v}
      onChange={(v) => onChange(v)}
      readOnly={readOnly}
    />
  );
};

export default SelectEstadoCivil;
