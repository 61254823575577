import React, {useEffect, useState} from 'react';
import {Modal, TextField} from "@mui/material";
import * as C from "../../style";
import CloseIcon from "@mui/icons-material/Close";
import CircleCheckbox from "../../../../../components/CircleCheckBox";
import {EnumStatusGDProposta, EnumStatusGDPropostaNumericMap} from "../../../../../enums/EnumStatusGDProposta.enum";
import * as D from "../../../../../styles/appComponents";
import HistoricoCard from "../../../../../components/HistoricoCard";
import {ICreateLog, ILog} from "../../../../../models/Log";
import * as logServices from "../../../../../services/api/LogService";
import {AxiosError, AxiosResponse} from "axios";
import {DefaultUpdateGDProposta, IGDProposta, IUpdateGDProposta} from "../../../../../models/GDProposta";
import * as services from "../../../../../services/api/PropostaService";
import {toastMessage} from "../../../../../utils/toastMessage";
import MaskDefaultInput from "../../../../../components/MasDefaultinput/MaskDefaultInput";

type PropostasModalProps = {
  id: string | undefined;
  openPropostasModal: boolean;
  callback?: () => void;
  onClose?: VoidFunction;
}

enum PropostasModalEnum {
  ID = "id",
  IDREFERENCIAL = "idReferencial",
  CELULAR = "celular",
  EMAIL = "email",
  NOME = "nome",
  NOMEBACKOFFICE = "nomeBackoffice",
  NOMECAPTADOR = "nomeCaptador",
  STATUS = "status",
  VALORMEDIOCONTA = "valorMedioConta",
  CONTA = "conta",
  DATA = "data",
  KWH = "kwh",
}

const PropostasModal: (prop: PropostasModalProps) => React.JSX.Element = (prop: PropostasModalProps): React.JSX.Element => {
  //region Variáveis
  const {id, openPropostasModal, callback, onClose} = prop;
  const [historico, setHistorico] = useState<ILog[]>([]);
  const [historicoAssunto, setHistoricoAssunto] = useState<string>("");
  const [historicoDescricao, setHistoricoDescricao] = useState<string>("");
  const [historicoAnexo, setHistoricoAnexo] = useState<File | null>(null);
  const [editar, setEditar] = useState(false);
  const [selectedId, setSelectedId] = React.useState<number>(0);
  const [GDProposta, setGDPropostaModal] = useState<IGDProposta>({
    id: "",
    idReferencial: "",
    createdAt: new Date(),
    updatedAt: new Date(),
    cliente: "",
    celular: "",
    status: EnumStatusGDProposta.contratoGerado,
    nomeCaptador: "",
    celularCaptador: "",
    email: "",
    nomeBackoffice: "",
    valor: "",
    kwh: "",
  });
  //endregion

  //region Services
  const createNewLog = async () => {
    if (historicoAssunto === "") {
      toastMessage("warning", "Campo não pode ser vazio");
      return;
    }

    const historicoData: ICreateLog = {
      gdLeadId: null,
      gdPropostaId: GDProposta.id,
      gdContratoId: null,
      assunto: historicoAssunto,
      descricao: historicoDescricao,
      anexo: historicoAnexo,
    };

    await logServices
      .CreateLog(historicoData)
      .then(() => {
        getGDPropostaModalData(GDProposta.id);
        toastMessage("success", "Criado com sucesso!");
      })
      .catch((e: AxiosError) => {
        toastMessage("error", e.response
          ? String(e.response?.data)
          : "Houve um erro ao pegar os dados.");
      });
  };

  const handleUpdateGDProposta = async () => {
    var uploadGDPropostaData: IUpdateGDProposta = {
      ...DefaultUpdateGDProposta,
      id: GDProposta.id,
      nome: GDProposta.cliente,
      celular: GDProposta.celular,
      email: GDProposta.email,
      status: selectedId,
    };

    await services
      .UpdateGDProposta(uploadGDPropostaData)
      .then(() => {
        getGDPropostaModalData(GDProposta.id);
        setEditar(false);
        toastMessage("success", "Editado com sucesso");
      })
      .catch((e: AxiosError) => {
        toastMessage("error", e.response
          ? String(e.response?.data)
          : "Houve um erro ao pegar os dados.");
      });
  };

  const getGDPropostaModalData = async (id: string) => {
    await services
      .GetPropostaById(id)
      .then((response: AxiosResponse<IGDProposta>) => {
        setGDPropostaModal(response.data);
        setSelectedId(response.data.status as unknown as number);
      })
      .catch((e: AxiosError) => {
        toastMessage("error", e.response
          ? String(e.response?.data)
          : "Houve um erro ao pegar os dados.");
      });
    await getLogsByPropostaId(id);
  };

  const getLogsByPropostaId = async (id: string) => {
    await logServices
      .GetLogsByItemId(id)
      .then((response: any) => {
        setHistorico(response.data);
      })
      .catch((e: AxiosError) => {
        toastMessage("error", e.response
          ? String(e.response?.data)
          : "Houve um erro ao pegar os dados.");
      });
  };
  //endregion

  //region UI
  const handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const name: string = event.target.name;
    const value: string = event.target.value;

    setGDPropostaModal((state: IGDProposta) => {
      const updatedState = {
        ...state,
        [name]: value,
      };
      return updatedState;
    });
  }

  const handleNewHistoricoChange = (
    assunto: string,
    descricao: string,
    anexo: File | null
  ) => {
    setHistoricoAssunto(assunto);
    setHistoricoDescricao(descricao);
    setHistoricoAnexo(anexo);
  };

  const handleOnCloseModal = () => {
    setGDPropostaModal({
      id: "",
      idReferencial: "",
      createdAt: new Date(),
      updatedAt: new Date(),
      cliente: "",
      celular: "",
      status: EnumStatusGDProposta.contratoGerado,
      nomeCaptador: "",
      celularCaptador: "",
      email: "",
      nomeBackoffice: "",
      valor: "",
      kwh: "",
    });
  }

  const handleSelect = (id: number) => {
    if (editar) {
      setSelectedId(id);
    }
  };

  useEffect(() => {
    if(id)
      getGDPropostaModalData(id);
  }, [id]);
  //endregion

  return (
      <Modal
        sx={{ width: "100%", zIndex: 10 }}
        open={openPropostasModal}
        onClose={handleOnCloseModal}
        aria-labelledby="modal-details"
        aria-describedby="modal-details"
      >
        <C.ModalContainer>
          <C.ModalArea>
            <C.ModalCard>
              <C.ModalHeader>
                <h2>
                  {GDProposta.cliente} - {GDProposta.idReferencial}
                </h2>
                <C.CloseButton
                  aria-label="close"
                  onClick={callback}
                >
                  <CloseIcon />
                </C.CloseButton>
              </C.ModalHeader>
              <h4>Status atual:</h4>
              <C.ModalStatusArea>
                <CircleCheckbox
                  id={
                    EnumStatusGDPropostaNumericMap[
                      EnumStatusGDProposta.naoTeveInteresse
                      ]
                  }
                  color="yellow"
                  label="Não teve interesse"
                  selected={
                    selectedId ===
                    EnumStatusGDPropostaNumericMap[
                      EnumStatusGDProposta.naoTeveInteresse
                      ]
                  }
                  onSelect={handleSelect}
                />
                <CircleCheckbox
                  id={
                    EnumStatusGDPropostaNumericMap[
                      EnumStatusGDProposta.emAnalise
                      ]
                  }
                  color="blue"
                  label="Em análise"
                  selected={
                    selectedId ===
                    EnumStatusGDPropostaNumericMap[
                      EnumStatusGDProposta.emAnalise
                      ]
                  }
                  onSelect={handleSelect}
                />
                <CircleCheckbox
                  id={
                    EnumStatusGDPropostaNumericMap[
                      EnumStatusGDProposta.aprovada
                      ]
                  }
                  color="purple"
                  label="Aprovado"
                  selected={
                    selectedId ===
                    EnumStatusGDPropostaNumericMap[
                      EnumStatusGDProposta.aprovada
                      ]
                  }
                  onSelect={handleSelect}
                />
                <CircleCheckbox
                  id={
                    EnumStatusGDPropostaNumericMap[
                      EnumStatusGDProposta.contratoGerado
                      ]
                  }
                  color="green"
                  label="Contrato Gerado"
                  selected={
                    selectedId ===
                    EnumStatusGDPropostaNumericMap[
                      EnumStatusGDProposta.contratoGerado
                      ]
                  }
                  onSelect={handleSelect}
                />
                <CircleCheckbox
                  id={
                    EnumStatusGDPropostaNumericMap[
                      EnumStatusGDProposta.reprovada
                      ]
                  }
                  color="red"
                  label="Reprovado"
                  selected={
                    selectedId ===
                    EnumStatusGDPropostaNumericMap[
                      EnumStatusGDProposta.reprovada
                      ]
                  }
                  onSelect={handleSelect}
                />
                <CircleCheckbox
                  id={
                    EnumStatusGDPropostaNumericMap[
                      EnumStatusGDProposta.cancelada
                      ]
                  }
                  color="orange"
                  label="Cancelado"
                  selected={
                    selectedId ===
                    EnumStatusGDPropostaNumericMap[
                      EnumStatusGDProposta.cancelada
                      ]
                  }
                  onSelect={handleSelect}
                />
              </C.ModalStatusArea>
              <D.FWStack direction={"column"} spacing={2}>
                <D.FWStack direction={"row"} spacing={2}>
                  <TextField
                    label="Nome"
                    fullWidth
                    value={GDProposta.cliente}
                    name={PropostasModalEnum.NOME}
                    onChange={handleInputChange}
                    inputProps={{ readOnly: !editar }}
                  />
                  <MaskDefaultInput
                    type={"CELULAR"}
                    label="Celular"
                    value={GDProposta.celular ?? ""}
                    onChange={(rawValue) => {
                      const value =
                        typeof rawValue === "string"
                          ? rawValue
                          : (rawValue as { value?: string })?.value ?? "";

                      handleInputChange({
                        target: {
                          name: PropostasModalEnum.NOME,
                          value: value
                        }
                      } as React.ChangeEvent<HTMLInputElement>)
                    }}
                    readonly={!editar}
                  />
                </D.FWStack>

                <D.FWStack direction={"row"} spacing={2}>
                  <TextField
                    label="Email"
                    fullWidth
                    value={GDProposta.email}
                    name={PropostasModalEnum.EMAIL}
                    onChange={handleInputChange}
                    inputProps={{ readOnly: !editar }}
                  />
                  <TextField
                    fullWidth
                    label="Parceiro"
                    value={GDProposta.nomeCaptador}
                    onChange={handleInputChange}
                    inputProps={{ readOnly: true }}
                  />
                </D.FWStack>

                <D.FWStack direction={"row"} spacing={2}>
                  <TextField
                    label="Backoffice"
                    fullWidth
                    value={GDProposta.nomeBackoffice}
                    onChange={handleInputChange}
                    inputProps={{ readOnly: true }}
                  />
                  <TextField
                    label="Data"
                    fullWidth
                    value={new Date(GDProposta.createdAt).toLocaleDateString()}
                    inputProps={{ readOnly: true }}
                  />
                </D.FWStack>

                <D.FWStack direction={"row"} spacing={2}>
                  <MaskDefaultInput
                    type={"REAL"}
                    label="Valor"
                    value={
                      GDProposta.valor ?? ""
                    }
                    onChange={(rawValue) => {
                      const value =
                        typeof rawValue === "string"
                          ? rawValue
                          : (rawValue as { value?: string })?.value ?? "";

                      handleInputChange({
                        target: {
                          name: PropostasModalEnum.VALORMEDIOCONTA,
                          value: value
                        }
                      } as React.ChangeEvent<HTMLInputElement>)
                    }}
                  />
                  <MaskDefaultInput
                    label="kWh"
                    type={"KWH"}
                    value={GDProposta.kwh ?? ""}
                    onChange={(rawValue) => {
                      const value =
                        typeof rawValue === "string"
                          ? rawValue
                          : (rawValue as { value?: string })?.value ?? "";

                      handleInputChange({
                        target: {
                          name: PropostasModalEnum.KWH,
                          value: value
                        }
                      } as React.ChangeEvent<HTMLInputElement>)
                    }}
                  />
                  <D.FWStack></D.FWStack>
                  <D.FWStack></D.FWStack>
                </D.FWStack>
              </D.FWStack>
              <C.ModalButtonsArea>
                <C.ModalSaveButton
                  variant="contained"
                  disabled={editar}
                  onClick={() => setEditar(true)}
                >
                  Editar
                </C.ModalSaveButton>
                <C.ModalSaveButton
                  variant="contained"
                  disabled={!editar}
                  onClick={() => handleUpdateGDProposta()}
                >
                  Salvar
                </C.ModalSaveButton>
              </C.ModalButtonsArea>
            </C.ModalCard>
            <HistoricoCard
              tableData={historico}
              getNewHistoricData={handleNewHistoricoChange}
              saveFunction={createNewLog}
              reloadTable={() => getGDPropostaModalData(GDProposta.id)}
            />
          </C.ModalArea>
        </C.ModalContainer>
      </Modal>
    );
};

export default PropostasModal;