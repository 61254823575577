import React, {useEffect, useState} from 'react';
import {Modal, Stack} from "@mui/material";
import * as C from "../../../Leads/style";
import CloseIcon from "@mui/icons-material/Close";
import * as D from "../../../../../styles/appComponents";
import CircleCheckbox from "../../../../../components/CircleCheckBox";
import MaskDefaultInput from "../../../../../components/MasDefaultinput/MaskDefaultInput";
import {AxiosResponse} from "axios";
import {GetOperadoraById, setOperadora, updateOperadora} from "../../../../../services/api/OperadoraService";
import {toastMessage} from "../../../../../utils/toastMessage";
import {IOperadora, IOperadoraDashboard} from "../../../../../models/Operadora";
import {EnumStatusOperadora, OperadoraEnum} from "../../../../../enums/EnumOperadora";

type ModalDistribuidoraProps = {
  onClose: VoidFunction;
  callback: VoidFunction;
  modalOpen: boolean;
  id?: string;
}

function ModalDistribuidora(props: ModalDistribuidoraProps): JSX.Element {
  //region Variáveis
  const {onClose, modalOpen, id, callback} = props;
  const handleClose = () => onClose();
  const callBack = () => callback();
  const [isEditable, setIsEditable] = useState<boolean>(false);
  const [dataForm, setDataForm] = useState<IOperadora>({
    id: undefined,
    nome: "",
    nomeFantasia: "",
    cnpj: "",
    ativo: 1,
  });
  const EnumStatusOperadoraNumericMap = {
    [EnumStatusOperadora.ativa]: 1,
    [EnumStatusOperadora.inativa]: 0,
  };
  //endregion

  //region Services
  const setDistribuidora: () => Promise<void> = async () => {
    try {
      const {data}: AxiosResponse<any, IOperadoraDashboard> = !id ? await setOperadora(dataForm) : await updateOperadora(dataForm);

      toastMessage("success", `Operadora ${!id ? "criada" : "atualizada"} com sucesso`);
      handleClose();
      callback();

      if(id) {
        callBack();
        setIsEditable(false);
      }
    } catch (e: unknown) {
      toastMessage("error", `Erro ao ${!id ? "criada" : "atualizada"} a operadora`);
    }
  }

  const getDistribuidora: (id: string) => Promise<void> = async (id: string) => {
    try {
      const {data}: AxiosResponse<IOperadora> = await GetOperadoraById(id);

      const ativo = data.ativo ? 1 : 0;

      setDataForm({...data, ativo});

    } catch (e: unknown) {
      toastMessage("error", "Erro ao buscar a operadora");
    }
  };
  //endregion

  //region UI
  const handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const name: string = event.target.name;
    const value: string = event.target.value;

    setDataForm((state: IOperadora) => {
      const updatedState = {
        ...state,
        [name]: value,
      };
      return updatedState;
    });
  }

  const cleaDataForm = () => {
    setDataForm({
      id: undefined,
      nome: null,
      nomeFantasia: null,
      cnpj: null,
      ativo: 1,
    })
  }

  useEffect(() => {
    if (id) {
      getDistribuidora(id);
      setIsEditable(false)
    } else {
      cleaDataForm()
      setIsEditable(true)
    }
  }, [modalOpen]);
  //endregion

  return (
    <Modal
      sx={{width: "100%", zIndex: 10}}
      open={modalOpen}
      onClose={handleClose}
      aria-labelledby="modal-details"
      aria-describedby="modal-details"
    >
      <C.ModalContainer>
        <C.ModalArea style={{height: "auto"}}>
          <C.ModalCard onSubmit={(e) => {
            e.preventDefault();
            setDistribuidora();
          }}>
            <C.ModalHeader style={{display: 'flex', justifyContent: "flex-end"}}>
              <C.CloseButton aria-label="close" onClick={handleClose}>
                <CloseIcon/>
              </C.CloseButton>
            </C.ModalHeader>
            <C.ModalStatusArea>
              <CircleCheckbox
                id={EnumStatusOperadoraNumericMap[EnumStatusOperadora.ativa]}
                label="Ativo"
                color="green"
                selected={dataForm.ativo == EnumStatusOperadoraNumericMap[EnumStatusOperadora.ativa]}
                onSelect={function (id: number): void {
                  if (isEditable) {
                    handleInputChange({
                      target: {
                        name: OperadoraEnum.ATIVO,
                        value: id
                      }
                    } as unknown as React.ChangeEvent<HTMLInputElement>)
                  }
                }}/>
              <CircleCheckbox
                id={EnumStatusOperadoraNumericMap[EnumStatusOperadora.inativa]}
                label="Inativo"
                color="red"
                selected={dataForm.ativo == EnumStatusOperadoraNumericMap[EnumStatusOperadora.inativa]}
                onSelect={function (id: number): void {
                  if (isEditable) {
                    handleInputChange({
                      target: {
                        name: OperadoraEnum.ATIVO,
                        value: id
                      }
                    } as unknown as React.ChangeEvent<HTMLInputElement>)
                  }
                }}/>
            </C.ModalStatusArea>
            <C.ModalInputsArea>
              <C.ModalInput
                inputProps={{readOnly: !isEditable}}
                label="Nome da distribuidora"
                name={OperadoraEnum.NOME}
                value={dataForm.nome ?? ""}
                onChange={handleInputChange}
                required
              />
              <C.ModalInput
                inputProps={{readOnly: !isEditable}}
                label="Apelido"
                name={OperadoraEnum.NOMEFANTASIA}
                value={dataForm.nomeFantasia ?? ""}
                onChange={handleInputChange}
                required
              />
              <MaskDefaultInput
                readonly={!isEditable}
                label="Cnpj"
                type="CNPJ"
                value={dataForm.cnpj ?? ""}
                onChange={(data: string | Object) => {
                  handleInputChange({
                    target: {
                      name: OperadoraEnum.CNPJ,
                      value: data as string
                    }
                  } as unknown as React.ChangeEvent<HTMLInputElement>)
                }}
              />
            </C.ModalInputsArea>
            <Stack direction={"row"} spacing={2} justifyContent={"flex-end"} onClick={() => setIsEditable(true)}>
              {
                id ?
                  <D.ContainedButton
                    disabled={isEditable}
                    variant="contained"
                    $color={"lightpink"}
                  >
                    Editar
                  </D.ContainedButton> : null
              }
              <D.ContainedButton variant="contained" type="submit" disabled={!isEditable && typeof(id) == "string"}>
                Salvar
              </D.ContainedButton>
            </Stack>
          </C.ModalCard>
        </C.ModalArea>
      </C.ModalContainer>
    </Modal>
  );
}

export default ModalDistribuidora;