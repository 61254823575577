import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { iDadosAcesso } from "../../../../models/Colaborador/cadastros/DadosAcesso";
import {
  DadosPagamentos,
  IDadosGerador,
} from "../../../../models/Gerador/Cadastro/DadosPagamentoGerador";
import {
  IDadosUsinaPessoaFisica,
  IDadosUsinaPessoaJuridica,
} from "../../../../models/Gerador/Cadastro/DadosUsina";
import { IInformacaoContrato } from "../../../../models/Gerador/Cadastro/InformacaoContrato";
import { IResponsavelComunicacao } from "../../../../models/Gerador/Cadastro/ResponsavelComunicacao";
import { iResponsavelIndicacao } from "../../../../models/Gerador/Cadastro/ResponsavelIndicacao";
import { IResponsavelLegal } from "../../../../models/Gerador/Cadastro/ResponsavelLegal";
import {
  UsinaTipoPessoaFisica,
  UsinaTipoPessoaJuridica,
} from "../../../../models/Gerador/Usina";
import { IOperadoraListItem } from "../../../../models/Operadora";
import { ICreateResponsavelLegal } from "../../../../models/ResponsavelLegal";
import {
  CreateGeradorPessoaFisica,
  CreateGeradorPessoaJuridica,
  GenerateContrato,
  GetAll,
  GetOnePessaoFisica,
  GetOnePessaoJuridica,
  UpdateGeradorPessoaFisica,
  UpdateGeradorPessoaJuridica,
} from "../../../../services/api/GeradorService";
import ibgeService from "../../../../services/api/IbgeService";
import { GetOperadoras } from "../../../../services/api/OperadoraService";
import * as D from "../../../../styles/appComponents";
import { ErrorMessage } from "../../../../utils/AbstractValidation";
import { HandleObjects } from "../../../../utils/HandleObjects";
import {
  dataCreateGeradorPessoaFisica,
  dataCreateGeradorPessoaJuridica,
  dataUpdateGeradorPessoaFisica,
  dataUpdateGeradorPessoaJuridica,
  dataUpdateTarifaGerador,
} from "../../../../utils/returnNewData";
import { toastMessage } from "../../../../utils/toastMessage";
import DadosAcesso from "../../Colaborador/Colaborador/Cadastro/components/DadosAcesso";
import { DadosAcessoGeradorValidate } from "../../Colaborador/Colaborador/Cadastro/components/validation/DadosAcessoGeradorValidate";
import { DadosUsinaPessoaJuridicaValidate } from "../Validation/DadosUsina/DadosUsinaPessoaJuridicaValidate";
import { ResponsavelLegalValidate } from "../Validation/ResponsavelLegal/ResponsavelLegalValidate";
import { DadosPagamentoGerador } from "./Components/DadosPagamentoGerador";
import { DadosUsina } from "./Components/DadosUsina";
import { InformacaoContrato } from "./Components/InformacaoContrato";
import { ResponsavelIndicacao } from "./Components/ResponsavelIndicacao";
import { ResponsavelLegal } from "./Components/ResponsavelLegal";
import * as C from "./style";
import {
  IFormCreateGeradorPessoaJuridica,
  IFormUpdateGeradorPessoaFisica,
  IFormUpdateGeradorPessoaJuridica,
} from "../../../../models/Gerador/Cadastro/Gerador";
import { EnumStatusContratoGerador } from "../../../../enums/Gerador/EnumStatusContratoGerador.enum";
import { getFirstWord, siglaTransform } from "../../../../utils/customWords";
import { ResponsavelUsina } from "./Components/ResponsavelUsina";
import { IResponsavelUsina } from "../../../../models/Gerador/Cadastro/ResponsavelUsina";
import { EnumTipoPessoa } from "../../../../enums/Gerador/EnumTipoPessoa.enum";
import { GeradorResponse } from "../../../../models/Gerador/GeradorResponse";
import { EnumTipoConexao } from "../../../../enums/Gerador/EnumTipoConexao.enum";
import { ResetSenha } from "../../../../services/api/UsuarioService";
import { UpdateUsinaResult } from "../../../../models/Gerador/Cadastro/UpdateUsinaResult";
import { calcValues } from "../../../../utils/moneyFormatter";
import { ICreateLog } from "../../../../models/Log";
import {
  CreateLog,
  GetLogByUsinaId,
} from "../../../../services/api/LogService";
import { DadosUsinaPessoaFisicaValidate } from "../Validation/DadosUsina/DadosUsinaPessoaFisicaValidate";
import { ResponsavelUsinaValidate } from "../Validation/ResponsavelUsina/ResponsavelUsinaValidate";
import { InformacaoContratoValidate } from "../Validation/InformacaoContrato/InformacaoContratoValidate";
import { InformacaoContratoGenerate } from "../../../../models/Gerador/Cadastro/InformacaoContratoGenerate";
import { GenerateContratoValidate } from "../Validation/InformacaoContrato/GenerateContratoValidate";
import { RequiredFieldsPessoaFisicaValidate } from "../Validation/DadosUsina/RequiredFieldsPessoaFisicaValidate";
import { RequiredFieldsPessoaJuridicaValidate } from "../Validation/DadosUsina/RequiredFieldsPessoaJuridicaValidate";

const CadastroGerador = () => {
  const [searchParams] = useSearchParams();
  const { geradorId } = useParams();
  const tipoFormulario = searchParams.get("tipoFormulario");

  const [operadoras, setOperadoras] = useState<IOperadoraListItem[]>([]);
  const [generateScroll, setGenerateScroll] = useState<boolean>(false);
  const [geradorIdCreated, setGeradorIdCreated] = useState<string | null>(null);
  const [geradorCreated, setGeradorCreated] = useState<boolean>(false);
  const [contratoGenerate, setContratoGenerate] = useState<boolean>(false);

  const [dadosUsina, setDadosUsina] = useState<
    IDadosUsinaPessoaJuridica | IDadosUsinaPessoaFisica | undefined
  >(undefined);
  const [responsavelComunicacao, setResponsavelComunicacao] = useState<
    IResponsavelComunicacao | undefined
  >(undefined);
  const [responsavelUsina, setResponsavelUsina] = useState<
    IResponsavelUsina | undefined
  >(undefined);
  const [informacaoContrato, setInformacaoContrato] = useState<
    IInformacaoContrato | undefined
  >(undefined);
  const [responsavelIndicacaos, setResponsavelIndicacaos] = useState<
    iResponsavelIndicacao[] | undefined
  >([]);
  const [dadosPagamentoGerador, setDadosPagamentoGerador] = useState<
    IDadosGerador | undefined
  >(undefined);
  const [dadosAcesso, setDadosAcesso] = useState<iDadosAcesso | undefined>(
    undefined
  );

  const [buttonSaveGenerateContract, setButtonSaveGenerateContract] =
    useState<boolean>(true);
  const [buttonSaveDadosUsina, setButtonSaveDadosUsina] =
    useState<boolean>(false);
  const [
    buttonSaveResponsavelComunicacao,
    setButtonSaveResponsavelComunicacao,
  ] = useState<boolean>(false);
  const [buttonSaveInformacaoContrato, setButtonSaveInformacaoContrato] =
    useState<boolean>(false);
  const [buttonSaveResponsavelIndicacao, setButtonSaveResponsavelIndicacao] =
    useState<boolean>(false);
  const [buttonSaveDadosPagamentoGerador, setButtonSaveDadosPagamentoGerador] =
    useState<boolean>(false);
  const [buttonSaveDadosAcesso, setButtonSaveDadosAcesso] =
    useState<boolean>(false);
  const [buttonSaveResponsavelLegal, setButtonSaveResponsavelLegal] =
    useState<boolean>(false);

  const [buttonSaveResponsavelUsina, setButtonSaveResponsavelUsina] =
    useState<boolean>(false);

  const [buttonSaveGenerateContrato, setButtonSaveGenerateContrato] =
    useState<boolean>(false);

  const [fieldErrosDadosUsina, setFieldsErrosDadosUsina] = useState<
    ErrorMessage[] | null
  >(null);
  const [fieldErrosResponsavelLegal, setFieldsErrosResponsavelLegal] = useState<
    ErrorMessage[] | null
  >(null);
  const [fieldErrosResponsavelUsina, setFieldsErrosResponsavelUsina] = useState<
    ErrorMessage[] | null
  >(null);
  const [
    fieldErrosResponsavelCominicacao,
    setFieldsErrosResponsavelComunicacao,
  ] = useState<ErrorMessage[] | null>(null);
  const [fieldErrosInformacaoContrato, setFieldsErrosInformacaoContrato] =
    useState<ErrorMessage[] | null>(null);
  const [fieldErrosResponsavelIndicacao, setFieldsErrosResponsavelIndicacao] =
    useState<ErrorMessage[] | null>(null);
  const [fieldErrosDadosPagamentoGerador, setFieldsErrosDadosPagamentoGerador] =
    useState<ErrorMessage[] | null>(null);
  const [dadosAcessoFieldsErros, setDadosAcessoFieldsErros] = useState<
    ErrorMessage[] | null
  >([]);

  const [load, setLoad] = useState<{ status: boolean; section: string }>({
    status: false,
    section: "",
  });

  const [uuidContratoGenerated, setUuidContratoGenerated] = useState<
    string | null
  >(null);
  const [uuidContratoUpdated, setUuidContratoUpdated] = useState<string | null>(
    null
  );
  const [usina, setUsina] = useState<
    UsinaTipoPessoaJuridica | UsinaTipoPessoaFisica | undefined
  >(undefined);
  const [cities, setCities] = useState<string[]>([]);
  const myElementRef = useRef<HTMLDivElement>(null);
  const [usinas, setUsinas] = useState<GeradorResponse[]>([]);

  const [temContrato, setTemContrato] = useState<boolean>(false);
  const navigate = useNavigate();
  let data: any = undefined;

  useEffect(() => {
    const loadCities = async () => {
      try {
        if (dadosUsina?.uf) {
          const { data } = await ibgeService.getCities(dadosUsina?.uf);
          setCities((data || []).map((x: any) => x.nome));
        }
      } catch (error) {
        console.error(`Erro ao listar cidades: ${error}`);
      }
    };
    loadCities();
  }, [dadosUsina?.uf, geradorId]);

  useEffect(() => {
    const loadOperadoras = async () => {
      try {
        const { data } = await GetOperadoras();
        setOperadoras(data);
      } catch (error) {
        toastMessage("error", "erro ao buscar todas as operadoras");
      }
    };
    loadOperadoras();
  }, []);

  useEffect(() => {
    const ContractPropertiers: (keyof UsinaTipoPessoaJuridica)[] = [
      "porcentagemDescontoTarifa",
      "tarifaVigenteContrato",
      "potencia",
      "vigenciaContratual",
      "performaceAlvo",
    ];

    loadUsina().then((usina) => {
      setTemContrato(
        HandleObjects.VerifyIfExistPropertities<UsinaTipoPessoaJuridica>(
          usina as UsinaTipoPessoaJuridica,
          ContractPropertiers
        )
      );
    });

    if (geradorId) {
      setButtonSaveDadosAcesso(true);
      setButtonSaveDadosUsina(true);
      setButtonSaveInformacaoContrato(true);
      setButtonSaveResponsavelComunicacao(true);
      setButtonSaveResponsavelIndicacao(true);
      setButtonSaveResponsavelLegal(true);
      setButtonSaveDadosPagamentoGerador(true);
      setButtonSaveResponsavelUsina(true);
    }
  }, [
    geradorId,
    uuidContratoGenerated,
    geradorIdCreated,
    geradorCreated,
    contratoGenerate,
  ]);

  const loadUsina = async () => {
    try {
      if (geradorId) {
        const { data } =
          tipoFormulario === "pessoaJuridica"
            ? await GetOnePessaoJuridica(geradorId)
            : await GetOnePessaoFisica(geradorId);
        setUsina(data);

        return data;
      } else if (geradorIdCreated) {
        const { data } =
          tipoFormulario === "pessoaJuridica"
            ? await GetOnePessaoJuridica(geradorIdCreated)
            : await GetOnePessaoFisica(geradorIdCreated);
        setUsina(data);

        return data;
      }
    } catch (error) {
      toastMessage("error", "Erro ao listar uma usina");
    }
  };

  const createTarifa = async (
    usina: UsinaTipoPessoaJuridica | UsinaTipoPessoaFisica
  ) => {
    const body = dataUpdateTarifaGerador(usina);

    if (usina.tipoPessoa === EnumTipoPessoa.pessoaJuridica)
      await UpdateGeradorPessoaJuridica(body);
    else await UpdateGeradorPessoaFisica(body);
  };

  const onSaveDadosUsina = async (data: any): Promise<void> => {
    const resultValidation =
      tipoFormulario === "pessoaJuridica"
        ? new DadosUsinaPessoaJuridicaValidate()
        : new DadosUsinaPessoaFisicaValidate();

    const errorMessages = resultValidation.errorMessage(data);

    setLoad({ status: true, section: "dadosUsina" });
    const dataDadosAcesso = {
      nomegerador:
        tipoFormulario === "pessoaFisica"
          ? data?.nomeusina
          : tipoFormulario === "pessoaJuridica"
          ? data?.razaosocialnome
          : null,
    };

    if (errorMessages) {
      setFieldsErrosDadosUsina(errorMessages);
      setLoad({ status: false, section: "dadosUsina" });

      errorMessages.forEach((error: ErrorMessage) => {
        toastMessage("warning", error?.message);
      });

      return;
    }

    if (geradorId) {
      await updateGerador({ ...data, ...dataDadosAcesso }).then((x) => {
        if (x === "Este usuário já existe") {
          toastMessage("warning", x);
          setLoad({ status: false, section: "dadosUsina" });
        } else setLoad({ status: false, section: "dadosUsina" });
      });

      setButtonSaveDadosUsina(true);
      setFieldsErrosDadosUsina([]);
    } else {
      await createGerador(tipoFormulario as string).then((x) => {
        if (x === "Este usuário já existe") {
          toastMessage("warning", x);
          setLoad({ status: false, section: "dadosUsina" });
        } else if(x?.id) {
          navigate(`/Editar/Gerador/${x?.id}?tipoFormulario=${tipoFormulario}`);
          setLoad({ status: false, section: "dadosUsina" });
        }
      });

      setDadosUsina(data);
      setButtonSaveDadosUsina(true);
      setFieldsErrosDadosUsina([]);
    }
  };

  const onUpdateDadosUsina = (): void => {
    setButtonSaveDadosUsina(false);
  };

  const onChangeDadosUsina = (data: IDadosUsinaPessoaJuridica): void => {
    setDadosUsina(data);
  };

  const handleFieldsRequired = (section: string) => {
    const fieldsRequired =
      usina?.tipoPessoa === EnumTipoPessoa.pessoaFisica
        ? {
            nomeusina: (usina as UsinaTipoPessoaFisica)?.nomeUsina,
            instalacao: usina?.instalacao,
          }
        : {
            razaosocialnome: (usina as UsinaTipoPessoaJuridica)
              ?.razaoSocialNome,
            instalacao: usina?.instalacao,
          };

    const resultValidationFieldsRequired =
      usina?.tipoPessoa === EnumTipoPessoa.pessoaFisica
        ? new RequiredFieldsPessoaFisicaValidate()
        : new RequiredFieldsPessoaJuridicaValidate();

    const errorMessagesFieldsRequired =
      resultValidationFieldsRequired.errorMessage(fieldsRequired);

    if (errorMessagesFieldsRequired) {
      setFieldsErrosDadosUsina(errorMessagesFieldsRequired);

      errorMessagesFieldsRequired.forEach((error: ErrorMessage) => {
        toastMessage("warning", error?.message);
      });

      setLoad({ status: false, section });

      return false;
    }

    return true;
  };

  const onSaveResponsavelLegal = async (data: IResponsavelComunicacao) => {
    setLoad({ status: true, section: "responsavelLegal" });
    const newDadosAcesso = {
      telefone: (data?.dadosResponsavelLegal || [{ celular: "" }])[0].celular,
      email: (data?.dadosResponsavelLegal || [{ email: "" }])[0]?.email,
      whatsapp: (data?.dadosResponsavelLegal || [{ celular: "" }])[0].celular,
    };

    const resultValidation = new ResponsavelLegalValidate();
    const errorMessages = resultValidation.errorMessage(
      data?.dadosResponsavelLegal[0]
    );

    if (!handleFieldsRequired("responsavelLegal")) {
      return;
    }

    if (errorMessages) {
      setFieldsErrosResponsavelLegal(errorMessages);

      errorMessages.forEach((error: ErrorMessage) => {
        toastMessage("warning", error?.message);
      });

      setLoad({ status: false, section: "responsavelLegal" });

      return;
    }

    if (geradorId) {
      await updateGerador({ ...data, ...newDadosAcesso }).then((x) => {
        if (x === "Este usuário já existe") {
          toastMessage("warning", x);
          setLoad({ status: false, section: "responsavelLegal" });
        } else setLoad({ status: false, section: "responsavelLegal" });
      });

      setResponsavelComunicacao(data);
      setButtonSaveResponsavelLegal(true);
      setFieldsErrosResponsavelLegal([]);
    } else {
      await createGerador(tipoFormulario as string).then((x) => {
        if (x === "Este usuário já existe") {
          toastMessage("warning", x);
          setLoad({ status: false, section: "responsavelLegal" });
        } else if(x?.id) {
          navigate(`/Editar/Gerador/${x?.id}?tipoFormulario=${tipoFormulario}`);
          setLoad({ status: false, section: "responsavelLegal" });
        }
      });
      setButtonSaveResponsavelLegal(true);
      setResponsavelComunicacao(data);
    }
  };

  const onChangeResponsavelLegal = (data: IResponsavelComunicacao) => {
    setResponsavelComunicacao(data);
  };

  const onUpdateResponsavelLegal = (data: IResponsavelComunicacao) => {
    setResponsavelComunicacao(data);
    setButtonSaveResponsavelLegal(false);
  };

  const onSaveResponsavelUsina = async (data: IResponsavelUsina) => {
    const resultValidation = new ResponsavelUsinaValidate();
    const errorMessages = resultValidation.errorMessage(data);

    setLoad({ status: true, section: "responsavelUsina" });
    const newDadosAcesso = {
      email: data.emailresponsavelusina,
      telefone: data.celularresponsavelusina,
      whatsapp: data.celularresponsavelusina,
    };

    if (errorMessages) {
      setFieldsErrosResponsavelUsina(errorMessages);
      setLoad({ status: false, section: "responsavelUsina" });

      errorMessages.forEach((error: ErrorMessage) => {
        toastMessage("warning", error?.message);
      });

      return;
    }

    if (geradorId) {
      await updateGerador({ ...data, ...newDadosAcesso }).then((x) => {
        if (x === "Este usuário já existe") {
          toastMessage("warning", x);
          setLoad({ status: false, section: "responsavelUsina" });
        } else setLoad({ status: false, section: "responsavelUsina" });
      });

      setResponsavelUsina(data);
      setButtonSaveResponsavelUsina(true);
      setFieldsErrosResponsavelUsina([]);
    } else {
      await createGerador(tipoFormulario as string).then((x) => {
        if (x === "Este usuário já existe") {
          toastMessage("warning", x);
          setLoad({ status: false, section: "responsavelUsina" });
        } else if(x?.id) {
          navigate(`/Editar/Gerador/${x?.id}?tipoFormulario=${tipoFormulario}`);
          setLoad({ status: false, section: "responsavelUsina" });
        }
      });
      setButtonSaveResponsavelUsina(true);
      setResponsavelUsina(data);
    }
  };

  const onChangeResponsavelUsina = (data: IResponsavelUsina) => {
    setResponsavelUsina(data);
  };

  const onUpdateResponsavelUsina = (data: IResponsavelUsina) => {
    setResponsavelUsina(data);
    setButtonSaveResponsavelUsina(false);
  };

  const onSaveInformacaoContrato = async (data: IInformacaoContrato) => {
    const resultValidation = new InformacaoContratoValidate();
    const errorMessages = resultValidation.errorMessage(data);

    setLoad({ status: true, section: "informaçãoContrato" });
    setInformacaoContrato(data);

    if (!handleFieldsRequired("informaçãoContrato")) {
      return;
    }

    if (errorMessages) {
      setFieldsErrosInformacaoContrato(errorMessages);

      errorMessages.forEach((error: ErrorMessage) => {
        toastMessage("warning", error?.message);
      });

      setLoad({ status: false, section: "informaçãoContrato" });

      return;
    }

    if (geradorId) {
      await updateGerador(data).then((x) => {
        if (x === "Este usuário já existe") {
          toastMessage("warning", x);
          setLoad({ status: false, section: "informaçãoContrato" });
        } else setLoad({ status: false, section: "informaçãoContrato" });
      });

      setInformacaoContrato(data);
      setButtonSaveInformacaoContrato(true);
      setButtonSaveGenerateContrato(true);
      setFieldsErrosInformacaoContrato([]);
    } else {
      await createGerador(tipoFormulario as string).then((x) => {
        if (x === "Este usuário já existe") {
          toastMessage("warning", x);
          setLoad({ status: false, section: "informaçãoContrato" });
        } else if(x?.id) {
          navigate(`/Editar/Gerador/${x?.id}?tipoFormulario=${tipoFormulario}`);
          setLoad({ status: false, section: "informaçãoContrato" });
        }

        setButtonSaveGenerateContrato(true);
      });

      setButtonSaveInformacaoContrato(true);
    }
  };

  const onChangeInformacaoContrato = (data: IInformacaoContrato) => {
    setInformacaoContrato(data);
  };

  const onUpdateInformacaoContrato = (data: IInformacaoContrato) => {
    setInformacaoContrato(data);
    setButtonSaveInformacaoContrato(false);
  };

  const onGenerateContrato = async (data: InformacaoContratoGenerate) => {
    setLoad({ section: "generateContract", status: true });

    const resultValidation = new GenerateContratoValidate();
    const errorMessages = resultValidation.errorMessage(data);

    if (errorMessages) {
      setLoad({ section: "generateContract", status: false });

      errorMessages.forEach((error: ErrorMessage) => {
        toastMessage("warning", error?.message);
      });

      return;
    }

    try {
      if (geradorId) {
        await GenerateContrato(geradorId);
        setContratoGenerate(true);
        setLoad({ section: "generateContract", status: false });
        toastMessage("success", "contrato gerado com sucesso");
      }
    } catch (error) {
      toastMessage("warning", "Erro ao gerar o contrato");
      setLoad({ section: "informaçãoContrato", status: false });
    }
  };

  const onGenerateDistrato = async (data: IInformacaoContrato) => {
    const dataUpdated = {
      ...data,
      statuscontratogerador:
        EnumStatusContratoGerador.aguardandoAssinaturaDistrato,
    };

    await updateGerador(dataUpdated);
    setButtonSaveInformacaoContrato(true);
  };

  const onSaveResponsavelIndicacao = async (data: iResponsavelIndicacao[]) => {
    setLoad({ status: true, section: "informaçãoContrato" });
    if (geradorId) {
      await updateGerador(data).then(() => {
        setLoad({ status: false, section: "informaçãoContrato" });
      });

      setResponsavelIndicacaos(data);
      setButtonSaveResponsavelIndicacao(true);
      setFieldsErrosResponsavelIndicacao([]);
    } else {
      await createGerador(tipoFormulario as string).then((x) => {
        navigate(`/Editar/Gerador/${x?.id}?tipoFormulario=${tipoFormulario}`);
        setLoad({ status: false, section: "informaçãoContrato" });
      });
      setResponsavelIndicacaos(data);
    }
  };

  const onChangeResponsavelIndicacao = (data: iResponsavelIndicacao[]) => {
    setResponsavelIndicacaos(data);
    setInformacaoContrato((x: any) => {
      return { ...x, responsavelIndicacaos: data };
    });
  };

  const onUpdateResponsavelIndicacao = (data: iResponsavelIndicacao[]) => {
    setResponsavelIndicacaos(data);
  };

  const onSaveDadosPagamentoGerador = async (data: IDadosGerador) => {
    setLoad({ status: true, section: "dadosPagamentoGerador" });

    if (!handleFieldsRequired("dadosPagamentoGerador")) {
      return;
    }

    if (geradorId) {
      await updateGerador(data).then(() => {
        setLoad({ status: false, section: "dadosPagamentoGerador" });
      });

      setDadosPagamentoGerador(data);
      setButtonSaveDadosPagamentoGerador(true);
      setFieldsErrosDadosPagamentoGerador([]);
    } else {
      await createGerador(tipoFormulario as string).then((x) => {
        navigate(`/Editar/Gerador/${x?.id}?tipoFormulario=${tipoFormulario}`);
        setLoad({ status: false, section: "dadosPagamentoGerador" });
      });
      setButtonSaveDadosPagamentoGerador(true);
      setDadosPagamentoGerador(data);
    }
  };

  const onChangeDadosPagamentoGerador = (data: IDadosGerador) => {
    setDadosPagamentoGerador(data);
  };

  const onUpdateDadosPagamentoGerador = (data: IDadosGerador) => {
    setDadosPagamentoGerador(data);
    setButtonSaveDadosPagamentoGerador(false);
  };

  const onSaveDadosAcesso = async (data: iDadosAcesso) => {
    setLoad({ status: true, section: "dadosAcesso" });

    if (!handleFieldsRequired("dadosAcesso")) {
      return;
    }

    if (geradorId) {
      await updateGerador(data).then(() => {
        setLoad({ status: false, section: "dadosAcesso" });
      });

      setDadosAcesso(data);
      setButtonSaveDadosAcesso(true);
      setDadosAcessoFieldsErros([]);
    } else {
      await createGerador(tipoFormulario as string).then((x) => {
        navigate(`/Editar/Gerador/${x?.id}?tipoFormulario=${tipoFormulario}`);
        setLoad({ status: false, section: "dadosAcesso" });
      });
      setDadosAcesso(data);
      setButtonSaveDadosAcesso(true);
      setDadosAcessoFieldsErros([]);
    }
  };

  const onChangeDadosAcesso = (data: iDadosAcesso) => {
    setDadosAcesso(data);
  };

  const onUpdateDadosAcesso = () => {
    setButtonSaveDadosAcesso(false);
  };

  const onResetSenha = async (usuarioId: string | null) => {
    if (usuarioId) {
      try {
        await ResetSenha(usuarioId);
        toastMessage("success", "Senha resetada com sucesso!");
      } catch (error) {
        toastMessage("warning", "Erro ao resetar a senha!");
      }
    }
  };

  if (tipoFormulario === "pessoaFisica") {
    data = {
      ...dadosUsina,
      ...responsavelUsina,
      ...dadosPagamentoGerador,
      ...informacaoContrato,
      ...dadosAcesso,
    };
  } else {
    data = {
      ...dadosUsina,
      ...responsavelComunicacao,
      ...informacaoContrato,
      ...dadosAcesso,
      ...dadosPagamentoGerador,
    };
  }

  const updateGerador = async (internalData: unknown | null = null) => {
    setGeradorCreated(false);
    let newDataUpdate:
      | IFormUpdateGeradorPessoaJuridica
      | IFormUpdateGeradorPessoaFisica;

    if (internalData)
      newDataUpdate =
        tipoFormulario === "pessoaJuridica"
          ? dataUpdateGeradorPessoaJuridica(
              data ? { ...data, ...internalData } : {}
            )
          : dataUpdateGeradorPessoaFisica(
              data ? { ...data, ...internalData } : {}
            );
    else
      newDataUpdate =
        tipoFormulario === "pessoaJuridica"
          ? dataUpdateGeradorPessoaJuridica(data)
          : dataUpdateGeradorPessoaFisica(data);

    try {
      if (geradorId || geradorIdCreated) {
        const newUsina = { ...usina };
        HandleObjects.Copy(newDataUpdate, newUsina);

        const { data } =
          tipoFormulario === "pessoaJuridica"
            ? await UpdateGeradorPessoaJuridica(
                newUsina as UsinaTipoPessoaJuridica
              )
            : await UpdateGeradorPessoaFisica(
                newUsina as UsinaTipoPessoaFisica
              );

        if (geradorId && data)
          toastMessage("success", "gerador atualizado com sucesso");

        setGeradorCreated(true);
        return data;
      }
    } catch (error: any) {
      toastMessage("error", "Erro ao atualizar gerador");
      setGeradorCreated(false);
      return error?.response?.data;
    }
  };

  const createGerador = async (typeForm: string) => {
    setGeradorCreated(false);
    let newData;

    if (typeForm === "pessoaJuridica")
      newData = dataCreateGeradorPessoaJuridica({
        ...data,
        nomegerador: data?.razaosocialnome,
        telefone: (data?.dadosResponsavelLegal || [{ celular: "" }])[0]
          ?.celular,
        whatsapp: (data?.dadosResponsavelLegal || [{ celular: "" }])[0]
          ?.celular,
        email: (data?.dadosResponsavelLegal || [{ email: "" }])[0]?.email,
      });
    else
      newData = dataCreateGeradorPessoaFisica({
        ...data,
        nomegerador: data?.nomeusina,
        telefone: data?.celularresponsavelusina,
        whatsapp: data?.celularresponsavelusina,
        email: data?.emailresponsavelusina,
      });

    try {
      const { data } =
        tipoFormulario === "pessoaJuridica"
          ? await CreateGeradorPessoaJuridica({
              ...newData,
              tipoPessoa: EnumTipoPessoa.pessoaJuridica,
            } as IFormUpdateGeradorPessoaJuridica)
          : await CreateGeradorPessoaFisica({
              ...newData,
              tipoPessoa: EnumTipoPessoa.pessoaFisica,
            } as IFormUpdateGeradorPessoaFisica);

      if (data?.uuidContrato && data?.id) {
        toastMessage("success", "Gerador criado com sucesso!");
        setGeradorIdCreated(data?.id);
        setUuidContratoGenerated(data?.uuidContrato);
        setButtonSaveInformacaoContrato(false);
      }
      setGeradorCreated(true);
      return data;
    } catch (error: any) {
      toastMessage("error", "Erro ao criar um gerador");
      setGeradorCreated(false);
      return error?.response?.data;
    }
  };

  return (
    <>
      <DadosUsina
        onSave={onSaveDadosUsina}
        onChange={onChangeDadosUsina}
        onUpdate={onUpdateDadosUsina}
        buttonSave={buttonSaveDadosUsina}
        fieldErros={fieldErrosDadosUsina}
        geradorId={geradorId}
        cities={cities}
        usina={usina}
        scrollRef={myElementRef}
        tipoFormulario={tipoFormulario}
        usinas={usinas}
        load={load}
      />
      {tipoFormulario === "pessoaJuridica" ||
      (geradorId && usina?.tipoPessoa === EnumTipoPessoa.pessoaJuridica) ? (
        <ResponsavelLegal
          onSave={onSaveResponsavelLegal}
          onChange={onChangeResponsavelLegal}
          onUpdate={onUpdateResponsavelLegal}
          buttonSave={buttonSaveResponsavelLegal}
          fieldErros={fieldErrosResponsavelLegal}
          geradorId={geradorId}
          usina={usina}
          scrollRef={myElementRef}
          load={load}
        />
      ) : (
        <ResponsavelUsina
          onSave={onSaveResponsavelUsina}
          onChange={onChangeResponsavelUsina}
          onUpdate={onUpdateResponsavelUsina}
          buttonSave={buttonSaveResponsavelUsina}
          fieldErros={fieldErrosResponsavelUsina}
          geradorId={geradorId}
          usina={usina}
          load={load}
        />
      )}
      <InformacaoContrato
        onSave={onSaveInformacaoContrato}
        onChange={onChangeInformacaoContrato}
        onUpdate={onUpdateInformacaoContrato}
        onGenerate={onGenerateContrato}
        buttonSave={buttonSaveInformacaoContrato}
        fieldErros={fieldErrosInformacaoContrato}
        operadoras={operadoras}
        geradorId={geradorId}
        usina={usina}
        scrollRef={myElementRef}
        uuidContratoGenerated={uuidContratoUpdated}
        generateScroll={generateScroll}
        buttonSaveGenerate={buttonSaveGenerateContrato}
        onDistrato={onGenerateDistrato}
        tipoFormulario={tipoFormulario}
        load={load}
        responsavelIndicacaos={responsavelIndicacaos}
        temContrato={temContrato}
        dadosUsina={dadosUsina}
        responsavelUsina={responsavelUsina}
        responsavelLegal={responsavelComunicacao?.dadosResponsavelLegal}
      >
        <ResponsavelIndicacao
          onSave={onSaveResponsavelIndicacao}
          onChange={onChangeResponsavelIndicacao}
          onUpdate={onUpdateResponsavelIndicacao}
          buttonSave={buttonSaveInformacaoContrato}
          fieldErros={fieldErrosResponsavelIndicacao}
          geradorId={geradorId}
          usina={usina}
          scrollRef={myElementRef}
        />
      </InformacaoContrato>
      <DadosPagamentoGerador
        onSave={onSaveDadosPagamentoGerador}
        onChange={onChangeDadosPagamentoGerador}
        onUpdate={onUpdateDadosPagamentoGerador}
        buttonSave={buttonSaveDadosPagamentoGerador}
        fieldErros={fieldErrosDadosPagamentoGerador}
        informacaoContrato={informacaoContrato}
        geradorId={geradorId}
        usina={usina}
        scrollRef={myElementRef}
        uuidContratoGenerated={uuidContratoGenerated}
        buttonSaveInformacaoContrato={buttonSaveInformacaoContrato}
      />
      <DadosAcesso
        onChange={onChangeDadosAcesso}
        onSave={onSaveDadosAcesso}
        onUpdate={onUpdateDadosAcesso}
        buttonSave={buttonSaveDadosAcesso}
        fieldErros={dadosAcessoFieldsErros}
        geradorId={geradorId}
        usina={usina}
        scrollRef={myElementRef}
        onReset={onResetSenha}
        usinas={usinas}
        dadosUsina={dadosUsina}
        tipoFormulario={tipoFormulario}
        responsavelUsina={responsavelUsina}
        responsavelLegal={responsavelComunicacao?.dadosResponsavelLegal}
      />
    </>
  );
};

export default CadastroGerador;
