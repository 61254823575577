import { useEffect, useState } from "react";
import { ICaptador } from "../../../../../models/Captador";
import * as C from "./style";
import DadosContrato from "./Components/DadosContrato";
import ReponsavelLegal from "./Components/ReponsavelLegal";
import AssociadoManager from "../Util/AssociadoManager";
import { useParams } from "react-router-dom";
import { SkeletonCreationCards } from "../../../../../components/SkeletonLoads/CreationCards";
import LogoCardInput from "../../../../../components/LogoCardInput/LogoCardInput";
import { DicLogo } from "../../../../../models/Logo";

const obterDicLogoInicial = (): DicLogo => {
  return {
    svg: null,
    png: null,
    pdf: null,
  };
};

const EditarAssociado = () => {
  const [captador, setCaptador] = useState<ICaptador | null>(null);
  const [dicLogo, setDicLogo] = useState<DicLogo>(obterDicLogoInicial());

  const { associadoId } = useParams();

  useEffect(() => {
    const getCapacitador = async () => {
      if (!associadoId) return;
      const captador = await AssociadoManager.getCaptadorById(associadoId);
      setCaptador(captador);
    };

    const getLogo = async () => {
      if (!associadoId) return;
      const novoDicLogo = await AssociadoManager.getLogo(associadoId);
      if (novoDicLogo) setDicLogo(novoDicLogo);
    };

    const buscarDados = async () => {
      await Promise.all([getCapacitador(), getLogo()]);
    };
    buscarDados();
  }, [associadoId]);

  const updateResponsavelLegal = async (updateDicLogo: DicLogo) => {
    if (captador) {
      const novoDicLogo = await AssociadoManager.updateCaptadorLogo(
        captador.id,
        updateDicLogo
      );
      if (novoDicLogo) setDicLogo(novoDicLogo);
    }
  };

  const obterKeyDicLogo = () =>
    Object.entries(dicLogo).reduce((acc, [chave, valor]) => {
      if (valor === null) return acc;
      return acc + `${chave}:${"id" in valor ? valor.id : ""}`;
    }, "");

  return captador ? (
    <C.Container>
      <DadosContrato
        captador={captador}
        updateAssociadoDadosContrato={
          AssociadoManager.updateAssociadoDadosContrato
        }
      />
      <ReponsavelLegal
        captador={captador}
        updateResponsavelLegal={AssociadoManager.updateResponsavelLegal}
      />
      <LogoCardInput
        title="Anexo logo do parceiro"
        value={dicLogo}
        updateCaptadorLogo={updateResponsavelLegal}
        dowloadAnexo={AssociadoManager.dowloadAnexo}
        key={obterKeyDicLogo()}
      />
    </C.Container>
  ) : (
    <C.Container>
      <SkeletonCreationCards />
    </C.Container>
  );
};

export default EditarAssociado;
