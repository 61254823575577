import SearchIcon from "@mui/icons-material/Search";
import {
  CircularProgress,
  InputAdornment,
  MenuItem,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { ptBR } from "@mui/x-data-grid/locales";
import * as D from "../../styles/appComponents";
import * as C from "./style";
import React, { useEffect, useState } from "react";
import { EnumStatusLeadColorMap } from "../../enums/EnumStatusLead.enum";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

interface DatatableProps {
  hasButton?: boolean;
  hasSearch?: boolean;
  columns: GridColDef[];
  rows: any;
  pageNumber: number;
  pageSize: number;
  totalPages: number;
  onChangePage?: (value: number) => void;
  onChangePageSize?: (value: number) => void;
  onSearch?: (value: string) => void;
  handleButton?: () => void;
  titleButton?: string;
}

const Datatable: React.FC<DatatableProps> = ({
  hasButton=false,
  hasSearch=true,
  columns,
  rows,
  pageNumber,
  pageSize,
  totalPages,
  onChangePage,
  onChangePageSize,
  onSearch,
  handleButton,
  titleButton
}) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1024);

  const handleChangePage = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    if(onChangePage)
      onChangePage(value);
  };
  
  const handleChangePageSize = (value: number) => {
    if(onChangePageSize)
      onChangePageSize(value);
  };
  const handleSearch = () => {
    setLoading(true);

    if(onSearch)
      onSearch(search);
  };

  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1024);
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    setLoading(false);
  }, [rows]);

  return (
    <C.Wrapper>
      {hasSearch &&
      <C.Header>
        <Select
          size="small"
          value={pageSize}
          onChange={(e) => handleChangePageSize(Number(e.target.value))}
        >
          <MenuItem value={10}>10</MenuItem>
          <MenuItem value={25}>25</MenuItem>
          <MenuItem value={50}>50</MenuItem>
          <MenuItem value={100}>100</MenuItem>
        </Select>
        <Stack direction={"row"} width={"100%"} justifyContent={"right"} spacing={1}>
          {hasButton &&
            <Stack sx={{ width:"25%" }}>
              <D.ContainedButton
                  startIcon={<AddCircleOutlineIcon />}
                  onClick={handleButton}
                >
                  {titleButton}
              </D.ContainedButton>
            </Stack>
          }
          <TextField
            size="small"
            variant="outlined"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            placeholder="Pesquisar"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
          <D.ContainedButton $color="pink" onClick={handleSearch}>
            {loading ? (
              <CircularProgress size={25} color="secondary" />
            ) : (
              <SearchIcon />
            )}
          </D.ContainedButton>
        </Stack>
      </C.Header>
      }

      {rows.length > 0 ? (
        <>
          {!isMobile ? (
            <>
              <D.DefaultTable
                autoHeight
                getRowHeight={() => "auto"}
                disableColumnResize
                disableRowSelectionOnClick
                localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
                rows={rows}
                columns={columns}
                hideFooter
                disableColumnFilter
                disableColumnSelector
                disableColumnMenu
                sx={{
                  "& .MuiDataGrid-cell": {
                    whiteSpace: "normal",
                    lineHeight: "1.5",
                    padding: "5px",
                  },
                }}
              />
              <D.DefaultPagination
                page={pageNumber}
                count={totalPages}
                size={"medium"}
                siblingCount={1}
                showFirstButton
                showLastButton
                onChange={handleChangePage}
                color="primary"
              />
            </>
          ) : (
            <>
              <D.CardDataAreaMobile>
                {rows.map((row: any, index: number) => (
                  <D.CardDataMobile key={index}>
                    {columns.map((column: any, index: number) => (
                      <D.CardDataItemMobile key={index}>
                        <label>{column.headerName}:</label>
                        <p>
                          { column.renderCell? column?.renderCell({ value: row[column.field], row }): row[column.field]}
                        </p>
                      </D.CardDataItemMobile>
                    ))}
                  </D.CardDataMobile>
                ))}
              </D.CardDataAreaMobile>
              <D.DefaultPagination
                page={pageNumber}
                count={totalPages}
                size={"medium"}
                siblingCount={1}
                showFirstButton
                showLastButton
                onChange={handleChangePage}
                color="primary"
              />
            </>
          )}
        </>
      ) : (
        <C.NoDataDiv>Não há dados.</C.NoDataDiv>
      )}
    </C.Wrapper>
  );
};

export default Datatable;
