import { FC, useState } from "react";
import RegisterCard from "../../../../../../components/RegisterCard";
import * as C from "../style";
import { TextField } from "@mui/material";
import MaskDefaultInput from "../../../../../../components/MasDefaultinput/MaskDefaultInput";
import { ICepReturn } from "../../../../../../models/IbgeService";
import {
  ICaptador,
  ICaptadorDadosContrato,
} from "../../../../../../models/Captador";
import { ContainedButton } from "../../../../../../styles/appComponents";

interface IPropsDadosContrato {
  captador: ICaptador;
  updateAssociadoDadosContrato: (
    updateDadosContrato: ICaptadorDadosContrato
  ) => Promise<boolean>;
}

const obterDadosContratoInicial = (captador: ICaptador) => ({
  captadorId: captador.id,
  razaoSocial: captador.razaoSocial ?? "",
  cnpj: captador.cnpj ?? "",
  cep: captador.cep ?? "",
  uf: captador.uf ?? "",
  cidade: captador.cidade ?? "",
  bairro: captador.bairro ?? "",
  logradouro: captador.logradouro ?? "",
  numero: captador.numero ?? "",
  complemento: captador.complemento ?? "",
});

const DadosContrato: FC<IPropsDadosContrato> = ({
  captador,
  updateAssociadoDadosContrato,
}) => {
  const [dadosContrato, setDadosContrato] = useState<ICaptadorDadosContrato>(
    obterDadosContratoInicial(captador)
  );

  const [salvandoDados, setSalvandoDados] = useState<boolean>(false);

  const [editar, setEditar] = useState<boolean>(false);

  const editarDadosContrato = (novosDados: ICaptadorDadosContrato) => {
    if (editar) setDadosContrato(novosDados);
  };

  const handleEditeAssociado = async () => {
    setSalvandoDados(true);
    await updateAssociadoDadosContrato(dadosContrato);
    setEditar(false);
    setSalvandoDados(false);
  };

  return (
    <RegisterCard title="Dados do contrato">
      <C.FWStack direction={"row"} spacing={2}>
        <TextField
          label="Razão social"
          required
          fullWidth
          onChange={(e) =>
            editarDadosContrato({
              ...dadosContrato,
              razaoSocial: e.target.value,
            })
          }
          value={dadosContrato.razaoSocial}
          inputProps={{ readOnly: !editar }}
        />
        <MaskDefaultInput
          label="CNPJ"
          type="CNPJ"
          onChange={(cnpj) =>
            editarDadosContrato({ ...dadosContrato, cnpj: cnpj as string })
          }
          value={dadosContrato.cnpj ?? ""}
          readonly={!editar}
        />
        <MaskDefaultInput
          label="CEP"
          type="CEP"
          onChange={(cep) => {
            const cepReturn = cep as ICepReturn;
            editarDadosContrato({
              ...dadosContrato,
              ...cepReturn,
              cidade: cepReturn.localidade ?? dadosContrato.cidade,
            });
          }}
          value={dadosContrato.cep ?? ""}
          readonly={!editar}
        />
        <TextField
          required
          label="UF"
          onChange={(e) =>
            editarDadosContrato({ ...dadosContrato, uf: e.target.value })
          }
          value={dadosContrato.uf}
          inputProps={{ readOnly: !editar }}
        />
      </C.FWStack>
      <C.FWStack direction={"row"} spacing={2}>
        <TextField
          label="Cidade"
          required
          fullWidth
          onChange={(e) =>
            editarDadosContrato({ ...dadosContrato, cidade: e.target.value })
          }
          value={dadosContrato.cidade}
          inputProps={{ readOnly: !editar }}
        />
        <TextField
          label="Bairro"
          required
          fullWidth
          onChange={(e) =>
            editarDadosContrato({ ...dadosContrato, bairro: e.target.value })
          }
          value={dadosContrato.bairro}
          inputProps={{ readOnly: !editar }}
        />
        <TextField
          label="Logradouro"
          required
          fullWidth
          onChange={(e) =>
            editarDadosContrato({
              ...dadosContrato,
              logradouro: e.target.value,
            })
          }
          value={dadosContrato.logradouro}
          inputProps={{ readOnly: !editar }}
        />
        <TextField
          label="Número"
          type="number"
          required
          sx={{ width: "40%" }}
          onChange={(e) =>
            editarDadosContrato({ ...dadosContrato, numero: e.target.value })
          }
          value={dadosContrato.numero}
          inputProps={{ readOnly: !editar }}
        />
        <TextField
          label="Complemento"
          required
          fullWidth
          onChange={(e) =>
            editarDadosContrato({
              ...dadosContrato,
              complemento: e.target.value,
            })
          }
          value={dadosContrato.complemento}
          inputProps={{ readOnly: !editar }}
        />
      </C.FWStack>
      <C.FWStack direction={"row"} spacing={2} justifyContent={"flex-end"}>
        <ContainedButton
          $color="yellow"
          onClick={() => setEditar(true)}
          disabled={editar || salvandoDados}
        >
          Editar
        </ContainedButton>
        <ContainedButton
          $color="pink"
          onClick={handleEditeAssociado}
          disabled={!editar || salvandoDados}
        >
          Salvar
        </ContainedButton>
      </C.FWStack>
    </RegisterCard>
  );
};

export default DadosContrato;
