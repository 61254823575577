import { isEnumDeclaration, isEnumMember } from "typescript";

export interface Validate<T>{
    field:keyof T;
    validate: (value:any, model:T, addError:(message:string) => void) => void;
}

export interface ErrorMessage{
    ref:string;
    message:string;
    index?: number | null;
}

export abstract class AbstractValidation<T>{
    constructor(){}


    errorMessage(model:T, index?:number | null): ErrorMessage[] | null | undefined {
        const erros:ErrorMessage[] | null = []
        const validate = this.getValidate()

        validate.forEach((item) => {
            item.validate(model[item.field], model, (message:string) => {
                erros.push({ref:item.field as string, message, index})
            })
        })

        return erros.length === 0? null: erros
    }

    abstract getValidate():Validate<T>[];
}