import * as G from "../../style";
import { TextField } from "@mui/material";
import * as D from "../../../../../../../styles/appComponents";

import { useEffect, useState } from "react";
import { iDadosAcesso, iDadosAcessoColaborador } from "../../../../../../../models/Colaborador/cadastros/DadosAcesso";
import { ColaboradorByIdResponse } from "../../../../../../../models/Colaborador/Colaborador";
import { ErrorMessage } from "../../../../../../../utils/AbstractValidation";
import RegisterCard from "../../../../../../../components/RegisterCard";
import { handleStyleFieldError } from "../../../../../../../utils/verifyFieldError";
import { EnumTipoDocumento } from "../../../../../../../enums/EnumTipoDocumento.enum";
import { DadosAcessoColaboradorEnum } from "../../../../../../../enums/Gerador/EnumInputModel.ts/DadosAcessoEnum";

export interface PropsDadosAcesso {
    onSave: (data: iDadosAcessoColaborador) => void;
    onChange: (data: iDadosAcessoColaborador) => void;
    onUpdate: () => void;
    onReset: (usuarioId: string | null | undefined) => void;
    buttonSave: boolean;
    fieldErros: ErrorMessage[] | null;
    colaboradorId: string | undefined;
    colaborador: ColaboradorByIdResponse | undefined;
    load: { status: boolean, section: string };
}

export const DadosAcessoColaborador: React.FC<PropsDadosAcesso> = (
    {
        onSave,
        onUpdate,
        onChange,
        onReset,
        fieldErros,
        colaboradorId,
        colaborador,
        load,
        buttonSave,
    }
) => {
    const formDadosAcessoModel: iDadosAcessoColaborador = {
        nomedadosacesso: colaborador?.nome || null,
        email: colaborador?.emailParaContato || null,
        telefone: colaborador?.celularParaContato || null,
        login: colaborador?.documentos
        ?.find(x => x.tipoDocumento === EnumTipoDocumento.CPF)?.numero || null,
        whatsappdadosacesso: !colaborador?.whatsappDadosAcesso? colaborador?.celularParaContato: colaborador?.whatsappDadosAcesso
    };
    const [dadosAcesso, setDadosAcesso] = useState<iDadosAcessoColaborador>(formDadosAcessoModel);
    const sendOnSave = (): void => onSave(dadosAcesso);
    const sendOnUpdate = (): void => onUpdate();
    const sendOnChange = (update: iDadosAcessoColaborador): void => onChange(update);
    const sendOnReset = (usuarioId: string | null | undefined): void => onReset(usuarioId);

    useEffect(() => {
        if(colaboradorId){
            const formDadosAcessoModel: iDadosAcessoColaborador = {
                nomedadosacesso: colaborador?.nome || null,
                email: colaborador?.emailParaContato || null,
                telefone: colaborador?.celularParaContato || null,
                login: colaborador?.documentos
                ?.find(x => x.tipoDocumento === EnumTipoDocumento.CPF)?.numero || null,
                whatsappdadosacesso: !colaborador?.whatsappDadosAcesso? colaborador?.celularParaContato: colaborador?.whatsappDadosAcesso
            };

            setDadosAcesso(formDadosAcessoModel);
        }

    }, [colaboradorId, colaborador])

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const name = event?.target?.name?.toLocaleLowerCase();
        const value = event?.target?.value;

        setDadosAcesso((state: iDadosAcessoColaborador) => {
            sendOnChange({ ...state, [name]: value });
            return { ...state, [name]: value };
        });
    };

    return (
    <RegisterCard title="Dados de acesso">
        <G.FWStack direction={"row"} spacing={2}>
          <TextField
            inputProps={{ readOnly: true }}
            sx={handleStyleFieldError(DadosAcessoColaboradorEnum.NOMEDADOSACESSO, fieldErros, { width:"33%" })}
            name={DadosAcessoColaboradorEnum.NOMEDADOSACESSO}
            onChange={handleInputChange}
            value={dadosAcesso?.nomedadosacesso ?? ""}
            label="Nome"
            fullWidth
          />
          <TextField
            inputProps={{ readOnly: true }}
            sx={handleStyleFieldError(DadosAcessoColaboradorEnum.TELEFONE, fieldErros, { width:"33%" })}
            name={DadosAcessoColaboradorEnum.TELEFONE}
            onChange={handleInputChange}
            value={dadosAcesso?.telefone || ""}
            label="Celular"
            fullWidth
          />
          <TextField
            inputProps={{ readOnly: true }}
            sx={handleStyleFieldError(DadosAcessoColaboradorEnum.LOGIN, fieldErros, { width:"33%" })}
            name={DadosAcessoColaboradorEnum.LOGIN}
            value={dadosAcesso?.login ?? ""}
            label="Login"
            fullWidth
          />
        </G.FWStack>
        <G.FWStack direction={"row"} spacing={2}>
          <TextField
            inputProps={{ readOnly: buttonSave }}
            name={DadosAcessoColaboradorEnum.WHATSAPPDADOSACESSO}
            value={dadosAcesso?.whatsappdadosacesso ?? ""}
            onChange={handleInputChange}
            label="whatsapp"
            sx={{ width:"33%" }}
          />
        </G.FWStack>
        <G.FWStack direction={"row"} spacing={2} justifyContent={"flex-end"}>
          <D.ContainedButton
            disabled={buttonSave}
            $color="lightpink"
            onClick={() => sendOnReset(colaborador?.usuarioId)}
          >
            Reset Senha
          </D.ContainedButton>
          <D.ContainedButton
            disabled={!buttonSave}
            onClick={sendOnUpdate}
            $color="yellow"
          >
            Editar
          </D.ContainedButton>
          <D.ContainedButton disabled={buttonSave} onClick={sendOnSave}>
            Salvar
          </D.ContainedButton>
        </G.FWStack>
      </RegisterCard>
    )
}