import AddIcon from "@mui/icons-material/Add";
import { Chip, MenuItem, TextField } from "@mui/material";
import { FC, RefObject, useEffect, useState } from "react";
import { MaskCurrencyInput } from "../../../../../components/MakCurrencyInput";
import RegisterCard from "../../../../../components/RegisterCard";
import { DadosPagamentoGeradorEnum } from "../../../../../enums/Gerador/EnumInputModel.ts/DadosPagamentoGeradorEnum";
import { EnumTipoChavePix } from "../../../../../enums/Gerador/EnumTipoChavePix.enum";
import { EnumTipoConexao } from "../../../../../enums/Gerador/EnumTipoConexao.enum";
import {
  DadosPagamentos,
  IDadosGerador,
} from "../../../../../models/Gerador/Cadastro/DadosPagamentoGerador";
import { IInformacaoContrato } from "../../../../../models/Gerador/Cadastro/InformacaoContrato";
import { UsinaTipoPessoaJuridica } from "../../../../../models/Gerador/Usina";
import * as D from "../../../../../styles/appComponents";
import { ErrorMessage } from "../../../../../utils/AbstractValidation";
import { addOneDay } from "../../../../../utils/dateFormatter";
import { calcValues } from "../../../../../utils/moneyFormatter";
import * as C from "../style";
export interface PropsDadosPagamentoGerador {
  onSave: (data: IDadosGerador) => void;
  onChange: (data: IDadosGerador) => void;
  onUpdate: (data: IDadosGerador) => void;
  buttonSave: boolean;
  fieldErros: ErrorMessage[] | null;
  geradorId: string | null | undefined;
  usina: UsinaTipoPessoaJuridica | undefined;
  scrollRef: RefObject<HTMLDivElement> | null;
  informacaoContrato: IInformacaoContrato | undefined;
  uuidContratoGenerated: string | null;
  buttonSaveInformacaoContrato: boolean;
}

export const DadosPagamentoGerador: FC<PropsDadosPagamentoGerador> = ({
  onSave,
  onChange,
  onUpdate,
  buttonSave,
  fieldErros,
  geradorId,
  usina,
  scrollRef,
  informacaoContrato,
  uuidContratoGenerated,
  buttonSaveInformacaoContrato,
}) => {
  const formDadosGerador: IDadosGerador = {
    agenciapagamentogerador: null,
    cnpjfavorecidopagamentogerador: null,
    bancopagamentogerador: null,
    tipochavepixpagamentogerador: 0,
    favorecidopagamentogerador: null,
    contapagamentogerador: null,
    chavepixpagamentogerador: null,
    dadospagamentos: [],
  };
  const [dadosGerador, setDadosGerador] =
    useState<IDadosGerador>(formDadosGerador);
  const sendOnChange = (update: IDadosGerador): void => onChange(update);
  const sendOnSave = () =>
    onSave({ ...dadosGerador, dadospagamentos: dadosPagamentos });
  const sendOnUpdate = () =>
    onUpdate({ ...dadosGerador, dadospagamentos: dadosPagamentos });

  const formDadosPagamentos: DadosPagamentos = {
    tipoconexao: 0,
    descontotarifapercentual: informacaoContrato?.porcentagemdescontotarifa,
    tarifagerador: undefined,
    tarifavigente: undefined,
    datainicial: undefined,
    datafinal: undefined,
  };

  const [dadosPagamentos, setDadosPagamentos] = useState<DadosPagamentos[]>([
    formDadosPagamentos,
  ]);

  useEffect(() => {
    if (geradorId) {
      const formDadosGeradorModel: IDadosGerador = {
        agenciapagamentogerador: usina?.agenciaPagamentoGerador || null,
        cnpjfavorecidopagamentogerador:
          usina?.cnpjFavorecidoPagamentoGerador || null,
        bancopagamentogerador: usina?.bancoPagamentoGerador || null,
        tipochavepixpagamentogerador:
          usina?.tipoChavePixPagamentoGerador as EnumTipoChavePix,
        favorecidopagamentogerador: usina?.favorecidoPagamentoGerador || null,
        contapagamentogerador: usina?.contaPagamentoGerador || null,
        chavepixpagamentogerador: usina?.chavePixPagamentoGerador || null,
        dadospagamentos: (usina?.tarifa || []).map((x) => {
          return {
            id: x?.id,
            tipoconexao: x.tipoConexao,
            descontotarifapercentual: x.descontoTarifaPercentual,
            tarifagerador: x.tarifaGerador,
            tarifavigente: x.tarifaVigente,
            datainicial: x.dataInicial,
            datafinal: x.dataFinal,
          };
        }),
      };
      setDadosGerador(formDadosGeradorModel);
      setDadosPagamentos(formDadosGeradorModel?.dadospagamentos || []);
    }
  }, [geradorId, usina]);

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const name = event.target.name.toLocaleLowerCase();
    const value = event.target.value;

    setDadosGerador((state: any) => {
      sendOnChange({ ...state, [name]: value });
      return { ...state, [name]: value };
    });
  };

  const handleChangeDadosPagamentos = (index: number) => (event: any) => {
    setDadosPagamentos((initialState: DadosPagamentos[]) => {
      const name = event.target.name.toLowerCase();
      const value = event.target.value;

      const newForms = [...initialState];
      newForms[index] = { ...newForms[index], [name]: value };
      sendOnChange({ ...dadosGerador, dadospagamentos: newForms });

      return newForms;
    });
  };

  const addNewForm = () => {
    if (!geradorId)
      setDadosPagamentos((state) => [...state, formDadosPagamentos]);
    else
      setDadosPagamentos((state: DadosPagamentos[]) => [
        ...state,
        {
          ...formDadosPagamentos,
          descontotarifapercentual: usina?.porcentagemDescontoTarifa,
        },
      ]);

    handleChangeDadosPagamentos(dadosPagamentos?.length - 1)({
      target: {
        name: DadosPagamentoGeradorEnum.DATAFINAL,
        value: new Date().toLocaleDateString("en-CA"),
      },
    });

    const nextDay = addOneDay(
      dadosPagamentos[dadosPagamentos?.length - 1]?.datainicial ?? ""
    );

    handleChangeDadosPagamentos(dadosPagamentos?.length)({
      target: {
        name: DadosPagamentoGeradorEnum.DATAINICIAL,
        value: nextDay.toString()?.split("T")[0],
      },
    });
  };

  return (
    <RegisterCard title="Dados para pagamento do gerador">
      {
        <>
          {!!usina?.dataAssinaturaContrato && (
            <>
              {(dadosPagamentos || []).map(
                (x: DadosPagamentos, index: number) => (
                  <C.FWStack direction={"row"} spacing={2}>
                    <TextField
                      inputProps={{ readOnly: buttonSave }}
                      name={DadosPagamentoGeradorEnum.TIPOCONEXAO}
                      onChange={handleChangeDadosPagamentos(index)}
                      value={x?.tipoconexao ?? ""}
                      label="Tipo de conexão"
                      select
                      required
                      fullWidth
                    >
                      {Object.entries(EnumTipoConexao)
                        .filter(([value, key]) => isNaN(Number(key)))
                        .map(([value, key]) => (
                          <MenuItem key={key} value={Number(value)}>
                            {key}
                          </MenuItem>
                        ))}
                    </TextField>
                    <MaskCurrencyInput
                      label="Desconto de tarifa percentual"
                      readonly={true}
                      name={DadosPagamentoGeradorEnum.DESCONTOTARIFAPERCENTUAL}
                      value={x.descontotarifapercentual}
                      placeholder="00,00%"
                      defaultValue={0}
                      decimalsLimit={2}
                      suffix="%"
                    />
                    <MaskCurrencyInput
                      label="Tarifa vigente"
                      readonly={index === 0 ? true : buttonSave}
                      name={DadosPagamentoGeradorEnum.TARIFAVIGENTE}
                      onValueChange={(newValue, name, values) =>
                        handleChangeDadosPagamentos(index)({
                          target: { name, value: newValue },
                        })
                      }
                      value={x.tarifavigente}
                      placeholder="00,00"
                      defaultValue={0}
                      decimalsLimit={2}
                    />
                    <MaskCurrencyInput
                      label="Tarifa do gerador"
                      readonly
                      name={DadosPagamentoGeradorEnum.TARIFAGERADOR}
                      onValueChange={(newValue, name, values) =>
                        handleChangeDadosPagamentos(index)({
                          target: { name, value: newValue },
                        })
                      }
                      value={
                        index === 0 && !geradorId
                          ? calcValues(
                              informacaoContrato?.tarifavigentecontrato,
                              informacaoContrato?.porcentagemdescontotarifa,
                              "%",
                              4
                            )
                          : calcValues(
                              x?.tarifavigente,
                              x?.descontotarifapercentual,
                              "%",
                              4
                            )
                      }
                      placeholder="00,00"
                      defaultValue={0}
                      decimalsLimit={4}
                    />
                    <D.DateTextfield
                      inputProps={{ readOnly: true }}
                      name={DadosPagamentoGeradorEnum.DATAFINAL}
                      onChange={handleChangeDadosPagamentos(index)}
                      value={
                        ((x?.datainicial ?? "").toString() ?? "")?.split("T")[0]
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                      type="date"
                      label="Data inicial"
                      required
                      fullWidth
                    />
                    <D.DateTextfield
                      inputProps={{ readOnly: true }}
                      name={DadosPagamentoGeradorEnum.DATAINICIAL}
                      onChange={handleChangeDadosPagamentos(index)}
                      value={(x?.datafinal ?? "").toString()?.split("T")[0]}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      type="date"
                      label="Data final"
                      required
                      fullWidth
                    />
                  </C.FWStack>
                )
              )}
            </>
          )}
        </>
      }

      <C.FWStack direction={"row"} spacing={2} justifyContent={"flex-end"}>
        <D.ContainedButton
          startIcon={<AddIcon />}
          onClick={addNewForm}
          disabled={buttonSave || !!!usina?.dataAssinaturaContrato}
          $color="pink"
        >
          Nova tarifa
        </D.ContainedButton>
      </C.FWStack>
      <C.FWStack direction={"row"} spacing={16}>
        <C.MWDiv>
          <C.Subtitle>Dados bancários*</C.Subtitle>
          <C.Text>Insira seus dados bancários</C.Text>
          <C.FWStack direction={"row"} spacing={2}>
            <TextField
              inputProps={{ readOnly: buttonSave }}
              onChange={handleInputChange}
              name={DadosPagamentoGeradorEnum.AGENCIAPAGAMENTOGERADOR}
              value={dadosGerador?.agenciapagamentogerador || ""}
              type="number"
              label="Agência"
              required
              fullWidth
            />
            <TextField
              inputProps={{ readOnly: buttonSave }}
              onChange={handleInputChange}
              name={DadosPagamentoGeradorEnum.CONTAPAGAMENTOGERADOR}
              value={dadosGerador?.contapagamentogerador || ""}
              type="number"
              label="Conta"
              required
              fullWidth
            />
          </C.FWStack>
          <C.FWStack direction={"row"} spacing={2}>
            <TextField
              inputProps={{ readOnly: buttonSave }}
              onChange={handleInputChange}
              name={DadosPagamentoGeradorEnum.FAVORECIDOPAGAMENTOGERADOR}
              value={dadosGerador?.favorecidopagamentogerador || ""}
              label="Favorecido"
              required
              fullWidth
              onKeyDown={(event) => {
                const regex = /[0-9]/;
                if (regex.test(event.key)) {
                  event.preventDefault();
                }
              }}
            />
            <TextField
              inputProps={{ readOnly: buttonSave }}
              onChange={handleInputChange}
              name={DadosPagamentoGeradorEnum.BANCOPAGAMENTOGERADOR}
              value={dadosGerador?.bancopagamentogerador || ""}
              label="Banco"
              required
              fullWidth
            />
          </C.FWStack>
          <C.FWStack direction={"row"} spacing={2}>
            <TextField
              inputProps={{ readOnly: buttonSave }}
              type="number"
              onChange={handleInputChange}
              name={DadosPagamentoGeradorEnum.CNPJFAVORECIDOPAGAMENTOGERADOR}
              value={dadosGerador?.cnpjfavorecidopagamentogerador || ""}
              label="CNPJ do banco"
              required
              fullWidth
            />
            <C.FWDiv></C.FWDiv>
          </C.FWStack>
        </C.MWDiv>
        <C.MWDiv>
          <C.Subtitle>Pix*</C.Subtitle>
          <C.Text>Insira sua chave pix</C.Text>
          <C.FWStack direction={"row"} spacing={2}>
            <TextField
              type={
                dadosGerador.tipochavepixpagamentogerador ===
                  EnumTipoChavePix.CELULAR ||
                dadosGerador.tipochavepixpagamentogerador ===
                  EnumTipoChavePix.ALEATORIO ||
                dadosGerador.tipochavepixpagamentogerador ===
                  EnumTipoChavePix.CPF
                  ? "number"
                  : "text"
              }
              inputProps={{ readOnly: buttonSave }}
              onChange={handleInputChange}
              name={DadosPagamentoGeradorEnum.CHAVEPIXPAGAMENTOGERADOR}
              value={dadosGerador?.chavepixpagamentogerador || ""}
              label="Chave PIX"
              required
              fullWidth
            />
          </C.FWStack>
          <C.Text>Qual dado você quer usar?</C.Text>
          <C.FWStack direction={"row"} spacing={2}>
            {Object.entries(EnumTipoChavePix)
              .filter(([key, value]) => isNaN(Number(key)))
              .map(([key, value]) => (
                <Chip
                  disabled={buttonSave}
                  label={key}
                  onClick={() =>
                    handleInputChange({
                      target: {
                        name: DadosPagamentoGeradorEnum.TIPOCHAVEPIXPAGAMENTOGERADOR,
                        value,
                      },
                    } as React.ChangeEvent<HTMLInputElement>)
                  }
                  color={
                    dadosGerador?.tipochavepixpagamentogerador === value
                      ? "primary"
                      : "default"
                  }
                />
              ))}
          </C.FWStack>
        </C.MWDiv>
      </C.FWStack>
      <C.FWStack direction={"row"} spacing={2} justifyContent={"flex-end"}>
        <D.ContainedButton
          disabled={!buttonSave}
          onClick={sendOnUpdate}
          $color="yellow"
        >
          Editar
        </D.ContainedButton>
        <D.ContainedButton disabled={buttonSave} onClick={sendOnSave}>
          Salvar
        </D.ContainedButton>
      </C.FWStack>
    </RegisterCard>
  );
};
