import React from "react";
import * as D from "../../../../../styles/appComponents";
import * as C from "../style";
import DownloadIcon from "@mui/icons-material/Download";
import CloseIcon from "@mui/icons-material/Close";
import { ColaboradorByIdResponse } from "../../../../../models/Colaborador/Colaborador";
import { EnumTipoDocumento } from "../../../../../enums/EnumTipoDocumento.enum";
import { dateFormatter } from "../../../../../utils/dateFormatter";
import { Modal } from "@mui/material";
import { IAnexoDTO } from "../../../../../models/Anexo";
import { GridColDef } from "@mui/x-data-grid";
import Datatable from "../../../../../components/Datatable";
import { GetUrl } from "../../../../../services/api/AnexoService";
import { toastMessage } from "../../../../../utils/toastMessage";
import { EnumTipoAnexo } from "../../../../../enums/EnumTipoAnexo.enum";

export interface PropsModalInfoColaborador{
    handleCloseModalSeeMore: () => void;
    colaborador:ColaboradorByIdResponse | undefined;
    openModalSeeMore: boolean;
    anexos: IAnexoDTO[];
}

export const ModalInfoColaborador:React.FC<PropsModalInfoColaborador> = (
    {
        handleCloseModalSeeMore, 
        colaborador, 
        openModalSeeMore, 
        anexos
    }
) => {

    const columns: GridColDef[] = [
        { field: "idReferencial", headerName: "ID", flex: 1 },
        { field: "nomeResponsavel", headerName: "Responsável", flex: 1 },
        { field: "assunto", headerName: "Assunto", flex: 1 },
        { field: "data", headerName: "Data Upload", flex: 1 },
        {
          field: "anexo",
          headerName: "Anexo",
          flex: 1,
          align: "center",
          headerAlign: "center",
          renderCell: (params) => (
            <D.GridField>
              <C.ModalGridDownloadButton
              onClick={() => downloadConta(params?.row?.id)}
                startIcon={<DownloadIcon />}
                variant="outlined"
              >
                Baixar
              </C.ModalGridDownloadButton>
            </D.GridField>
          ),
        },
    ];
    
    const rows = anexos.filter(f => f.enumTipoAnexo !== EnumTipoAnexo.fotoPerfilColaborador).map((anexo:IAnexoDTO) => {
        return {
            id: anexo?.id,
            idReferencial: anexo?.idReferencial,
            nomeResponsavel: anexo?.nomeResponsavel,
            assunto: anexo.titulo,
            data: dateFormatter(anexo.createdAt || ""),
            anexo: 0
          }
    })

    const downloadConta = async (id: string | null) => {
        if(id)
        try {
            const {data} = await GetUrl(id);
            const fileUrl = data;
    
            const link = document.createElement("a");
            link.href = fileUrl;
            link.target = "_blank";
            link.rel = "noopener noreferrer";
    
            document.body.appendChild(link);
            link.click();
    
            document.body.removeChild(link);
            
        } catch (error) {
            toastMessage("warning","Erro ao baixar o anexo")
        }
    };

    return (
        <Modal
            sx={{ width: "100%" }}
            open={openModalSeeMore}
            onClose={handleCloseModalSeeMore}
            aria-labelledby="modal-details"
            aria-describedby="modal-details"
        >
            <C.ModalContainer>
                <C.ModalArea>
                    <C.ModalCard>
                        <C.ModalHeader>
                            <h2>Colaborador - 9591</h2>
                            <C.CloseButton
                                aria-label="close"
                                onClick={handleCloseModalSeeMore}
                            >
                                <CloseIcon />
                            </C.CloseButton>
                        </C.ModalHeader>
                        <C.ModalLine>
                            <C.ModalItem>
                                <label>Nome:</label>
                                <p>{colaborador?.nome}</p>
                            </C.ModalItem>
                            <C.ModalItem>
                                <label>CPF:</label>
                                <p>{colaborador?.documentos?.find(x => x.tipoDocumento === EnumTipoDocumento.CPF)?.numero}</p>
                            </C.ModalItem>
                            <C.ModalItem>
                                <label>RG:</label>
                                <p>{colaborador?.documentos?.find(x => x.tipoDocumento === EnumTipoDocumento.RG)?.numero}</p>
                            </C.ModalItem>
                        </C.ModalLine>
                        <C.ModalLine>
                            <C.ModalItem>
                                <label>Data de nascimento:</label>
                                <p>{dateFormatter(colaborador?.dataNascimento || "")}</p>
                            </C.ModalItem>
                            <C.ModalItem>
                                <label>Data de admissão:</label>
                                <p>{dateFormatter(colaborador?.dataAdmissao || "")}</p>
                            </C.ModalItem>
                            <C.ModalItem></C.ModalItem>
                            <C.ModalItem></C.ModalItem>
                        </C.ModalLine>
                    </C.ModalCard>
                    <C.ModalCard>
                        <C.ModalHeader>
                            <h2>Documentos/exames</h2>
                        </C.ModalHeader>
                        <Datatable
                            hasButton={false}
                            hasSearch={false}
                            columns={columns}
                            rows={rows}
                            pageNumber={0}
                            totalPages={0}
                            pageSize={0}
                        />
                    </C.ModalCard>
                </C.ModalArea>
            </C.ModalContainer>
        </Modal>
    );
}