export enum EnumEstadoCivil {
    solteiro = 0,
    casado = 1,
    separado = 2,
    divorciado = 3,
    viuvo = 4,
  }
  
  export const EnumEstadoCivilMap = [
    {
        name:"Solteiro",
        value:EnumEstadoCivil.solteiro
    },
    {
        name:"Casado",
        value:EnumEstadoCivil.casado
    },
    {
        name:"Separado",
        value:EnumEstadoCivil.separado
    },
    {
        name:"Divorciado",
        value:EnumEstadoCivil.divorciado
    },
    {
        name:"Viúvo",
        value:EnumEstadoCivil.viuvo
    }
  ];
  