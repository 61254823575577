import {MenuItem, TextField,} from "@mui/material";
import React, {useEffect, useState} from "react";
import * as C from "../../Colaborador/Colaborador/Cadastro/style";
import RegisterCard from "../../../../components/RegisterCard";
import {handleStyleFieldError} from "../../../../utils/verifyFieldError";
import {ErrorMessage} from "../../../../utils/AbstractValidation";
import {calcValues, formatValueFloatInString,} from "../../../../utils/moneyFormatter";
import {MaskCurrencyInput} from "../../../../components/MakCurrencyInput";
import * as D from "../../../../styles/appComponents";
import PublishIcon from "@mui/icons-material/Publish";
import ButtonFile from "../../../../components/ButtonFile";
import {EnumTipoPagamento} from "../../../../enums/EnumTipoPagamento";
import {AxiosError, AxiosResponse} from "axios";
import {DisableContaComprovante, GetConta} from "../../../../services/api/ContaService";
import {toastMessage} from "../../../../utils/toastMessage";
import {NovoPagamentoEnum} from "../../../../enums/EnumOrdemPagamento";
import {INovoPagamento} from "../../../../models/OrdemPagamento";
import {IContaOrdemPagamento} from "../../../../models/Conta";
import {DataGrid, GridCellParams, GridColDef} from "@mui/x-data-grid";
import {ptBR} from "@mui/x-data-grid/locales";
import * as anexoServices from "../../../../services/api/AnexoService";
import Swal from "sweetalert2";
import * as services from "../../../../services/api/LogService";
import {ILog} from "../../../../models/Log";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import DownloadIcon from "@mui/icons-material/Download";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import {FormatDate} from "../../../../utils/dateFormatter";

type NovoPagamentoProps = {
  onSave: (data: INovoPagamento) => void;
  onChange: (data: INovoPagamento) => void;
  callbackExit: () => void;
  fieldErros: ErrorMessage[] | null;
  ordemPagamento: IContaOrdemPagamento;
  id?: string | null;
}

const InserirNovoPagamento: React.FC<NovoPagamentoProps> = (props: NovoPagamentoProps) => {
  //region Variáveis
  const {onSave, onChange, fieldErros, id, callbackExit, ordemPagamento} = props;
  const paginationModel = {page: 0, pageSize: 5};
  const [hideEditComprovante, setHideEditComprovante] = useState<boolean>(false);
  const [tableData, setTableData] = useState<{
    id: string;
    responsavel: string;
    assunto: string;
    data: string;
    descricao: string;
    comprovanteNome: string;
  }[]>([]);
  const [novoPagamento, setNovoPagamento] = useState<INovoPagamento>({
    data: null,
    tipoPagamento: null,
    valor: null,
    energiaTotalGd: null,
    ordemPagamentoId: null,
    comprovanteNome: null,
    assunto: null,
    descricao: null,
    comprovante: null,
    cliente: null,
    comprovanteId: null,
  });
  const columns: GridColDef[] = [
    {field: "responsavel", headerName: "Responsável", flex: 1},
    {
      field: "assunto",
      headerName: "Assunto",
      flex: 1,
      renderCell: (params: GridCellParams<ILog>) => (
        <D.GridField>
          {params.row.assunto}
          <Tooltip title={params.row.descricao} arrow>
            <IconButton>
              <InfoOutlinedIcon/>
            </IconButton>
          </Tooltip>
        </D.GridField>
      ),
    },
    {
      field: "data",
      headerName: "Data",
      flex: 1,
      renderCell: (params: any) => (
        <D.GridField>
          {params.value != "" ? new Date(params.value.slice(0, 10)).toLocaleDateString() : ""}
        </D.GridField>
      ),
    },
    {
      field: "anexo",
      headerName: "Anexo",
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params: GridCellParams<ILog>) => (
        <D.GridField style={{justifyContent: "flex-end"}}>
          <D.ModalGridEditButton
            variant="outlined"
            size="small"
            startIcon={<EditIcon/>}
            onClick={() => handleEditButton(params.row.id)}
          >
            Editar
          </D.ModalGridEditButton>
        </D.GridField>
      ),
    },
    {
      field: "comprovanteNome",
      headerName: "Baixar",
      flex: 1,
      renderCell: (params: any) => (
        <D.GridField>
          {params.value && (
            <D.ModalGridDownloadButton
              startIcon={<DownloadIcon/>}
              size="small"
              variant="outlined"
              onClick={() => downloadConta()}
            >
              Baixar
            </D.ModalGridDownloadButton>
          )}
        </D.GridField>
      ),
    },
    {
      field: "remover",
      headerName: "Remover",
      flex: 1,
      renderCell: (params: any) => (
        <D.GridField>
          <D.ModalGridDeleteButton
            variant="outlined"
            size="small"
            startIcon={<DeleteIcon/>}
            onClick={() => handleDeleteLog()}
          >
            Deletar
          </D.ModalGridDeleteButton>
        </D.GridField>
      ),
    },
  ];
  const sendOnChange: (update: INovoPagamento) => void = (update: INovoPagamento): void => onChange(update);
  const sendOnSave: (update: INovoPagamento) => void = (update: INovoPagamento): void => {
    setHideEditComprovante(false);
    onSave(update)
  };
  //endregion

  //region Services
  const getConta: (id: string) => Promise<void> = async (id: string) => {
    try {
      const response: AxiosResponse<INovoPagamento, any> = await GetConta(id);

      setNovoPagamento((state: INovoPagamento) => {
        return {
          ...state, ...response.data,
          valor: formatValueFloatInString(response.data.valor as number),
          energiaTotalGd: formatValueFloatInString(response.data.energiaTotalGd as number)
        }
      });

      if (![null, ""].includes(response.data.comprovanteNome) || ![null, ""].includes(response.data.assunto) || ![null, ""].includes(response.data.descricao)) {
        setTableData([{
          id: "",
          responsavel: response.data.cliente ?? "",
          assunto: response.data.assunto ?? "",
          data: response.data.data ?? "",
          descricao: response.data.descricao ?? "",
          comprovanteNome: response.data.comprovanteNome ?? ""
        }
        ]);

        console.log(tableData )
      } else {
        setHideEditComprovante(true);
      }

    } catch (e: unknown) {
      toastMessage("error", "Erro ao tentarmos pegar a conta");
    }
  }

  const downloadConta: () => Promise<void> = async () => {
    await anexoServices
      .GetUrl(novoPagamento.comprovanteId!)
      .then((response) => {
        const fileUrl = response.data;

        const link = document.createElement("a");
        link.href = fileUrl;
        link.target = "_blank";
        link.rel = "noopener noreferrer";

        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
      })
      .catch((e: AxiosError) => {
        toastMessage("error", e.response
          ? String(e.response?.data)
          : "Houve um erro ao pegar os dados.");
      });
  };

  const deleteLog: () => Promise<void> = async () => {
    await DisableContaComprovante(id!)
      .then(() => {
        setHideEditComprovante(true);
        novoPagamento.comprovanteNome = "";
        novoPagamento.comprovante = null;
        setTableData([]);
        getConta(id!);
        toastMessage("success", "Deletado com sucesso!");
      })
      .catch((e: AxiosError) => {
        toastMessage("error", e.response
          ? String(e.response?.data)
          : "Houve um erro ao deletar o log.");
      });
  };

  const handleDeleteLog: () => Promise<void> = async () => {
    Swal.fire({
      title: "Você tem certeza?",
      text: "Você não podera reverter esta ação!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sim, deletar!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        await deleteLog();
      }
    });
  };
  //endregion

  //region UI
  const handleChangeFile: (file: File) => void = (file: File): void => {
    handleInputChange({
      target: {
        name: NovoPagamentoEnum.COMPROVANTE,
        value: file
      }
    } as unknown as React.ChangeEvent<HTMLInputElement>)
  }

  const handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const name: string = event.target.name;
    const value: string = event.target.value;

    setNovoPagamento((state: INovoPagamento) => {
      const updatedState = {
        ...state,
        [name]: value,
      };
      sendOnChange({
        ...updatedState,
        valor: updatedState.valor,
        energiaTotalGd: updatedState.energiaTotalGd
      });
      return updatedState;
    });
  }

  const handleCalcEnergiaTotalGD: (valorBase: number, valor: number) => void = (valorBase: number, valor: number) => {
    handleInputChange({
      target: {
        name: NovoPagamentoEnum.ENERGIATOTALGD,
        value: calcValues(ordemPagamento.valorBase, novoPagamento.valor, "/", 2)
      }
    } as unknown as React.ChangeEvent<HTMLInputElement>)
  }

  const handleEditButton: (id: string) => Promise<void> = async (id: string) => {
    setHideEditComprovante(!hideEditComprovante);
  };

  useEffect(() => {
    handleCalcEnergiaTotalGD(ordemPagamento.valorBase ?? 0, novoPagamento.valor as number);
  }, [novoPagamento.valor]);

  useEffect(() => {
    handleInputChange({
      target: {
        name: NovoPagamentoEnum.IDORDEMPAGAMENTO,
        value: ordemPagamento.id
      }
    } as unknown as React.ChangeEvent<HTMLInputElement>)
  }, [ordemPagamento]);

  useEffect(() => {
    if (typeof (id) != "string") {
      setHideEditComprovante(true);
      return;
    }

    getConta(id)
  }, [ordemPagamento, id])
  //endregion

  return (
    <>
      <RegisterCard title="Inserir novo pagamento" showExitButton={true} callback={callbackExit}>
        <C.FWStack direction={"row"} spacing={2}>
          <D.DateTextfield
            name={NovoPagamentoEnum.DATA}
            onChange={handleInputChange}
            value={
              ((novoPagamento.data ?? "").toString())?.split("T")[0]
            }
            InputLabelProps={{
              shrink: true,
            }}
            label="Data"
            type="date"
            required
            fullWidth
          />
          <TextField
            name={NovoPagamentoEnum.TIPOPAGAMENTO}
            value={novoPagamento.tipoPagamento}
            select
            InputLabelProps={{
              shrink: true,
            }}
            onChange={handleInputChange}
            label="Tipo de pagamento"
            required
            fullWidth
          >
            {Object.keys(EnumTipoPagamento).filter(x => isNaN(Number(x))).map((key: string, i: number) => (
              <MenuItem key={key} value={i}>
                {key}
              </MenuItem>
            ))}
          </TextField>

          <MaskCurrencyInput
            label="Valor"
            name={NovoPagamentoEnum.VALOR}
            onValueChange={(newValue: string, name: string, values: any) => {
              handleInputChange({
                target: {
                  name,
                  value: newValue
                }
              } as unknown as React.ChangeEvent<HTMLInputElement>)
            }}
            sx={handleStyleFieldError(
              NovoPagamentoEnum.VALOR,
              fieldErros
            )}
            placeholder="0,00"
            defaultValue={0}
            decimalsLimit={2}
            value={novoPagamento.valor || ""}
          />

          <MaskCurrencyInput
            onValueChange={(newValue: string, name: string, values: any) => handleInputChange({
              target: {
                name,
                value: newValue
              }
            } as unknown as React.ChangeEvent<HTMLInputElement>)}
            sx={handleStyleFieldError(
              NovoPagamentoEnum.ENERGIATOTALGD,
              fieldErros
            )}
            label="energia total GD"
            name={NovoPagamentoEnum.ENERGIATOTALGD}
            value={novoPagamento.energiaTotalGd || ""}
            placeholder="0,00"
            defaultValue={0}
            decimalsLimit={2}
          />

        </C.FWStack>
        {
          hideEditComprovante && (
            <>
              <C.FWStack direction={"row"} spacing={2} sx={{marginTop: 2}}>
                <h4>
                  <PublishIcon/> Upload de Arquivos
                </h4>
              </C.FWStack>
              <C.FWStack direction={"row"} spacing={2}>
                <TextField
                  name={NovoPagamentoEnum.ASSUNTO}
                  onChange={handleInputChange}
                  value={novoPagamento.assunto ?? ""}
                  label={"Assunto"}
                  fullWidth
                />
              </C.FWStack>
              <C.FWStack direction={"row"} spacing={2}>
                <TextField
                  name={NovoPagamentoEnum.DESCRICAO}
                  onChange={handleInputChange}
                  value={novoPagamento.descricao ?? ""}
                  label="Descrição do documento"
                  fullWidth
                  multiline
                  rows={5}
                />
              </C.FWStack>
            </>
          )
        }
        <C.FWStack direction={"row"} spacing={2} justifyContent={"space-between"}>
          {
            hideEditComprovante &&
            (<ButtonFile multiple={false} onFileChange={handleChangeFile}
                         setTitle={novoPagamento.comprovanteNome ?? ""}/>)
          }

          <D.ContainedButton style={{marginTop: 'auto', marginBottom: 'auto', height: 0}}
                             onClick={() => sendOnSave(novoPagamento)}>
            Salvar
          </D.ContainedButton>
        </C.FWStack>
      </RegisterCard>

      {
        id != null && (
          <RegisterCard title="Comprovante">
            {tableData.length > 0 ? (
              <DataGrid
                rows={tableData}
                disableColumnSelector
                disableRowSelectionOnClick
                localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
                columns={columns}
                initialState={{pagination: {paginationModel}}}
                pageSizeOptions={[5, 10, 25, 50, 100]}
                sx={{border: 0}}
              />
            ) : (
              <>Não há dados.</>
            )}
          </RegisterCard>
        )
      }

    </>
  );
};

export default InserirNovoPagamento;