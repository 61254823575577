export enum EnumTipoComissao {
    fixa = 0,
    porcentagem = 1,
};

export const enumTipoComissaoMap = [
    {
        name:"Fixa",
        value:EnumTipoComissao.fixa
    },
    {
        name:"Porcentagem",
        value:EnumTipoComissao.porcentagem
    }
];
  