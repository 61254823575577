export enum EnumModalidadePlano {
    comCoParticipacao = 0,
    semCoParticipacao = 1,
};

export const enumModalidadePlanoMap = [
    {
        name:"Com Co-participação",
        value:EnumModalidadePlano.comCoParticipacao
    },
    {
        name:"Sem Co-participação",
        value:EnumModalidadePlano.semCoParticipacao
    }
];
  