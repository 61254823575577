import { FC, RefObject, useEffect, useReducer, useState } from "react";
import { iResponsavelIndicacao } from "../../../../../models/Gerador/Cadastro/ResponsavelIndicacao";
import { MenuItem, Stack, TextField } from "@mui/material";
import * as C from "../style";
import * as D from "../../../../../styles/appComponents";
import AddIcon from "@mui/icons-material/Add";
import { ErrorMessage } from "../../../../../utils/AbstractValidation";
import { UsinaTipoPessoaJuridica } from "../../../../../models/Gerador/Usina";
import { MaskCurrencyInput } from "../../../../../components/MakCurrencyInput";
import { ResponsavelIndicacaoEnum } from "../../../../../enums/Gerador/EnumInputModel.ts/ResponsavelIndicacaoEnum";

export interface PropsResponsavelIndicacao {
  onSave: (data: iResponsavelIndicacao[]) => void;
  onChange: (data: iResponsavelIndicacao[]) => void;
  onUpdate: (data: iResponsavelIndicacao[]) => void;
  fieldErros: ErrorMessage[] | null;
  buttonSave: boolean;
  geradorId: string | null | undefined;
  usina: UsinaTipoPessoaJuridica | undefined;
  scrollRef: RefObject<HTMLDivElement> | null;
}

export const ResponsavelIndicacao: FC<PropsResponsavelIndicacao> = ({
  onSave,
  onChange,
  onUpdate,
  buttonSave,
  fieldErros,
  geradorId,
  usina,
  scrollRef
}) => {
  const formResponsavelIndicacaoModel: iResponsavelIndicacao = {
    temcomissao: 0,
    percentualcomissao: null,
    nome: null,
    celular: null,
    email: null,
  };
  const [responsavelIndicacao, setResponsavelIndicacao] = useState<
    iResponsavelIndicacao[]
  >([formResponsavelIndicacaoModel]);
  const sendOnChange = (update: iResponsavelIndicacao[]): void =>
    onChange(update);
  const sendOnSave = () => onSave(responsavelIndicacao);
  const sendOnUpdate = () => onUpdate(responsavelIndicacao);

  const handleInputChange =
    (index: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
      const name: string = event.target.name.toLocaleLowerCase();
      const value = event.target.value;

      const newForms = [...responsavelIndicacao];
      newForms[index] = { ...newForms[index], [name]: value };

      setResponsavelIndicacao(newForms);
      sendOnChange(newForms);
    };

  useEffect(() => {
    const formResponsavelIndicacaoModel: iResponsavelIndicacao[] = (
      usina?.responsavelIndicacaos || []
    ).map((x) => {
      return {
        id: x?.id,
        temcomissao: x.temComissao,
        percentualcomissao: x.percentualComissao,
        nome: x.nome,
        celular: x.celular,
        email: x.email,
      };
    });
    setResponsavelIndicacao(formResponsavelIndicacaoModel.length === 0? [{
      temcomissao: 0,
      percentualcomissao: null,
      nome: null,
      celular: null,
      email: null,
    }]:formResponsavelIndicacaoModel);
  }, [geradorId, usina]);

  const addNewForm = () => {
    setResponsavelIndicacao((state: any) => [
      ...state,
      formResponsavelIndicacaoModel,
    ]);
  };

  return (
    <>
      <C.TitleArea>
        <h2>Responsável pela indicação</h2>
      </C.TitleArea>
      {(responsavelIndicacao || []).map(
        (x: iResponsavelIndicacao, index: number) => (
          <>
            <C.FWStack direction={"row"} spacing={2}>
              <TextField
                inputProps={{ readOnly: buttonSave }}
                name={ResponsavelIndicacaoEnum.TEMCOMISSAO}
                onChange={handleInputChange(index)}
                value={x?.temcomissao ?? ""}
                select
                label="Tem comissão"
                required
                fullWidth
              >
                <MenuItem value={1}>Sim</MenuItem>
                <MenuItem value={0}>Não</MenuItem>
              </TextField>
              <MaskCurrencyInput
                label="Percentual Comissão"
                readonly={buttonSave}
                name={ResponsavelIndicacaoEnum.PERCENTUALCOMISSAO}
                onValueChange={(newValue, name, values) =>
                  handleInputChange(index)({
                    target: { name, value: newValue },
                  } as React.ChangeEvent<HTMLInputElement>)
                }
                value={x?.percentualcomissao || ""}
                placeholder="00,00%"
                defaultValue={0}
                decimalsLimit={2}
                suffix="%"
              />
              <TextField
                inputProps={{ readOnly: buttonSave }}
                name={ResponsavelIndicacaoEnum.NOME}
                onChange={handleInputChange(index)}
                value={x?.nome || ""}
                label="Nome do parceiro"
                required
                fullWidth
                onKeyDown={(event) => {
                  const regex = /[0-9]/;
                  if (regex.test(event.key)) {
                    event.preventDefault();
                  }
                }}
              />
              <TextField
                inputProps={{ readOnly: buttonSave }}
                type="number"
                name={ResponsavelIndicacaoEnum.CELULAR}
                onChange={handleInputChange(index)}
                value={x?.celular || ""}
                label="Celular do parceiro"
                required
                fullWidth
              />
              <TextField
                inputProps={{ readOnly: buttonSave }}
                type="email"
                name={ResponsavelIndicacaoEnum.EMAIL}
                onChange={handleInputChange(index)}
                value={x?.email || ""}
                label="Email do parceiro"
                required
                fullWidth
              />
            </C.FWStack>
          </>
        )
      )}
      <C.FWStack direction={"row"} spacing={2} justifyContent={"flex-end"}>
        <D.ContainedButton
          disabled={buttonSave}
          startIcon={<AddIcon />}
          onClick={addNewForm}
          $color="lightpink"
        >
          Adicionar indicação
        </D.ContainedButton>
      </C.FWStack>
      </>
  );
};
