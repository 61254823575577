import RegisterCard from "../../../../../../../components/RegisterCard";
import React, {FC, useEffect, useReducer, useState} from "react";
import * as G from "../../style";
import { Checkbox, Stack, TextField } from "@mui/material";
import * as D from "../../../../../../../styles/appComponents";
import * as C from "../Situacao/style";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { formatValues } from "../../../../../../../utils/moneyFormatter";
import { useAuth } from "../../../../../../../hooks/useAuth";
import { ErrorMessage } from "../../../../../../../utils/AbstractValidation";
import { EnumTipoVT } from "../../../../../../../enums/EnumTipoVT";
import { handleStyleFieldError } from "../../../../../../../utils/verifyFieldError";
import { EnumModalidadePlano, enumModalidadePlanoMap } from "../../../../../../../enums/Colaborador/EnumModalidadePlano.enum";
import { EnumTipoComissao, enumTipoComissaoMap } from "../../../../../../../enums/Colaborador/EnumTipoComissao.enum";
import { MaskCurrencyInput } from "../../../../../../../components/MakCurrencyInput";
import { ColaboradorByIdResponse } from "../../../../../../../models/Colaborador/Colaborador";

enum beneficiosComissao {
  BOOLPLANOSAUDE = "BOOLPLANOSAUDE",
  BOOLPLANOODONTOLOGICO = "BOOLPLANOODONTOLOGICO",
  ENUMMODALIDADEPLANOSAUDE = "ENUMMODALIDADEPLANOSAUDE",
  ENUMMODALIDADEPLANOODONTOLOGICO = "ENUMMODALIDADEPLANOODONTOLOGICO",
  BOOLPONTOELETRONICO = "BOOLPONTOELETRONICO",
  BOOLRECEBECOMISSAO = "BOOLRECEBECOMISSAO",
  ENUMTIPOCOMISSAO = "ENUMTIPOCOMISSAO",
  PERCENTUALCOMISSAO = "PERCENTUALCOMISSAO",
  VALORFIXOCOMISSAO = "VALORFIXOCOMISSAO",
  BOOLRECEBEVR = "BOOLRECEBEVR",
  VALORDIAVR = "VALORDIAVR",
  BOOLRECEBEVA = "BOOLRECEBEVA",
  VALORDIAVA = "VALORDIAVA",
  BOOLRECEBECOMBUSTIVEL = "BOOLRECEBECOMBUSTIVEL",
  VALORDIACOMBUSTIVEL = "VALORDIACOMBUSTIVEL",
  BOOLRECEBEVT = "BOOLRECEBEVT",
  ENUMTIPOVT = "ENUMTIPOVT",
  VALORDIAVT = "VALORDIAVT",
  ADDNEWFORM = "ADDNEWFORM",
  REMOVEFORM = "REMOVEFORM",
  LINHASDEONIBUS = "LINHASDEONIBUS",
  VALORDIABHBUSVT = "VALORDIABHBUSVT",
  VALORDIAOTIMOVT = "VALORDIAOTIMOVT",
  LINHABHBUSIDA = "LINHABHBUSIDA",
  LINHABHBUSVOLTA = "LINHABHBUSVOLTA",
  LINHAOTIMOIDA = "LINHAOTIMOIDA",
  LINHAOTIMOVOLTA = "LINHAOTIMOVOLTA"
}

export interface IFormBeneficiosComissao {
  boolplanosaude: boolean | undefined,
  boolplanoodontologico: boolean | undefined,
  enummodalidadeplanosaude: EnumModalidadePlano | undefined,
  enummodalidadeplanoodontologico: EnumModalidadePlano | undefined,
  boolpontoeletronico: boolean | undefined,
  boolrecebecomissao: boolean | undefined,
  enumtipocomissao: EnumTipoComissao | undefined,
  percentualcomissao: string | undefined | number,
  valorfixocomissao: string | null | number | undefined,
  boolrecebevr: boolean | undefined,
  valordiavr: string | null | undefined | number,
  boolrecebeva: boolean | undefined,
  valordiava: string | null | number | undefined,
  boolrecebecombustivel: boolean | undefined,
  valordiacombustivel: string | null | number | undefined,
  boolrecebevt: boolean | undefined,
  enumtipovt: EnumTipoVT,
  valordiavt: number | null | undefined | string,
  linhasDeOnibus: IFormInputLinhasDeOnibus[]
}

export interface IFormInputLinhasDeOnibus{
  id?: string | null;
  valordiabhbusvt: string | null;
  valordiaotimovt: string | null;
  linhabhbusida: string | null;
  linhabhbusvolta: string | null;
  linhaotimoida: string | null;
  linhaotimovolta: string | null;
}

type PropsBeneficiosComissao = {
  onSave: (data: IFormBeneficiosComissao) => void;
  onUpdate: (data: IFormBeneficiosComissao) => void;
  onChange: (data: IFormBeneficiosComissao) => void;
  getData: (data: IFormInputLinhasDeOnibus[]) => void;
  getValorTotalVt: (data:number) => void;
  fieldErros: ErrorMessage[] | null;
  buttonSave: boolean;
  data?: object | null;
  colaboradorId: string | undefined;
  colaborador: ColaboradorByIdResponse | undefined;
  load: { status:boolean, section:string };
};

const BeneficiosComissao:FC<PropsBeneficiosComissao> = (
  {
    onSave,
    onUpdate,
    onChange,
    getData,
    getValorTotalVt,
    fieldErros,
    buttonSave,
    data,
    colaborador,
    colaboradorId,
    load
  }
) => {
  const {user} = useAuth()

  const formInputLinhasDeOnibus:IFormInputLinhasDeOnibus = {
    valordiabhbusvt: null,
    valordiaotimovt: null,
    linhabhbusida: null,
    linhabhbusvolta: null,
    linhaotimoida: null,
    linhaotimovolta: null
  }

  const beneficiosComissaoFormModel:IFormBeneficiosComissao = {
    boolplanosaude: false,
    boolplanoodontologico: false,
    enummodalidadeplanosaude: undefined,
    enummodalidadeplanoodontologico: undefined,
    boolpontoeletronico: true,
    boolrecebecomissao: true,
    enumtipocomissao: EnumTipoComissao.fixa,
    percentualcomissao: undefined,
    valorfixocomissao: null,
    boolrecebevr: false,
    valordiavr: null,
    boolrecebeva: false,
    valordiava: null,
    boolrecebecombustivel: false,
    valordiacombustivel: null,
    boolrecebevt: false,
    enumtipovt: EnumTipoVT.BHBUS,
    valordiavt: 0,
    linhasDeOnibus: []
  }

  const [linhasDeOnibus, setLinhasDeOnibus] = useState<IFormInputLinhasDeOnibus[]>([formInputLinhasDeOnibus])
  const [dadosBeneficioComissao, setDadosBeneficioComissao] = useState<IFormBeneficiosComissao>({...beneficiosComissaoFormModel, linhasDeOnibus})

  const totalDiaVt = linhasDeOnibus.reduce((total:number, linha:IFormInputLinhasDeOnibus) => {
    const valordiabhbusvt = typeof linha?.valordiabhbusvt === "string"? parseFloat((linha?.valordiabhbusvt || "")?.replace(/,/g, '.')): linha?.valordiabhbusvt
    const valordiaotimovt = typeof linha?.valordiaotimovt === "string"? parseFloat((linha?.valordiaotimovt || "")?.replace(/,/g, '.')): linha?.valordiaotimovt

    return total + ((valordiabhbusvt || 0) + (valordiaotimovt || 0))

  }, 0)
    
  const sendOnSave = (): void => onSave({...dadosBeneficioComissao, linhasDeOnibus, valordiavt:totalDiaVt});
  const sendOnUpdate = (): void => onUpdate(dadosBeneficioComissao);
  const sendOnChange = (update: IFormBeneficiosComissao): void => onChange(update);

  useEffect(() => {
    const formInputLinhasDeOnibus:IFormInputLinhasDeOnibus[] = (colaborador?.linhaOnibus || []).map((linha) => {
      return{
        id: linha.id,
        valordiabhbusvt: linha.valordiaBhBusvt,
        valordiaotimovt: linha.valordiaOtimovt,
        linhabhbusida: linha.linhaBhBusIda,
        linhabhbusvolta: linha.linhaBhBusVolta,
        linhaotimoida: linha.linhaOtimoIda,
        linhaotimovolta: linha.linhaOtimoVolta
      }
    });

    const beneficiosComissaoFormModel:IFormBeneficiosComissao = {
      boolplanosaude: colaborador?.boolPlanoSaude,
      boolplanoodontologico: colaborador?.boolPlanoOdontologico,
      enummodalidadeplanosaude: colaborador?.enumModalidadePlanoSaude as EnumModalidadePlano | undefined,
      enummodalidadeplanoodontologico: colaborador?.enumModalidadePlanoOdontologico as EnumModalidadePlano | undefined,
      boolpontoeletronico: colaborador?.boolPontoEletronico,
      boolrecebecomissao: colaborador?.boolRecebeComissao,
      enumtipocomissao: colaborador?.enumTipoComissao as EnumTipoComissao | undefined,
      percentualcomissao: colaborador?.percentualComissao,
      valorfixocomissao: colaborador?.valorFixoComissao,
      boolrecebevr: colaborador?.boolRecebeVR,
      valordiavr: colaborador?.valorDiaVR,
      boolrecebeva: colaborador?.boolRecebeVA,
      valordiava: colaborador?.valorDiaVA,
      boolrecebecombustivel: colaborador?.boolRecebeCombustivel,
      valordiacombustivel: colaborador?.valorDiaCombustivel,
      boolrecebevt: colaborador?.boolRecebeVT,
      enumtipovt: colaborador?.enumTipoVT as EnumTipoVT,
      valordiavt: colaborador?.valorDiaVT,
      linhasDeOnibus: formInputLinhasDeOnibus
    }
    setDadosBeneficioComissao(beneficiosComissaoFormModel);
    setLinhasDeOnibus((beneficiosComissaoFormModel?.linhasDeOnibus || []));

  }, [colaboradorId, colaborador]);

  useEffect(() => {
    if(dadosBeneficioComissao?.boolrecebevt){
      getData(linhasDeOnibus)
      getValorTotalVt(totalDiaVt)

    }else{
      getData([])
      getValorTotalVt(0)
    }
      
  }, [dadosBeneficioComissao?.boolrecebevt])

  const handleInputChange = ({target}:React.ChangeEvent<HTMLInputElement>) => {
    const name = target.name.toLocaleLowerCase()
    const value = target.value

    setDadosBeneficioComissao((state:IFormBeneficiosComissao) => {
      sendOnChange({ ...state, [name]:value })
      return { ...state, [name]:value }
    })
  }

  useEffect(() => {
    if(!dadosBeneficioComissao?.boolrecebecombustivel)
      handleInputChange(
        {
          target:{
            name:beneficiosComissao.VALORDIACOMBUSTIVEL,
            value: null
          }
        } as any
      );

    if(!dadosBeneficioComissao?.boolrecebevr)
      handleInputChange(
        {
          target:{
            name:beneficiosComissao.VALORDIAVR,
            value: null
          }
        } as any
      );

    if(!dadosBeneficioComissao?.boolrecebeva)
      handleInputChange(
        {
          target:{
            name:beneficiosComissao.VALORDIAVA,
            value: null
          }
        } as any
      );

    if(dadosBeneficioComissao?.enumtipocomissao === EnumTipoComissao.fixa)
      handleInputChange(
        {
          target:{
            name:beneficiosComissao.PERCENTUALCOMISSAO,
            value: null
          }
        } as any
      );

    if(dadosBeneficioComissao?.enumtipocomissao === EnumTipoComissao.porcentagem)
      handleInputChange(
        {
          target:{
            name:beneficiosComissao.VALORFIXOCOMISSAO,
            value: null
          }
        } as any
      );

    if(!dadosBeneficioComissao?.boolplanosaude)
      handleInputChange(
        {
          target:{
            name:beneficiosComissao.ENUMMODALIDADEPLANOSAUDE,
            value: null
          }
        } as any
      );

    if(!dadosBeneficioComissao?.boolplanoodontologico)
      handleInputChange(
        {
          target:{
            name:beneficiosComissao.ENUMMODALIDADEPLANOODONTOLOGICO,
            value: null
          }
        } as any
      );

    if(!dadosBeneficioComissao?.boolrecebevt)
      setLinhasDeOnibus([formInputLinhasDeOnibus])

  }, 
  [
    dadosBeneficioComissao?.boolrecebecombustivel,
    dadosBeneficioComissao?.boolrecebevr,
    dadosBeneficioComissao?.boolrecebeva,
    dadosBeneficioComissao?.enumtipocomissao,
    dadosBeneficioComissao?.boolrecebevt
  ])

  const addNewForm = () => {
    setLinhasDeOnibus((x) => [...x, formInputLinhasDeOnibus])
  }

  const removeForm = (index:number) => {
    setLinhasDeOnibus((x) => x.filter((_, i:number) => i !== index))
  }

  const handleChangeLinhaDeOnibus = (index:number) => (event:React.ChangeEvent<HTMLInputElement>) => {
    const name = event?.target?.name?.toLocaleLowerCase();
    const value = event?.target?.value;

    const newForm = [...linhasDeOnibus];
    newForm[index] = { ...newForm[index], [name]: value };

    setLinhasDeOnibus(newForm);
    sendOnChange({
      ...dadosBeneficioComissao,
      valordiavt: totalDiaVt,
      linhasDeOnibus:newForm
    });
  }

  return (
    <RegisterCard title="Benefícios/comissão">
      <G.FWStack direction={"row"} spacing={8}>
        <G.FWStack direction={"column"} spacing={2} alignItems={"flex-start"}>
          <G.Title>Planos</G.Title>
          <G.CheckboxWrapper>
            <Checkbox
              disabled={buttonSave}
              checked={dadosBeneficioComissao?.boolplanosaude}
              value={dadosBeneficioComissao?.boolplanosaude ?? ""}
              onChange={({target}) => handleInputChange(
                {
                  target:{
                    name:beneficiosComissao.BOOLPLANOSAUDE,
                    value: target.checked 
                  }
                } as any
              )}
            />
            <p>Plano de saúde</p>
          </G.CheckboxWrapper>
          <G.CheckboxWrapper>
            <Checkbox
              disabled={buttonSave}
              checked={dadosBeneficioComissao?.boolplanoodontologico}
              value={dadosBeneficioComissao?.boolplanoodontologico ?? ""}
              onChange={({target}) => handleInputChange(
                {
                  target:{
                    name:beneficiosComissao.BOOLPLANOODONTOLOGICO,
                    value: target.checked 
                  }
                } as any
              )}
            />
            <p>Plano odontológico</p>
          </G.CheckboxWrapper>
        </G.FWStack>
        <G.FWStack direction={"column"} spacing={2} alignItems={"flex-start"}>
          <G.Title>Modalidade plano de saúde</G.Title>
          {enumModalidadePlanoMap.map((x, index) => (
            <G.CheckboxWrapper key={index}>
              <Checkbox
                sx={handleStyleFieldError(beneficiosComissao.ENUMMODALIDADEPLANOSAUDE, fieldErros)}
                disabled={!dadosBeneficioComissao.boolplanosaude || buttonSave}
                checked={dadosBeneficioComissao?.enummodalidadeplanosaude === x.value}
                value={x.value}
                onChange={({target}) => handleInputChange(
                  {
                    target:{
                      name:beneficiosComissao.ENUMMODALIDADEPLANOSAUDE,
                      value:parseInt(target.value)
                    }
                  } as any
                )}
              />
              <p>{x.name}</p>
            </G.CheckboxWrapper>
          ))}
        </G.FWStack>
        <G.FWStack direction={"column"} spacing={2} alignItems={"flex-start"}>
          <G.Title>Modalidade plano odontológico</G.Title>
          {enumModalidadePlanoMap.map((x, index) => (
            <G.CheckboxWrapper key={index}>
              <Checkbox
                sx={handleStyleFieldError(beneficiosComissao.ENUMMODALIDADEPLANOODONTOLOGICO, fieldErros)}
                disabled={!dadosBeneficioComissao.boolplanoodontologico || buttonSave}
                checked={dadosBeneficioComissao?.enummodalidadeplanoodontologico === x.value}
                value={x.value}
                onChange={({target}) => handleInputChange(
                  {
                    target:{
                      name:beneficiosComissao.ENUMMODALIDADEPLANOODONTOLOGICO,
                      value:parseInt(target.value)
                    }
                  } as any
                )}
              />
              <p>{x.name}</p>
            </G.CheckboxWrapper>
          ))}
        </G.FWStack>
        <G.FWStack direction={"column"} spacing={2} alignItems={"flex-start"}>
          <G.Title>Bate ponto elêtronico</G.Title>
          <G.CheckboxWrapper>
            <Checkbox
              disabled={buttonSave}
              name={beneficiosComissao.BOOLPONTOELETRONICO}
              checked={dadosBeneficioComissao?.boolpontoeletronico}
              value={dadosBeneficioComissao?.boolpontoeletronico}
              onChange={() => handleInputChange(
                {
                  target:{
                    name:beneficiosComissao.BOOLPONTOELETRONICO,
                    value: true
                  }
                } as any
              )}
            />
            <p>Sim</p>
          </G.CheckboxWrapper>
          <G.CheckboxWrapper>
            <Checkbox
              name={beneficiosComissao.BOOLPONTOELETRONICO}
              disabled={buttonSave}
              checked={!dadosBeneficioComissao?.boolpontoeletronico}
              value={dadosBeneficioComissao?.boolpontoeletronico}
              onChange={() => handleInputChange(
                {
                  target:{
                    name:beneficiosComissao.BOOLPONTOELETRONICO,
                    value: false
                  }
                } as any
              )}
            />
            <p>Não</p>
          </G.CheckboxWrapper>
        </G.FWStack>
        <G.FWStack direction={"column"} spacing={2} alignItems={"flex-start"}>
          <G.Title>Recebe comissão</G.Title>
          <G.CheckboxWrapper>
            <Checkbox
              name={beneficiosComissao.BOOLRECEBECOMISSAO}
              disabled={buttonSave}
              checked={dadosBeneficioComissao?.boolrecebecomissao}
              value={dadosBeneficioComissao?.boolrecebecomissao}
              onChange={() => handleInputChange(
                {
                  target:{
                    name:beneficiosComissao.BOOLRECEBECOMISSAO,
                    value: true
                  }
                } as any
              )}
            />
            <p>Sim</p>
          </G.CheckboxWrapper>
          <G.CheckboxWrapper>
            <Checkbox
              name={beneficiosComissao.BOOLRECEBECOMISSAO}
              disabled={buttonSave}
              checked={!dadosBeneficioComissao?.boolrecebecomissao}
              value={dadosBeneficioComissao?.boolrecebecomissao}
              onChange={() => handleInputChange(
                {
                  target:{
                    name:beneficiosComissao.BOOLRECEBECOMISSAO,
                    value: false
                  }
                } as any
              )}
            />
            <p>Não</p>
          </G.CheckboxWrapper>
        </G.FWStack>
      </G.FWStack>
      <G.FWStack direction={"row"} style={{alignItems: "center"}} spacing={2}>
        <Stack direction={"column"} spacing={2}>
          <G.Title>Comissão</G.Title>
          {enumTipoComissaoMap.map((x, index) => (
            <G.CheckboxWrapper key={index}>
              <Checkbox
                disabled={buttonSave}
                checked={dadosBeneficioComissao.enumtipocomissao === x.value}
                value={x.value}
                onChange={({target}) => handleInputChange(
                  {
                    target:{
                      name:beneficiosComissao.ENUMTIPOCOMISSAO,
                      value: parseInt(target.value)
                    }
                  } as any
                )}
              />
              <p>{x.name}</p>
            </G.CheckboxWrapper>
          ))}
        </Stack>
        <MaskCurrencyInput
          name={beneficiosComissao.PERCENTUALCOMISSAO}
          label="Porcentual de comissão"
          placeholder="00,00%"
          defaultValue={0}
          decimalsLimit={2}
          value={dadosBeneficioComissao?.percentualcomissao ?? ""}
          suffix="%"
          sx={handleStyleFieldError(beneficiosComissao.PERCENTUALCOMISSAO, fieldErros, { width:"100%" })}
          disabled={dadosBeneficioComissao.enumtipocomissao != EnumTipoComissao.porcentagem || buttonSave}
          onValueChange={(newValue, name, values) => handleInputChange(
            { target: { name, value: newValue } } as React.ChangeEvent<HTMLInputElement>
          )}
        />
        <MaskCurrencyInput
          name={beneficiosComissao.VALORFIXOCOMISSAO}
          label="Valor fixo de comissão"
          placeholder="R$ 00,00"
          defaultValue={0}
          decimalsLimit={2}
          value={dadosBeneficioComissao?.valorfixocomissao ?? ""}
          prefixo="R$ "
          sx={handleStyleFieldError(beneficiosComissao.VALORFIXOCOMISSAO, fieldErros, { width:"100%" })}
          disabled={dadosBeneficioComissao.enumtipocomissao != EnumTipoComissao.fixa || buttonSave}
          onValueChange={(newValue, name, values) => handleInputChange(
            { target: { name, value: newValue } } as React.ChangeEvent<HTMLInputElement>
          )}
        />
        <Stack direction={"column"} spacing={2} alignItems={"flex-start"}>
          <G.Title>Recebe</G.Title>
          <G.CheckboxWrapper>
            <Checkbox 
              disabled={buttonSave} 
              checked={dadosBeneficioComissao?.boolrecebevr}
              value={dadosBeneficioComissao?.boolrecebevr ?? ""}
              onChange={({target}) => handleInputChange(
                {
                  target:{
                    name:beneficiosComissao.BOOLRECEBEVR,
                    value: target.checked
                  }
                } as any
              )}
            />
            <p>VR</p>
          </G.CheckboxWrapper>
          <G.CheckboxWrapper>
            <Checkbox 
                disabled={buttonSave} 
                checked={dadosBeneficioComissao?.boolrecebeva}
                value={dadosBeneficioComissao?.boolrecebeva ?? ""}
                onChange={({target}) => handleInputChange(
                  {
                    target:{
                      name:beneficiosComissao.BOOLRECEBEVA,
                      value: target.checked
                    }
                  } as any
                )}
              />
            <p>VA</p>
          </G.CheckboxWrapper>
          <G.CheckboxWrapper>
            <Checkbox 
                  disabled={buttonSave} 
                  checked={dadosBeneficioComissao?.boolrecebecombustivel}
                  value={dadosBeneficioComissao?.boolrecebecombustivel ?? ""}
                  onChange={({target}) => handleInputChange(
                    {
                      target:{
                        name:beneficiosComissao.BOOLRECEBECOMBUSTIVEL,
                        value: target.checked
                      }
                    } as any
                  )}
                />
            <p>Combustível</p>
          </G.CheckboxWrapper>
        </Stack>
        <MaskCurrencyInput
          name={beneficiosComissao.VALORDIAVR}
          label="Valor dia VR"
          placeholder="R$ 00,00"
          defaultValue={0}
          decimalsLimit={2}
          value={dadosBeneficioComissao?.valordiavr ?? ""}
          prefixo="R$ "
          sx={handleStyleFieldError(beneficiosComissao.VALORDIAVR, fieldErros, { width:"100%" })}
          disabled={!dadosBeneficioComissao.boolrecebevr || buttonSave}
          onValueChange={(newValue, name, values) => handleInputChange(
            { target: { name, value: newValue } } as React.ChangeEvent<HTMLInputElement>
          )}
        />
        <MaskCurrencyInput
          name={beneficiosComissao.VALORDIAVA}
          label="Valor dia VA"
          placeholder="R$ 00,00"
          defaultValue={0}
          decimalsLimit={2}
          value={dadosBeneficioComissao?.valordiava ?? ""}
          prefixo="R$ "
          sx={handleStyleFieldError(beneficiosComissao.VALORDIAVA, fieldErros, { width:"100%" })}
          disabled={!dadosBeneficioComissao.boolrecebeva || buttonSave}
          onValueChange={(newValue, name, values) => handleInputChange(
            { target: { name, value: newValue } } as React.ChangeEvent<HTMLInputElement>
          )}
        />
        <MaskCurrencyInput
          name={beneficiosComissao.VALORDIACOMBUSTIVEL}
          label="Valor dia Combustível"
          placeholder="R$ 00,00"
          defaultValue={0}
          decimalsLimit={2}
          value={dadosBeneficioComissao?.valordiacombustivel ?? ""}
          prefixo="R$ "
          sx={handleStyleFieldError(beneficiosComissao.VALORDIACOMBUSTIVEL, fieldErros, { width:"100%" })}
          disabled={!dadosBeneficioComissao.boolrecebecombustivel || buttonSave}
          onValueChange={(newValue, name, values) => handleInputChange(
            { target: { name, value: newValue } } as React.ChangeEvent<HTMLInputElement>
          )}
        />
      </G.FWStack>
      <G.FWStack direction={"row"} spacing={25}>
        <Stack direction={"column"} spacing={4} alignItems={"flex-start"}>
          <G.Title>Recebe VT</G.Title>
          <G.CheckboxWrapper>
            <Checkbox
              disabled={buttonSave}
              checked={dadosBeneficioComissao?.boolrecebevt}
              value={dadosBeneficioComissao?.boolrecebevt}
              onChange={({target}) => handleInputChange(
                {
                  target:{
                    name:beneficiosComissao.BOOLRECEBEVT,
                    value:true
                  }
                } as any
              )}
            />
            <p>Sim</p>
          </G.CheckboxWrapper>
          <G.CheckboxWrapper>
            <Checkbox
              disabled={buttonSave}
              checked={!dadosBeneficioComissao?.boolrecebevt}
              value={dadosBeneficioComissao?.boolrecebevt}
              onChange={({target}) => handleInputChange(
                {
                  target:{
                    name:beneficiosComissao.BOOLRECEBEVT,
                    value:false
                  }
                } as any
              )}
            />
            <p>Não</p>
          </G.CheckboxWrapper>
        </Stack>
        <Stack direction={"column"} spacing={2} alignItems={"flex-start"}>
          <G.Title>Cartão</G.Title>
          <G.CheckboxWrapper>
            <Checkbox
              disabled={buttonSave}
              value={"sim"}
              // onChange={(e) => handleChangeSelectRecebeCartao(e)}
            />
            <p>Sim</p>
          </G.CheckboxWrapper>
          <G.CheckboxWrapper>
            <Checkbox
              disabled={buttonSave}
              value={"nao"}
              // onChange={(e) => handleChangeSelectRecebeCartao(e)}
            />
            <p>Não</p>
          </G.CheckboxWrapper>
        </Stack>
        <G.FWStack direction={"column"} spacing={4}>
          {linhasDeOnibus.map((linha, index:number) => (
            <>
              <G.FWStack style={{ flexDirection:"row", gap:20 }}>
                <G.FWStack direction={"column"} spacing={2} alignItems={"flex-start"}>
                  <TextField
                    fullWidth
                    value={linha?.linhabhbusida ?? ""}
                    label="Linhas BHBUS ida"
                    name={beneficiosComissao.LINHABHBUSIDA}
                    disabled={!dadosBeneficioComissao?.boolrecebevt || buttonSave}
                    onChange={handleChangeLinhaDeOnibus(index)}
                    sx={handleStyleFieldError(beneficiosComissao.LINHABHBUSIDA, fieldErros, { width:"100%" }, index)}
                  />
                  <TextField
                    fullWidth
                    value={linha?.linhabhbusvolta ?? ""}
                    name={beneficiosComissao.LINHABHBUSVOLTA}
                    label="Linhas BHBUS volta"
                    onChange={handleChangeLinhaDeOnibus(index)}
                    disabled={!dadosBeneficioComissao?.boolrecebevt || buttonSave}
                    sx={handleStyleFieldError(beneficiosComissao.LINHABHBUSVOLTA, fieldErros, null, index)}
                  />
                  <MaskCurrencyInput
                    name={beneficiosComissao.VALORDIABHBUSVT}
                    label="Valor dia BHBUS"
                    placeholder="R$ 00,00"
                    defaultValue={0}
                    decimalsLimit={2}
                    value={linha?.valordiabhbusvt ?? ""}
                    prefixo="R$ "
                    sx={handleStyleFieldError(beneficiosComissao.VALORDIABHBUSVT, fieldErros, null, index)}
                    disabled={!dadosBeneficioComissao?.boolrecebevt || buttonSave}
                    onValueChange={(newValue, name, values) => handleChangeLinhaDeOnibus(index)(
                      { target: { name, value: newValue } } as React.ChangeEvent<HTMLInputElement>
                    )}
                  />
                </G.FWStack>
                <G.FWStack direction={"row"}>
                  <G.FWStack direction={"column"} spacing={2} alignItems={"flex-start"}>
                    <TextField
                      fullWidth
                      value={linha?.linhaotimoida ?? ""}
                      name={beneficiosComissao.LINHAOTIMOIDA}
                      label="Linhas ÓTIMO ida"
                      onChange={handleChangeLinhaDeOnibus(index)}
                      disabled={!dadosBeneficioComissao?.boolrecebevt || buttonSave}
                      sx={handleStyleFieldError(beneficiosComissao.VALORDIABHBUSVT, fieldErros, null, index)}
                    />
                    <TextField
                      fullWidth
                      value={linha?.linhaotimovolta ?? ""}
                      name={beneficiosComissao.LINHAOTIMOVOLTA}
                      label="Linhas ÓTIMO volta"
                      onChange={handleChangeLinhaDeOnibus(index)}
                      disabled={!dadosBeneficioComissao?.boolrecebevt || buttonSave}
                      sx={handleStyleFieldError(beneficiosComissao.LINHAOTIMOVOLTA, fieldErros, null, index)}
                    />
                    <MaskCurrencyInput
                      name={beneficiosComissao.VALORDIAOTIMOVT}
                      label="Valor dia ÓTIMO"
                      placeholder="R$ 00,00"
                      defaultValue={0}
                      decimalsLimit={2}
                      value={linha?.valordiaotimovt ?? ""}
                      prefixo="R$ "
                      sx={handleStyleFieldError(beneficiosComissao.VALORDIAOTIMOVT, fieldErros, { width:"100%" }, index)}
                      disabled={!dadosBeneficioComissao?.boolrecebevt || buttonSave}
                      onValueChange={(newValue, name, values) => handleChangeLinhaDeOnibus(index)(
                        { target: { name, value: newValue } } as React.ChangeEvent<HTMLInputElement>
                      )}
                    />
                  </G.FWStack>
                </G.FWStack>
              </G.FWStack>
            </>
          ))}
          <G.FWStack style={{ display:"flex", flexDirection:"column", gap:10 }}>
            <TextField
                fullWidth
                value={formatValues(totalDiaVt) }
                disabled
              />
            <D.ContainedButton
              disabled={!dadosBeneficioComissao?.boolrecebevt || buttonSave}
              startIcon={<AddCircleIcon />}
              style={{marginTop: 'auto'}}
              onClick={addNewForm}>Adicionar linhas
            </D.ContainedButton>
          </G.FWStack>
        </G.FWStack>
      </G.FWStack>
      <C.FWStack direction={"row"} spacing={2} justifyContent={"flex-end"}>
          <D.ContainedButton disabled={!buttonSave} $color="yellow" onClick={sendOnUpdate}>Editar</D.ContainedButton>
          <D.ContainedButton disabled={buttonSave} onClick={sendOnSave}>Salvar</D.ContainedButton>
        </C.FWStack>
    </RegisterCard>
  );
};

export default BeneficiosComissao;
