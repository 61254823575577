import CloseIcon from "@mui/icons-material/Close";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Modal } from "@mui/material";
import { GridCellParams, GridColDef } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import Datatable from "../../../../components/Datatable";
import StatusCard from "../../../../components/StatusCard";
import TotalCard from "../../../../components/TotalCard";
import * as D from "../../../../styles/appComponents";
import * as C from "./style";
import * as services from "../../../../services/api/GDContratoClienteService";
import * as logServices from "../../../../services/api/LogService";
import {
  IGDContrato,
  IGDContratoPaged,
  IGDContratoStats,
} from "../../../../models/GDContrato";
import {
  EnumStatusContratoCliente,
  StatusContratoColorMap,
  StatusGDContratoNumberMap,
} from "../../../../enums/EnumStatusContratoCliente.enum";
import { moneyFormatter } from "../../../../utils/moneyFormatter";
import Swal from "sweetalert2";
import { AxiosError } from "axios";
import HistoricoCard from "../../../../components/HistoricoCard";
import { ICreateLog, ILog } from "../../../../models/Log";
import { useNavigate } from "react-router-dom";
import SkeletonDefaultPage from "../../../../components/SkeletonLoads/DefaultPage";

const ParceiroContratos: React.FC = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(true);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [openModalSeeMore, setOpenModalSeeMore] = useState(false);
  const [statusOpen, setStatusOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1024);
  const [rows, setRows] = useState<IGDContratoPaged[]>([]);
  const [modalContrato, setModalContrato] = useState<IGDContrato>({
    backoffice: "",
    celular: "",
    celularParceiro: "",
    cpf: "",
    cpfParceiro: "",
    createdAt: new Date(),
    dataAssinatura: new Date(),
    email: "",
    emailParceiro: "",
    id: "",
    idReferencial: 0,
    instalacao: "",
    nomeParceiro: "",
    nomeUsina: "",
    status: 0,
    titular: "",
    totalkWh: 0,
    updatedAt: new Date(),
    valor: 0,
  });

  //historico
  const [historico, setHistorico] = useState<ILog[]>([]);
  const [historicoAssunto, setHistoricoAssunto] = useState<string>("");
  const [historicoDescricao, setHistoricoDescricao] = useState<string>("");
  const [historicoAnexo, setHistoricoAnexo] = useState<File | null>(null);

  //historico
  const handleNewHistoricoChange = (
    assunto: string,
    descricao: string,
    anexo: File | null
  ) => {
    setHistoricoAssunto(assunto);
    setHistoricoDescricao(descricao);
    setHistoricoAnexo(anexo);
  };

  const createNewLog = async () => {
    if (historicoAssunto === "") {
      Swal.fire({
        position: "top-end",
        toast: true,
        icon: "warning",
        title: "Assunto não pode ser vazio.",
        showConfirmButton: false,
        showCloseButton: true,
        timer: 3000,
      });

      return;
    }

    const historicoData: ICreateLog = {
      gdLeadId: null,
      gdPropostaId: null,
      gdContratoId: modalContrato.id,
      assunto: historicoAssunto,
      descricao: historicoDescricao,
      anexo: historicoAnexo,
    };

    await logServices
      .CreateLog(historicoData)
      .then(() => {
        handleOpenModal(modalContrato.id);
        Swal.fire({
          position: "top-end",
          toast: true,
          icon: "success",
          title: "Criado com sucesso!",
          showConfirmButton: false,
          showCloseButton: true,
          timer: 3000,
        });
      })
      .catch((e: AxiosError) => {
        var errorMessage: string = e.response
          ? String(e.response?.data)
          : "Houve um erro ao pegar os dados.";

        Swal.fire({
          position: "top-end",
          toast: true,
          icon: "warning",
          title: errorMessage,
          showConfirmButton: false,
          showCloseButton: true,
          timer: 3000,
        });
      });
  };

  const getLogsByContratoId = async (id: string) => {
    await logServices
      .GetLogsByItemId(id)
      .then((response) => {
        setHistorico(response.data);
      })
      .catch((e: AxiosError) => {
        var errorMessage: string = e.response
          ? String(e.response?.data)
          : "Houve um erro ao pegar os dados.";

        Swal.fire({
          position: "top-end",
          toast: true,
          icon: "warning",
          title: errorMessage,
          showConfirmButton: false,
          showCloseButton: true,
          timer: 3000,
        });
      });
  };

  const handleCloseModalSeeMore = () => {
    setOpenModalSeeMore(false);
  };

  const handleStatusOpen = () => {
    setStatusOpen(!statusOpen);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1024);
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    getContratos();
  }, [page, pageSize, openModalSeeMore]);

  const handleSearch = (value: string) => {
    getContratos(value);
  };

  const handleChangePage = (value: number) => {
    setPage(value);
  };

  const handleChangePageSize = (value: number) => {
    setPageSize(value);
  };

  const handleOpenModal = async (contratoId: string) => {
    await services
      .GetContratoById(contratoId)
      .then(async (response) => {
        await setModalContrato(response.data);
        await getLogsByContratoId(contratoId);
        setOpenModalSeeMore(true);
      })
      .catch((e: AxiosError) => {
        var errorMessage: string = e.response
          ? String(e.response?.data)
          : "Houve um erro ao pegar os dados.";

        Swal.fire({
          position: "top-end",
          toast: true,
          icon: "warning",
          title: errorMessage,
          showConfirmButton: false,
          showCloseButton: true,
          timer: 3000,
        });
      });
  };

  const getContratos = async (search?: string) => {
    await getStats();

    await services
      .GetContratos(page, pageSize, search)
      .then((response) => {
        setRows(response.data.data);
        setTotalPages(response.data.totalPages);
        setLoading(false);
      })
      .catch((e: AxiosError) => {
        var errorMessage: string = e.response
          ? String(e.response?.data)
          : "Houve um erro ao pegar os dados.";

        Swal.fire({
          position: "top-end",
          toast: true,
          icon: "warning",
          title: errorMessage,
          showConfirmButton: false,
          showCloseButton: true,
          timer: 3000,
        });
      });
  };

  const getStats = () => {
    services
      .GetStats()
      .then((response: { data: React.SetStateAction<IGDContratoStats[]> }) => {
        setStatsDashboard(response.data);
      })
      .catch((e: AxiosError) => {
        var errorMessage: string = e.response
          ? String(e.response?.data)
          : "Houve um erro ao pegar os dados.";

        Swal.fire({
          position: "top-end",
          toast: true,
          icon: "warning",
          title: errorMessage,
          showConfirmButton: false,
          showCloseButton: true,
          timer: 3000,
        });
      });
  };

  const [statsDashboard, setStatsDashboard] = useState<IGDContratoStats[]>([
    {
      status: EnumStatusContratoCliente.aguardandoAssinatura,
      quantidade: 0,
      totalReais: 0,
      totalkWh: 0,
    },
    {
      status: EnumStatusContratoCliente.processoConexao,
      quantidade: 0,
      totalReais: 0,
      totalkWh: 0,
    },
    {
      status: EnumStatusContratoCliente.conectadoAOperadora,
      quantidade: 0,
      totalReais: 0,
      totalkWh: 0,
    },
    {
      status: EnumStatusContratoCliente.inadimplente,
      quantidade: 0,
      totalReais: 0,
      totalkWh: 0,
    },
    {
      status: EnumStatusContratoCliente.desconexaoSolicitada,
      quantidade: 0,
      totalReais: 0,
      totalkWh: 0,
    },
  ]);

  const columns: GridColDef[] = [
    {
      field: "idReferencial",
      headerName: "Id",
      renderCell: (params: GridCellParams<IGDContratoPaged>) => (
        <D.GridField
          onClick={() => navigate(`/Parceiro/Editar/Contrato/${params.row.id}`)}
        >
          <D.LinkClick
            style={{
              whiteSpace: "normal",
              fontSize: 14,
            }}
          >
            {params.row.idReferencial}
          </D.LinkClick>
        </D.GridField>
      ),
    },
    {
      field: "titular",
      headerName: "Titular",
      flex: 1,
      align: "center",
      renderCell: (params) => <D.GridField>{params.value}</D.GridField>,
    },
    {
      field: "instalacao",
      headerName: "Instalação",
      flex: 1,
      align: "center",
      renderCell: (params) => <D.GridField>{params.value}</D.GridField>,
    },
    {
      field: "nomeParceiro",
      headerName: "Parceiro",
      flex: 1,
      renderCell: (params) => <D.GridField>{params.value}</D.GridField>,
    },
    {
      field: "createdAt",
      headerName: "Data",
      flex: 1,
      renderCell: (params: GridCellParams<IGDContratoPaged>) => (
        <D.GridField onClick={() => {}}>
          {new Date(params.row.createdAt).toLocaleDateString()}
        </D.GridField>
      ),
    },
    {
      field: "status",
      headerName: "Status",
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <D.GridField $alignCenter>
          <D.Circle color={StatusContratoColorMap[params.value]} />
        </D.GridField>
      ),
    },
    {
      field: "outras",
      headerName: "Outras",
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params: GridCellParams<IGDContratoPaged>) => (
        <D.GridField $alignCenter>
          <D.DefaultGridButton onClick={() => handleOpenModal(params.row.id)}>
            Ver mais
          </D.DefaultGridButton>
        </D.GridField>
      ),
    },
  ];

  return (
    <>
      {loading ? (
        <SkeletonDefaultPage />
      ) : (
        <>
          <C.Container>
            <C.Title onClick={() => handleStatusOpen()}>
              Status
              {isMobile && (
                <>
                  {!statusOpen ? (
                    <KeyboardArrowDownRoundedIcon />
                  ) : (
                    <KeyboardArrowUpIcon />
                  )}
                </>
              )}
            </C.Title>

            {(!isMobile || (statusOpen && isMobile)) && (
              <C.StatusArea>
                <C.StatusWrapper>
                  <StatusCard
                    color="yellow"
                    text="Aguardando assinatura"
                    number={statsDashboard[0].quantidade}
                  />
                  <TotalCard
                    color="yellow"
                    title="Valor total de contratos"
                    text={`${moneyFormatter.format(
                      statsDashboard[0].totalReais
                    )}`}
                    evolutionNumber="-8%"
                    evolution={false}
                  />
                  <TotalCard
                    color="yellow"
                    title="Valor total de kwh"
                    text={`${statsDashboard[0].totalkWh} kwh`}
                    evolutionNumber="+8%"
                    evolution={false}
                  />
                </C.StatusWrapper>
                <C.StatusWrapper>
                  <StatusCard
                    color="blue"
                    text="Em processo de conexão"
                    number={statsDashboard[1].quantidade}
                  />
                  <TotalCard
                    color="blue"
                    title="Valor total de contratos"
                    text={`${moneyFormatter.format(
                      statsDashboard[1].totalReais
                    )}`}
                    evolutionNumber="-8%"
                    evolution={false}
                  />
                  <TotalCard
                    color="blue"
                    title="Valor total de kwh"
                    text={`${statsDashboard[1].totalkWh} kwh`}
                    evolutionNumber="+8%"
                    evolution={true}
                  />
                </C.StatusWrapper>
                <C.StatusWrapper>
                  <StatusCard
                    color="green"
                    text="Conectado a operadora"
                    number={statsDashboard[2].quantidade}
                  />
                  <TotalCard
                    color="green"
                    title="Valor total de contratos"
                    text={`${moneyFormatter.format(
                      statsDashboard[2].totalReais
                    )}`}
                    evolutionNumber="-8%"
                    evolution={false}
                  />
                  <TotalCard
                    color="green"
                    title="Valor total de kwh"
                    text={`${statsDashboard[2].totalkWh} kwh`}
                    evolutionNumber="+8%"
                    evolution={true}
                  />
                </C.StatusWrapper>
                <C.StatusWrapper>
                  <StatusCard
                    color="red"
                    text="Inadimplente"
                    number={statsDashboard[3].quantidade}
                  />
                  <TotalCard
                    color="red"
                    title="Valor total de contratos"
                    text={`${moneyFormatter.format(
                      statsDashboard[3].totalReais
                    )}`}
                    evolutionNumber="-8%"
                    evolution={false}
                  />
                  <TotalCard
                    color="red"
                    title="Valor total de kwh"
                    text={`${statsDashboard[3].totalkWh} kwh`}
                    evolutionNumber="+8%"
                    evolution={true}
                  />
                </C.StatusWrapper>
                <C.StatusWrapper>
                  <StatusCard
                    color="purple"
                    text="Desconexão solicitada"
                    number={statsDashboard[4].quantidade}
                  />
                  <TotalCard
                    color="purple"
                    title="Valor total de contratos"
                    text={`${moneyFormatter.format(
                      statsDashboard[4].totalReais
                    )}`}
                    evolutionNumber="-8%"
                    evolution={false}
                  />
                  <TotalCard
                    color="purple"
                    title="Valor total de kwh"
                    text={`${statsDashboard[4].totalkWh} kwh`}
                    evolutionNumber="+8%"
                    evolution={true}
                  />
                </C.StatusWrapper>
              </C.StatusArea>
            )}

            <D.DataArea $align="right">
              <Datatable
                columns={columns}
                rows={rows}
                pageNumber={page}
                pageSize={pageSize}
                onChangePage={(e) => handleChangePage(e)}
                onChangePageSize={(e) => handleChangePageSize(e)}
                totalPages={totalPages}
                onSearch={handleSearch}
              />
              <C.LineBreak />
            </D.DataArea>
          </C.Container>
          <Modal
            sx={{ width: "100%" }}
            open={openModalSeeMore}
            onClose={handleCloseModalSeeMore}
            aria-labelledby="modal-details"
            aria-describedby="modal-details"
          >
            <C.ModalContainer>
              <C.ModalArea>
                <C.ModalCard>
                  <C.ModalHeader>
                    <h2>
                      Contrato - {modalContrato.idReferencial}
                      {modalContrato.status && (
                        <C.ModalTextIcon>
                          <D.Circle
                            color={StatusContratoColorMap[modalContrato.status]}
                          />
                          <p>
                            {StatusGDContratoNumberMap[modalContrato.status]}
                          </p>
                        </C.ModalTextIcon>
                      )}
                    </h2>
                    <C.CloseButton
                      aria-label="close"
                      onClick={handleCloseModalSeeMore}
                    >
                      <CloseIcon />
                    </C.CloseButton>
                  </C.ModalHeader>
                  <C.ModalLine>
                    <C.ModalItem>
                      <label>Total de kwh:</label>
                      <p>{modalContrato.totalkWh} kwh</p>
                    </C.ModalItem>
                    <C.ModalItem>
                      <label>Valor:</label>
                      <p>R$ {modalContrato.valor}</p>
                    </C.ModalItem>
                    <C.ModalItem>
                      <label>Data de assinatura:</label>
                      <p>
                        {modalContrato.dataAssinatura
                          ? new Date(
                              modalContrato.dataAssinatura?.toString()
                            ).toLocaleDateString()
                          : "Não informado."}
                      </p>
                    </C.ModalItem>
                    <C.ModalItem>
                      <label>Backoffice:</label>
                      <p>{modalContrato.backoffice}</p>
                    </C.ModalItem>
                  </C.ModalLine>
                  <C.ModalLine>
                    <C.ModalItem>
                      <label>Titular:</label>
                      <p>{modalContrato.titular}</p>
                    </C.ModalItem>
                    <C.ModalItem>
                      <label>CPF:</label>
                      <p>{modalContrato.cpf}</p>
                    </C.ModalItem>
                    <C.ModalItem>
                      <label>Telefone:</label>
                      <p>{modalContrato.celular}</p>
                    </C.ModalItem>
                    <C.ModalItem>
                      <label>Email:</label>
                      <p>{modalContrato.email}</p>
                    </C.ModalItem>
                  </C.ModalLine>
                  <C.ModalLine>
                    <C.ModalItem>
                      <label>Parceiro:</label>
                      <p>{modalContrato.nomeParceiro}</p>
                    </C.ModalItem>
                    <C.ModalItem>
                      <label>CPF:</label>
                      <p>{modalContrato.cpfParceiro}</p>
                    </C.ModalItem>
                    <C.ModalItem>
                      <label>Telefone:</label>
                      <p>{modalContrato.celularParceiro}</p>
                    </C.ModalItem>
                    <C.ModalItem>
                      <label>Email:</label>
                      <p>{modalContrato.emailParceiro}</p>
                    </C.ModalItem>
                  </C.ModalLine>
                  <C.ModalLine>
                    <C.ModalItem>
                      <label>Nome da usina:</label>
                      <p>{modalContrato.nomeUsina}</p>
                    </C.ModalItem>
                    <C.ModalItem>
                      <label>Instalação:</label>
                      <p>{modalContrato.instalacao}</p>
                    </C.ModalItem>
                    <C.ModalItem></C.ModalItem>
                    <C.ModalItem></C.ModalItem>
                  </C.ModalLine>
                </C.ModalCard>
                <HistoricoCard
                  tableData={historico}
                  getNewHistoricData={handleNewHistoricoChange}
                  saveFunction={createNewLog}
                  reloadTable={() => handleOpenModal(modalContrato.id)}
                />
              </C.ModalArea>
            </C.ModalContainer>
          </Modal>
        </>
      )}
    </>
  );
};

export default ParceiroContratos;
