export const addOneDay = (dateToBeFormated: string | Date | null): string => {
  if (dateToBeFormated) {
    const date = new Date(dateToBeFormated ?? "");
    date.setDate(date.getDate() + 1);
    return date.toISOString().split("T")[0];
  } else return "";
};

type MascaraData = "YYYY-MM-DD" | "DD/MM/YYYY";
export const FormatDate = (
  date: Date,
  maskara: MascaraData = "YYYY-MM-DD"
): string => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // getMonth() retorna 0-11
  const day = String(date.getDate()).padStart(2, "0");

  if (maskara === "YYYY-MM-DD") return `${year}-${month}-${day}`;
  return `${day}/${month}/${year}`;
};

export const dateFormatter = (
  dateToBeFormated: string | Date | null
): string => {
  if (dateToBeFormated) {
    const date = new Date(dateToBeFormated);

    const formattedDate = `${String(date.getUTCDate()).padStart(
      2,
      "0"
    )}/${String(date.getUTCMonth() + 1).padStart(
      2,
      "0"
    )}/${date.getUTCFullYear()}`;

    return formattedDate;
  } else return "";
};
