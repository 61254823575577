import { TextField } from "@mui/material";
import RegisterCard from "../../../../../components/RegisterCard";
import * as D from "../../../../../styles/appComponents";
import { IResumoFaturaProposta } from "../../../../../models/GDProposta";
import {
  moneyFormatter,
  numberFormatter,
} from "../../../../../utils/moneyFormatter";

interface IResumoFaturaGerarPropostaProps {
  data: IResumoFaturaProposta;
}

const ResumoFaturaGerarProposta: React.FC<IResumoFaturaGerarPropostaProps> = ({
  data,
}) => {
  return (
    <RegisterCard title="Resumo da fatura após contratação">
      <D.FWStack direction={"row"} spacing={2}>
        <TextField
          label="Valor total distribuidar + cooperativa"
          required
          fullWidth
          value={
            data.valorTotal
              ? moneyFormatter.format(Number(data.valorTotal))
              : ""
          }
          inputProps={{ readOnly: true }}
        />
        <TextField
          label="Tarifa cooperativa"
          required
          fullWidth
          value={
            data.tarifaCooperativa
              ? moneyFormatter.format(Number(data.tarifaCooperativa))
              : ""
          }
          inputProps={{ readOnly: true }}
        />
        <TextField
          label="Economia mensal bruta"
          required
          fullWidth
          value={
            data.economiaMensalBruta
              ? moneyFormatter.format(Number(data.economiaMensalBruta))
              : ""
          }
          inputProps={{ readOnly: true }}
        />
        <TextField
          label="Economia mensal liquida"
          required
          fullWidth
          value={
            data.economiaMensalLiquida
              ? moneyFormatter.format(Number(data.economiaMensalLiquida))
              : ""
          }
          inputProps={{ readOnly: true }}
        />
        <TextField
          label="Economia anual"
          required
          fullWidth
          value={
            data.economiaAnual
              ? moneyFormatter.format(Number(data.economiaAnual))
              : ""
          }
          inputProps={{ readOnly: true }}
        />
      </D.FWStack>
      <D.FWStack direction={"row"} spacing={2}>
        <TextField
          label="Contas economizadas anualmente"
          required
          fullWidth
          value={
            data.contasEconomizadasAnualmente
              ? numberFormatter.format(
                  Number(data.contasEconomizadasAnualmente)
                )
              : ""
          }
          inputProps={{ readOnly: true }}
        />
        <D.FWStack></D.FWStack>
        <D.FWStack></D.FWStack>
        <D.FWStack></D.FWStack>
        <D.FWStack></D.FWStack>
      </D.FWStack>
    </RegisterCard>
  );
};

export default ResumoFaturaGerarProposta;
