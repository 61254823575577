import { ColorType } from "../types/ColorType";

export enum EnumStatusContratoCliente {
  aguardandoAssinatura = "Aguardando assinatura",
  processoConexao = "Processo de conexão",
  conectadoAOperadora = "Conectado a operadora",
  inadimplente = "Inadimplente",
  desconexaoSolicitada = "Deconexão solicitada",
  emAnalise = "Em análise",
  cancelado = "Cancelado",
  contratoAssinado = "Contrato assinado",
  medidorZerado = "Medidor zerado",
  processoJudicial = "Processo Judicial",
  saldoGeracaoEmExcesso = "Saldo de geração em excesso",
  aguardandoAssinaturaAditivo = "Aguardando assinatura de aditivo",
  aguardandoAssinaturaDistrato = "Aguardando assinatura de distrato",
  desconectado = "Desconectado",
}

export const StatusGDContratoNumberMap: {
  [key: number]: EnumStatusContratoCliente;
} = {
  0: EnumStatusContratoCliente.aguardandoAssinatura,
  1: EnumStatusContratoCliente.processoConexao,
  2: EnumStatusContratoCliente.conectadoAOperadora,
  3: EnumStatusContratoCliente.inadimplente,
  4: EnumStatusContratoCliente.desconexaoSolicitada,
  5: EnumStatusContratoCliente.emAnalise,
  6: EnumStatusContratoCliente.cancelado,
  7: EnumStatusContratoCliente.contratoAssinado,
  8: EnumStatusContratoCliente.medidorZerado,
  9: EnumStatusContratoCliente.processoJudicial,
  10: EnumStatusContratoCliente.saldoGeracaoEmExcesso,
  11: EnumStatusContratoCliente.aguardandoAssinaturaAditivo,
  12: EnumStatusContratoCliente.aguardandoAssinaturaDistrato,
  13: EnumStatusContratoCliente.desconectado,
};

export const EnumStatusGDContratoNumericMap = {
  [EnumStatusContratoCliente.aguardandoAssinatura]: 0,
  [EnumStatusContratoCliente.processoConexao]: 1,
  [EnumStatusContratoCliente.conectadoAOperadora]: 2,
  [EnumStatusContratoCliente.inadimplente]: 3,
  [EnumStatusContratoCliente.desconexaoSolicitada]: 4,
  [EnumStatusContratoCliente.emAnalise]: 5,
  [EnumStatusContratoCliente.cancelado]: 6,
  [EnumStatusContratoCliente.contratoAssinado]: 7,
  [EnumStatusContratoCliente.medidorZerado]: 8,
  [EnumStatusContratoCliente.processoJudicial]: 9,
  [EnumStatusContratoCliente.saldoGeracaoEmExcesso]: 10,
  [EnumStatusContratoCliente.aguardandoAssinaturaAditivo]: 11,
  [EnumStatusContratoCliente.aguardandoAssinaturaDistrato]: 12,
  [EnumStatusContratoCliente.desconectado]: 13,
};

export const StatusContratoColorMap: Record<number, ColorType> = {
  0: "yellow",
  1: "blue",
  2: "green",
  3: "red",
  4: "purple",
  5: "orange",
  6: "green",
  7: "green",
  8: "green",
  9: "green",
  10: "green",
  11: "green",
  12: "green",
  13: "green",
};
