import { Autocomplete, MenuItem, TextField } from "@mui/material";
import RegisterCard from "../../../../../components/RegisterCard";
import * as D from "../../../../../styles/appComponents";
import * as operadoraService from "../../../../../services/api/OperadoraService";
import {
  EnumTipoOperadora,
  EnumTipoOperadoraNumericMap,
} from "../../../../../enums/EnumTipoOperadora.enum";
import {
  EnumTipoConexao,
  EnumTipoConexaoNumericMap,
} from "../../../../../enums/EnumTipoConexao.enum";
import {
  EnumTipoClasse,
  EnumTipoClasseNumericMap,
} from "../../../../../enums/EnumTipoClasse.enum";
import { useEffect, useState } from "react";
import {
  IConexaoContaProposta,
  IGetCalculoParams,
  IGetCalculoResponse,
} from "../../../../../models/GDProposta";
import * as service from "../../../../../services/api/PropostaService";
import { AxiosError } from "axios";
import Swal from "sweetalert2";
import * as captadorService from "../../../../../services/api/CaptadorService";
import { useAuth } from "../../../../../hooks/useAuth";
import { IOperadoraListItem } from "../../../../../models/Operadora";

interface IConexaoContaGerarPropostaProps {
  onSendData: (data: IConexaoContaProposta) => void;
  sendCalculoProposta: (data: IGetCalculoResponse) => void;
}

const ConexaoContaGerarProposta: React.FC<IConexaoContaGerarPropostaProps> = ({
  onSendData,
  sendCalculoProposta,
}) => {
  const { user } = useAuth();
  const [operadora, setOperadora] = useState<string>("");
  const [conexao, setConexao] = useState<string>("");
  const [classe, setClasse] = useState<string>("");
  const [valorConta, setValorConta] = useState<string>("");
  const [consumoMensalkWh, setConsumoMensalkWh] = useState<string>("");
  const [tarifaDistribuidora, setTarifaDistribuidora] = useState<string>("");
  const [iluminacaoPublica, setIluminacaoPublica] = useState<string>("");
  const [numeroInstalacao, setNumeroInstalacao] = useState<string>("");
  const [desconto, setDesconto] = useState<string>("");
  const [tributosPisCofins, setTributosPisCofins] = useState<string>("3,69");

  const [operadoraList, setOperadoraList] = useState<IOperadoraListItem[]>([]);

  useEffect(() => {
    const data: IConexaoContaProposta = {
      operadoraId: operadora,
      conexao: conexao,
      classe: classe,
      valorConta: valorConta.replaceAll(",", "."),
      consumoMensalkWh,
      tarifaDistribuidora: tarifaDistribuidora.replaceAll(",", "."),
      iluminacaoPublica: iluminacaoPublica.replaceAll(",", "."),
      numeroInstalacao,
      valorDesconto: desconto,
      tributosPisCofins: tributosPisCofins.replaceAll(",", "."),
    };

    onSendData(data);
  }, [
    operadora,
    conexao,
    classe,
    valorConta,
    consumoMensalkWh,
    tarifaDistribuidora,
    iluminacaoPublica,
    numeroInstalacao,
    desconto,
    tributosPisCofins,
  ]);

  useEffect(() => {
    getValorDesconto();
    getOperadoras();
  }, []);

  const getValorDesconto = async () => {
    await captadorService
      .GetLimiteDesconto(user?.idCaptador ?? "")
      .then((response) => {
        setDesconto(response.data);
      })
      .catch((e: AxiosError) => {
        var errorMessage: string = e.response
          ? String(e.response?.data)
          : "Houve um erro ao pegar os dados.";

        Swal.fire({
          position: "top-end",
          toast: true,
          icon: "warning",
          title: errorMessage,
          showConfirmButton: false,
          showCloseButton: true,
          timer: 3000,
        });
      });
  };

  const getOperadoras = async () => {
    await operadoraService
      .GetOperadoras()
      .then((response) => {
        setOperadoraList(response.data);
      })
      .catch((e: AxiosError) => {
        var errorMessage: string = e.response
          ? String(e.response?.data)
          : "Houve um erro ao pegar os dados.";

        Swal.fire({
          position: "top-end",
          toast: true,
          icon: "warning",
          title: errorMessage,
          showConfirmButton: false,
          showCloseButton: true,
          timer: 3000,
        });
      });
  };

  const isEmptyOrNull = (
    value: string | number | null | undefined
  ): boolean => {
    return value === null || value === undefined || value === "";
  };

  const validateParams = (data: IGetCalculoParams): boolean => {
    if (
      isEmptyOrNull(data.iluminacaoPublica) ||
      isEmptyOrNull(data.consumoMensalkWh) ||
      isEmptyOrNull(data.percentualDesconto) ||
      isEmptyOrNull(data.pisConfins) ||
      isEmptyOrNull(data.tarifaDistribuidora) ||
      isEmptyOrNull(data.tipoConexao) ||
      isEmptyOrNull(data.valorConta) ||
      isEmptyOrNull(data.pisConfins)
    ) {
      return false;
    }
    return true;
  };

  const calcularProposta = () => {
    if (Number(desconto) < 20) {
      Swal.fire({
        position: "top-end",
        toast: true,
        icon: "warning",
        title: "O desconto não pode ser menor que 20%",
        showConfirmButton: false,
        showCloseButton: true,
        timer: 3000,
      });
      return;
    }

    var data: IGetCalculoParams = {
      iluminacaoPublica: iluminacaoPublica.replaceAll(",", "."),
      consumoMensalkWh: consumoMensalkWh,
      percentualDesconto: desconto,
      pisConfins: tributosPisCofins.replaceAll(",", "."),
      tarifaDistribuidora: tarifaDistribuidora.replaceAll(",", "."),
      tipoConexao: conexao,
      valorConta: valorConta.replaceAll(",", "."),
    };

    if (!validateParams(data)) {
      Swal.fire({
        position: "top-end",
        toast: true,
        icon: "warning",
        title: "Preencha todos os campos obrigatórios.",
        showConfirmButton: false,
        showCloseButton: true,
        timer: 3000,
      });
      return;
    }

    service
      .GetCalculoGDProposta(data)
      .then((response) => {
        sendCalculoProposta(response.data);
      })
      .catch((e: AxiosError) => {
        var errorMessage: string = e.response
          ? String(e.response?.data)
          : "Houve um erro ao pegar os dados.";

        Swal.fire({
          position: "top-end",
          toast: true,
          icon: "warning",
          title: errorMessage,
          showConfirmButton: false,
          showCloseButton: true,
          timer: 3000,
        });
      });
  };

  const handleChangeDesconto = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    const numericValue = Number(inputValue);

    if (inputValue === "" || (numericValue >= 0 && numericValue <= 30)) {
      setDesconto(inputValue);
    } else if (numericValue < 0) {
      setDesconto("0");
    } else if (numericValue > 30) {
      setDesconto("30");
    }
  };

  return (
    <RegisterCard title="Conexao/Conta">
      <D.FWStack direction={"row"} spacing={2}>
        <Autocomplete
          options={operadoraList}
          fullWidth
          noOptionsText="Nenhuma operadora encontrada"
          getOptionLabel={(option) => option.nome}
          renderInput={(params) => <TextField {...params} label="Operadora" />}
          defaultValue={null}
          onChange={(event, newValue) => {
            setOperadora(newValue ? newValue.id : "");
          }}
          isOptionEqualToValue={(option, value) => option.id === value.id}
        />
        <TextField
          select
          label="Conexão"
          fullWidth
          value={conexao}
          onChange={(e) => setConexao(e.target.value)}
        >
          {Object.values(EnumTipoConexao).map((value) => (
            <MenuItem key={value} value={EnumTipoConexaoNumericMap[value]}>
              {value}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          select
          label="Classe"
          fullWidth
          value={classe}
          onChange={(e) => setClasse(e.target.value)}
        >
          {Object.values(EnumTipoClasse).map((value) => (
            <MenuItem key={value} value={EnumTipoClasseNumericMap[value]}>
              {value}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          label="Valor da conta"
          type="number"
          required
          fullWidth
          value={valorConta}
          onChange={(e) => setValorConta(e.target.value)}
          InputProps={{
            startAdornment: valorConta ? (
              <div style={{ marginRight: "5px" }}>R$</div>
            ) : (
              ""
            ),
          }}
        />
        <TextField
          label="Consumo mensal"
          type="number"
          required
          fullWidth
          value={consumoMensalkWh}
          onChange={(e) => setConsumoMensalkWh(e.target.value)}
          InputProps={{
            endAdornment: consumoMensalkWh ? <>kWh</> : <></>,
          }}
        />
      </D.FWStack>
      <D.FWStack direction={"row"} spacing={2}>
        <TextField
          label="Tarifa distribuidora"
          type="number"
          required
          fullWidth
          value={tarifaDistribuidora}
          onChange={(e) => setTarifaDistribuidora(e.target.value)}
          InputProps={{
            startAdornment: tarifaDistribuidora ? (
              <div style={{ marginRight: "5px" }}>R$</div>
            ) : (
              ""
            ),
          }}
        />
        <TextField
          label="Iluminação pública"
          type="number"
          required
          fullWidth
          value={iluminacaoPublica}
          onChange={(e) => setIluminacaoPublica(e.target.value)}
          InputProps={{
            startAdornment: iluminacaoPublica ? (
              <div style={{ marginRight: "5px" }}>R$</div>
            ) : (
              ""
            ),
          }}
        />
        <TextField
          label="Número de instalação"
          type="number"
          required
          fullWidth
          value={numeroInstalacao}
          onChange={(e) => setNumeroInstalacao(e.target.value)}
        />
        <TextField
          type="number"
          label="Desconto"
          required
          fullWidth
          inputProps={{ min: 0, max: 30 }}
          value={desconto}
          onChange={handleChangeDesconto}
          InputProps={{
            endAdornment: desconto ? <>%</> : <></>,
          }}
        />
        <TextField
          label="Alíquota tributária ( pis + confins )"
          required
          fullWidth
          value={tributosPisCofins}
          onChange={(e) => setTributosPisCofins(e.target.value)}
          InputProps={{
            startAdornment: tributosPisCofins ? (
              <div style={{ marginRight: "5px" }}>R$</div>
            ) : (
              ""
            ),
          }}
        />
      </D.FWStack>
      <D.FWStack direction={"row"} justifyContent={"flex-end"}>
        <D.ContainedButton $color="lightpink" onClick={calcularProposta}>
          Calcular proposta
        </D.ContainedButton>
      </D.FWStack>
    </RegisterCard>
  );
};

export default ConexaoContaGerarProposta;
