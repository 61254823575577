import React from "react";
import * as C from "./style";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {Box, Fab} from "@mui/material";

interface RegisterCardProps {
  title: string;
  showExitButton?: boolean;
  callback?: () => void;
}

type RegisterCardPropsWithChildren = React.FC<
  React.PropsWithChildren<RegisterCardProps>
>;

const RegisterCard: RegisterCardPropsWithChildren = ({children, title, showExitButton, callback}) => {
  return (
    <C.Container>
      {
        showExitButton ? (
          <C.Header style={{display: "flex", flexDirection: "row", alignItems: "center", gap: 5}}>
            <Fab size="small" aria-label="add" sx={{backgroundColor: "#fff", boxShadow: "none"}} onClick={callback}>
              <ArrowBackIcon sx={{color: "#F36A7B"}}/>
            </Fab>
            <Box>
              <h2>{title}</h2>
            </Box>
          </C.Header>
        ) : (
          <C.Header>
            <Box>
              <h2>{title}</h2>
            </Box>
          </C.Header>
        )
      }
      <C.Content>{children}</C.Content>
    </C.Container>
  );
};

export default RegisterCard;
