import { IAnexoDTO } from "../../models/Anexo";
import { ICreateAssociado } from "../../models/Associado";
import {
  ICaptador,
  ICaptadorDadosContrato,
  ICaptadorListItem,
  ICaptadorResponsavelUpdateRequest,
  ICaptadorResponse,
} from "../../models/Captador";
import { IUpdateLogoReq } from "../../models/Logo";
import http from "../common/http-common";

export const GetCaptadores = () => {
  return http.get<ICaptadorListItem[]>(`/Captador/BuscaTodosCaptadores`);
};

export const GetById = (id: string) => {
  return http.get<ICaptadorResponse>(`/Captador/${id}`);
};

export const CreateAssociado = (createAssociado: ICreateAssociado) => {
  return http.post<ICaptador>(`/Captador/Associado`, createAssociado);
};

export const GetLimiteDesconto = (idCaptador: string | null) => {
  return http.get<string>(`/Captador/BuscaLimiteDesconto/${idCaptador}`);
};

export const UpdateAssociadoDadosContrato = (
  updateDadosContrato: ICaptadorDadosContrato
) => {
  return http.patch<boolean>(
    "/Captador/Associado/DadosContrato",
    updateDadosContrato
  );
};

export const UpdateResponsavelLegal = (
  req: ICaptadorResponsavelUpdateRequest
) => {
  return http.patch<boolean>("/Captador/Associado/ReponsavelLegal", req);
};

export const UpdateCaptadorLogo = async (data: IUpdateLogoReq) => {
  const formData = new FormData();
  formData.append("captadorId", data.captadorId);

  Object.entries(data.dicLogo).forEach(([formato, logoDetalhes]) => {
    if (logoDetalhes) {
      if ("arquivo" in logoDetalhes) {
        formData.append(`DicLogo[${formato}].arquivo`, logoDetalhes.arquivo);
        formData.append(
          `DicLogo[${formato}].descricao`,
          logoDetalhes.descricao
        );
        formData.append(
          `DicLogo[${formato}].usuarioAnexouId`,
          logoDetalhes.usuarioAnexouId
        );
      } else {
        formData.append(`DicLogo[${formato}].id`, logoDetalhes.id);
      }
    } else {
      formData.append(`DicLogo[${formato}]`, "");
    }
  });

  return http.patch<Record<string, IAnexoDTO | null>>(
    "/Captador/Logo",
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
};

export const GetLogo = (id: string) => {
  return http.get<Record<string, IAnexoDTO | null>>(`/Captador/Logo/${id}`);
};
