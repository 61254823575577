import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {
   Autocomplete,
 
  MenuItem,
 
  TextField,
  Typography,
 } from "@mui/material";
import {  GridColDef  } from "@mui/x-data-grid";
import { AxiosResponse } from "axios";
import React, { useEffect, useReducer, useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import ExcelIcon from "../../../../assets/Plataforma/excelIcon.svg";
import FolderIcon from "../../../../assets/Plataforma/folderIcon.svg";
import Datatable from "../../../../components/Datatable";
import SkeletonDefaultPage from "../../../../components/SkeletonLoads/DefaultPage";
import { EnumStatusGDProposta } from "../../../../enums/EnumStatusGDProposta.enum";
import { useAuth } from "../../../../hooks/useAuth";
import { ICaptadorListItem } from "../../../../models/Captador";
import { ICooperativaPaginated } from "../../../../models/Cooperativa";
import { IPaginatedList } from "../../../../models/PaginatedList";
import * as servicesBackOffice from "../../../../services/api/ColaboradorService";
import * as services from "../../../../services/api/CooperativaService";
import * as servicesParceiro from "../../../../services/api/CaptadorService";
import * as D from "../../../../styles/appComponents";
import * as C from "./style";
import { IBackofficeListItem } from "../../../../models/Colaborador/Colaborador";

export enum ReportActionType {
  SELECTEDSTATUSPROPOSTA = "SELECTEDSTATUSPROPOSTA",
  SELECTEDPARCEIRO = "SELECTEDPARCEIRO",
  SELECTEDBACKOFFICE = "SELECTEDBACKOFFICE",
  DATAINICIO = "DATAINICIO",
  DATAFIM = "DATAFIM",
}

export interface ICooperativaRelatoriosFilters {
  selectedstatusproposta: string | undefined;
  selectedparceiro: string | undefined;
  selectedbackoffice: string | undefined;
  datainicio: string | undefined;
  datafim: string | undefined;
}

const Cooperativa: React.FC = () => {
  const { user } = useAuth();
  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(true);
  const [editar, setEditar] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [openModalSeeMore, setOpenModalSeeMore] = useState(false);
  const [statusOpen, setStatusOpen] = useState(false);
  const [selectedId, setSelectedId] = React.useState<number>(0);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1024);
  const [rows, setRows] = useState<ICooperativaPaginated[]>([]);
  const [backOffice, setbackOffice] = useState<IBackofficeListItem[]>([]);

  const [selectedStatus, setSelectedStatus] = useState<string | undefined>();
  const [proposta, setProposta] = useState<ICaptadorListItem[]>([]);
  const [selectedParceiro, setSelectedParceiro] = useState();
  const [selectedBackoffice, setSelectedBackoffice] = useState<
    string | undefined
  >();
  const [selectedStatusProposta, setSelectedCaptador] = useState<
    string | undefined
  >();
  const [dataInicio, setDataInicio] = useState<string | undefined>();
  const [dataFim, setDataFim] = useState<string | undefined>();

  const columns: GridColDef[] = [
    {
      field: "idReferencial",
      headerName: "Id",
      flex: 1,
      renderCell: (params) => (
        <D.GridField>
          <Typography
            style={{
              whiteSpace: "normal",
              fontSize: 14,
            }}
          >
            {params.value}
          </Typography>
        </D.GridField>
      ),
    },
    {
      field: "nome",
      headerName: "Nome da cooperativa",
      flex: 1,
      align: "center",
      renderCell: (params) => (
        <D.GridField
          style={{
            display: "flex",
            justifyContent: "start",
          }}
        >
          <Typography
            style={{
              whiteSpace: "normal",
              fontSize: 14,
            }}
          >
            {params.value}
          </Typography>
        </D.GridField>
      ),
    },
    {
      field: "cnpj",
      headerName: "CNPJ",
      flex: 1,
      align: "center",
      renderCell: (params) => (
        <D.GridField>
          <Typography
            style={{
              whiteSpace: "normal",
              fontSize: 14,
            }}
          >
            {params.value}
          </Typography>
        </D.GridField>
      ),
    },
    {
      field: "nomeResponsavelOemig",
      headerName: "Nome responsável Ocemg",
      flex: 1,
      align: "center",
      renderCell: (params) => (
        <D.GridField $alignCenter>
          <Typography
            style={{
              whiteSpace: "normal",
              fontSize: 14,
            }}
          >
            {params.value}
          </Typography>
        </D.GridField>
      ),
    },
    {
      field: "celularResponsavelOemig",
      headerName: "Celular responsável Ocemg",
      flex: 1,
      align: "center",
      renderCell: (params) => (
        <D.GridField>
          <Typography
            style={{
              whiteSpace: "normal",
              fontSize: 14,
            }}
          >
            {params.value}
          </Typography>
        </D.GridField>
      ),
    },
    {
      field: "documentos",
      headerName: "Documentos",
      flex: 1,
      align: "right",
      headerAlign: "center",
      renderCell: (params) => (
        <D.GridField
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <D.GridImage src={FolderIcon} />
        </D.GridField>
      ),
    },
  ];

  const setDataForm = (
    state: ICooperativaRelatoriosFilters,
    action: {
      type: ReportActionType;
      payload: any;
    }
  ): ICooperativaRelatoriosFilters => {
    const { type, payload } = action;

    if (state.hasOwnProperty(type.toLowerCase())) {
      return { ...state, [type.toLowerCase()]: payload };
    }

    return state;
  };

  const getCooperativas: (queryString?: string) => Promise<void> = async (
    queryString?: string
  ): Promise<void> => {
    try {
      const response: AxiosResponse<IPaginatedList<ICooperativaPaginated>> =
        await services.GetAll(page, pageSize, queryString);

      setRows(response.data.data || []);
      setTotalPages(response.data.totalPages);

      setLoading(false);
    } catch (e: unknown) {
      console.error(e);
    }
  };

  const handleChangePage: (value: number) => void = (value: number): void => {
    setPage(value);
  };

  const handleChangePageSize: (value: number) => void = (
    value: number
  ): void => {
    setPageSize(value);
  };

  const getBackoffices: () => Promise<void> = async (): Promise<void> => {
    try {
      const response = await servicesBackOffice.GetBackoffices();

      if (Array.isArray(response.data)) {
        setbackOffice(response.data as IBackofficeListItem[]);
      } else {
        setbackOffice([]);
      }
    } catch (e: unknown) {
      console.error(e);
    }
  };

  const handleChangeMobile: (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => void = (event: React.ChangeEvent<unknown>, value: number): void => {};

  const geraRelatorio: () => Promise<void> = async (): Promise<void> => {
    var filters: ICooperativaRelatoriosFilters = {
      selectedstatusproposta: state.selectedstatusproposta,
      selectedparceiro: state.selectedparceiro,
      selectedbackoffice: state.selectedbackoffice,
      datainicio: state.datainicio,
      datafim: state.datafim,
    };

    try {
      const response: AxiosResponse<File, any> = await services.GeraRelatorio(
        filters
      );
      const blob: File = response.data;

      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = `cooperativa_${new Date()
        .toISOString()
        .slice(0, 19)
        .replace(/[-:T]/g, "")}.xlsx`;
      link.click();

      URL.revokeObjectURL(link.href);
    } catch (e) {
      var errorMessage: string = "Houve um erro ao pegar o relatório.";

      Swal.fire({
        position: "top-end",
        toast: true,
        icon: "warning",
        title: errorMessage,
        showConfirmButton: false,
        showCloseButton: true,
        timer: 3000,
      });
    }
  };

  const handleOpenNewCooperativa: () => void = (): void => {
    navigate("/Colaborador/Comercial/GD/Novo/Cooperativa");
  };

  const handleSeeMoreCooperativa: (id: number) => void = (
    id: number
  ): void => {};

  const getParceiros: () => Promise<void> = async (): Promise<void> => {
    try {
      const response = await servicesParceiro.GetCaptadores();
    } catch (e) {
      console.error(e);
    }
  };

  const handleSearch = (value: string) => {
    getCooperativas(value);
  };

  const [state, dispatch] = useReducer(setDataForm, {
    selectedstatusproposta: undefined,
    selectedparceiro: undefined,
    selectedbackoffice: undefined,
    datainicio: undefined,
    datafim: undefined,
  });

  useEffect((): void => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1024);
    };

    window.addEventListener("resize", function () {
      handleResize();
    });

    return window.removeEventListener("resize", handleResize);
  }, []);
  useEffect((): void => {
    getCooperativas(undefined);
    getBackoffices();
  }, [page, pageSize, openModalSeeMore]);

  return (
    <>
      {loading ? (
        <SkeletonDefaultPage />
      ) : (
        <C.Container>
          <C.Title>
            Cooperativa
            {isMobile && (
              <>
                {!statusOpen ? (
                  <KeyboardArrowDownRoundedIcon />
                ) : (
                  <KeyboardArrowUpIcon />
                )}
              </>
            )}
          </C.Title>
          <D.DataArea $align="right">
            <D.ContainedButton
              startIcon={<AddCircleOutlineOutlinedIcon />}
              onClick={handleOpenNewCooperativa}
            >
              Nova cooperativa
            </D.ContainedButton>
            <Datatable
              columns={columns}
              rows={rows}
              pageNumber={page}
              pageSize={pageSize}
              onChangePage={(e) => handleChangePage(e)}
              onChangePageSize={(e) => handleChangePageSize(e)}
              totalPages={totalPages}
              onSearch={handleSearch}
            />
            <C.LineBreak />

            <C.ReportsArea>
              <C.SelectArea>
                <C.ReportsLabel>Status de propostas</C.ReportsLabel>
                <C.ReportsSelect
                  displayEmpty
                  defaultValue=""
                  value={selectedStatus}
                  inputProps={{ "aria-label": "Without label" }}
                  onChange={(e) =>
                    dispatch({
                      type: ReportActionType.SELECTEDSTATUSPROPOSTA,
                      payload: e.target.value,
                    })
                  }
                >
                  <MenuItem value="">
                    <C.DefaultColorText>Todos as propostas</C.DefaultColorText>
                  </MenuItem>
                  {Object.keys(EnumStatusGDProposta).map((key, index) => (
                    <MenuItem key={key} value={index}>
                      {
                        EnumStatusGDProposta[
                          key as keyof typeof EnumStatusGDProposta
                        ]
                      }
                    </MenuItem>
                  ))}
                </C.ReportsSelect>
              </C.SelectArea>

              <C.SelectArea>
                <C.ReportsLabel>Selecione um parceiro</C.ReportsLabel>
                <C.ReportsSelect
                  onChange={(e) =>
                    dispatch({
                      type: ReportActionType.SELECTEDPARCEIRO,
                      payload: e.target.value,
                    })
                  }
                  defaultValue="0"
                >
                  <MenuItem value="0">
                    <C.DefaultColorText>Todos o parceiros</C.DefaultColorText>
                  </MenuItem>
                </C.ReportsSelect>
              </C.SelectArea>

              <C.SelectArea>
                <C.ReportsLabel>Selecione o backoffice</C.ReportsLabel>
                <Autocomplete
                  options={backOffice}
                  fullWidth
                  noOptionsText="Nenhum backoffice encontrado"
                  getOptionLabel={(option) => option.nome}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Todos os backoffices"
                      inputProps={{
                        ...params.inputProps,
                        "aria-label": "Without label",
                      }}
                    />
                  )}
                  defaultValue={null}
                  onChange={(e, newValue) =>
                    dispatch({
                      type: ReportActionType.SELECTEDBACKOFFICE,
                      payload: newValue?.id ?? null,
                    })
                  }
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                />
              </C.SelectArea>

              <C.SelectArea>
                <C.ReportsLabel>Data da movimentação</C.ReportsLabel>
                <D.DateTextfield
                  type="date"
                  label="Data início"
                  fullWidth
                  value={state.datainicio}
                  onChange={(e) =>
                    dispatch({
                      type: ReportActionType.DATAINICIO,
                      payload: e.target.value,
                    })
                  }
                />
              </C.SelectArea>

              <C.SelectArea>
                <C.ReportsLabel>Data da movimentação</C.ReportsLabel>
                <D.DateTextfield
                  type="date"
                  label="Data fim"
                  fullWidth
                  value={state.datafim}
                  onChange={(e) =>
                    dispatch({
                      type: ReportActionType.DATAFIM,
                      payload: e.target.value,
                    })
                  }
                />
              </C.SelectArea>
            </C.ReportsArea>
            <C.ExportButton
              onClick={geraRelatorio}
              variant="contained"
              startIcon={
                <img
                  style={{ width: "20px", color: "white", fill: "white" }}
                  src={ExcelIcon}
                  alt="excelIcon"
                />
              }
            >
              Gerar relatório
            </C.ExportButton>
          </D.DataArea>
        </C.Container>
      )}
    </>
  );
};

export default Cooperativa;
