import { IFormDadosPessoais } from "../../../../../../../models/Colaborador/cadastros/DadosPessoais";
import { AbstractValidation, Validate } from "../../../../../../../utils/AbstractValidation";

export class DadosPessoaisValidate extends AbstractValidation<IFormDadosPessoais>{
    getValidate(): Validate<IFormDadosPessoais>[] {
        return[
            {
                field: "nome",
                validate: (value, data, addError:(message:string) => void) => {
                    if(!value) addError("O Nome deve ser informado")
                }
            },
            {
                field: "nome",
                validate: (value, data, addError:(message:string) => void) => {
                    if(value?.length > 150) addError("O Nome não pode exceder 150 caracteres")
                }
            },
            {
                field: "datanascimento",
                validate: (value, data, addError:(message:string) => void) => {
                    if(!value) addError("A Data de nascimento deve ser informada")
                }
            },
            {
                field: "municipionascimento",
                validate: (value, data, addError:(message:string) => void) => {
                    if(!value) addError("O Municipio de nascimento deve ser informado")
                }
            },
            {
                field: "nacionalidade",
                validate: (value, data, addError:(message:string) => void) => {
                    if(!value) addError("A Nacionalidade deve ser informada")
                }
            },
            {
                field: "dataadmissao",
                validate: (value, data, addError:(message:string) => void) => {
                    if(!value) addError("A Data de admissão deve ser informada")
                }
            },
            {
                field: "cep",
                validate: (value, data, addError:(message:string) => void) => {
                    if(!value) addError("O CEP deve ser informado")
                }
            },
            {
                field: "uf",
                validate: (value, data, addError:(message:string) => void) => {
                    if(!value) addError("O Estado deve ser informado")
                }
            },
            {
                field: "cidade",
                validate: (value, data, addError:(message:string) => void) => {
                    if(!value) addError("A Cidade deve ser informado")
                }
            },
            {
                field: "bairro",
                validate: (value, data, addError:(message:string) => void) => {
                    if(!value) addError("O Bairro deve ser informado")
                }
            },
            {
                field: "logradouro",
                validate: (value, data, addError:(message:string) => void) => {
                    if(!value) addError("O Logradouro deve ser informado")
                }
            },
            {
                field: "numero",
                validate: (value, data, addError:(message:string) => void) => {
                    if(!value) addError("O Número deve ser informado")
                }
            }
        ]
    }
}