import { FC, ReactNode } from "react";
import RegisterCard from "../RegisterCard";
import { Stack } from "@mui/material";
import { ContainedButton } from "../../styles/appComponents";

interface IRegisterCardButtonProps {
  title: string;
  children?: ReactNode;
  onClickEditar?: () => void;
  onClickSalvar?: () => void;
  desativarBotaoEditar?: boolean;
  desativarBotaoSalvar?: boolean;
}

const RegisterCardButton: FC<IRegisterCardButtonProps> = ({
  title,
  children,
  onClickEditar,
  onClickSalvar,
  desativarBotaoEditar = false,
  desativarBotaoSalvar = false,
}) => {
  return (
    <RegisterCard title={title}>
      {children}
      <Stack direction="row" alignSelf="flex-end" spacing={2}>
        <ContainedButton
          $color="yellow"
          onClick={onClickEditar}
          disabled={desativarBotaoEditar}
        >
          Editar
        </ContainedButton>

        <ContainedButton
          $color="pink"
          onClick={onClickSalvar}
          disabled={desativarBotaoSalvar}
        >
          Salvar
        </ContainedButton>
      </Stack>
    </RegisterCard>
  );
};

export default RegisterCardButton;
