import React, { useEffect, useRef, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import * as C from "./style";
import { Avatar, Badge, IconButton } from "@mui/material";
import { useOutsideClick } from "../../hooks/useOutsideClick";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import NotificationsNoneRoundedIcon from "@mui/icons-material/NotificationsNoneRounded";
import HelpOutlineRoundedIcon from "@mui/icons-material/HelpOutlineRounded";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useAuth } from "../../hooks/useAuth";
const LayoutNav: React.FC = () => {
  const [avatarOpen, setAvatarOpen] = useState(false);
  const [helpOpen, setHelpOpen] = useState(false);
  const [notificationOpen, setNotificationOpen] = useState(false);
  const navigate = useNavigate();
  const { user } = useAuth();

  useEffect(() => {
    if (!user) {
      navigate("/");
    }
  }, [user]);

  const location = useLocation();
  const refs = useRef<{ [key: string]: React.RefObject<HTMLElement> }>({});

  const avatarButtonRef = useRef(null);
  const avatarRef = useOutsideClick(() => {
    setAvatarOpen(false);
  }, [avatarButtonRef]);

  const helpButtonRef = useRef(null);
  const helpRef = useOutsideClick(() => {
    setHelpOpen(false);
  }, [helpButtonRef]);

  const notificationButtonRef = useRef(null);
  const notificationRef = useOutsideClick(() => {
    setNotificationOpen(false);
  }, [notificationButtonRef]);

  useEffect(() => {
    const currentRef = refs.current[location.pathname];
    if (currentRef && currentRef.current) {
      currentRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [location]);

  const handleAvatarOpen = () => {
    setAvatarOpen(!avatarOpen);
  };

  const handleHelpOpen = () => {
    setHelpOpen(!helpOpen);
  };

  const handleNotificationOpen = () => {
    setNotificationOpen(!notificationOpen);
  };

  const redirectPage = () => {
    const url = window.location.pathname.split("/").slice(0, 3).join("/");

    if (url === "/Cadastro/Gerador" || url === "/Editar/Gerador")
      navigate("/Colaborador/Gerador");

    else if (url === "/Cadastro/Colaborador" || url === "/Editar/Colaborador")
      navigate("/Colaborador/Cadastro/GD/Colaborador");
    
    else navigate(-1);
  };

  return (
    <>
      <C.Container>
        <C.Navbar>
          <IconButton onClick={redirectPage}>
            <ArrowBackIcon />
          </IconButton>
          <C.Right>
            <C.DropdownContainer>
              <C.NavBadges
                ref={notificationButtonRef}
                onClick={() => handleNotificationOpen()}
              >
                <Badge badgeContent={5} color="success">
                  <NotificationsNoneRoundedIcon />
                </Badge>
              </C.NavBadges>
              {notificationOpen && (
                <C.NotificationOpen ref={notificationRef}>
                  <C.Notification>
                    <h3>Notificações</h3>
                  </C.Notification>
                  <C.Notification>
                    <C.NotificationLeft>
                      <p>
                        Luiza Parente Souza saiu do status aguardando envio de
                        conta para conta anexada
                      </p>
                      <span>Há 2 horas</span>
                    </C.NotificationLeft>
                    <C.NotificationRight>
                      <C.Circle color={0} />
                    </C.NotificationRight>
                  </C.Notification>
                  <C.Notification>
                    <C.NotificationLeft>
                      <p>Quatro novas leads</p>
                      <span>Há 2 horas</span>
                    </C.NotificationLeft>
                    <C.NotificationRight>
                      <C.Circle color={2} />
                    </C.NotificationRight>
                  </C.Notification>
                </C.NotificationOpen>
              )}
            </C.DropdownContainer>

            <C.DropdownContainer
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <C.NavBadges ref={helpButtonRef} onClick={() => handleHelpOpen()}>
                <Badge>
                  <HelpOutlineRoundedIcon />
                </Badge>
              </C.NavBadges>
              {helpOpen && (
                <C.DropdownOpen ref={helpRef}>
                  <h3>Seu backoffice</h3>
                  <Avatar sx={{ width: 56, height: 56 }}>RA</Avatar>
                  <C.NameWrapper>
                    <h4>Rebeca Assis</h4>
                    <p>(37) 984563123</p>
                  </C.NameWrapper>
                  <C.WppButton variant="contained" startIcon={<WhatsAppIcon />}>
                    Contate-nos
                  </C.WppButton>
                </C.DropdownOpen>
              )}
            </C.DropdownContainer>

            <C.DropdownContainer>
              <C.AvatarWrapper
                ref={avatarButtonRef}
                onClick={() => handleAvatarOpen()}
              >
                <Avatar>AW</Avatar>
                <C.NameWrapper>
                  <h4>Avatar Wattwise</h4>
                  <p>avatarwattwise@gmail.com</p>
                </C.NameWrapper>
                <KeyboardArrowDownRoundedIcon />
              </C.AvatarWrapper>
              {avatarOpen && (
                <C.DropdownOpen ref={avatarRef}>
                  <Avatar sx={{ width: 56, height: 56 }}>AW</Avatar>
                  <C.NameWrapper>
                    <h4>Avatar Wattwise</h4>
                    <p>avatarwattwise@gmail.com</p>
                  </C.NameWrapper>
                  <C.AvatarLine />
                  <C.AvatarLinksWrapper>
                    <C.AvatarLinks to="/Colaborador/Leads">
                      Ver meu perfil
                    </C.AvatarLinks>
                    <C.AvatarLinks to="/Colaborador/Leads">
                      Link do parceiro
                    </C.AvatarLinks>
                    <C.AvatarLinks to="/">Sair</C.AvatarLinks>
                  </C.AvatarLinksWrapper>
                </C.DropdownOpen>
              )}
            </C.DropdownContainer>
          </C.Right>
        </C.Navbar>
        <C.Wrapper>
          {(avatarOpen || helpOpen || notificationOpen) && <C.WrapperBlock />}
          <C.Content>
            <Outlet />
          </C.Content>
        </C.Wrapper>
      </C.Container>
    </>
  );
};

export default LayoutNav;
